import { createEntityAdapter, createSlice, PayloadAction, EntityId } from "@reduxjs/toolkit";

import { RootState } from "../Store";
import { resetState } from "../actions/reset-actions";
import { referralReasonsSliceProps } from "../../models/referral-reasons.model";
import { ReferralReasonsThunkApis } from "../actions/referral-reason-actions";
import { referralReasonsProps } from "../../models/patient.model";
import { OTHER_REFERRAL_REASON } from "../../constants/variable-constants";

const referralReasonsAdapter = createEntityAdapter<referralReasonsProps>({
    selectId: (reason) => {
        // Ensure ReferralReasonId is not null
        if (reason.ReferralReasonId === null) {
            throw new Error('ReferralReasonId cannot be null');
        }
        return reason.ReferralReasonId as EntityId;
    },
    sortComparer: (a, b) => {
        if (a.ReferralReasonId === null || b.ReferralReasonId === null) {
            return 0;
        }
        return b.ReferralReasonId! - a.ReferralReasonId!;
    },
});

const initialState = referralReasonsAdapter.getInitialState<referralReasonsSliceProps>({
    pageSize: 10,
    currentPage: 0,
    listLoading: false,
    totalReferralReasons: 0,
    searchTerm: null,
    referralReasonDetails: null,
    openReferralReasonDialog: false,
    filteredReferralReasons: [],
    forceEditMsg: null
});

const ReferralReasonsSlice = createSlice({
    name: 'referralReasons',
    initialState,
    reducers: {
        patchState(state, action: PayloadAction<Partial<referralReasonsSliceProps>>) {
            return { ...state, ...action.payload };
        },
        setInitialState(state) {
            return {
                ...state,
                pageSize: 10,
                currentPage: 0,
                listLoading: false,
                totalReferralReasons: 0,
                searchTerm: null,
                referralReasonDetails: null,
                openReferralReasonDialog: false,
                filteredReferralReasons: [],
                forceEditMsg: null
            }
        },
        removeReferralReason: (state, action: PayloadAction<{ id: number }>) => {
            referralReasonsAdapter.removeOne(state, action.payload.id);
        },
        updateReferralReason: (state, action: PayloadAction<{ id: number, name: string }>) => {
            const { id, name } = action.payload;

            referralReasonsAdapter.updateOne(state, {
                id,
                changes: { ReferralReasonName: name }
            });
        },
    },
    extraReducers: (builder) => {
        // To reset all the state variables
        builder.addCase(resetState, () => initialState);
        // getReferralreasons
        builder.addCase(ReferralReasonsThunkApis.getReferralreasons.pending, (state) => {
            state.listLoading = true;
        });
        builder.addCase(ReferralReasonsThunkApis.getReferralreasons.fulfilled, (state, action) => {
            state.listLoading = false;
            const referralReasons = action.payload.data ?? [];
            state.totalReferralReasons = referralReasons.length;

            const filteredReferralReasons = referralReasons.filter(
                reason => reason.ReferralReasonName !== OTHER_REFERRAL_REASON
            );

            referralReasonsAdapter.setAll(state, filteredReferralReasons);
        });
        builder.addCase(ReferralReasonsThunkApis.getReferralreasons.rejected, (state) => {
            state.listLoading = false;
        });
        // getReferralReasonLists
        builder.addCase(ReferralReasonsThunkApis.getReferralReasonLists.fulfilled, (state, action) => {
            // Filter out the "Other" referral reason
            const otherReferralReasons = action.payload.data?.filter(reason => reason.ReferralReasonName === OTHER_REFERRAL_REASON);
            // Filter out all other referral reasons except "Other"
            const remainingReferralReasons = action.payload.data?.filter(reason => reason.ReferralReasonName !== OTHER_REFERRAL_REASON);
            state.filteredReferralReasons = [...remainingReferralReasons, ...otherReferralReasons];
        });
    },
});

export const referralReasonsSelectors = referralReasonsAdapter.getSelectors<RootState>((state) => state.referralReasons);

export const ReferralReasonsSliceActions = ReferralReasonsSlice.actions;
export default ReferralReasonsSlice.reducer;