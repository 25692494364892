import { useAppSelector } from "../../redux/Hooks";

export const useFilterHasValue = () => {
    const { referralReasonId, referredOn, referredById, currentPatientStatusId, selectedHospitalId } = useAppSelector(state => state.patients) || {};
    const hasValue =
        (referralReasonId && referralReasonId.length > 0) ||
        referredOn !== null ||
        referredById !== null ||
        selectedHospitalId !== null ||
        (currentPatientStatusId && currentPatientStatusId.length > 0)

    return hasValue;
}