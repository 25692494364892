import { API } from "./api";
import { sessionStorageGet, sessionStorageRemove } from "./components/session-storage-helpers/session-storage-actions";
import { CANCELED, INVALID_ROLE, LOGGED_OUT, LOGOUT, SESSION_KEYS, USER_NOT_FOUND, httpStatusCode } from "./constants/variable-constants";
import { AuthThunkApis } from "./redux/actions/login-actions";
import { AuthSliceActions } from "./redux/reducers/login-slice";
import { UserSliceActions } from "./redux/reducers/user-slice";

const clearLoginState = (store: any) => {
    sessionStorageRemove(SESSION_KEYS.TOKEN);
    sessionStorageRemove(SESSION_KEYS.USER_ID);
    sessionStorageRemove(SESSION_KEYS.REDIRECT_URL);
    sessionStorageRemove(SESSION_KEYS.LAST_VISITED_PAGE);
    store.dispatch(AuthSliceActions.setIsLoggedIn({ isLoggedIn: false }));
    store.dispatch(UserSliceActions.patchState({ isUsersOpen: false }));
}

const handleUnauthorizedError = (error: any, store: any, userId: string | number, errorInterceptor: number) => {
    const { status, data } = error.response || error;
    const isLoginPath = window.location.hash === "#/" || window.location.hash === "";

    if (status === httpStatusCode.UN_AUTORIZED && !isLoginPath) {
        if (data?.isExpired) {
            let redirectPath = '';
            if (data.msg === USER_NOT_FOUND) {
                redirectPath = '#/user-removed';
            } else if (data.msg === INVALID_ROLE) {
                redirectPath = '#/role-changed';
            }

            if (redirectPath) {
                window.location.href = redirectPath;
            }
            clearLoginState(store);
            return Promise.reject(data);
        } else {
            API.interceptors.response.eject(errorInterceptor);
            return store.dispatch(AuthThunkApis.userLogout({ userId }))
                .unwrap()
                .catch(() => {
                    window.location.href = "#/";
                })
                .finally(() => {
                    clearLoginState(store);
                    interceptors(store); // Reattach the interceptors
                    throw new Error(LOGGED_OUT);
                });
        }
    } else if (status === httpStatusCode.FORBIDDEN && !isLoginPath) {
        window.location.href = '#/home/403';
    }

    return Promise.reject(error);
}

const interceptors = (store: any) => {
    const userId = sessionStorageGet(SESSION_KEYS.USER_ID);

    const errorInterceptor = API.interceptors.response.use(
        response => response,
        error => {
            if (error?.message === CANCELED) {
                return Promise.reject(error);
            }

            if (error?.request?.responseURL?.includes(LOGOUT)) {
                return Promise.resolve();
            }

            return handleUnauthorizedError(error, store, userId, errorInterceptor);
        }
    );

    API.interceptors.request.use(
        config => {
            config.headers['X-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );
};

export default interceptors;