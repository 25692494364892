import { INDEX_FOR_REFERRAL_REASONS } from "../constants/variable-constants";
import { ToastAlert } from "./toast-alert";

export const formatPhoneNumber = (inputValue: string) => {
    // Remove all non-digit characters
    const phoneNumber = inputValue.replace(/\D/g, '');

    // Format the phone number as (###) ###-####
    const formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

    return formattedPhoneNumber;
};

export const handleErrorMsg = (error: any) => {
    const errorMessage = error?.response?.data?.message || "Something went wrong!";
    ToastAlert(errorMessage, 'error');
}

export const convertToArray = (input: string) => {
    // Check if the input is already an array string (e.g., "[30,40,50]")
    if (input.startsWith("[") && input.endsWith("]")) {
        return JSON.parse(input);
    }

    // Otherwise, wrap the input in brackets to convert a single number string to an array
    return [Number(input)];
}

export const getPracticeNameId = (id: string) => {
    const practiceNameIds = convertToArray(id);
    return practiceNameIds.length > 0 ? practiceNameIds[0] : 0;
}

export const convertHTMLToText = (htmlContent: string) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    // Extract plain text
    const plainText = tempDiv.innerText;
    return plainText;
}

export const combinedReasonForReferral = (values: string[]) => {

    // Find the start index for "Reason for Referral"
    const startReasonIndex = INDEX_FOR_REFERRAL_REASONS; // Known fixed position for start
    const endReasonIndex = values.findIndex((value, index) => index > startReasonIndex && value.match(/^\d{1,2}\/\d{1,2}\/\d{4}$/));

    // Extract and combine the "Reason for Referral" elements
    const reasonForReferralParts = values.slice(startReasonIndex, endReasonIndex);
    const combinedReasonForReferral = reasonForReferralParts.join(', ').replace(/^"|"$/g, '').trim();

    // Reconstruct the array with the combined "Reason for Referral"
    const result = [
        values[0], // Name
        values[1], // Age
        values[2], // Doctor
        `${combinedReasonForReferral}`, // Combined "Reason for Referral" with parentheses
        values[endReasonIndex], // Referral Date
        values[endReasonIndex + 1], // Email
        values[endReasonIndex + 2]  // Phone Number
    ];
    return result;
}

// Truncate utility function
export const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
        return text;
    }
    return text.slice(0, maxLength) + '...';
};

export const uniqueEmailValidation = (values: any) => {
    const { toEmails, cc, bcc } = values;

    const emailGroups = [
        { field: 'toEmails', emails: toEmails },
        { field: 'cc', emails: cc },
        { field: 'bcc', emails: bcc },
    ];

    for (let i = 0; i < emailGroups.length; i++) {
        for (let j = i + 1; j < emailGroups.length; j++) {
            const duplicateEmail = emailGroups[i].emails.find((email: any) => emailGroups[j].emails.includes(email));
            if (duplicateEmail) {
                return {
                    field: emailGroups[j].field,
                    message: `Email ${duplicateEmail} is already used in another section.`
                };
            }
        }
    }

    return null;
};

// convert raw html to plain text
export const stripHtmlTags = (htmlString: string): string => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return (doc.body.textContent || "").replace(/\n/g, " ").trim();
};