import { createEntityAdapter, createSlice, PayloadAction, EntityId } from "@reduxjs/toolkit";

import { practiceSliceProps, practicesResponseParseProps } from "../../models/practice.model";
import { PracticeThunkApis } from "../actions/practice-actions";
import { RootState } from "../Store";
import { resetState } from "../actions/reset-actions";

const practiceAdapter = createEntityAdapter<practicesResponseParseProps>({
    selectId: (practice) => {
        // Ensure practiceId is not null
        if (practice.PracticeNameId === null) {
            throw new Error('practiceId cannot be null');
        }
        return practice.PracticeNameId as EntityId;
    },
    sortComparer: (a, b) => {
        if (a.PracticeNameId === null || b.PracticeNameId === null) {
            return 0;
        }
        return b.PracticeNameId! - a.PracticeNameId!;
    },
});

const initialState = practiceAdapter.getInitialState<practiceSliceProps>({
    pagesize: 10,
    currentPage: 0,
    listLoading: false,
    totalPractices: 0,
    deletePractice: null,
    searchTerm: null,
    viewPractice: null,
    doctorLists: [],
    doctorListsLoading: false,
    pageSizeForDoctors: 10,
    currentPageForDoctors: 0,
    doctorPractices: [],
    doctorStaffPractices: [],
    adminPractices: [],
    practiceLists: [],
    showDoctorStaffDialog: false,
    editedPracticeId: null
});

const PracticeSlice = createSlice({
    name: 'practices',
    initialState,
    reducers: {
        patchState(state, action: PayloadAction<Partial<practiceSliceProps>>) {
            return { ...state, ...action.payload };
        },
        setInitialState(state) {
            return {
                ...state,
                pagesize: 10,
                currentPage: 0,
                totalPractices: 0,
                deletePractice: null,
                searchTerm: null,
                viewPractice: null,
                doctorLists: [],
                pageSizeForDoctors: 10,
                currentPageForDoctors: 0
            }
        },
        removePractice: (state, action: PayloadAction<{ id: number }>) => {
            practiceAdapter.removeOne(state, action.payload.id);
        },
        updateOnePractice: (state, action: PayloadAction<{ id: number; changes: Partial<practicesResponseParseProps> }>) => {
            practiceAdapter.updateOne(state, { id: action.payload.id, changes: action.payload.changes });
        },
        addPractices: (state, action: PayloadAction<practicesResponseParseProps[]>) => {
            practiceAdapter.addMany(state, action.payload);
        },
        removeAssociatedPractices: (state, action: PayloadAction<{ id: number }>) => {
            state.adminPractices = state.adminPractices?.filter(practice => practice.PracticeNameId !== action.payload.id);
        }
    },
    extraReducers: (builder) => {
        // To reset all the state variables
        builder.addCase(resetState, () => initialState);
        // getAuthAllPractices
        builder.addCase(PracticeThunkApis.getAuthAllPractices.pending, (state) => {
            state.listLoading = true;
        });
        builder.addCase(PracticeThunkApis.getAuthAllPractices.fulfilled, (state, action) => {
            state.listLoading = false;
            state.totalPractices = action.payload.data.length || 0;
            practiceAdapter.setAll(state, action.payload.data || []);
        });
        builder.addCase(PracticeThunkApis.getAuthAllPractices.rejected, (state) => {
            state.listLoading = false;
        });
        // getAllPractices
        builder.addCase(PracticeThunkApis.getAllPractices.fulfilled, (state, action) => {
            state.practiceLists = action.payload.data || [];
        });
        // getAllDoctorsThroughPractice
        builder.addCase(PracticeThunkApis.getAllDoctorsThroughPractice.pending, (state) => {
            state.doctorListsLoading = true
        });
        builder.addCase(PracticeThunkApis.getAllDoctorsThroughPractice.fulfilled, (state, action) => {
            state.doctorListsLoading = false;

            const groupedData = action.payload.data?.reduce((acc, item) => {
                const key = `${item.FullName}-${item.EmailAddress}`;

                if (!acc[key]) {
                    acc[key] = {
                        FullName: item.FullName,
                        EmailAddress: item.EmailAddress,
                        OccupationName: item.OccupationName,
                        RoleName: item.RoleName,
                        SubcategoryName: item.SubcategoryName ? [item.SubcategoryName] : []
                    };
                } else if (item.SubcategoryName && !acc[key].SubcategoryName.includes(item.SubcategoryName)) {
                    acc[key].SubcategoryName.push(item.SubcategoryName);
                }

                return acc;
            }, {});

            state.doctorLists = Object.values(groupedData);
        });
        builder.addCase(PracticeThunkApis.getAllDoctorsThroughPractice.rejected, (state) => {
            state.doctorListsLoading = false
        });
        // getAllDoctorPractices
        builder.addCase(PracticeThunkApis.getAllDoctorPractices.fulfilled, (state, action) => {
            state.doctorPractices = action.payload.data || [];
        });
        // getAllDoctorStaffPractices
        builder.addCase(PracticeThunkApis.getAllDoctorStaffPractices.fulfilled, (state, action) => {
            state.doctorStaffPractices = action.payload.data || [];
        });
        // getAllAdminPractices
        builder.addCase(PracticeThunkApis.getAllAdminPractices.fulfilled, (state, action) => {
            state.adminPractices = action.payload.data || [];
        });
    },
});

export const practiceSelectors = practiceAdapter.getSelectors<RootState>((state) => state.practices);

export const PracticeSliceActions = PracticeSlice.actions;
export default PracticeSlice.reducer;