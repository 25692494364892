import React, { ChangeEvent } from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

interface SubCategoryInputProps {
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onRemove: () => void;
}

const SubCategoryInput: React.FC<SubCategoryInputProps> = ({ value, onChange, onRemove }) => {
    return (
        <Box display="flex" alignItems="center" gap={1} my={1} sx={{width: {md: '90%', sm: '90%', xs: '100%'}}}>
            <TextField
                variant="outlined"
                size="small"
                placeholder="Enter a sub-category name"
                value={value}
                onChange={onChange}
                fullWidth
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                        padding: '3px 5px',
                    },
                }}
            />
            <IconButton onClick={onRemove} className='categoryClose'>
                <CancelIcon color="primary" />
            </IconButton>
        </Box>
    );
};

export default SubCategoryInput;