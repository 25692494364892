import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../api";
import { filterUserListResponse, filterUserSearchParams, userBodyProps, userListsResponse, usersDetailResponse } from "../../models/user.model";
import { ResponseModel } from "../../models/patient.model";
import { FILTER_BY_USERS, GET_ALL_USERS, GET_USERS_SUPER_ADMIN, GET_USER_DETAILS } from "../../constants/api-constants";
import { ERROR_MESSAGE, USER_ROLES } from "../../constants/variable-constants";

const getUserLists = createAsyncThunk(
    'users/getUserLists',
    async ({ userBody, roleId }: { userBody: userBodyProps, roleId: number }, { rejectWithValue }) => {
        const url = roleId === USER_ROLES.SUPER_ADMIN ? GET_USERS_SUPER_ADMIN : GET_ALL_USERS;

        try {
            const response = await api.fetchAuthGet<userListsResponse>(url, userBody);
            return response.data;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return rejectWithValue(errorMessage);
        }
    }
);

const getFilterUserLists = createAsyncThunk(
    'users/getFilterUserLists',
    async ({ userBody }: { userBody: filterUserSearchParams }, { rejectWithValue }) => {
        try {
            const response = await api.fetchAuthPostWithBody<filterUserListResponse>(FILTER_BY_USERS, userBody);
            return response.data;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return rejectWithValue(errorMessage);
        }
    }
);

const getUserDetails = createAsyncThunk(
    'users/getUserDetails',
    async (payload: { userId: string }, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<usersDetailResponse>>(GET_USER_DETAILS(payload?.userId));
            return response.data
        } catch (error: any) {
            let errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

export const UserThunkApis = { getUserLists, getUserDetails, getFilterUserLists }