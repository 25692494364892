import React, { useState } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { useAppDispatch } from "../../../redux/Hooks";
import { useDebounce } from "../../../components/custom-hook/useDebounce";
import { DoctorSliceActions } from "../../../redux/reducers/doctor-slice";

import DoctorListsStyles from "../DoctorLists.module.css";

const DoctorListsHeader = () => {
    const dispatch = useAppDispatch();

    const [searchTerm, setSearchTerm] = useState<string>("");

    const handleDebouncedSearch = useDebounce((term: string) => {
        dispatch(DoctorSliceActions.patchState({ searchTerm: term }));
    }, 2000);

    const handleSearchTerm = (term: string) => {
        setSearchTerm(term);
        dispatch(DoctorSliceActions.patchState({ currentPage: 0, pagesize: 10 }));
        handleDebouncedSearch(term);
    };

    const clearSearchTerm = () => {
        setSearchTerm("");
        dispatch(DoctorSliceActions.patchState({ searchTerm: null }));
    }

    return (
        <Box className={DoctorListsStyles.search_panal}>
            <TextField
                value={searchTerm}
                onChange={(e) => handleSearchTerm(e.target.value)}
                sx={{ minWidth: '200px' }}
                variant="standard"
                placeholder='Search Doctors'
                size='small'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <SearchIcon />
                        </InputAdornment>),
                    endAdornment: (
                        <InputAdornment position="end">
                            <CloseIcon sx={{ display: searchTerm ? "inline-block" : "none", fontSize: "22px" }} className={DoctorListsStyles.closeIcon} onClick={clearSearchTerm} />
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    );
}

export default DoctorListsHeader