import React, { useEffect, useMemo, useState } from "react";
import {
    Box, CircularProgress, InputAdornment, SelectChangeEvent, Table, TableBody, TableCell,
    TableHead, TableRow, TextField, Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

import AvantTabs from "../../components/tabs/Tabs";
import AvantTab from "../../components/tabs/Tab";
import { DELETE_MAIL_FAILED, INITIAL_MAIL_LIST_SCREEN, MAIL_LIST_SCREENS, httpStatusCode } from "../../constants/variable-constants";
import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import { MailTunkApis } from "../../redux/actions/mail-actions";
import ComponentLoader from "../../components/ComponentLoader";
import { MailSliceActions, mailSelectors } from "../../redux/reducers/mail-slice";
import AvantPaginationWithPageSize from "../../components/pagination/PaginationWithPageSize";
import { AvantTooltip } from "../../components/tool-tip/Tooltip";
import { handleErrorMsg, stripHtmlTags, truncateText } from "../../components/utils";
import api from "../../api";
import { ResponseModel } from "../../models/patient.model";
import { DELETE_MAIL } from "../../constants/api-constants";
import { ToastAlert } from "../../components/toast-alert";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import EmailTemplateDialog from "../users/components/EmailTemplateDialog";

import MailListStyles from "./MailList.module.css";

dayjs.extend(utc);

const MailList = () => {
    const [screening, setScreening] = useState<string>(INITIAL_MAIL_LIST_SCREEN);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [isDeleting, setDeleting] = useState<boolean>(false);
    const [deleteMailId, setDeleteMailId] = useState<number | null>(null);
    const [viewMailId, setViewMailId] = useState<number | null>(null);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { listLoading, pageSize, currentPage, totalMails } = useAppSelector(state => state.mails);
    const mailsData = useAppSelector(mailSelectors.selectAll);

    const [expandedCellForMessage, setExpandedCellForMessage] = useState<Set<number>>(new Set());

    const filteredMails = useMemo(() => {
        return searchTerm
            ? mailsData?.filter(mail => mail?.Subject?.toLowerCase()?.includes(searchTerm.toLowerCase()))
            : mailsData;
    }, [mailsData, searchTerm]);

    const paginatedMails = useMemo(() => {
        const startIndex = currentPage * pageSize;
        const endIndex = startIndex + pageSize;
        return filteredMails.slice(startIndex, endIndex);
    }, [filteredMails, currentPage, pageSize]);

    const resultPages = useMemo(() => Math.ceil(filteredMails.length / pageSize), [filteredMails, pageSize]);

    const handleSearchTerm = (term: string) => {
        setSearchTerm(term);
        dispatch(MailSliceActions.patchState({ currentPage: 0, pageSize: 10 }));
    };

    const clearSearchTerm = () => {
        setSearchTerm("");
    }

    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
        const size = event.target.value as any;
        dispatch(MailSliceActions.patchState({ currentPage: 0, pageSize: size }));
    }

    const handlePageChange = (e: any, val: number) => {
        dispatch(MailSliceActions.patchState({ currentPage: val - 1 }));
    };

    const handleToggleCellForMessage = (index: number) => {
        setExpandedCellForMessage((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(index)) {
                newSet.delete(index);
            } else {
                newSet.add(index);
            }
            return newSet;
        });
    };

    useEffect(() => {
        dispatch(MailTunkApis.getMailLists({ status: INITIAL_MAIL_LIST_SCREEN }));
    }, []);

    const handleNavigate = () => {
        navigate('/home/user_lists');
    }

    const handleTabChange = (event: React.SyntheticEvent, tabValue: string) => {
        setScreening(tabValue);
        setSearchTerm("");
        dispatch(MailTunkApis.getMailLists({ status: tabValue }));
    }

    const handleDeleteMail = async () => {
        setDeleting(true);
        try {
            const deleteRes = await api.deleteAuth<ResponseModel<{}>>(DELETE_MAIL(deleteMailId!));
            if (deleteRes?.status === httpStatusCode.SUCCESS) {
                ToastAlert(deleteRes?.data?.message!, 'success');
                dispatch(MailSliceActions.removeMail({ id: deleteMailId! }));
                setDeleteMailId(null);
            } else {
                throw new Error(DELETE_MAIL_FAILED);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setDeleting(false);
        }
    }

    useEffect(() => {
        return () => {
            dispatch(MailSliceActions.setInitialState());
        }
    }, []);

    return (
        <Box className={MailListStyles.container}>
            <Box className={MailListStyles.search_panal}>
                <Box className={MailListStyles.panel_container}>
                    <Box className="flexCenterStart pointer" sx={{ width: "200px" }} onClick={handleNavigate}>
                        <img src="images/back.svg" className={MailListStyles.backImg} />
                        <Typography ml={2} className={MailListStyles.panal_header}>View Mails</Typography>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: {md: 'space-between', xs: 'center'}, flexDirection: {md: 'row', xs: 'column'}, gap: {md: '0px', xs: '15px'}, margin: {md: '15px', sm: '15px', xs: '5px 5px 15px 5px'}}}>
                    <AvantTabs
                        value={screening}
                        onChange={handleTabChange}
                        textColor="primary"
                        indicatorColor="primary"
                        aria-label="primary tabs example"
                    >
                        {MAIL_LIST_SCREENS?.map((screen: { value: string, label: string }, index: number) => (
                            <AvantTab
                                key={index}
                                value={screen.value}
                                label={screen.label}
                            />
                        ))}
                    </AvantTabs>
                    <TextField
                        value={searchTerm}
                        onChange={(e) => handleSearchTerm(e.target.value)}
                        sx={{ minWidth: {md: '200px', sm: '400px', xs: '100%'} }}
                        variant="standard"
                        placeholder='Search Mails'
                        size='small'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CloseIcon sx={{ display: searchTerm ? "inline-block" : "none", fontSize: "22px" }} className={MailListStyles.closeIcon} onClick={clearSearchTerm} />
                                </InputAdornment>
                            )
                        }}
                    />
                </Box>
                <Box ml={3} mt={2} className="MailmobileSpaceing">
                    {!paginatedMails?.length && listLoading ?
                        <ComponentLoader /> :
                        <Box display="flex" flexDirection="column">
                            <Box className={MailListStyles.mailTableBox}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" width={"30%"}>
                                                <Typography fontWeight={500} color={'primary'}>Subject</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography fontWeight={500} color={'primary'}>Message</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography fontWeight={500} color={'primary'}>Attachments</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography fontWeight={500} color={'primary'}>Status</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography fontWeight={500} color={'primary'}>Date</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography fontWeight={500} color={'primary'}>Action</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paginatedMails.length ? (
                                            paginatedMails?.map((mail, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="left" width={"30%"}>
                                                        <Typography component={"div"} onClick={() => setViewMailId(mail.SentEmailId!)} className={MailListStyles.link}>
                                                            {mail.Subject || "(no subject)"}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography className={MailListStyles.label}>
                                                            {expandedCellForMessage.has(index)
                                                                ? stripHtmlTags(mail.Message || "")
                                                                : truncateText(stripHtmlTags(mail.Message || "") || "", 20)}
                                                            {mail.Message!.length > 20 && (
                                                                <Typography
                                                                    component="span"
                                                                    className={MailListStyles.toggleText}
                                                                    onClick={() => handleToggleCellForMessage(index)}
                                                                    sx={{marginLeft: '5px'}}
                                                                >
                                                                    {expandedCellForMessage.has(index) ? "Less" : "More"}
                                                                </Typography>
                                                            )}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography className={MailListStyles.label}>{mail.Attachments ? JSON.parse(mail.Attachments).length : 0}</Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography className={MailListStyles.label}>{mail.Status || "-"}</Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography className={MailListStyles.label} sx={{whiteSpace: 'nowrap'}}>{dayjs.utc(mail.CreatedOn).format('YYYY-MM-DD') || "-"}</Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <AvantTooltip title="Delete" placement="top" arrow followCursor>
                                                            <img src="images/delete.svg" alt="Delete_Image" className={MailListStyles.editImg} onClick={() => setDeleteMailId(mail.SentEmailId!)} />
                                                        </AvantTooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))) :
                                            <TableRow>
                                                <TableCell colSpan={6}>
                                                    <Typography variant="h6" align="center">
                                                        No Mails Found
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </Box>
                            <Box display="grid" gridAutoFlow="column" alignItems="center" justifyContent="flex-end" marginTop={3} className="mailPaginatonOption">
                                {listLoading && (
                                    <Box mr={2} className="flexCenterCenter">
                                        <CircularProgress size={25} />
                                    </Box>
                                )}
                                <AvantPaginationWithPageSize
                                    pageSize={pageSize}
                                    handlePageSizeChange={handlePageSizeChange}
                                    page={currentPage}
                                    totalPages={resultPages}
                                    disabled={listLoading || !totalMails}
                                    handlePageChange={handlePageChange}
                                />
                            </Box>
                        </Box>}
                </Box>
            </Box>
            <ConfirmationDialog
                open={!!deleteMailId}
                accept={handleDeleteMail}
                reject={() => setDeleteMailId(null)}
                msg={`Are you sure you want to delete this mail?`}
                acceptText={"Yes"}
                rejectText={"No"}
                isLoading={isDeleting}
            />
            {viewMailId ? <EmailTemplateDialog viewMailId={viewMailId!} screening={screening} closeMailDialog={() => setViewMailId(null)} /> : null}
        </Box>
    );
}

export default MailList;