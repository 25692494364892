import React from "react";
import {
  Box,
  CircularProgress,
  Dialog, DialogContent,
  DialogTitle, IconButton, Typography
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';

import UpdatePatientStyles from "../UpdatePatient.module.css";

interface imageViewProps {
  apiImage: string | null,
  setViewImageModal: Function,
  setApiImage: Function
}

const ViewImageDialog = ({ apiImage, setViewImageModal, setApiImage }: imageViewProps) => {

  const handleClose = () => {
    setViewImageModal(false);
    setApiImage(null);
  };

  const handleDownloadImage = () => {
    const link = document.createElement('a');
    link.href = apiImage!;
    link.target = '_blank';
    link.download = "image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePrintImage = () => {
    const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin?.document.open();
    popupWin?.document.write(`
              <html>
                <head>
                  <title>Avant</title>
                  <style>
                    @media print {
                      body, html {
                        margin: 0;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      }
                    }
                  </style>
                </head>
                <body onload="window.print();window.close()">
                  <div align='center'>
                    <img style='width:auto;height: calc(100vh - 16px)' alt="Loading..." src="${apiImage}" />
                  </div>
                </body>
              </html>
            `);
    popupWin?.document.close();
  }

  const handleFullScreen = () => {
    const image = new Image();
    image.src = apiImage!;
    const newWindow = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    newWindow?.document.write(`
      <html>
        <head>
          <title>Full Screen Image</title>
          <style>
            body {
              margin: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;
              background-color: black;
            }
            img {
              max-width: 100%;
              max-height: 100%;
            }
          </style>
        </head>
        <body>
          <img src="${apiImage}" alt="Full Screen Image" />
        </body>
      </html>
    `);
    newWindow?.document.close();
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth maxWidth={"sm"}
        open={true}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Box className="flexCenterStart">
            <Typography sx={{ color: '#818080', fontSize: '1.5rem' }}>
              View Image
            </Typography>
            <Box className="flexCenterCenter" gap={2}>
              <IconButton title="Download" onClick={handleDownloadImage} disabled={!apiImage}>
                <FileDownloadOutlinedIcon sx={{ fontSize: "30px" }} />
              </IconButton>
              <IconButton title="Print" onClick={handlePrintImage} disabled={!apiImage}>
                <PrintOutlinedIcon sx={{ fontSize: "30px" }} />
              </IconButton>
              <IconButton title="Fullscreen" onClick={handleFullScreen} disabled={!apiImage}>
                <FullscreenOutlinedIcon sx={{ fontSize: "30px" }} />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CancelIcon color={'primary'} />
        </IconButton>
        <DialogContent>
          <Box className={UpdatePatientStyles.viewImagePanel} id="print-section">
            {!apiImage ? <CircularProgress size={25} /> :
              <img src={apiImage!} alt="View image..." className={UpdatePatientStyles.viewImageContainer} />}
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default ViewImageDialog