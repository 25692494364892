import React, { useEffect } from "react";
import { Box, IconButton, Typography, Collapse } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import CategoryIcon from '@mui/icons-material/Category';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import { DOCTOR_STAFF_ROLE, USERLIST_STATUS, USER_LIST, USER_ROLES } from "../../constants/variable-constants";
import { UserSliceActions } from "../../redux/reducers/user-slice";
import { SIDEBAR } from "../../constants/color-constants";

import "./Sidebar.css";

const MySidebar = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { roleId } = useAppSelector(state => state.auth.userDetails! || {});
    const { isUsersOpen, activeUserList } = useAppSelector(state => state.users! || {});
    const { isMenuOpen } = useAppSelector(state => state.users);

    const handleNavigate = () => {
        navigate('profile');
    }

    const toggleUsers = () => {
        dispatch(UserSliceActions.patchState({ isUsersOpen: !isUsersOpen }));
    }

    const handleUserLists = (id: number, list: string) => {
        const assignedList = {
            approved: list === USERLIST_STATUS.APPROVED,
            waiting: list === USERLIST_STATUS.WAITING,
            rejected: list === USERLIST_STATUS.REJECTED
        };
        navigate('/home/user_lists');
        dispatch(UserSliceActions.setInitialState());
        dispatch(UserSliceActions.patchState({ activeUserList: assignedList, userListId: id, selectedUserMails: [] }));
        dispatch(UserSliceActions.patchState({ isMenuOpen: false }));
    }

    const handleDefaultUserList = () => {
        const assignedDefaultList = {
            approved: true,
            waiting: false,
            rejected: false
        };
        dispatch(UserSliceActions.setInitialState());
        dispatch(UserSliceActions.patchState({ activeUserList: assignedDefaultList, isUsersOpen: false, userListId: USER_LIST.approved }));
        dispatch(UserSliceActions.patchState({ isMenuOpen: false }));
    }

    const toggleMenu = (type: boolean) => {
        dispatch(UserSliceActions.patchState({ isMenuOpen: !type }));
    };

    useEffect(() => {
        return () => {
            dispatch(UserSliceActions.patchState({ userListId: USER_LIST.approved }));
        }
    }, []);

    return (
        <Box className={`sidebar-container ${isMenuOpen ? 'boxClosed' : 'boxOpen'}`}>
            <Box className="header_logo">
                <Box className="logoWrap">
                    <img src={"images/logo.svg"} alt="Avant_logo-1" className="avant_first_logo" />
                    <img src={"images/logo2.png"} alt="Avant_logo-2" className="avant_second_logo" />
                </Box>
                <Box className="mobileMenu">
                    <img src={"images/close-white.svg"} alt="Avant_menu" onClick={() => toggleMenu(isMenuOpen)} />
                </Box>
            </Box>
            <Box className={roleId !== USER_ROLES.SUPER_ADMIN ? "account" : "accountForSuperAdmin"} onClick={() => roleId !== USER_ROLES.SUPER_ADMIN ? handleNavigate() : undefined}>
                MY ACCOUNT
            </Box>
            <Box className="link">
                {Object.values(USER_ROLES).includes(roleId!) &&
                    <NavLink to={"dashboard"} className="textDecoration">
                        {({ isActive }) => (
                            <Box className={"patients_active_container"} onClick={handleDefaultUserList}>
                                <IconButton>
                                    <DashboardCustomizeOutlinedIcon sx={{ color: isActive ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }} />
                                </IconButton>
                                <Typography sx={{ color: isActive ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }}>DASHBOARD</Typography>
                            </Box>
                        )}
                    </NavLink>
                }

                <NavLink to={"patient_lists"} className="textDecoration">
                    {({ isActive }) => (
                        <Box className={"patients_active_container"} onClick={handleDefaultUserList}>
                            <IconButton>
                                <LocalHospitalOutlinedIcon sx={{ color: isActive ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }} />
                            </IconButton>
                            <Typography sx={{ color: isActive ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }}>PATIENTS</Typography>
                        </Box>
                    )}
                </NavLink>

                {Object.values(USER_ROLES).includes(roleId!) &&
                    <>
                        <NavLink to={"user_lists"} className="textDecoration">
                            {({ isActive }) => (
                                <Box className={"patients_active_container"} onClick={toggleUsers}>
                                    <IconButton>
                                        <PeopleOutlineOutlinedIcon sx={{ color: isActive ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }} />
                                    </IconButton>
                                    <Typography sx={{ color: isActive ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }}>USERS</Typography>
                                    <IconButton>
                                        {isUsersOpen ? <ExpandLessIcon sx={{ color: isActive ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }} /> : <ExpandMoreIcon sx={{ color: isActive ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }} />}
                                    </IconButton>
                                </Box>
                            )}
                        </NavLink>
                        <Collapse in={isUsersOpen} timeout="auto" unmountOnExit>
                            <Box>
                                <Box className={"users_active_container"} onClick={() => handleUserLists(USER_LIST.approved, USERLIST_STATUS.APPROVED)}>
                                    <IconButton>
                                        <CheckOutlinedIcon sx={{ color: !!activeUserList["approved"] ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }} />
                                    </IconButton>
                                    <Typography sx={{ color: !!activeUserList["approved"] ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE, textTransform: "capitalize" }}>Approved Users</Typography>
                                </Box>
                                <Box className={"users_active_container"} onClick={() => handleUserLists(USER_LIST.waiting, USERLIST_STATUS.WAITING)}>
                                    <IconButton>
                                        <HourglassEmptyOutlinedIcon sx={{ color: !!activeUserList["waiting"] ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }} />
                                    </IconButton>
                                    <Typography sx={{ color: !!activeUserList["waiting"] ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE, textTransform: "capitalize" }}>Waiting for Approval</Typography>
                                </Box>
                                <Box className={"users_active_container"} onClick={() => handleUserLists(USER_LIST.rejected, USERLIST_STATUS.REJECTED)}>
                                    <IconButton>
                                        <NotInterestedOutlinedIcon sx={{ color: !!activeUserList["rejected"] ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }} />
                                    </IconButton>
                                    <Typography sx={{ color: !!activeUserList["rejected"] ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE, textTransform: "capitalize" }}>Rejected</Typography>
                                </Box>
                            </Box>
                        </Collapse>
                    </>
                }

                {Object.values(USER_ROLES).includes(roleId!) &&
                    <NavLink to={"practice_lists"} style={{ textDecoration: 'none' }}>
                        {({ isActive }) => (
                            <Box className={"patients_active_container"} onClick={handleDefaultUserList}>
                                <IconButton>
                                    <CorporateFareOutlinedIcon sx={{ color: isActive ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }} />
                                </IconButton>
                                <Typography sx={{ color: isActive ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }}>PRACTICES</Typography>
                            </Box>
                        )}
                    </NavLink>
                }

                {roleId === DOCTOR_STAFF_ROLE ?
                    <NavLink to={"doctor_lists"} className="textDecoration">
                        {({ isActive }) => (
                            <Box className={"patients_active_container"} onClick={handleDefaultUserList}>
                                <IconButton>
                                    <VaccinesIcon sx={{ color: isActive ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }} />
                                </IconButton>
                                <Typography sx={{ color: isActive ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }}>DOCTORS</Typography>
                            </Box>
                        )}
                    </NavLink> : null}

                {Object.values(USER_ROLES).includes(roleId!) ?
                    <NavLink to={"occupation_lists"} className="textDecoration">
                        {({ isActive }) => (
                            <Box className={"patients_active_container"} onClick={handleDefaultUserList}>
                                <IconButton>
                                    <CategoryIcon sx={{ color: isActive ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }} />
                                </IconButton>
                                <Typography sx={{ color: isActive ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }}>OCCUPATIONS</Typography>
                            </Box>
                        )}
                    </NavLink> : null}

                {Object.values(USER_ROLES).includes(roleId!) ?
                    <NavLink to={"referral-reasons"} className="textDecoration">
                        {({ isActive }) => (
                            <Box className={"patients_active_container"} onClick={handleDefaultUserList}>
                                <IconButton>
                                    <AssignmentIcon sx={{ color: isActive ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }} />
                                </IconButton>
                                <Typography sx={{ color: isActive ? SIDEBAR.ACTIVE : SIDEBAR.IN_ACTIVE }}>REFERRALS</Typography>
                            </Box>
                        )}
                    </NavLink> : null}
            </Box>
        </Box>
    );
}

export default MySidebar;
