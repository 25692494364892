import { styled, SelectProps, Select } from '@mui/material'
import { makeStyles } from '@mui/styles'

const selectStyles = makeStyles(() => ({
    icon: {
        fontSize: 40, right: -2.5
    }
}))

const AvantSelect = styled((props: SelectProps & { isPrimary?: boolean }) => {
    const classes = selectStyles();
    return <Select classes={{ icon: classes.icon }} {...props} />
})(() => ({}))

export default AvantSelect