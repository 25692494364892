import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const ComponentLoader = () => {
    return (
        <Box display="flex" flex="1" marginTop={15} marginBottom={15} justifyContent="center" alignItems="center">
            <CircularProgress color="primary" />
        </Box>
    )
}

export default ComponentLoader