import axios, { GenericAbortSignal } from "axios";

import { API_URL } from "./constants/api-constants";
import { sessionStorageGet } from "./components/session-storage-helpers/session-storage-actions";
import { SESSION_KEYS } from "./constants/variable-constants";

export const API = axios.create({
    baseURL: API_URL,
});

const getHeaders = (contentType: string = "application/json", isAuth: boolean = false) => {
    let headers: { [key: string]: string } = {
        "Content-Type": contentType,
    };
    if (isAuth) {
        headers["x-access-token"] = sessionStorageGet(SESSION_KEYS.TOKEN);
        headers["user-id"] = sessionStorageGet(SESSION_KEYS.USER_ID);
    }
    return headers;
};

const commonGet = <T = any>(url: string, params = {}, headers = {}, abortSignal: GenericAbortSignal | undefined = undefined) => {
    return API.get<T>(url, {
        headers,
        params,
        signal: abortSignal,
    });
};

const commonPost = <T = any, D = any>(url: string, body: D, params = {}, headers = {}, abortSignal: GenericAbortSignal | undefined = undefined) => {
    return API.post<T>(url, body, {
        headers,
        params,
        signal: abortSignal,
    });
};

const commonPut = <T = any, D = any>(url: string, body: D, params = {}, headers = {}, abortSignal: GenericAbortSignal | undefined = undefined) => {
    return API.put<T>(url, body, {
        headers,
        params,
        signal: abortSignal,
    });
};

export default {
    // GET
    fetchGet<T = any>(url: string, params = {}, abortSignal: GenericAbortSignal | undefined = undefined) {
        return commonGet<T>(url, params, getHeaders(), abortSignal);
    },

    fetchAuthGet<T = any>(url: string, params = {}, abortSignal: GenericAbortSignal | undefined = undefined) {
        return commonGet<T>(url, params, getHeaders("application/json", true), abortSignal);
    },

    fetchAuthImageGet<T = any>(url: string, params = {}, abortSignal: GenericAbortSignal | undefined = undefined) {
        return commonGet<T>(url, params, getHeaders(undefined, true), abortSignal);
    },

    // POST
    fetchPostWithBody<T = any, D = any>(url: string, body: D, params = {}, abortSignal: GenericAbortSignal | undefined = undefined) {
        return commonPost<T, D>(url, body, params, getHeaders(), abortSignal);
    },

    fetchAuthPostWithBody<T = any, D = any>(url: string, body: D, params = {}, abortSignal: GenericAbortSignal | undefined = undefined) {
        return commonPost<T, D>(url, body, params, getHeaders("application/json", true), abortSignal);
    },

    fetchAuthPostImageWithBody<T = any, D = any>(url: string, body: D, params = {}, abortSignal: GenericAbortSignal | undefined = undefined) {
        return commonPost<T, D>(url, body, params, getHeaders("multipart/form-data", true), abortSignal);
    },

    // PUT
    fetchAuthPutWithBody<T = any, D = any>(url: string, body: D, params = {}, abortSignal: GenericAbortSignal | undefined = undefined) {
        return commonPut<T, D>(url, body, params, getHeaders("application/json", true), abortSignal);
    },

    fetchAuthPutWithBodyForText<T = any, D = any>(url: string, body: D, params = {}, abortSignal: GenericAbortSignal | undefined = undefined) {
        return commonPut<T, D>(url, body, params, getHeaders("text/plain", true), abortSignal);
    },

    // PATCH
    fetchAuthPatchWithBody<T = any, D = any>(url: string, body: D, params = {}, abortSignal: GenericAbortSignal | undefined = undefined) {
        return API.patch<T>(url, body, {
            headers: getHeaders("application/json", true),
            params,
            signal: abortSignal,
        });
    },

    // DELETE
    deleteAuth<T = any>(url: string, params = {}, abortSignal: GenericAbortSignal | undefined = undefined) {
        return API.delete<T>(url, {
            headers: getHeaders("application/json", true),
            params,
            signal: abortSignal,
        });
    },

    deleteAuthWithBody<T = any, D = any>(url: string, data: D, params = {}, abortSignal: GenericAbortSignal | undefined = undefined) {
        return API.delete<T>(url, {
            headers: getHeaders("application/json", true),
            data,
            params,
            signal: abortSignal,
        });
    },

    // Downloading file using GET
    fetchAuthDownloadTemplateGet<T = any>(url: string, params = {}) {
        return API.get<T>(url, {
            headers: getHeaders(undefined, true),
            responseType: "blob" as "json",
            params,
        });
    },

    // Downloading file using POST
    fetchAuthDownloadTemplatePost<T = any>(url: string, body: object | {}, params = {}) {
        return API.post<T>(url, body, {
            headers: getHeaders(undefined, true),
            responseType: "blob" as "json",
            params,
        });
    },
};