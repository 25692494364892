import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { LoginData, loginAuthResponse } from "../../models/login.model";
import api from "../../api";
import { LOGIN_API } from "../../constants/api-constants";
import { DOCTOR_STAFF_ROLE, LOGIN_FAILED_MSG, SESSION_KEYS, USER_ROLES, httpStatusCode } from "../../constants/variable-constants";
import { sessionStorageSet } from "../../components/session-storage-helpers/session-storage-actions";
import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import { AuthSliceActions } from "../../redux/reducers/login-slice";
import { AuthThunkApis } from "../../redux/actions/login-actions";
import AvantIndex from "../HomeIndex";
import { DoctorThunkApis } from "../../redux/actions/doctor-actions";
import { convertToArray } from "../../components/utils";

import LoginStyles from "./Login.module.css";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { successiveMsg } = useAppSelector(state => state.auth! || {});

    const [isLoading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [successMsg, setSuccessMsg] = useState<string>(successiveMsg ? successiveMsg : "");

    const initialValues: LoginData = { userName: '', password: '' };

    const validationSchema = Yup.object().shape({
        userName: Yup.string().email('Please enter a valid username').max(255).required('Please enter a valid username'),
        password: Yup.string().required('Please enter the valid password'),
    });

    const onSubmit = async (values: LoginData) => {
        setLoading(true);
        setErrorMsg("");
        try {
            const loginRes = await api.fetchPostWithBody<loginAuthResponse>(LOGIN_API, values);

            if (loginRes.status === httpStatusCode.SUCCESS) {
                setSuccessMsg(loginRes?.data?.message || "Login successful!");
                sessionStorageSet(SESSION_KEYS.TOKEN, loginRes?.data?.token);
                sessionStorageSet(SESSION_KEYS.USER_ID, loginRes?.data?.data?.userId);

                dispatch(AuthSliceActions.patchState({
                    isLoggedIn: true,
                    loginDetails: loginRes?.data?.data,
                    isLoggedChecked: true
                }));

                const userRole = loginRes?.data?.data?.roleId;

                if (userRole !== USER_ROLES.SUPER_ADMIN) {
                    await dispatch(AuthThunkApis.getUserDetails({ userId: loginRes?.data?.data?.userId.toString() }));
                } else {
                    dispatch(AuthSliceActions.patchState({ userDetails: loginRes?.data?.data }));
                }

                const navigateTo = Object.values(USER_ROLES).includes(userRole!)
                    ? '/home/dashboard'
                    : userRole === DOCTOR_STAFF_ROLE
                        ? '/home/doctor_lists'
                        : '/home/patient_lists';
                navigate(navigateTo);
            } else {
                throw new Error(loginRes?.data?.message || LOGIN_FAILED_MSG);
            }
        } catch (error: any) {
            const errMsg = error?.response?.data?.message || LOGIN_FAILED_MSG;
            setErrorMsg(errMsg);
        } finally {
            setLoading(false);
        }
    };

    const enterKeyPressHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            formik.handleSubmit();
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    const navigateToSignUp = () => {
        navigate('sign-up');
        dispatch(AuthSliceActions.patchState({ successiveMsg: null }));
    }

    const navigateToResetPassword = () => {
        navigate('resetpassword');
        dispatch(AuthSliceActions.patchState({ successiveMsg: null }));
    }

    return (
        <AvantIndex>
            <form onSubmit={formik.handleSubmit}>
                <Box className="flexCenterCenter f-col">
                    <Box className="flexCenterStart f-col">
                        <Typography className={LoginStyles.loginText}>Login</Typography>
                        {!!errorMsg && <Typography className={LoginStyles.errorMsg}>{errorMsg}</Typography>}
                        {!!successMsg && <Typography className={LoginStyles.successMsg}>{successMsg}</Typography>}
                        <TextField
                            required
                            id="userName"
                            name="userName"
                            variant="outlined"
                            size="small"
                            placeholder="Username"
                            sx={{
                                '& .MuiInputBase-root': {
                                    width: '280px',
                                    borderRadius: '100px',
                                    marginTop: '20px'
                                }
                            }}
                            onFocus={() => setErrorMsg("")}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.userName}
                            error={formik.touched.userName && Boolean(formik.errors.userName)}
                            helperText={formik.touched.userName && formik.errors.userName}
                        />

                        <TextField
                            required
                            id="password"
                            name="password"
                            variant="outlined"
                            size="small"
                            type="password"
                            placeholder="Password"
                            sx={{
                                '& .MuiInputBase-root': {
                                    width: '280px',
                                    borderRadius: '100px',
                                    marginTop: '20px'
                                }
                            }}
                            onKeyDown={e => enterKeyPressHandler(e)}
                            onFocus={() => setErrorMsg("")}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                    </Box>

                    <Box className="flexCenterEnd" sx={{ width: '100%', marginY: '15px', justifyContent: {xs: 'center', md: 'flex-end'}}} gap={2}>
                        <Typography component={'div'} className={LoginStyles.link} onClick={navigateToSignUp}>New User?</Typography>
                        <Typography component={'div'} className={LoginStyles.link} onClick={navigateToResetPassword}>Forgot Password?</Typography>
                    </Box>

                    <Box sx={{ width: '100%', display: 'flex', justifyContent: {xs: 'center', md: 'flex-start'} }}>
                        <LoadingButton
                            type="submit"
                            loading={isLoading}
                            sx={{
                                color: '#ffffff',
                                backgroundColor: isLoading ? '#6fc1e8' : '#b5b5b5',
                                padding: '5px 15px',
                                minWidth: '100px',
                                borderRadius: '100px',
                                '&:hover': {
                                    color: '#ffffff',
                                    backgroundColor: '#6fc1e8'
                                }
                            }}
                        >
                            <Typography sx={{ textTransform: 'capitalize', fontSize: '14px' }}>Login</Typography>
                        </LoadingButton>
                    </Box>
                </Box>
            </form>
        </AvantIndex>
    );
}

export default Login;