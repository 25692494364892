import React from "react";
import { Box, Typography } from "@mui/material";

import practiceListStyles from "../PracticeLists.module.css";

const PracticeTableHeader = ({ label }: { label: string }) => {
    return (
        <Box className={practiceListStyles.practiceTableHeader}>
            <Typography mr={1} fontWeight={500} color={'primary'}>{label}</Typography>
        </Box>
    );
}

export default PracticeTableHeader