import { useAppSelector } from "../../redux/Hooks";

export const useFilterHasValue = () => {
    const { occupationId, subcategoryId, roleId, practiceNameId } = useAppSelector(state => state.users) || {};
    const hasValue =
        occupationId !== null ||
        subcategoryId !== null ||
        roleId !== null ||
        practiceNameId !== null

    return hasValue;
}