import React, { useEffect, useState } from "react";
import {
    Autocomplete, Box, Button, Chip, CircularProgress, FormControl, FormHelperText, Grid, IconButton,
    InputAdornment,
    MenuItem, Popper, Select, SelectChangeEvent, TextField, TextareaAutosize, Typography
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import MaskedInput from "react-text-mask";

import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import {
    ADD_PATIENT_FAILED_MSG, DOCTOR_ROLE, DOCTOR_STAFF_ROLE, EMAIL_EXISTS_MSG, HOSPITAL_NAMES, OTHER_REFERRAL_REASON, SAVE_PATIENT_FAILED_MSG,
    SESSION_KEYS, USER_ROLES, genderList, httpStatusCode, patientDateIds, patientStatusNotesIds, PHONE_NUMBER_EXACT_LENGTH, PHONE_OR_EMAIL_REQUIRED
} from "../../constants/variable-constants";
import { addPatientResponse, patientDetailsProps, patientImageProps, referralReasonsProps, referralReasonsUpdatedProps } from "../../models/patient.model";
import api from "../../api";
import { ADD_PATIENT, CHECK_PATIENT, DELETE_IMAGE, GET_IMAGE, PATIENT_DETAILS } from "../../constants/api-constants";
import { PatientSliceActions } from "../../redux/reducers/patient-slice";
import { ToastAlert } from "../../components/toast-alert";
import ImageUploadDialog from "./components/ImageUploadDialog";
import { PatientThunkApis } from "../../redux/actions/patient-actions";
import ComponentLoader from "../../components/ComponentLoader";
import ViewImageDialog from "./components/ViewImageDialog";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { sessionStorageGet } from "../../components/session-storage-helpers/session-storage-actions";
import { convertToArray, getPracticeNameId, handleErrorMsg } from "../../components/utils";
import MultipleImagePrint from "./components/MultipleImagesPrint";
import { AvantTooltip } from "../../components/tool-tip/Tooltip";
import { adminPracticeResponseProps, doctorListsResponse, practicesBasedLoginProps, practicesResponseParseProps } from "../../models/practice.model";
import { PracticeThunkApis } from "../../redux/actions/practice-actions";
import { DoctorThunkApis } from "../../redux/actions/doctor-actions";
import { practiceSelectors } from "../../redux/reducers/practice-slice";

import UpdatePatientStyles from "./UpdatePatient.module.css";

const UpdatePatient = () => {
    const { patientId } = useParams<{ patientId: string }>();

    const [isLoading, setLoading] = useState<boolean>(false);
    const [detailsLoading, setDetailsLoading] = useState<boolean>(false);
    const [deleteImgLoading, setDeleteImgLoading] = useState<boolean>(false);
    const [openImageUploadModal, setOpenImageUploadModal] = useState<boolean>(false);
    const [openMultiImageModal, setOpenMultiImageModal] = useState<boolean>(false);
    const [viewImageModal, setViewImageModal] = useState<boolean>(false);
    const [apiImage, setApiImage] = useState<string | null>(null);
    const [deleteImg, setDeleteImg] = useState<patientImageProps | null>(null);
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
    const [otherReasonSelected, setOtherReasonSelected] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const practiceLists = useAppSelector(practiceSelectors.selectAll);

    const isViewPath = location.pathname?.includes("view");
    const userId = sessionStorageGet(SESSION_KEYS.USER_ID);

    const { allPracticeDoctorList: doctorLists } = useAppSelector(state => state.doctors! || {});
    const { doctorPractices, doctorStaffPractices, adminPractices } = useAppSelector(state => state.practices! || {});
    const { patientStatus, patientDetails, patientDetailsLoading, newPatientId, hospitalsList, referralReasons } = useAppSelector(state => state.patients! || {});
    const { userDetails } = useAppSelector(state => state.auth! || {});
    const { firstName, lastName, roleId, practiceNameId } = userDetails! || {};

    useEffect(() => {
        dispatch(PatientThunkApis.getReferralreasons(''));
        dispatch(PatientThunkApis.getPatientStatus(''));
        dispatch(PatientThunkApis.getHospitals(''));
        const fetchPractices = () => {
            switch (roleId) {
                case DOCTOR_ROLE:
                    dispatch(PracticeThunkApis.getAllDoctorPractices(''));
                    break;
                case DOCTOR_STAFF_ROLE:
                    dispatch(PracticeThunkApis.getAllDoctorStaffPractices(''));
                    break;
                case USER_ROLES.USER_ADMIN:
                    dispatch(PracticeThunkApis.getAllAdminPractices(''));
                    break;
                default:
                    dispatch(PracticeThunkApis.getAuthAllPractices({ roleId: roleId! }));
            }
        };

        fetchPractices();
        const practiceId = convertToArray(userDetails?.practiceNameId!);
        if (practiceId) {
            dispatch(DoctorThunkApis.getDoctorsAssociatedPractice({ staffId: practiceId }));
        }
    }, [dispatch, userDetails]);

    const addingInitialValues = {
        adminNotes: "",
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        gender: "Male",
        age: 0,
        referralReasonId: [],
        currentPatientStatusId: 1,
        notes: "",
        preferredContactMethod: "Email",
        statusDate: "",
        statusNotes: "",
        otherReason: "",
        createdBy: +userId,
        updatedBy: +userId,
        createdByName: firstName + " " + lastName,
        practiceNameId: roleId === DOCTOR_STAFF_ROLE ? getPracticeNameId(practiceNameId!) : null,
        referredBy: roleId === DOCTOR_ROLE ? userId : null,
        ...(Object.values(USER_ROLES).includes(roleId!) ? { hospitalId: HOSPITAL_NAMES.AVANT_LASIK } : {})
    }

    const updatingInitialValues = {
        ...patientDetails!,
        referralReasonId: patientDetails?.referralReasonId.map((val: any) => val.ReferralReasonId!) || [],
        otherReason: (patientDetails?.referralReasonId as any)?.find(
            (reason: referralReasonsUpdatedProps) => reason?.OtherReason!
        )?.OtherReason || "",
        createdByName: patientDetails?.referredByFirstName + " " + patientDetails?.referredByLastName || ""
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
        emailAddress: Yup.string()
            .email("Invalid email format")
            .test("email-or-phone", PHONE_OR_EMAIL_REQUIRED, function (value) {
                return value || this.parent.phoneNumber;
            }),
        phoneNumber: Yup.string()
            .matches(/^\d+$/, "Phone number must be only digits")
            .transform((value, originalValue) => originalValue ? originalValue.replace(/\D/g, '') : originalValue)
            .min(10, PHONE_NUMBER_EXACT_LENGTH)
            .max(10, PHONE_NUMBER_EXACT_LENGTH)
            .test("phone-or-email", PHONE_OR_EMAIL_REQUIRED, function (value) {
                return value || this.parent.emailAddress;
            }),
        gender: Yup.string().required("Gender is required"),
        age: Yup.number().required("Age is required").min(0, "Age must be a positive number"),
        referralReasonId: Yup.array()
            .of(Yup.number())
            .min(1, "Reason for referral is required"),
        currentPatientStatusId: Yup.string().required("Status is required"),
        notes: Yup.string().required("Symptoms/Notes are required"),
        practiceNameId: Yup.number().required('Practice name is required'),
        referredBy: Yup.number().required('Assigner name is required'),
        otherReason: Yup.string().when('referralReasonId', (referralReasonId, schema) => {
            const flatReferralReasonId = referralReasonId.flat(); // Flatten the array
            const isOtherSelected = flatReferralReasonId.some(id =>
                referralReasons?.find(reason => reason.ReferralReasonId === id)?.ReferralReasonName === OTHER_REFERRAL_REASON
            );
            return isOtherSelected ? schema.required("Other referral reason is required") : schema.notRequired();
        })
    });

    useEffect(() => {
        if (patientDetails) {
            const { statusDate, referralReasonId } = patientDetails!;

            if (statusDate) {
                const selectedDate = dayjs(statusDate);
                setSelectedDate(selectedDate);
                formik.setFieldValue('statusDate', statusDate);
            }

            if (referralReasonId?.length) {
                // Find the object with OtherReason
                const otherReason = (referralReasonId as any)?.find(
                    (reason: referralReasonsUpdatedProps) => reason?.OtherReason!
                )?.OtherReason || "";

                setOtherReasonSelected(!!otherReason);
            }
        }
    }, [patientDetails]);

    const formik = useFormik<patientDetailsProps>({
        initialValues: patientId && patientDetails ? updatingInitialValues : addingInitialValues,
        validationSchema: validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                const response = await api.fetchAuthGet<{ exists: boolean, message: string }>(CHECK_PATIENT(values?.emailAddress!));
                if (response?.status === httpStatusCode.SUCCESS && !response?.data?.exists) {
                    handleAddPatient(values as any);
                } else {
                    ToastAlert(EMAIL_EXISTS_MSG, 'error');
                }
            } catch (error: any) {
                console.error('Failed to add the patient', error);
            }
        },
    });

    useEffect(() => {
        if (!patientId) {
            return;
        }
        dispatch(PatientThunkApis.getPatientDetails({ patientId: +patientId }));
    }, [patientId]);

    const handleReferralReasonChange = (event: React.SyntheticEvent<Element, Event>, value: referralReasonsProps[] | null) => {
        if (value) {
            const selectedReasons = value || [];
            const referralReasonIds = selectedReasons.map(({ ReferralReasonId }) => ReferralReasonId);

            const isSelectedOtherReason = selectedReasons.some(
                ({ ReferralReasonName }) => ReferralReasonName === OTHER_REFERRAL_REASON
            );

            formik.setFieldValue('referralReasonId', referralReasonIds);
            formik.setFieldValue('otherReason', isSelectedOtherReason ? formik.values.otherReason : "");
            setOtherReasonSelected(isSelectedOtherReason);
        } else {
            formik.setFieldValue('referralReasonId', []);
            formik.setFieldValue('otherReason', '');
            setOtherReasonSelected(false);
        }
    };

    useEffect(() => {
        if ([USER_ROLES.SUPER_ADMIN, USER_ROLES.USER_ADMIN].includes(roleId!)) {
            dispatch(DoctorThunkApis.getDoctorsAssociatedPractice({ staffId: [formik.values.practiceNameId!] }));
        }
    }, [formik.values.practiceNameId]);

    const handleAddPatient = async (values: patientDetailsProps) => {
        setLoading(true);
        try {
            const response = await api.fetchAuthPostWithBody<addPatientResponse>(ADD_PATIENT, { ...values, age: +values?.age! });
            if (response.status === httpStatusCode.SUCCESS) {
                dispatch(PatientSliceActions.patchState({ newPatientId: response?.data?.patientId }));
                ToastAlert(response?.data?.message, 'success');
                handleImageUpload();
            } else {
                throw new Error(ADD_PATIENT_FAILED_MSG);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setLoading(false);
        }
    }

    const handleNavigate = () => {
        navigate('/home/patient_lists');
    }

    const resetForm = () => {
        formik.resetForm();
    }

    const handleImageUpload = () => {
        setOpenImageUploadModal(true);
    }

    const handlePracticeChange = (event: React.SyntheticEvent<Element, Event>, value: practicesResponseParseProps | any | null) => {
        if (value) {
            formik.setFieldValue('practiceNameId', value?.PracticeNameId || 0);
            formik.setFieldValue('referredBy', null);
        } else {
            formik.setFieldValue('practiceNameId', 0);
            formik.setFieldValue('referredBy', null);
        }
    };

    const handleDoctorPracticeChange = (event: React.SyntheticEvent<Element, Event>, value: practicesBasedLoginProps | any | null) => {
        if (value) {
            formik.setFieldValue('practiceNameId', value?.PracticeId || 0);
        } else {
            formik.setFieldValue('practiceNameId', 0);
        }
    }

    const handleDoctorStaffPracticeChange = (event: React.SyntheticEvent<Element, Event>, value: practicesBasedLoginProps | any | null) => {
        if (value) {
            formik.setFieldValue('practiceNameId', value?.PracticeId || 0);
            formik.setFieldValue('referredBy', null);
        } else {
            formik.setFieldValue('practiceNameId', 0);
            formik.setFieldValue('referredBy', null);
        }
    }

    const handleAdminPracticeChange = (event: React.SyntheticEvent<Element, Event>, value: adminPracticeResponseProps | any | null) => {
        if (value) {
            formik.setFieldValue('practiceNameId', value?.PracticeNameId || 0);
            formik.setFieldValue('referredBy', null);
        } else {
            formik.setFieldValue('practiceNameId', 0);
            formik.setFieldValue('referredBy', null);
        }
    }

    const handleSavePatient = async () => {
        setDetailsLoading(true);
        // Trigger validation and get validation errors
        const errors = await formik.validateForm();

        // Check if there are any validation errors
        if (Object.keys(errors).length > 0) {
            // Set errors to formik and return early to prevent saving
            formik.setTouched(
                Object.keys(errors).reduce((acc, key) => {
                    acc[key] = true; // Mark all fields with errors as touched
                    return acc;
                }, {})
            );
            setDetailsLoading(false);
            return; // Exit the function if there are validation errors
        }

        const payload = { ...formik.values }
        try {
            const saveRes = await api.fetchAuthPutWithBody<{ message: string }>(PATIENT_DETAILS(patientDetails ? patientDetails.patientId! : newPatientId!), payload);
            if (saveRes?.status === httpStatusCode.SUCCESS) {
                ToastAlert(saveRes?.data?.message, 'success');
                handleNavigate();
            } else {
                throw new Error(SAVE_PATIENT_FAILED_MSG);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setDetailsLoading(false);
        }
    }

    const handleViewImage = async (image: patientImageProps) => {
        setViewImageModal(true);
        try {
            const response = await api.fetchAuthImageGet<any>(GET_IMAGE(patientDetails?.patientId!, image?.relativePath!));
            if (response?.status === (httpStatusCode?.SUCCESS || httpStatusCode.NOT_MODIFIED)) {
                const responseImg = "data:image;base64," + response?.data;
                setApiImage(responseImg);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        }
    }

    const handleDeleteImage = async () => {
        setDeleteImgLoading(true);
        try {
            const imgRes = await api.deleteAuthWithBody<{ message: string }>(DELETE_IMAGE, { patientImageId: deleteImg?.patientImageId, relativePath: deleteImg?.relativePath });
            if (imgRes?.status === httpStatusCode?.SUCCESS) {
                ToastAlert(imgRes?.data?.message, 'success');
                dispatch(PatientThunkApis.getPatientDetails({ patientId: +patientId! }));
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setDeleteImgLoading(false);
            setDeleteImg(null);
        }
    }

    const handleDeleteImgDialog = (e: any, image: patientImageProps) => {
        e.stopPropagation();
        setDeleteImg(image);
    }

    const handleDateChange = (newDate: Dayjs | null) => {
        if (newDate?.isValid()) {
            setSelectedDate(newDate);
            formik.setFieldValue('statusDate', newDate.toISOString());
        } else {
            // Handle invalid date
            console.error('Invalid date');
        }
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('phoneNumber', event.target.value);
    }

    const handleReferredByChange = (event: SelectChangeEvent<number>) => {
        const id = event.target.value as number;
        formik.setFieldValue('referredBy', id);
    };

    const handleOpenMultiImagePrint = () => {
        setOpenMultiImageModal(true);
    }

    const handleDefaultReferralReason = () => {
        formik.handleChange('otherReason')('');
    }

    const isDisabled = patientId ? isViewPath || ![USER_ROLES.USER_ADMIN, USER_ROLES.SUPER_ADMIN, DOCTOR_STAFF_ROLE].includes(roleId!) : false;

    useEffect(() => {
        return () => {
            resetForm();
            dispatch(PatientSliceActions.patchState({ newPatientId: null, patientDetails: null }));
        }
    }, []);

    return (
        <Box className={UpdatePatientStyles.container}>
            <Box className={UpdatePatientStyles.search_panal}>
                {!patientDetails?.imageDetails?.length && patientDetailsLoading ? <ComponentLoader /> :
                    <>
                        <Box className={UpdatePatientStyles.panel_container}>
                            <Box className="flexCenterBetween">
                                <Box className="flexCenterStart pointer" sx={{ width: "200px" }} onClick={handleNavigate}>
                                    <img src="images/back.svg" alt="Backward_Image" className={UpdatePatientStyles.backImg} />
                                    <Typography ml={2} className={UpdatePatientStyles.panal_header}>{isViewPath ? 'Patient Details' : `${patientId ? 'Edit' : 'Add'} Basic Details`}</Typography>
                                </Box>
                                {isViewPath ?
                                    <AvantTooltip title="Edit" placement="top" arrow followCursor>
                                        <img src="images/edit.svg" alt="Edit_Image" className={UpdatePatientStyles.editImg} onClick={() => navigate(`/home/patient_lists/${patientId}`)} />
                                    </AvantTooltip> : null}
                            </Box>
                        </Box>

                        <form onSubmit={formik.handleSubmit}>
                            <Grid container>
                                <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                    <Typography className={UpdatePatientStyles.label}>First Name</Typography>
                                    <TextField
                                        id="firstName"
                                        name="firstName"
                                        variant="standard"
                                        disabled={isViewPath}
                                        placeholder="Enter a first name"
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                        helperText={formik.touched.firstName && formik.errors.firstName}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                    <Typography className={UpdatePatientStyles.label}>Last Name</Typography>
                                    <TextField
                                        id="lastName"
                                        name="lastName"
                                        variant="standard"
                                        disabled={isViewPath}
                                        placeholder="Enter a last name"
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                        helperText={formik.touched.lastName && formik.errors.lastName}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                    <Typography className={UpdatePatientStyles.label}>Age</Typography>
                                    <TextField
                                        id="age"
                                        name="age"
                                        variant="standard"
                                        disabled={isViewPath}
                                        value={formik.values.age}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.age && Boolean(formik.errors.age)}
                                        helperText={formik.touched.age && formik.errors.age}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                    <Typography className={UpdatePatientStyles.label}>Gender</Typography>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={formik.touched.gender && Boolean(formik.errors.gender)}
                                    >
                                        <Select
                                            labelId="gender-select-label"
                                            id="gender-select"
                                            name="gender"
                                            disabled={isViewPath}
                                            value={formik.values.gender}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            {genderList.map((item) => (
                                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                        {formik.touched.gender && formik.errors.gender && (
                                            <FormHelperText>{formik.errors.gender}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                {roleId !== DOCTOR_STAFF_ROLE ?
                                    <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                        <Typography className={UpdatePatientStyles.label}>Name of the Practice</Typography>
                                        {roleId === DOCTOR_ROLE ?
                                            <FormControl
                                                variant="standard"
                                                fullWidth
                                                error={formik.touched.practiceNameId && Boolean(formik.errors.practiceNameId)}
                                            >
                                                <Autocomplete
                                                    options={doctorPractices || []}
                                                    getOptionLabel={(option: any) => option?.PracticeName || ""}
                                                    value={doctorPractices?.find(practice => practice.PracticeId === formik.values.practiceNameId) || null}
                                                    onChange={handleDoctorPracticeChange}
                                                    disabled={isDisabled}
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="standard" />
                                                    )}
                                                    freeSolo
                                                />
                                                {formik.touched.practiceNameId && formik.errors.practiceNameId && (
                                                    <FormHelperText>{formik.errors.practiceNameId}</FormHelperText>
                                                )}
                                            </FormControl> : roleId === DOCTOR_STAFF_ROLE ?
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    error={formik.touched.practiceNameId && Boolean(formik.errors.practiceNameId)}
                                                >
                                                    <Autocomplete
                                                        options={doctorStaffPractices || []}
                                                        getOptionLabel={(option: any) => option?.PracticeName || ""}
                                                        value={doctorStaffPractices?.find(practice => practice.PracticeId === formik.values.practiceNameId) || null}
                                                        onChange={handleDoctorStaffPracticeChange}
                                                        disabled={isDisabled}
                                                        renderInput={(params) => (
                                                            <TextField {...params} variant="standard" />
                                                        )}
                                                        freeSolo
                                                    />
                                                    {formik.touched.practiceNameId && formik.errors.practiceNameId && (
                                                        <FormHelperText>{formik.errors.practiceNameId}</FormHelperText>
                                                    )}
                                                </FormControl> : roleId === USER_ROLES.USER_ADMIN ?
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        error={formik.touched.practiceNameId && Boolean(formik.errors.practiceNameId)}
                                                    >
                                                        <Autocomplete
                                                            options={adminPractices || []}
                                                            getOptionLabel={(option: any) => option?.PracticeName || ""}
                                                            value={adminPractices?.find(practice => practice.PracticeNameId === formik.values.practiceNameId) || null}
                                                            onChange={handleAdminPracticeChange}
                                                            disabled={isDisabled}
                                                            renderInput={(params) => (
                                                                <TextField {...params} variant="standard" />
                                                            )}
                                                            freeSolo
                                                        />
                                                        {formik.touched.practiceNameId && formik.errors.practiceNameId && (
                                                            <FormHelperText>{formik.errors.practiceNameId}</FormHelperText>
                                                        )}
                                                    </FormControl> :
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        error={formik.touched.practiceNameId && Boolean(formik.errors.practiceNameId)}
                                                    >
                                                        <Autocomplete
                                                            options={practiceLists || []}
                                                            getOptionLabel={(option: any) => option?.PracticeName || ""}
                                                            value={practiceLists?.find(practice => practice.PracticeNameId === formik.values.practiceNameId) || null}
                                                            onChange={handlePracticeChange}
                                                            disabled={isDisabled}
                                                            renderInput={(params) => (
                                                                <TextField {...params} variant="standard" />
                                                            )}
                                                            freeSolo
                                                        />
                                                        {formik.touched.practiceNameId && formik.errors.practiceNameId && (
                                                            <FormHelperText>{formik.errors.practiceNameId}</FormHelperText>
                                                        )}
                                                    </FormControl>}
                                    </Grid> : null}
                                <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                    <Typography className={UpdatePatientStyles.label}>Referral Doctor</Typography>
                                    <FormControl sx={{ minWidth: "100%", marginTop: "-5px" }} variant="standard" error={formik.touched.referredBy && Boolean(formik.errors.referredBy)}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={formik.values.referredBy!}
                                            label="Doctor"
                                            disabled={isDisabled || roleId === DOCTOR_ROLE}
                                            onChange={handleReferredByChange}
                                            sx={{
                                                borderRadius: '25px',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderRadius: '25px'
                                                },
                                                '& .MuiSelect-select': {
                                                    padding: '7px 14px'
                                                }
                                            }}
                                        >
                                            {doctorLists?.length ? (
                                                roleId !== DOCTOR_ROLE ? (
                                                    doctorLists.filter(doctor => doctor.PracticeId === formik.values.practiceNameId).map((doctor: doctorListsResponse) => (
                                                        <MenuItem key={doctor.UserId} value={doctor.UserId!}>{doctor.FullName}</MenuItem>
                                                    ))
                                                ) : (
                                                    doctorLists.map((doctor: doctorListsResponse) => (
                                                        <MenuItem key={doctor.UserId} value={doctor.UserId!}>{doctor.FullName}</MenuItem>
                                                    ))
                                                )
                                            ) : null}
                                        </Select>
                                        {formik.touched.referredBy && formik.errors.referredBy && (
                                            <FormHelperText>{formik.errors.referredBy}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                    <Typography className={UpdatePatientStyles.label}>Referred By</Typography>
                                    <TextField
                                        id="createdByName"
                                        name="createdByName"
                                        variant="standard"
                                        disabled
                                        placeholder="Enter a referred user name"
                                        value={formik.values.createdByName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.createdByName && Boolean(formik.errors.createdByName)}
                                        helperText={formik.touched.createdByName && formik.errors.createdByName}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid lg={4} md={4} sm={6} xs={12} p={3}>
                                    <Typography className={UpdatePatientStyles.label}>Reason for Referral</Typography>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={formik.touched.referralReasonId && Boolean(formik.errors.referralReasonId)}
                                    >
                                        <Autocomplete
                                            multiple
                                            options={referralReasons || []}
                                            getOptionLabel={(option) => option?.ReferralReasonName!}
                                            value={referralReasons?.filter((option: referralReasonsProps) => formik.values.referralReasonId.includes(option?.ReferralReasonId!)) || []}
                                            onChange={handleReferralReasonChange}
                                            disabled={isViewPath}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="standard" />
                                            )}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        variant="outlined"
                                                        label={option.ReferralReasonName}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                            PopperComponent={(props) => (
                                                <Popper {...props} placement="bottom-start" style={{ zIndex: 1300 }} />
                                            )}
                                            freeSolo={false}
                                        />
                                        {formik.touched.referralReasonId && formik.errors.referralReasonId && (
                                            <FormHelperText>{formik.errors.referralReasonId}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                {otherReasonSelected ?
                                    <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                        <Typography className={UpdatePatientStyles.label}>Other Referral Reason</Typography>
                                        <TextField
                                            id="otherReason"
                                            name="otherReason"
                                            variant="standard"
                                            size="small"
                                            placeholder="Enter other referral reason"
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    borderRadius: '100px'
                                                }
                                            }}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            disabled={isViewPath}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CloseIcon sx={{ display: formik.values.otherReason ? "inline-block" : "none", fontSize: "22px" }} className={UpdatePatientStyles.closeIcon} onClick={handleDefaultReferralReason} />
                                                    </InputAdornment>
                                                )
                                            }}
                                            value={formik.values.otherReason}
                                            error={formik.touched.otherReason && Boolean(formik.errors.otherReason)}
                                            helperText={formik.touched.otherReason && formik.errors.otherReason}
                                            fullWidth
                                        />
                                    </Grid> : null}
                                <Grid item md={4} p={3}>
                                    <Typography className={UpdatePatientStyles.label}>Email</Typography>
                                    <TextField
                                        id="emailAddress"
                                        name="emailAddress"
                                        variant="standard"
                                        disabled={isViewPath}
                                        placeholder="Enter an email address"
                                        value={formik.values.emailAddress}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                                        helperText={formik.touched.emailAddress && formik.errors.emailAddress}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                    <Typography className={UpdatePatientStyles.label}>Phone Number</Typography>
                                    <MaskedInput
                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        placeholder="Enter a phone number"
                                        guide={false}
                                        id="phoneNumber"
                                        value={formik.values.phoneNumber!}
                                        onBlur={formik.handleBlur}
                                        onChange={handlePhoneChange}
                                        render={(ref, props) => (
                                            <TextField
                                                {...props}
                                                inputRef={ref}
                                                variant="standard"
                                                disabled={isViewPath}
                                                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                    <Typography className={UpdatePatientStyles.label}>Status</Typography>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={formik.touched.currentPatientStatusId && Boolean(formik.errors.currentPatientStatusId)}
                                    >
                                        <Select
                                            labelId="status-select-label"
                                            id="status-select"
                                            name="currentPatientStatusId"
                                            value={formik.values.currentPatientStatusId!}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            disabled={!Object.values(USER_ROLES).includes(roleId!) || isViewPath}
                                        >
                                            {patientStatus?.map((status) => (
                                                <MenuItem key={status.PatientStatusId} value={status.PatientStatusId!}>{status.PatientStatusName}</MenuItem>
                                            ))}
                                        </Select>
                                        {formik.touched.currentPatientStatusId && formik.errors.currentPatientStatusId && (
                                            <FormHelperText>{formik.errors.currentPatientStatusId}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                {Object.values(USER_ROLES).includes(roleId!) ?
                                    <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                        <Typography className={UpdatePatientStyles.label}>Referred To</Typography>
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            error={formik.touched.hospitalId && Boolean(formik.errors.hospitalId)}
                                        >
                                            <Select
                                                labelId="hospital-select-label"
                                                id="hospital-select"
                                                name="hospitalId"
                                                disabled={isViewPath}
                                                value={formik.values.hospitalId}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            >
                                                {hospitalsList.map((item) => (
                                                    <MenuItem key={item.HospitalId} value={item.HospitalId!}>{item.HospitalName}</MenuItem>
                                                ))}
                                            </Select>
                                            {formik.touched.hospitalId && formik.errors.hospitalId && (
                                                <FormHelperText>{formik.errors.hospitalId}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid> : null}
                                <Grid item md={6} sm={12} xs={12} p={3}>
                                    <Typography className={UpdatePatientStyles.label}>Symptoms/Notes</Typography>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={formik.touched.notes && Boolean(formik.errors.notes)}
                                    >
                                        <TextareaAutosize
                                            className={UpdatePatientStyles.textArea}
                                            id="notes"
                                            name="notes"
                                            disabled={isViewPath}
                                            placeholder="Enter symptoms/notes"
                                            value={formik.values.notes!}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.notes && formik.errors.notes && (
                                            <FormHelperText>{formik.errors.notes}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                {Object.values(USER_ROLES).includes(roleId!) ?
                                    <Grid item md={6} sm={12} xs={12} p={3}>
                                        <Typography className={UpdatePatientStyles.label}>Admin Notes</Typography>
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            error={formik.touched.adminNotes && Boolean(formik.errors.adminNotes)}
                                        >
                                            <TextareaAutosize
                                                className={UpdatePatientStyles.textArea}
                                                id="'adminNotes"
                                                name="adminNotes"
                                                disabled={isViewPath}
                                                placeholder="Enter notes"
                                                value={formik.values.adminNotes!}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.adminNotes && formik.errors.adminNotes && (
                                                <FormHelperText>{formik.errors.adminNotes}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid> : null}
                                {patientStatusNotesIds.includes(formik.values.currentPatientStatusId!) ?
                                    <Grid item md={6} sm={12} xs={12} p={3}>
                                        <Typography className={UpdatePatientStyles.label}>Status Note</Typography>
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            error={formik.touched.statusNotes && Boolean(formik.errors.statusNotes)}
                                        >
                                            <TextareaAutosize
                                                className={UpdatePatientStyles.textArea}
                                                id="'statusNotes"
                                                name="statusNotes"
                                                disabled={isViewPath}
                                                placeholder="Enter Status Notes(Max. 1000 chars)"
                                                value={formik.values.statusNotes!}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.statusNotes && formik.errors.statusNotes && (
                                                <FormHelperText>{formik.errors.statusNotes}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid> : null}

                                {patientDateIds.includes(formik.values.currentPatientStatusId!) ?
                                    <Grid item md={6} xs={12} p={3}>
                                        <Typography className={UpdatePatientStyles.label}>Selected Date</Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                slotProps={{
                                                    textField: {
                                                        variant: 'standard',
                                                    }
                                                }}
                                                format="YYYY-MM-DD"
                                            />
                                        </LocalizationProvider>
                                    </Grid> : null}
                            </Grid>
                            {!patientId && !newPatientId ?
                                <Box my={2} sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: {
                                        md: 'flex-end',
                                        sm: 'center',
                                        xs: 'center'
                                    }
                                }}>
                                    <LoadingButton
                                        loading={isLoading}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        sx={{ padding: "6px 11px", minWidth: "100px", borderRadius: "100px" }}
                                    >
                                        <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Save and Continue</Typography>
                                    </LoadingButton>
                                </Box> : null}
                        </form>
                        {!!patientId || !!newPatientId ?
                            <Box className={UpdatePatientStyles.imageContainer}>
                                <Box className={UpdatePatientStyles.imageHeader}>
                                    <Typography sx={{ color: "#70bfe4", fontSize: "18px", fontWeight: "500" }}>Images</Typography>
                                    {!isViewPath ?
                                        <Box className="flexCenter">
                                            <IconButton title="Print" onClick={handleOpenMultiImagePrint} disabled={!patientDetails?.imageDetails?.length}>
                                                <PrintOutlinedIcon sx={{ fontSize: "30px" }} />
                                            </IconButton>
                                            <IconButton onClick={handleImageUpload} title="Image upload">
                                                <FileUploadOutlinedIcon sx={{ fontSize: "35px" }} />
                                            </IconButton>
                                        </Box> : null}
                                </Box>
                                <Box my={2}>
                                    {patientDetails?.imageDetails?.length ?
                                        <Box className="flexWrap flexCenterCenter" gap={5}>
                                            {patientDetails.imageDetails.map((image: patientImageProps) => (
                                                <Box className={UpdatePatientStyles.imageItem} onClick={() => handleViewImage(image)}>
                                                    <img src={image.imageThumbnail!} className={UpdatePatientStyles.fullImage} />
                                                    {!isViewPath ?
                                                        <IconButton
                                                            aria-label="close"
                                                            sx={{
                                                                position: 'absolute',
                                                                right: '-18px',
                                                                top: '-19px',
                                                            }}
                                                            onClick={(e) => handleDeleteImgDialog(e, image)}
                                                        >
                                                            <CancelIcon sx={{ fontSize: '2.5rem' }} color="primary" />
                                                        </IconButton> : null}
                                                </Box>
                                            ))}
                                            <Box className="flexCenterCenter">
                                                {patientDetailsLoading ? <CircularProgress size={30} /> : null}
                                            </Box>
                                        </Box> : <Typography className="flexCenterCenter" sx={{ fontSize: "16px", fontWeight: "500" }}>No images found</Typography>}
                                </Box>
                                {!isViewPath ?
                                    <Box mb={2} sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: {
                                            md: 'flex-end',
                                            sm: 'center',
                                            xs: 'center'
                                        }
                                    }}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleNavigate}
                                            sx={{
                                                padding: "6px 11px",
                                                minWidth: {
                                                    md: '120px',
                                                    xs: '100px'
                                                },
                                                textTransform: "capitalize",
                                                borderRadius: "100px"
                                            }}
                                        >
                                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Cancel</Typography>
                                        </Button>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            loading={detailsLoading}
                                            onClick={handleSavePatient}
                                            disabled={!patientDetails?.imageDetails?.length && !patientId}
                                            sx={{
                                                padding: "6px 11px",
                                                minWidth: {
                                                    md: '120px',
                                                    xs: '100px'
                                                },
                                                textTransform: "capitalize",
                                                borderRadius: "100px",
                                                marginLeft: "10px"
                                            }}
                                        >
                                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Save</Typography>
                                        </LoadingButton>
                                    </Box> : null}
                            </Box> : null}
                        <ImageUploadDialog openImageUploadModal={openImageUploadModal} setOpenImageUploadModal={setOpenImageUploadModal} />
                        <MultipleImagePrint openMultiImageModal={openMultiImageModal} setOpenMultiImageModal={setOpenMultiImageModal} />
                        {viewImageModal && <ViewImageDialog apiImage={apiImage} setViewImageModal={setViewImageModal} setApiImage={setApiImage} />}
                        <ConfirmationDialog
                            open={!!deleteImg}
                            accept={handleDeleteImage}
                            reject={() => setDeleteImg(null)}
                            msg={"Are you sure you want to delete the image?"}
                            acceptText={"Yes"}
                            rejectText={"No"}
                            isLoading={deleteImgLoading}
                        />
                    </>}
            </Box>
        </Box>
    );
}

export default UpdatePatient;