import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { ArrowDropDown } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../redux/Hooks";
import { useDebounce } from "../../../components/custom-hook/useDebounce";
import { NotificationSliceActions, notificationUserSelectors } from "../../../redux/reducers/notification-slice";
import { practiceSelectors } from "../../../redux/reducers/practice-slice";
import { ADMIN_INFO_OVERALL_STATUS_UPDATES, CHECKED_USER, USER_ROLES, USER_ROLE_NAMES, VIEW_LIST } from "../../../constants/variable-constants";
import OverallPatientStatus from "./OverallPatientStatus";
import { AvantTooltip } from "../../../components/tool-tip/Tooltip";

import NotificationStyles from "../Notifications.module.css";

const NotificationsHeader = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const practicesData = useAppSelector(practiceSelectors.selectAll);
    const usersData = useAppSelector(notificationUserSelectors.selectAll);
    const { adminPractices } = useAppSelector(state => state.practices! || {});
    const { roleId } = useAppSelector(state => state.auth.userDetails! || {});
    const { selectedPracticeId, selectedUsers, searchTerm: notificationSearchKeywords, viewList } = useAppSelector(state => state.notifications! || {});

    const practices = roleId === USER_ROLES.USER_ADMIN ? adminPractices : practicesData;

    const isSuperAdmin = roleId === USER_ROLES.SUPER_ADMIN;
    const isAdminView = viewList === VIEW_LIST.ADMIN;

    // Filter users based on role
    const users = useMemo(() => {
        return !!isSuperAdmin
            ? usersData
            : usersData?.filter(user => user.RoleName !== USER_ROLE_NAMES.ADMIN);
    }, [roleId, usersData]);

    useEffect(() => {
        if (!notificationSearchKeywords) {
            setSearchTerm("");
        }
    }, [notificationSearchKeywords]);

    const isDisabled = useCallback(() => {
        // If there are no selected users or admin view, disable by default
        if ((!!isSuperAdmin && !selectedUsers?.length) || !!isAdminView) {
            return true;
        }

        // Check if any selected user is found and has `CHECKED_USER` status
        return !selectedUsers.some(selected => {
            const user = users?.find(user => user.UserId === selected.userId);
            return user && selected.isSelected === CHECKED_USER;
        });
    }, [users, selectedUsers, viewList]);

    const handleDebouncedSearch = useDebounce((term: string) => {
        dispatch(NotificationSliceActions.patchState({ searchTerm: term }));
    }, 2000);

    const handleSearchTerm = (term: string) => {
        setSearchTerm(term);
        dispatch(NotificationSliceActions.patchState({ currentPage: 0, pagesize: 10 }));
        handleDebouncedSearch(term);
    };

    const clearSearchTerm = () => {
        setSearchTerm("");
        dispatch(NotificationSliceActions.patchState({ searchTerm: null }));
    }

    const handleNavigate = () => {
        navigate('/home/dashboard');
    }

    const handlePracticeChange = (event: SelectChangeEvent<number>) => {
        const id = event.target.value as number;
        dispatch(NotificationSliceActions.patchState({ selectedPracticeId: id }));
    };

    const handleUpdateStatus = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closePatientStatusModal = () => {
        setAnchorEl(null); // Close the Popper
    }

    return (
        <>
            <Box className={`notificationWrap ${NotificationStyles.search_panal}`}>
                <Box sx={{ display: 'flex', width: { xs: '100%', sm: '100%', md: 'auto' } }} >
                    <img src="images/back.svg" className={NotificationStyles.backImg} onClick={handleNavigate} />
                    <TextField
                        className="notificationSearch"
                        value={searchTerm}
                        onChange={(e) => handleSearchTerm(e.target.value)}
                        sx={{ minWidth: '200px' }}
                        variant="standard"
                        placeholder='Search Users'
                        size='small'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CloseIcon sx={{ display: searchTerm ? "inline-block" : "none", fontSize: "22px" }} className={NotificationStyles.closeIcon} onClick={clearSearchTerm} />
                                </InputAdornment>
                            )
                        }}
                    />
                </Box>
                <Box className="flexCenterCenter selectContainer" gap={2}>
                    <AvantTooltip title={isDisabled() ? ADMIN_INFO_OVERALL_STATUS_UPDATES : ""} disableHoverListener={!isDisabled()}>
                        <span> {/* This span is needed because Tooltip requires a child node that can accept refs */}
                            <Button
                                onClick={(e) => handleUpdateStatus(e)}
                                className="btnSelect"
                                color="secondary"
                                variant="outlined"
                                disabled={isDisabled()}
                                sx={{
                                    padding: "2px 8px",
                                    minWidth: "90px",
                                    textTransform: "capitalize",
                                    borderRadius: "100px",
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography sx={{ textTransform: "capitalize", fontWeight: "500", flexGrow: 1 }}>
                                    Bulk Status Updates
                                </Typography>
                                <IconButton sx={{ p: "5px" }} onClick={handleUpdateStatus}>
                                    <ArrowDropDown />
                                </IconButton>
                            </Button>
                        </span>
                    </AvantTooltip>
                    <FormControl sx={{ minWidth: "250px" }} className="inputSelect">
                        <InputLabel
                            id="practice-select-label"
                            sx={{
                                fontSize: '15px',
                                fontWeight: 'bold'
                            }}
                        >
                            Practice
                        </InputLabel>
                        <Select
                            labelId="practice-select-label"
                            id="practice-select"
                            value={selectedPracticeId || 0}
                            label="Practice"
                            onChange={handlePracticeChange}
                            sx={{
                                borderRadius: '25px',
                                fontSize: '15px',
                                fontWeight: '500',
                                borderColor: 'secondary',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'secondary',
                                    borderRadius: '25px'
                                },
                                '& .MuiSelect-select': {
                                    padding: '9px 14px',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'secondary'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'secondary'
                                }
                            }}
                        >
                            {practices?.length ? practices.map(practice => (
                                <MenuItem
                                    key={practice.PracticeNameId}
                                    value={practice.PracticeNameId!}
                                    sx={{
                                        fontSize: '15px',
                                        fontWeight: '500'
                                    }}
                                >
                                    {practice.PracticeName}
                                </MenuItem>
                            )) : null}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <OverallPatientStatus
                anchorEl={anchorEl}
                handleClose={closePatientStatusModal}
            />
        </>
    );
}

export default NotificationsHeader