import React from "react";
import { Typography } from "@mui/material";

import AvantIndex from "../HomeIndex";

import LinkExpiredStyles from "./LinkExpired.module.css";

const LinkExpired = () => {
    const handleNavigation = () => {
        window.location.href = "#/";
    }

    return (
        <AvantIndex>
            <>
                <Typography className={LinkExpiredStyles.mainText}>The link has expired</Typography>
                <Typography component={'div'} onClick={handleNavigation} className={LinkExpiredStyles.navText} my={3}>Go to login page</Typography>
            </>
        </AvantIndex>
    );
}

export default LinkExpired