import { combineReducers } from "@reduxjs/toolkit";

import AuthSlice from "./reducers/login-slice";
import PatientSlice from "./reducers/patient-slice";
import DashboardSlice from "./reducers/dashboard-slice";
import UserSlice from "./reducers/user-slice";
import PracticeSlice from "./reducers/practice-slice";
import DoctorSlice from "./reducers/doctor-slice";
import NotificationSlice from "./reducers/notification-slice";
import OccupationSlice from "./reducers/occupation-slice";
import MailSlice from "./reducers/mail-slice";
import ReferralReasonsSlice from "./reducers/referral-reasons-slice";
import { resetState } from "./actions/reset-actions";

const appReducer = combineReducers({
    auth: AuthSlice,
    patients: PatientSlice,
    dashboard: DashboardSlice,
    users: UserSlice,
    practices: PracticeSlice,
    doctors: DoctorSlice,
    notifications: NotificationSlice,
    occupations: OccupationSlice,
    mails: MailSlice,
    referralReasons: ReferralReasonsSlice
})

const rootReducer = (state: any, action: any) => {
    if (action.type === resetState.type) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;