import { createAsyncThunk } from "@reduxjs/toolkit";

import { ResponseModel } from "../../models/patient.model";
import { ADMIN_ASSOCIATED_PRACTICES, ADMIN_PRACTICES, AUTH_PRACTICES_LIST, DOCTOR_LISTS_THROUGH_PRACTICE, DOCTOR_PRACTICES, DOCTOR_STAFF_PRACTICES, PRACTICES_LIST } from "../../constants/api-constants";
import api from "../../api";
import { adminPracticeResponseProps, doctorListsResponse, practicesBasedLoginProps, practicesResponseParseProps } from "../../models/practice.model";
import { ERROR_MESSAGE, USER_ROLES } from "../../constants/variable-constants";

const getAllPractices = createAsyncThunk(
    'practices/getAllPractices',
    async (payload: string, thunk) => {
        try {
            const response = await api.fetchGet<ResponseModel<practicesResponseParseProps[]>>(PRACTICES_LIST);
            return response.data
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

const getAuthAllPractices = createAsyncThunk(
    'practices/getAuthAllPractices',
    async (payload: { roleId: number | null }, thunk) => {
        const url = payload.roleId === USER_ROLES.USER_ADMIN ? ADMIN_ASSOCIATED_PRACTICES : AUTH_PRACTICES_LIST;
        try {
            const response = await api.fetchAuthGet<ResponseModel<practicesResponseParseProps[]>>(url);
            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage);
        }
    }
);

const getAllDoctorPractices = createAsyncThunk(
    'practices/getAllDoctorPractices',
    async (payload: string, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<practicesBasedLoginProps[]>>(DOCTOR_PRACTICES);
            return response.data
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

const getAllDoctorStaffPractices = createAsyncThunk(
    'practices/getAllDoctorStaffPractices',
    async (payload: string, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<practicesBasedLoginProps[]>>(DOCTOR_STAFF_PRACTICES);
            return response.data
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

const getAllAdminPractices = createAsyncThunk(
    'practices/getAllAdminPractices',
    async (payload: string, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<adminPracticeResponseProps[]>>(ADMIN_PRACTICES);
            return response.data
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

const getAllDoctorsThroughPractice = createAsyncThunk(
    'practices/getAllDoctorsThroughPractice',
    async (payload: { id: string }, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<doctorListsResponse[]>>(DOCTOR_LISTS_THROUGH_PRACTICE(payload?.id));
            return response.data
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

export const PracticeThunkApis = { getAllPractices, getAllDoctorsThroughPractice, getAllDoctorPractices, getAllDoctorStaffPractices, getAuthAllPractices, getAllAdminPractices };