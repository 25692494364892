import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { resetState } from "../actions/reset-actions";
import { occupationListProps, occupationSliceProps } from "../../models/occupation.model";
import { OccupationTunkApis } from "../actions/occupation-actions";
import { WritableDraft } from "immer/dist/internal";

const initialState: occupationSliceProps = {
    pageSize: 10,
    currentPage: 0,
    listLoading: false,
    totalOccupations: 0,
    searchTerm: null,
    subCategoryLists: [],
    openOccupationDialog: false,
    occupationList: [],
    editOccupation: null,
    deleteOccupation: null,
    categoriesByOccupation: []
};

const OccupationSlice = createSlice({
    name: 'occupations',
    initialState,
    reducers: {
        patchState(state, action: PayloadAction<Partial<occupationSliceProps>>) {
            return { ...state, ...action.payload };
        },
        setInitialState(state) {
            return {
                ...state,
                pageSize: 10,
                currentPage: 0,
                listLoading: false,
                totalOccupations: 0,
                searchTerm: null,
                subCategoryLists: [],
                occupationList: [],
                editOccupation: null,
                deleteOccupation: null,
                categoriesByOccupation: []
            }
        },
        removeOccupation: (state, { payload: { id } }: PayloadAction<{ id: number }>) => {
            if (state.occupationList) {
                state.occupationList = state.occupationList.filter(occupation => occupation.OccupationId !== id);
            }
        }
    },
    extraReducers: (builder) => {
        // To reset all the state variables
        builder.addCase(resetState, () => initialState);
        // getOccupationsWithSubCategories
        builder.addCase(OccupationTunkApis.getOccupationsWithSubCategories.pending, (state) => {
            state.listLoading = true;
        });
        builder.addCase(OccupationTunkApis.getOccupationsWithSubCategories.fulfilled, (state, action) => {
            state.listLoading = false;
            state.totalOccupations = action.payload.data.length || 0;

            // Define the expected type for occupation data
            const occupationData = Object.values(action.payload.data.reduce((acc, item) => {
                const { OccupationId, OccupationName, SubcategoryId, SubcategoryName } = item;
                if (!acc[OccupationId]) {
                    acc[OccupationId] = {
                        OccupationId,
                        OccupationName,
                        SubcategoryId: SubcategoryId ? [SubcategoryId] : [] as any,
                        SubcategoryName: SubcategoryName ? [SubcategoryName] : [] as any
                    };
                } else {
                    if (SubcategoryId) acc[OccupationId].SubcategoryId.push(SubcategoryId as any);
                    if (SubcategoryName) acc[OccupationId].SubcategoryName.push(SubcategoryName as any);
                }
                return acc;
            }, {} as Record<number, occupationListProps>));

            // Sorting the occupation data by OccupationId in descending order
            occupationData.sort((a, b) => b.OccupationId - a.OccupationId);

            state.occupationList = occupationData as WritableDraft<occupationListProps>[];
        });
        builder.addCase(OccupationTunkApis.getOccupationsWithSubCategories.rejected, (state) => {
            state.listLoading = false;
        });
        // getAllSubCategories
        builder.addCase(OccupationTunkApis.getAllSubCategories.fulfilled, (state, action) => {
            state.subCategoryLists = action.payload.data || [];
        });
        // getSubCategoriesByOccupation
        builder.addCase(OccupationTunkApis.getSubCategoriesByOccupation.fulfilled, (state, action) => {
            state.categoriesByOccupation = action.payload.data || [];
        });
    }
});

export const OccupationSliceActions = OccupationSlice.actions;
export default OccupationSlice.reducer;