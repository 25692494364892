import React, { useEffect, useMemo } from "react";
import {
    Box,
    CircularProgress,
    Dialog, DialogContent,
    DialogTitle, IconButton, SelectChangeEvent, Typography
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';

import { useAppDispatch, useAppSelector } from "../../../redux/Hooks";
import { PracticeSliceActions } from "../../../redux/reducers/practice-slice";
import { PracticeThunkApis } from "../../../redux/actions/practice-actions";
import PracticeAssociatedDoctorsTableRow from "./PracticeAssociatedDoctorsTableRow";
import ComponentLoader from "../../../components/ComponentLoader";
import UserTableHeader from "../../users/components/UsersTableHeader";
import AvantPaginationWithPageSize from "../../../components/pagination/PaginationWithPageSize";
import { userTableHeaders } from "../../../components/table-headers";

import ViewPracticeStyles from "../PracticeLists.module.css";

const PracticeAssociatedWithDoctorsDialog = () => {
    const dispatch = useAppDispatch();
    const { viewPractice, doctorListsLoading, doctorLists, currentPageForDoctors, pageSizeForDoctors } = useAppSelector(state => state.practices! || {});

    const paginatedUsers = useMemo(() => {
        const startIndex = currentPageForDoctors * pageSizeForDoctors;
        const endIndex = startIndex + pageSizeForDoctors;
        return doctorLists?.slice(startIndex, endIndex);
    }, [doctorLists, currentPageForDoctors, pageSizeForDoctors]);

    const resultPages = useMemo(() => Math.ceil(doctorLists?.length / pageSizeForDoctors), [doctorLists, pageSizeForDoctors]);

    const handleClose = () => {
        dispatch(PracticeSliceActions.patchState({ viewPractice: null }));
        dispatch(PracticeSliceActions.setInitialState());
    };

    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
        const size = event.target.value as any;
        dispatch(PracticeSliceActions.patchState({ currentPageForDoctors: 0, pageSizeForDoctors: size }));
    }

    const handlePageChange = (e: any, val: number) => {
        dispatch(PracticeSliceActions.patchState({ currentPageForDoctors: val - 1 }));
    };

    const renderTableRows = () => {
        if (!paginatedUsers.length) {
            return (
                <Box gridColumn="span 5" py={5}>
                    <Typography variant="h6" align="center">
                        No Users Found
                    </Typography>
                </Box>
            );
        }

        return paginatedUsers.map((user, index) => (
            <PracticeAssociatedDoctorsTableRow key={index} data={user} userIndex={index} />
        ));
    };

    useEffect(() => {
        if (viewPractice) {
            dispatch(PracticeThunkApis.getAllDoctorsThroughPractice({ id: viewPractice?.PracticeNameId?.toString() || '' }));
        }
    }, [dispatch, viewPractice]);

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={Boolean(viewPractice)}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <Typography sx={{ color: '#818080', fontSize: '18px', marginLeft: '15px' }}>
                    {`${viewPractice?.PracticeName} associated with the users`}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CancelIcon color="primary" />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box className={ViewPracticeStyles.container}>
                    {doctorListsLoading && !paginatedUsers.length ? (
                        <ComponentLoader />
                    ) : (
                        <Box display="flex" flexDirection="column">
                            <Box
                                className={ViewPracticeStyles.practiceTableBox}
                                display="grid"
                                gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
                            >
                                {userTableHeaders.map((header, index) => (
                                    <UserTableHeader label={header} key={index} hideAction={Boolean(viewPractice)} />
                                ))}
                                {renderTableRows()}
                            </Box>
                            <Box
                                display="grid"
                                gridAutoFlow="column"
                                alignItems="center"
                                justifyContent="flex-end"
                                marginTop={3}
                            >
                                {doctorListsLoading && (
                                    <Box mr={2} className="flexCenterCenter">
                                        <CircularProgress size={25} />
                                    </Box>
                                )}
                                <AvantPaginationWithPageSize
                                    pageSize={pageSizeForDoctors}
                                    handlePageSizeChange={handlePageSizeChange}
                                    page={currentPageForDoctors}
                                    totalPages={resultPages}
                                    disabled={doctorListsLoading || !doctorLists?.length}
                                    handlePageChange={handlePageChange}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default PracticeAssociatedWithDoctorsDialog;