import React, { useEffect, useState, useCallback } from 'react';
import {
    Box, Dialog, DialogContent, DialogContentText,
    DialogTitle, IconButton, TextField, Typography, Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CancelIcon from '@mui/icons-material/Cancel';

import { ToastAlert } from '../../../components/toast-alert';
import { useAppDispatch, useAppSelector } from '../../../redux/Hooks';
import api from '../../../api';
import { ADD_REFERRAL_REASON, UPDATE_REFERRAL_REASON } from '../../../constants/api-constants';
import { ERROR_MESSAGE, REFERRAL_REASON, httpStatusCode } from '../../../constants/variable-constants';
import { ReferralReasonsSliceActions, referralReasonsSelectors } from '../../../redux/reducers/referral-reasons-slice';
import { ReferralReasonsThunkApis } from '../../../redux/actions/referral-reason-actions';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

import ReferralReasonListsStyles from "../ReferralReasonList.module.css";

const AddOrEditReferralReasonDialog = () => {
    const [referralReasonName, setReferralReasonName] = useState<string | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const { referralReasonDetails, forceEditMsg } = useAppSelector(state => state.referralReasons || {});
    const referralReasonsData = useAppSelector(referralReasonsSelectors.selectAll);

    useEffect(() => {
        if (referralReasonDetails) {
            setReferralReasonName(referralReasonDetails.ReferralReasonName || '');
        }
    }, [referralReasonDetails]);

    const closeDialog = useCallback(() => {
        dispatch(ReferralReasonsSliceActions.patchState({ openReferralReasonDialog: false, referralReasonDetails: null }));
        setReferralReasonName(null);
    }, [dispatch]);

    const handleAddReferralReason = useCallback(async () => {
        try {
            const response = await api.fetchAuthPostWithBody(ADD_REFERRAL_REASON, { ReferralReasonName: referralReasonName });
            if (response.status === httpStatusCode.SUCCESS) {
                ToastAlert(REFERRAL_REASON.ADDED, 'success');
                closeDialog();
                dispatch(ReferralReasonsThunkApis.getReferralreasons(''));
            } else {
                throw new Error(REFERRAL_REASON.FAILED);
            }
        } catch (error: any) {
            ToastAlert(error.message || ERROR_MESSAGE, 'error');
        }
    }, [referralReasonName]);

    const handleUpdateReferralReason = useCallback(async () => {
        try {
            const response = await api.fetchAuthPutWithBody(UPDATE_REFERRAL_REASON, {
                ReferralReasonId: referralReasonDetails?.ReferralReasonId,
                ReferralReasonName: referralReasonName
            });
            if (response.status === httpStatusCode.SUCCESS) {
                if (response?.data?.allowEdit) {
                    dispatch(ReferralReasonsSliceActions.patchState({ forceEditMsg: response?.data?.message || "" }));
                } else {
                    ToastAlert(REFERRAL_REASON.UPDATED, 'success');
                    closeDialog();
                    dispatch(ReferralReasonsSliceActions.updateReferralReason({
                        id: referralReasonDetails?.ReferralReasonId!,
                        name: referralReasonName!
                    }));
                }
            } else {
                throw new Error(REFERRAL_REASON.FAILED);
            }
        } catch (error: any) {
            ToastAlert(error.message || ERROR_MESSAGE, 'error');
        }
    }, [referralReasonName, referralReasonDetails]);

    const handleForceUpdateReferralReason = async () => {
        setIsUpdating(true);
        try {
            const response = await api.fetchAuthPutWithBody(`${UPDATE_REFERRAL_REASON}?forceUpdate=true`, {
                ReferralReasonId: referralReasonDetails?.ReferralReasonId,
                ReferralReasonName: referralReasonName
            });
            if (response.status === httpStatusCode.SUCCESS) {
                ToastAlert(REFERRAL_REASON.UPDATED, 'success');
                closeDialog();
                dispatch(ReferralReasonsSliceActions.updateReferralReason({
                    id: referralReasonDetails?.ReferralReasonId!,
                    name: referralReasonName!
                }));
                dispatch(ReferralReasonsSliceActions.patchState({ forceEditMsg: null }))
            } else {
                throw new Error(REFERRAL_REASON.FAILED);
            }
        } catch (error: any) {
            ToastAlert(error.message || ERROR_MESSAGE, 'error');
        } finally {
            setIsUpdating(false);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (!referralReasonName) {
                ToastAlert(REFERRAL_REASON.NON_EMPTY, 'error');
                return;
            }
            if (!referralReasonDetails &&
                referralReasonsData.some(reason => reason.ReferralReasonName!.toLowerCase() === referralReasonName!.toLowerCase())) {
                ToastAlert(REFERRAL_REASON.ALREADY_EXISTS, 'error');
                return;
            }
            if (referralReasonDetails) {
                await handleUpdateReferralReason();
            } else {
                await handleAddReferralReason();
            }
        } catch (error: any) {
            ToastAlert(error.message || ERROR_MESSAGE, 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Dialog
            className='referalDialog'
                fullWidth
                maxWidth="sm"
                open={true}
                onClose={closeDialog}
                aria-labelledby="add-edit-referral-reason-dialog-title"
            >
                <IconButton
                    aria-label="close"
                    onClick={closeDialog}
                    sx={{
                        position: 'absolute',
                        right: 2,
                        top: 2,
                    }}
                >
                    <CancelIcon color="primary" />
                </IconButton>
                <DialogContent>
                    <DialogTitle id="add-edit-referral-reason-dialog-title" className='referalDialogTitle'>
                        <Typography className={ReferralReasonListsStyles.title}>Add/Edit Referral Reason</Typography>
                    </DialogTitle>
                    <DialogContentText>
                        <Box sx={{padding: {xs: '0', md: '0 25px', sm: '0 25px'}}}>
                            <TextField
                                id="referralReasonName"
                                name="referralReasonName"
                                variant="outlined"
                                size="small"
                                placeholder="Enter a referral reason name"
                                value={referralReasonName || ''}
                                onChange={(e) => setReferralReasonName(e.target.value)}
                                fullWidth
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '12px',
                                        padding: { md: '3px 5px', xs: '3px 0' },
                                    },
                                }}
                            />
                        </Box>
                        <Box mt={2} display="flex" justifyContent="center">
                            <Button
                                variant="outlined"
                                onClick={closeDialog}
                                sx={{
                                    padding: "6px 11px",
                                    minWidth: "120px",
                                    textTransform: "capitalize",
                                    borderRadius: "100px",
                                }}
                            >
                                <Typography sx={{ fontWeight: "500" }}>Cancel</Typography>
                            </Button>
                            <LoadingButton
                                variant="contained"
                                loading={isLoading}
                                onClick={handleSubmit}
                                sx={{
                                    padding: "6px 11px",
                                    minWidth: "120px",
                                    textTransform: "capitalize",
                                    borderRadius: "100px",
                                    marginLeft: "10px",
                                }}
                            >
                                <Typography sx={{ fontWeight: "500" }}>Save</Typography>
                            </LoadingButton>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <ConfirmationDialog
                open={!!forceEditMsg}
                accept={handleForceUpdateReferralReason}
                reject={() => dispatch(ReferralReasonsSliceActions.patchState({ forceEditMsg: null }))}
                msg={forceEditMsg!}
                acceptText={"Proceed"}
                rejectText={"Cancel"}
                isLoading={isUpdating}
            />
        </>
    );
};

export default AddOrEditReferralReasonDialog;