import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from "@mui/lab";
import { Box, Checkbox, Grid, IconButton, MenuItem, Typography, Popper, Paper, Button, Fade } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../redux/Hooks";
import { AvantTooltip } from "../../../components/tool-tip/Tooltip";
import { NotificationSliceActions, notificationUserSelectors } from "../../../redux/reducers/notification-slice";
import { CHECKED_USER, USER_ROLES, USER_ROLE_NAMES, VIEW_LIST } from "../../../constants/variable-constants";

import NotificationStyles from "../Notifications.module.css";
interface updatePatientStatusProps {
    handleClose: () => void;
    anchorEl: HTMLElement | null;
}

const OverallPatientStatus = ({ handleClose, anchorEl }: updatePatientStatusProps) => {
    const dispatch = useAppDispatch();
    const { roleId } = useAppSelector(state => state.auth.userDetails! || {});
    const { patientStatus } = useAppSelector(state => state.patients! || {});
    const { selectedPracticeId, viewList, selectedUsers } = useAppSelector(state => state.notifications! || {});

    const [patientStatusIds, setPatientStatusIds] = useState<number[]>([]);
    const [isOverflowing, setIsOverflowing] = useState<Record<number, boolean>>({});
    const [isLoading, setLoading] = useState<boolean>(false);
    const textRef = useRef<HTMLDivElement>(null);

    const usersData = useAppSelector(notificationUserSelectors.selectAll);

    // Track if all statuses are selected
    const allSelected = patientStatus?.length! > 0 && patientStatus?.length === patientStatusIds?.length;

    const isSuperAdmin = roleId === USER_ROLES.SUPER_ADMIN;
    const isAdminView = viewList === VIEW_LIST.ADMIN;

    // Filter users based on role
    const users = useMemo(() => {
        return !!isSuperAdmin
            ? usersData
            : usersData?.filter(user => user.RoleName !== USER_ROLE_NAMES.ADMIN);
    }, [roleId, usersData]);

    const isDisabled = useCallback(() => {
        // If there are no selected users or admin view, disable by default
        if ((!!isSuperAdmin && !selectedUsers?.length) || !!isAdminView) {
            return true;
        }

        // Check if any selected user is found and has `CHECKED_USER` status
        return !selectedUsers.some(selected => {
            const user = users?.find(user => user.UserId === selected.userId);
            return user && selected.isSelected === CHECKED_USER;
        });
    }, [users, selectedUsers, viewList]);

    useEffect(() => {
        if (!viewList || !isDisabled()) {
            return;
        }
        handleClose();
    }, [viewList, selectedUsers]);

    const patientStatusChangeHandler = (statusId: number) => {
        setPatientStatusIds(prevIds =>
            prevIds.includes(statusId)
                ? prevIds.filter(id => id !== statusId)
                : [...prevIds, statusId]
        );
    };

    const handleSelectAllChange = () => {
        if (allSelected) {
            // If all are selected, unselect all
            setPatientStatusIds([]);
        } else {
            // Select all
            const allStatusIds = patientStatus?.map(status => status.PatientStatusId!) || [];
            setPatientStatusIds(allStatusIds);
        }
    };

    useEffect(() => {
        const newIsOverflowing: Record<number, boolean> = {};
        patientStatus?.forEach((_, index) => {
            const element = document.getElementById(`status-text-${index}`);
            if (element) {
                newIsOverflowing[index] = element.scrollWidth > element.clientWidth;
            }
        });
        setIsOverflowing(newIsOverflowing);
    }, [patientStatus]);

    // whenever we change the practice, to reset the patientStatusIds state.
    useEffect(() => {
        if (!selectedPracticeId) {
            return;
        }
        setPatientStatusIds([]);
    }, [selectedPracticeId]);

    const handleSubmit = () => {
        dispatch(NotificationSliceActions.patchState({ practiceOrientedPatientStatusIds: patientStatusIds, isOverallPatientStatusEnabled: true }));
        handleClose();
    }

    return (
        <Popper className="customdropMenu" open={!!anchorEl} anchorEl={anchorEl} placement="bottom" transition
            sx={{ zIndex: 1300 }}
            modifiers={[
                {
                    name: 'preventOverflow',
                    options: {
                        boundary: 'viewport',
                    },
                },
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
                {
                    name: 'flip',
                    options: {
                        altBoundary: true,
                        fallbackPlacements: ['top-start'],
                    },
                },
            ]}>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper elevation={3}>
                        <Box p={2} className="innerContainer">
                            <Grid container width={'100%'}>
                                <Grid item sm={12} md={12} className="customContainerHeader">
                                    <IconButton
                                        aria-label="close"
                                        onClick={handleClose}
                                        sx={{
                                            position: 'absolute',
                                            right: 2,
                                            top: 2,
                                        }}
                                    >
                                        <CancelIcon color={'primary'} />
                                    </IconButton>
                                    <Typography className={`${NotificationStyles.title} flexCenterCenter`}>Add/Update Patient Status</Typography>
                                    <Box className={NotificationStyles.existingPatientStatusList} my={2} height={250}>
                                        {/* Select All Checkbox */}
                                        <MenuItem onClick={handleSelectAllChange}>
                                            <Checkbox
                                                sx={{ p: 0, mr: 1, color: '#6fc1e8', '& .MuiSvgIcon-root': { fontSize: 24 } }}
                                                checked={patientStatusIds.length > 0}
                                                indeterminate={patientStatusIds.length > 0 && patientStatusIds.length < patientStatus?.length!}
                                            />
                                            <Typography>Select All</Typography>
                                        </MenuItem>
                                        {patientStatus?.map((status, index) => (
                                            <MenuItem
                                                key={index}
                                                onKeyDown={(e) => e.stopPropagation()}
                                                onClick={() => patientStatusChangeHandler(status.PatientStatusId!)}
                                            >
                                                <Checkbox
                                                    sx={{ p: 0, mr: 1, color: '#6fc1e8', '& .MuiSvgIcon-root': { fontSize: 24 } }}
                                                    checked={!!patientStatusIds?.includes(status.PatientStatusId!)}
                                                />
                                                <AvantTooltip title={isOverflowing[index] ? status.PatientStatusName : null} placement="top" followCursor>
                                                    <div
                                                        id={`status-text-${index}`}
                                                        ref={textRef}
                                                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                    >
                                                        {status.PatientStatusName}
                                                    </div>
                                                </AvantTooltip>
                                            </MenuItem>
                                        ))}
                                    </Box>
                                    <Box className="flexCenterCenter" gap={2} my={2}>
                                        <Button
                                            onClick={handleClose}
                                            color="primary"
                                            variant="outlined"
                                            sx={{
                                                padding: "6px 11px",
                                                minWidth: "70px",
                                                textTransform: "capitalize",
                                                borderRadius: "100px"
                                            }}>
                                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Cancel</Typography>
                                        </Button>
                                        <LoadingButton
                                            loading={isLoading}
                                            onClick={handleSubmit}
                                            color="primary"
                                            disableElevation
                                            disabled={!patientStatus?.length}
                                            variant="contained"
                                            sx={{
                                                padding: "6px 11px",
                                                minWidth: "70px",
                                                textTransform: "capitalize",
                                                borderRadius: "100px"
                                            }}
                                        >
                                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Save</Typography>
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Fade>
            )}
        </Popper>
    );
}

export default OverallPatientStatus;