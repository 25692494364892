import api from "../../api";
import { LOGOUT } from "../../constants/api-constants";
import { LOGOUT_SUCCESS_MSG, SESSION_KEYS, httpStatusCode } from "../../constants/variable-constants";
import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import { resetState } from "../../redux/actions/reset-actions";
import { sessionStorageRemove } from "../session-storage-helpers/session-storage-actions";
import { ToastAlert } from "../toast-alert";
import { handleErrorMsg } from "../utils";

export const useLogoutService = () => {
    const dispatch = useAppDispatch();
    const { userId } = useAppSelector(state => state.auth.userDetails! || {});

    const logout = async () => {
        try {
            const logoutRes = await api.fetchAuthPutWithBody<any>(LOGOUT(userId!), {});
            if (logoutRes?.status === httpStatusCode?.SUCCESS) {
                const message = logoutRes?.data?.message || LOGOUT_SUCCESS_MSG;
                ToastAlert(message, 'success');
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            clearLoginState();
        }
    }

    const clearLoginState = () => {
        window.location.href = "#/";
        sessionStorageRemove(SESSION_KEYS.TOKEN);
        sessionStorageRemove(SESSION_KEYS.USER_ID);
        dispatch(resetState());
    }

    return { logout, clearLoginState }
}