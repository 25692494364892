import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../api";
import { GET_USERS_THROUGH_PRACTICE, SELECTED_USERS_NOTIFICATION } from "../../constants/api-constants";
import { ResponseModel } from "../../models/patient.model";
import { handleErrorMsg } from "../../components/utils";
import { doctorListsResponse } from "../../models/practice.model";

const getNotificationUserLists = createAsyncThunk(
    'notifications/getNotificationUserLists',
    async (payload: { id: number }, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<doctorListsResponse[]>>(GET_USERS_THROUGH_PRACTICE(payload.id));
            return response.data
        } catch (error: any) {
            handleErrorMsg(error);
            return thunk.rejectWithValue(error?.response?.data?.message);
        }
    }
)

const getSelectedUsersNotificationList = createAsyncThunk(
    'notifications/getSelectedUserNotificationList',
    async (payload: { id: number }, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<{ UserId: number, RoleId: number | null }[]>>(SELECTED_USERS_NOTIFICATION(payload.id));
            return response.data
        } catch (error: any) {
            handleErrorMsg(error);
            return thunk.rejectWithValue(error?.response?.data?.message);
        }
    }
)

export const NotificationThunkApis = { getNotificationUserLists, getSelectedUsersNotificationList }