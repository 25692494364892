import { createEntityAdapter, createSlice, PayloadAction, EntityId } from "@reduxjs/toolkit";

import { RootState } from "../Store";
import { userSliceProps, usersDetailResponse } from "../../models/user.model";
import { UserThunkApis } from "../actions/user-actions";
import { resetState } from "../actions/reset-actions";
import { userRoles } from "../../constants/variable-constants";

const userAdapter = createEntityAdapter<usersDetailResponse>({
    selectId: (user) => {
        // Ensure userId is not null
        if (user.userId === null) {
            throw new Error('userId cannot be null');
        }
        return user.userId as EntityId;
    },
    sortComparer: (a, b) => {
        if (a.userId === null || b.userId === null) {
            return 0;
        }
        return b.userId! - a.userId!;
    },
});

const initialState = userAdapter.getInitialState<userSliceProps>({
    pagesize: 10,
    currentPage: 0,
    listLoading: false,
    totalUsers: 0,
    searchTerm: null,
    userDetails: null,
    userDetailsLoading: false,
    statusChangingUserId: null,
    statusMsg: null,
    deleteUserId: null,
    userListId: 1,
    approvedUserId: null,
    rejectedUserId: null,
    isUsersOpen: false,
    occupationId: null,
    subcategoryId: null,
    roleId: null,
    practiceNameId: null,
    selectedUserMails: [],
    isMenuOpen: false,
    highlightUserId: null,
    activeUserList: { approved: true, waiting: false, rejected: false }
});

const UserSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        patchState(state, action: PayloadAction<Partial<userSliceProps>>) {
            return { ...state, ...action.payload };
        },
        setInitialState(state) {
            return {
                ...state,
                pagesize: 10,
                currentPage: 0,
                totalUsers: 0,
                searchTerm: null,
                approvedUserId: null,
                rejectedUserId: null,
                highlightUserId: null
            }
        },
        removeUser: (state, action: PayloadAction<{ id: number }>) => {
            userAdapter.removeOne(state, action.payload.id);
        },
        toggleUserSelection: (state, action: PayloadAction<string>) => {
            if (state.selectedUserMails.includes(action.payload)) {
                state.selectedUserMails = state.selectedUserMails.filter(mail => mail !== action.payload);
            } else {
                state.selectedUserMails.push(action.payload);
            }
        },
        toggleAllUsers: (state) => {
            if (state.selectedUserMails.length === state.ids.length) {
                state.selectedUserMails = [];
            } else {
                state.selectedUserMails = Object.keys(state.entities).map(userId => state.entities[userId]?.emailAddress!);
            }
        },
    },
    extraReducers: (builder) => {
        // To reset all the state variables
        builder.addCase(resetState, () => initialState);
        // getUserLists
        builder.addCase(UserThunkApis.getUserLists.pending, (state) => {
            state.listLoading = true;
        });
        builder.addCase(UserThunkApis.getUserLists.fulfilled, (state, action) => {
            state.listLoading = false;
            state.totalUsers = action.payload.total!;
            userAdapter.setAll(state, action.payload.data || []);
        });
        builder.addCase(UserThunkApis.getUserLists.rejected, (state) => {
            state.listLoading = false;
        });
        // getFilterUserLists
        builder.addCase(UserThunkApis.getFilterUserLists.pending, (state) => {
            state.listLoading = true;
        });
        builder.addCase(UserThunkApis.getFilterUserLists.fulfilled, (state, action) => {
            state.listLoading = false;
            state.totalUsers = action.payload.total || 0;

            const alignedUsersData = action.payload.data?.map(user => {
                const roleName = userRoles.find(role => role.value === user.RoleId)?.label || null;

                return {
                    createdOn: user.CreatedOn || null,
                    emailAddress: user.EmailAddress || null,
                    firstName: user.FirstName || null,
                    lastName: user.LastName || null,
                    occupationId: user.OccupationId || null,
                    occupationName: user.OccupationName || null,
                    phoneNumber: user.PhoneNumber || null,
                    practiceNameId: user.PracticeNameId || null,
                    roleName,
                    userId: user.UserId || null,
                    subcategoryId: user.SubcategoryId || null
                };
            });

            userAdapter.setAll(state, alignedUsersData as any || []);
        });
        builder.addCase(UserThunkApis.getFilterUserLists.rejected, (state) => {
            state.listLoading = false;
        });
        //getUserDetails 
        builder.addCase(UserThunkApis.getUserDetails.pending, (state) => {
            state.userDetailsLoading = true
        });
        builder.addCase(UserThunkApis.getUserDetails.fulfilled, (state, action) => {
            state.userDetailsLoading = false
            state.userDetails = action?.payload?.data
        });
        builder.addCase(UserThunkApis.getUserDetails.rejected, (state) => {
            state.userDetailsLoading = false
        });
    },
});

export const userSelectors = userAdapter.getSelectors<RootState>((state) => state.users);

export const UserSliceActions = UserSlice.actions;
export default UserSlice.reducer;