import React from 'react';
import { createHashRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import useIdleTimerPopUp from './components/custom-hook/useIdleTimerPopUp';
import SessionExpiredDialog from './components/SessionExpiredDialog';
import LoginLayout from './pages/LoginLayout';
import Login from './pages/login/Login';
import SignUp from './pages/sign-up/SignUp';
import ResetPassword from './pages/reset-password/ResetPassword';
import PatientLists from './pages/patients/PatientLists';
import Dashboard from './pages/dashboard/Dashboard';
import Profile from './pages/profile/Profile';
import UpdatePatient from './pages/update-patient/UpdatePatient';
import LayoutWithHeaderSidebar from './components/LayoutWithHeaderSidebar';
import UserLists from './pages/users/UserLists';
import UpdateUser from './pages/update-user/UpdateUser';
import PracticeLists from './pages/practice/PracticeLists';
import UpdatePractice from './pages/update-practice/UpdatePractice';
import ChangePassword from './pages/ChangePassword';
import DoctorLists from './pages/doctors/DoctorLists';
import Notifications from './pages/notifications/Notifications';
import UnAuthorized from './pages/un-authorized/UnAuthorized';
import LinkExpired from './pages/link-expired/LinkExpired';
import OccupationList from './pages/occupations/OccupationList';
import CustomNotifications from './pages/custom-notifications/CustomNotifications';
import MailList from './pages/mail-list/MailList';
import ReferralReasonList from './pages/referral-reasons/ReferralReasonList';
import { SESSION_EXPIRED_MSG } from './constants/variable-constants';

// CSS file
import './css/App.css';

const routes = createHashRouter(
    createRoutesFromElements(
        <Route>
            <Route path="/" element={<LoginLayout />}>
                <Route index element={<Login />} />
                <Route path='sign-up' element={<SignUp />} />
                <Route path='resetpassword' element={<ResetPassword />} />
                <Route path='changePassword' element={<ChangePassword />} />
                <Route path='link-expired-change-password' element={<LinkExpired />} />
            </Route>
            <Route path='/home' element={<LayoutWithHeaderSidebar />}>
                <Route path='patient_lists'>
                    <Route path='' element={<PatientLists />} />
                    <Route path='summary_status/:statusId'>
                        <Route path='' element={<PatientLists />} />
                    </Route>
                    <Route path="create" element={<UpdatePatient />} />
                    <Route path=':patientId'>
                        <Route path='' element={<UpdatePatient />} />
                        <Route path="view" element={<UpdatePatient />} />
                    </Route>
                </Route>
                <Route path='user_lists'>
                    <Route path='' element={<UserLists />} />
                    <Route path='mail-list' element={<MailList />} />
                    <Route path="add-user" element={<UpdateUser />} />
                    <Route path=':userId'>
                        <Route path='' element={<UpdateUser />} />
                        <Route path="view" element={<UpdateUser />} />
                    </Route>
                </Route>
                <Route path='practice_lists'>
                    <Route path='' element={<PracticeLists />} />
                    <Route path="add-practice" element={<UpdatePractice />} />
                    <Route path=':practiceId'>
                        <Route path='' element={<UpdatePractice />} />
                    </Route>
                </Route>
                <Route path='doctor_lists'>
                    <Route path='' element={<DoctorLists />} />
                </Route>
                <Route path='occupation_lists'>
                    <Route path='' element={<OccupationList />} />
                </Route>
                <Route path='dashboard' element={<Dashboard />} />
                <Route path='profile' element={<Profile />} />
                <Route path='notifications' element={<Notifications />} />
                <Route path='custom-notifications' element={<CustomNotifications />} />
                <Route path='referral-reasons' element={<ReferralReasonList />} />
                <Route path='403' element={<UnAuthorized />} />
            </Route>
        </Route>
    )
);

function App() {
    const { open, accept, reject } = useIdleTimerPopUp({ defaultTimeout: 15 * 60 * 1000 }); // Show a session expired popup after 15 mins
    return (
        <div className="App">
            <SessionExpiredDialog
                open={open}
                accept={accept}
                reject={reject}
                msg={SESSION_EXPIRED_MSG}
                acceptText={"Yes"}
                rejectText={"No"}
            />
            <ToastContainer autoClose={5000} />
            <RouterProvider router={routes} />
        </div>
    );
}

export default App;