import React, { useEffect, useState } from "react";
import {
    Autocomplete,
    Box, Button, Chip, FormControl, FormHelperText, Grid, IconButton, InputAdornment,
    ListItem,
    ListItemText,
    MenuItem, Popper, Select, SelectChangeEvent, Switch, TextField, Typography
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { Delete, Edit } from "@mui/icons-material";
import MaskedInput from "react-text-mask";

import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import {
    ADD_USER_FAILED_MSG, DELETE_PRACTICE_FAILED_MSG, DELETE_PRACTICE_SUCCESS_MSG, DOCTOR_ROLE, DOCTOR_STAFF_ROLE, DOCTOR_STAFF_SINGLE_PRACTICE_VALIDATION, OTHER_OCCUPATION, RESET_PASSWORD_FAILED_MSG,
    UPDATE_USER_FAILED_MSG, USER_APPROVED_FAILED_MSG, USER_APPROVED_SUCCESS_MSG, USER_DELETE_FAILED_MSG, USER_DELETE_SUCCESS_MSG,
    USER_REJECTED_FAILED_MSG, USER_REJECTED_SUCCESS_MSG, USER_ROLES, USER_ROLE_NAMES, USER_STATUS_FAILED_MSG, httpStatusCode, roleList, userRoles
} from "../../constants/variable-constants";
import { ResponseModel, patientOccupationsResponse } from "../../models/patient.model";
import { ToastAlert } from "../../components/toast-alert";
import { PatientThunkApis } from "../../redux/actions/patient-actions";
import api from "../../api";
import { ADD_USER, APPROVE_USER, DELETE_PRACTICE, GET_USER_DETAILS, REJECT_USER, USER_PASSWORD_RESET, USER_STATUS } from "../../constants/api-constants";
import { UserThunkApis } from "../../redux/actions/user-actions";
import { usersDetailResponse } from "../../models/user.model";
import ComponentLoader from "../../components/ComponentLoader";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { UserSliceActions } from "../../redux/reducers/user-slice";
import { PracticeSliceActions, practiceSelectors } from "../../redux/reducers/practice-slice";
import { AvantTooltip } from "../../components/tool-tip/Tooltip";
import { adminPracticeResponseProps, practicesResponseParseProps } from "../../models/practice.model";
import { PracticeThunkApis } from "../../redux/actions/practice-actions";
import { convertToArray, handleErrorMsg } from "../../components/utils";
import { OccupationTunkApis } from "../../redux/actions/occupation-actions";
import { subCategoriesByOccupationProps } from "../../models/occupation.model";

import UpdateUserStyles from "./UpdateUser.module.css";

const UpdateUser = () => {
    const { userId } = useParams<{ userId: string }>();

    const [userAdding, setUserAdding] = useState<boolean>(false);
    const [practiceDeleting, setPracticeDeleting] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [statusChanging, setStatusChanging] = useState<boolean>(false);
    const [isApproving, setIsApproving] = useState<boolean>(false);
    const [isRejecting, setIsRejecting] = useState<boolean>(false);
    const [deletedPractice, setDeletedPractice] = useState<practicesResponseParseProps | adminPracticeResponseProps | null>(null);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const practiceLists = useAppSelector(practiceSelectors.selectAll);
    const { roleId } = useAppSelector(state => state.auth.userDetails! || {});
    const { patientOccupations } = useAppSelector(state => state.patients! || {});
    const { categoriesByOccupation } = useAppSelector(state => state.occupations! || {});
    const { showDoctorStaffDialog, adminPractices, editedPracticeId } = useAppSelector(state => state.practices || {});
    const { userDetails, userDetailsLoading, deleteUserId, statusChangingUserId, statusMsg, searchTerm, pagesize, currentPage, userListId, approvedUserId, rejectedUserId } = useAppSelector(state => state.users! || {});

    const isViewPath = location.pathname?.includes("view");

    useEffect(() => {
        if (!userId) {
            return;
        }
        dispatch(UserThunkApis.getUserDetails({ userId: userId! }));
    }, [userId]);

    const addingValues: usersDetailResponse = {
        firstName: '',
        lastName: '',
        emailAddress: '',
        practiceName: '',
        practiceNameId: '',
        roleId: showDoctorStaffDialog ? DOCTOR_STAFF_ROLE : DOCTOR_ROLE,
        createdBy: 1,
        updatedBy: null,
        phoneNumber: '',
        occupationId: 2,
        otherOccupation: '',
        profilePicPath: '',
        isDisabled: 0,
        subcategoryId: null
    }

    const updatingValues: usersDetailResponse = { ...userDetails!, practiceNameId: userDetails ? convertToArray(userDetails?.practiceNameId as any) : [] };

    const validationSchema = Yup.object({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        emailAddress: Yup.string().email("Invalid email address").required("Email is required"),
        roleId: Yup.number().required(),
        phoneNumber: Yup.string()
            .required("Phone number is required")
            .matches(/^\d+$/, "Phone number must be only digits")
            .transform((value, originalValue) => originalValue ? originalValue.replace(/\D/g, '') : originalValue)
            .min(10, "Phone number must be exactly 10 digits")
            .max(10, "Phone number must be exactly 10 digits"),
        practiceNameId: Yup.array().of(Yup.number()).when('roleId', {
            is: (roleId: number) => roleId === USER_ROLES.USER_ADMIN || roleId === DOCTOR_ROLE,
            then: schema => schema.required('Practice name is required'),
            otherwise: schema => schema
                .test(
                    'is-single-practice-for-doctor-staff',
                    DOCTOR_STAFF_SINGLE_PRACTICE_VALIDATION,
                    function (value) {
                        const { roleId } = this.parent;
                        if (roleId === DOCTOR_STAFF_ROLE && value && value.length > 1) {
                            return false;
                        }
                        return true;
                    }
                ),
        }),
    });

    useEffect(() => {
        dispatch(PatientThunkApis.getPatientOccupations(''));
        dispatch(PracticeThunkApis.getAuthAllPractices({ roleId: roleId! }));
    }, [dispatch]);

    const handleDefaultOccupation = () => {
        formik.handleChange('occupationId')('2');
        formik.handleChange('otherOccupation')('');
        formik.handleChange('subcategoryId')('');
    }

    const formik = useFormik({
        initialValues: userId && userDetails ? updatingValues : addingValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            await userId ? updateUserDetails(values) : addUserDetails(values);
        },
    });

    const addUserDetails = async (values: usersDetailResponse) => {
        setUserAdding(true);
        try {
            const userRes = await api.fetchAuthPostWithBody<{ message: string, userId: number }>(ADD_USER, values);
            if (userRes?.status === httpStatusCode.SUCCESS) {
                ToastAlert(userRes?.data?.message, 'success');
                handleNavigate();
            } else if (userRes?.status === httpStatusCode.CREATED) {
                ToastAlert(userRes?.data?.message, 'error');
            } else {
                throw new Error(ADD_USER_FAILED_MSG);
            }
        } catch (error: any) {
            console.error('Failed to add the user', error);
            handleErrorMsg(error);
        } finally {
            setUserAdding(false);
            dispatch(PracticeSliceActions.patchState({ editedPracticeId: null }));
        }
    }

    const updateUserDetails = async (values: usersDetailResponse) => {
        setUserAdding(true);
        try {
            const userRes = await api.fetchAuthPutWithBody<{ auth: boolean, message: string }>(GET_USER_DETAILS(userId!), values);
            if (userRes?.status === httpStatusCode.SUCCESS) {
                ToastAlert(userRes?.data?.message, 'success');
                handleNavigate();
            } else {
                throw new Error(UPDATE_USER_FAILED_MSG);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setUserAdding(false);
        }
    }

    const handlePracticeChange = (event: React.SyntheticEvent<Element, Event>, value: practicesResponseParseProps[] | null | any) => {
        if (value) {
            const practiceNameIds = value.map((practice: practicesResponseParseProps) => practice.PracticeNameId);
            formik.setFieldValue('practiceNameId', practiceNameIds);
        } else {
            formik.setFieldValue('practiceNameId', '');
        }
    };

    const handleAdminPracticeChange = (event: React.SyntheticEvent<Element, Event>, value: adminPracticeResponseProps[] | null | any) => {
        if (value) {
            const practiceNameIds = value.map((practice: adminPracticeResponseProps) => practice.PracticeNameId);
            formik.setFieldValue('practiceNameId', practiceNameIds);
        } else {
            formik.setFieldValue('practiceNameId', '');
        }
    };

    const handleRoleChange = (event: SelectChangeEvent<number>) => {
        const roleId = event.target.value as number;
        formik.setFieldValue('roleId', roleId);
        //  If the selected role is DOCTOR_STAFF_ROLE, clear the occupationId and subcategoryId fields
        if (roleId === DOCTOR_STAFF_ROLE) {
            formik.setFieldValue('occupationId', null);
            formik.setFieldValue('subcategoryId', null);
        }
    };

    const handleOccupationChange = (event: SelectChangeEvent<number>) => {
        const occupationId = event.target.value as number;
        formik.setFieldValue('occupationId', occupationId);
        formik.setFieldValue('subcategoryId', null);
    }

    const handleDisabilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked ? 1 : 0;
        formik.setFieldValue('isDisabled', isChecked);
    }

    const handleNavigate = () => {
        const path = editedPracticeId ? editedPracticeId : "add-practice"
        dispatch(PracticeSliceActions.patchState({ showDoctorStaffDialog: false }));
        const moveTo = showDoctorStaffDialog ? `/home/practice_lists/${path}` : '/home/user_lists';
        navigate(moveTo);
    }

    const resetForm = () => {
        formik.resetForm();
    }

    const handleOpenPractice = async () => {
        await dispatch(UserSliceActions.patchState({ isUsersOpen: false }));
        navigate(`/home/practice_lists/add-practice`);
    }

    const handleEditPractice = async (event: any, practice: practicesResponseParseProps | adminPracticeResponseProps) => {
        event.stopPropagation();
        await dispatch(UserSliceActions.patchState({ isUsersOpen: false }));
        navigate(`/home/practice_lists/${practice.PracticeNameId}`);
    }

    const openDeletePracticeDialog = async (event: any, practice: practicesResponseParseProps | adminPracticeResponseProps) => {
        event.stopPropagation();
        setDeletedPractice(practice);
    }

    const handleDeletePractice = async () => {
        setPracticeDeleting(true);
        try {
            const response = await api.fetchAuthPostWithBody<ResponseModel<practicesResponseParseProps[]>>(DELETE_PRACTICE, { PracticeNameId: deletedPractice?.PracticeNameId });
            if (response.status === httpStatusCode.SUCCESS) {
                dispatch(PracticeSliceActions.removePractice({ id: deletedPractice?.PracticeNameId! }));
                dispatch(PracticeSliceActions.removeAssociatedPractices({ id: deletedPractice?.PracticeNameId! }));
                ToastAlert(DELETE_PRACTICE_SUCCESS_MSG, 'success');
                setDeletedPractice(null);
            } else {
                throw new Error(DELETE_PRACTICE_FAILED_MSG);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setPracticeDeleting(false);
        }
    }

    const handleResetPassword = async () => {
        try {
            const resetRes = await api.fetchAuthPostWithBody<{ message: string }>(USER_PASSWORD_RESET, { userName: userDetails?.emailAddress });
            if (resetRes?.status === httpStatusCode.SUCCESS) {
                ToastAlert(resetRes?.data?.message, 'success');
            } else {
                throw new Error(RESET_PASSWORD_FAILED_MSG);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        }
    }

    const openDeleteUserDialog = () => {
        dispatch(UserSliceActions.patchState({ deleteUserId: +userId! }));
    }

    const handleDeleteUser = async () => {
        setIsDeleting(true);
        try {
            const deleteRes = await api.deleteAuth<ResponseModel<{}>>(GET_USER_DETAILS(deleteUserId!));
            if (deleteRes?.status === httpStatusCode.SUCCESS) {
                ToastAlert(USER_DELETE_SUCCESS_MSG, 'success');
                await dispatch(UserSliceActions.removeUser({ id: deleteUserId! }));
                dispatch(UserSliceActions.patchState({ deleteUserId: null }));
                handleNavigate();
            } else {
                throw new Error(USER_DELETE_FAILED_MSG);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setIsDeleting(false);
        }
    }

    const openStatusDialog = () => {
        const statusMsg = userDetails?.isDisabled === 1 ? 'enable' : 'disable';
        dispatch(UserSliceActions.patchState({ statusChangingUserId: +userId!, statusMsg }));
    }

    useEffect(() => {
        if (!formik.values.occupationId) {
            return;
        }
        dispatch(OccupationTunkApis.getSubCategoriesByOccupation({ id: formik.values.occupationId! }));
        if (formik.values.occupationId === OTHER_OCCUPATION.ID) {
            formik.setFieldValue('subcategoryId', null);
        }
    }, [formik.values.occupationId]);

    const handleUserStatus = async () => {
        setStatusChanging(true);
        try {
            const searchParams = {
                query: searchTerm ? searchTerm : "",
                pagesize,
                page: currentPage,
                t: userListId
            }
            const statusRes = await api.fetchAuthPostWithBody<ResponseModel<{}>>(USER_STATUS(statusMsg!, statusChangingUserId!), {});
            if (statusRes?.status === httpStatusCode.SUCCESS) {
                ToastAlert(`User ${statusMsg}d successfully`, 'success');
                dispatch(UserSliceActions.patchState({ statusChangingUserId: null, statusMsg: null }));
                dispatch(UserThunkApis.getUserLists({ userBody: searchParams, roleId: roleId! }));
            } else {
                throw new Error(USER_STATUS_FAILED_MSG);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setStatusChanging(false);
        }
    }

    const openApprovedUserDialog = () => {
        dispatch(UserSliceActions.patchState({ approvedUserId: +userId! }));
    }

    const handleApprovedUser = async () => {
        setIsApproving(true);
        try {
            const approveRes = await api.fetchAuthPostWithBody<ResponseModel<usersDetailResponse>>(APPROVE_USER(approvedUserId!), {});
            if (approveRes?.status === httpStatusCode.SUCCESS) {
                ToastAlert(USER_APPROVED_SUCCESS_MSG, 'success');
                dispatch(UserSliceActions.patchState({ approvedUserId: null }));
                handleNavigate();
            } else {
                throw new Error(USER_APPROVED_FAILED_MSG);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setIsApproving(false);
        }
    }

    const openRejectedUserDialog = () => {
        dispatch(UserSliceActions.patchState({ rejectedUserId: +userId! }));
    }

    const handleRejectedUser = async () => {
        setIsRejecting(true);
        try {
            const rejectRes = await api.fetchAuthPostWithBody<ResponseModel<{}>>(REJECT_USER(rejectedUserId!), {});
            if (rejectRes?.status === httpStatusCode.SUCCESS) {
                ToastAlert(USER_REJECTED_SUCCESS_MSG, 'success');
                dispatch(UserSliceActions.patchState({ rejectedUserId: null }));
                handleNavigate();
            } else {
                throw new Error(USER_REJECTED_FAILED_MSG);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setIsRejecting(false);
        }
    }

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('phoneNumber', event.target.value);
    }

    useEffect(() => {
        return () => {
            resetForm();
        }
    }, []);

    return (
        <Box className={UpdateUserStyles.container}>
            <Box className={UpdateUserStyles.search_panal}>
                {userDetailsLoading ? <ComponentLoader /> :
                    <>
                        <Box className={UpdateUserStyles.panel_container}>
                            <Box className="flexCenterBetween">
                                <Box className="flexCenterStart pointer" sx={{ width: "200px" }} onClick={handleNavigate}>
                                    <img src="images/back.svg" className={UpdateUserStyles.backImg} alt="back" />
                                    <Typography ml={2} className={UpdateUserStyles.panal_header}>{isViewPath ? 'User Detail' : userId ? 'Edit User' : `Add New ${!showDoctorStaffDialog ? 'User' : 'Doctor Staff'}`}</Typography>
                                </Box>
                                {isViewPath ? <Box className="flexCenterCenter">
                                    <AvantTooltip title="Edit" placement="top" arrow followCursor>
                                        <img src="images/edit.svg" alt="Edit_Image" className={UpdateUserStyles.editImg} onClick={() => navigate(`/home/user_lists/${userId}`)} />
                                    </AvantTooltip>
                                    <AvantTooltip title="Delete" placement="top" arrow followCursor>
                                        <img src="images/delete.svg" alt="Delete_Image" className={UpdateUserStyles.editImg} onClick={openDeleteUserDialog} />
                                    </AvantTooltip>
                                    {userListId === 1 ?
                                        <AvantTooltip title={userDetails?.isDisabled === 1 ? "Disabled" : "Enabled"} placement="top" arrow followCursor>
                                            <img src={userDetails?.isDisabled === 1 ? "images/user_disable.svg" : "images/user_enable.svg"} alt={userDetails?.isDisabled === 1 ? "Disable_Image" : "Enable_Image"} className={UpdateUserStyles.editImg} onClick={openStatusDialog} />
                                        </AvantTooltip> : null}
                                    {userListId !== 1 ?
                                        <AvantTooltip title="Approve" placement="top" arrow followCursor>
                                            <img src="images/checkMark-01.svg" alt="Approved_Image" className={UpdateUserStyles.editImg} onClick={openApprovedUserDialog} />
                                        </AvantTooltip> : null}
                                    {userListId === 0 ?
                                        <AvantTooltip title="Reject" placement="top" arrow followCursor>
                                            <img src="images/disable-01.svg" alt="Rejected_Image" className={UpdateUserStyles.editImg} onClick={openRejectedUserDialog} />
                                        </AvantTooltip> : null}
                                </Box> : null}
                            </Box>
                        </Box>

                        <form onSubmit={formik.handleSubmit}>
                            <Grid container>
                                <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                    <Typography className={UpdateUserStyles.label}>First Name</Typography>
                                    <TextField
                                        id="firstName"
                                        name="firstName"
                                        variant="standard"
                                        disabled={isViewPath}
                                        placeholder="Enter a first name"
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                        helperText={formik.touched.firstName && formik.errors.firstName}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                    <Typography className={UpdateUserStyles.label}>Last Name</Typography>
                                    <TextField
                                        id="lastName"
                                        name="lastName"
                                        variant="standard"
                                        disabled={isViewPath}
                                        placeholder="Enter a last name"
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                        helperText={formik.touched.lastName && formik.errors.lastName}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                    <Typography className={UpdateUserStyles.label}>Email</Typography>
                                    <TextField
                                        id="emailAddress"
                                        name="emailAddress"
                                        variant="standard"
                                        disabled={isViewPath}
                                        placeholder="Enter an email address"
                                        value={formik.values.emailAddress}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                                        helperText={formik.touched.emailAddress && formik.errors.emailAddress}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                    <Typography className={UpdateUserStyles.label}>Role</Typography>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={formik.touched.roleId && Boolean(formik.errors.roleId)}
                                    >
                                        <Select
                                            labelId="role-select-label"
                                            id="role-select"
                                            name="roleId"
                                            disabled={isViewPath || !!showDoctorStaffDialog}
                                            value={formik.values.roleId!}
                                            onChange={handleRoleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            {roleId === USER_ROLES.SUPER_ADMIN ? (
                                                userRoles.filter(role => role.value !== USER_ROLES.SUPER_ADMIN).map((item) => (
                                                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                                ))
                                            ) : roleId === DOCTOR_ROLE ? (
                                                <MenuItem key={DOCTOR_ROLE} value={DOCTOR_ROLE}>{USER_ROLE_NAMES.DOCTOR}</MenuItem>
                                            ) : roleId === DOCTOR_STAFF_ROLE ? (
                                                <MenuItem key={DOCTOR_STAFF_ROLE} value={DOCTOR_STAFF_ROLE}>{USER_ROLE_NAMES.DOCTOR_STAFF}</MenuItem>
                                            ) : (
                                                userRoles
                                                    .filter(role => ![USER_ROLES.SUPER_ADMIN, USER_ROLES.USER_ADMIN].includes(role.value))
                                                    .map((item) => (
                                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                                    ))
                                            )}
                                            {/* Admin condition for showing admin role during editing */}
                                            {userId && roleId === USER_ROLES.USER_ADMIN && formik.values.roleId === USER_ROLES.USER_ADMIN && (
                                                <MenuItem key={USER_ROLES.USER_ADMIN} value={USER_ROLES.USER_ADMIN}>
                                                    {USER_ROLE_NAMES.ADMIN}
                                                </MenuItem>
                                            )}
                                        </Select>
                                        {formik.touched.roleId && formik.errors.roleId && (
                                            <FormHelperText>{formik.errors.roleId}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                    <Typography className={UpdateUserStyles.label}>Phone Number</Typography>
                                    <MaskedInput
                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        placeholder="Enter a phone number"
                                        guide={false}
                                        id="phoneNumber"
                                        value={formik.values.phoneNumber!}
                                        onBlur={formik.handleBlur}
                                        onChange={handlePhoneChange}
                                        render={(ref, props) => (
                                            <TextField
                                                {...props}
                                                inputRef={ref}
                                                variant="standard"
                                                disabled={isViewPath}
                                                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                                {formik.values.roleId !== DOCTOR_STAFF_ROLE ?
                                    <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                        <Typography className={UpdateUserStyles.label}>Occupation</Typography>
                                        {formik.values.occupationId !== OTHER_OCCUPATION.ID ?
                                            <FormControl
                                                variant="standard"
                                                fullWidth
                                                error={formik.touched.occupationId && Boolean(formik.errors.occupationId)}
                                            >
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    name="occupationId"
                                                    disabled={isViewPath}
                                                    value={formik.values.occupationId!}
                                                    onChange={handleOccupationChange}
                                                    onBlur={formik.handleBlur}
                                                >
                                                    {patientOccupations?.length ? (
                                                        patientOccupations.map((item: patientOccupationsResponse) => (
                                                            <MenuItem key={item.OccupationId} value={item.OccupationId!}>
                                                                {item.OccupationName}
                                                            </MenuItem>
                                                        ))
                                                    ) : (
                                                        <MenuItem value={4}>Others</MenuItem>
                                                    )}
                                                </Select>
                                                {formik.touched.occupationId && formik.errors.occupationId && (
                                                    <FormHelperText>{formik.errors.occupationId}</FormHelperText>
                                                )}
                                            </FormControl> :
                                            <TextField
                                                required
                                                id="otherOccupation"
                                                name="otherOccupation"
                                                variant="standard"
                                                size="small"
                                                placeholder="Enter other occupation"
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        borderRadius: '100px'
                                                    }
                                                }}
                                                disabled={isViewPath}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <CloseIcon sx={{ display: formik.values.otherOccupation ? "inline-block" : "none", fontSize: "22px" }} className={UpdateUserStyles.closeIcon} onClick={handleDefaultOccupation} />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                value={formik.values.otherOccupation}
                                                error={formik.touched.otherOccupation && Boolean(formik.errors.otherOccupation)}
                                                helperText={formik.touched.otherOccupation && formik.errors.otherOccupation}
                                            />}
                                    </Grid> : null}
                                {formik.values.roleId !== DOCTOR_STAFF_ROLE && formik.values.occupationId !== OTHER_OCCUPATION.ID ?
                                    <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                        <Typography className={UpdateUserStyles.label}>Sub-Category</Typography>
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            error={formik.touched.subcategoryId && Boolean(formik.errors.subcategoryId)}
                                        >
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                name="subcategoryId"
                                                disabled={isViewPath}
                                                value={formik.values.subcategoryId}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            >
                                                {categoriesByOccupation?.length ? (
                                                    categoriesByOccupation.map((item: subCategoriesByOccupationProps) => (
                                                        <MenuItem key={item.SubcategoryId} value={item.SubcategoryId!}>
                                                            {item.SubcategoryName}
                                                        </MenuItem>
                                                    ))
                                                ) : null}
                                            </Select>
                                            {formik.touched.subcategoryId && formik.errors.subcategoryId && (
                                                <FormHelperText>{formik.errors.subcategoryId}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid> : null}
                                {!showDoctorStaffDialog ?
                                    <Grid item lg={4} sm={6} xs={12} md={!isViewPath && !showDoctorStaffDialog ? 4 : 4} p={3}>
                                        <Typography className={UpdateUserStyles.label}>Name of the Practice</Typography>
                                        <Box className="flexCenterCenter" sx={{ flexWrap: 'wrap', gap: '10px' }}>
                                            {roleId === USER_ROLES.USER_ADMIN ?
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    error={formik.touched.practiceNameId && Boolean(formik.errors.practiceNameId)}
                                                >
                                                    <Autocomplete
                                                        multiple
                                                        options={adminPractices || []}
                                                        getOptionLabel={(option) => option?.PracticeName!}
                                                        value={adminPractices?.filter(option => formik.values.practiceNameId!.includes(option.PracticeNameId! as any)) || []}
                                                        onChange={handleAdminPracticeChange}
                                                        disabled={isViewPath}
                                                        renderInput={(params) => (
                                                            <TextField {...params} variant="standard" />
                                                        )}
                                                        renderOption={(props, option: adminPracticeResponseProps) => (
                                                            !showDoctorStaffDialog && (
                                                                <ListItem {...props} key={option.PracticeNameId}>
                                                                    <ListItemText primary={option.PracticeName} />
                                                                    <IconButton edge="end" onClick={(event) => handleEditPractice(event, option)}>
                                                                        <Edit />
                                                                    </IconButton>
                                                                    <IconButton edge="end" onClick={(event) => openDeletePracticeDialog(event, option)}>
                                                                        <Delete />
                                                                    </IconButton>
                                                                </ListItem>
                                                            )
                                                        )}
                                                        renderTags={(value, getTagProps) =>
                                                            value.map((option, index) => (
                                                                <Chip
                                                                    variant="outlined"
                                                                    label={option.PracticeName}
                                                                    {...getTagProps({ index })}
                                                                />
                                                            ))
                                                        }
                                                        PopperComponent={(props) => (
                                                            <Popper {...props} placement="bottom-start" style={{ zIndex: 1300 }} />
                                                        )}
                                                        freeSolo={false}
                                                    />
                                                    {formik.touched.practiceNameId && formik.errors.practiceNameId && (
                                                        <FormHelperText>{formik.errors.practiceNameId}</FormHelperText>
                                                    )}
                                                </FormControl> :
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    error={formik.touched.practiceNameId && Boolean(formik.errors.practiceNameId)}
                                                >
                                                    <Autocomplete
                                                        multiple
                                                        options={practiceLists || []}
                                                        getOptionLabel={(option) => option?.PracticeName!}
                                                        value={practiceLists?.filter(option => formik.values.practiceNameId!.includes(option.PracticeNameId! as any)) || []}
                                                        onChange={handlePracticeChange}
                                                        disabled={isViewPath}
                                                        renderInput={(params) => (
                                                            <TextField {...params} variant="standard" />
                                                        )}
                                                        renderOption={(props, option: practicesResponseParseProps) => (
                                                            !showDoctorStaffDialog && (
                                                                <ListItem {...props} key={option.PracticeNameId}>
                                                                    <ListItemText primary={option.PracticeName} />
                                                                    <IconButton edge="end" onClick={(event) => handleEditPractice(event, option)}>
                                                                        <Edit />
                                                                    </IconButton>
                                                                    <IconButton edge="end" onClick={(event) => openDeletePracticeDialog(event, option)}>
                                                                        <Delete />
                                                                    </IconButton>
                                                                </ListItem>
                                                            )
                                                        )}
                                                        renderTags={(value, getTagProps) =>
                                                            value.map((option, index) => (
                                                                <Chip
                                                                    variant="outlined"
                                                                    label={option.PracticeName}
                                                                    {...getTagProps({ index })}
                                                                />
                                                            ))
                                                        }
                                                        PopperComponent={(props) => (
                                                            <Popper {...props} placement="bottom-start" style={{ zIndex: 1300 }} />
                                                        )}
                                                        freeSolo={false}
                                                    />
                                                    {formik.touched.practiceNameId && formik.errors.practiceNameId && (
                                                        <FormHelperText>{formik.errors.practiceNameId}</FormHelperText>
                                                    )}
                                                </FormControl>}
                                            {!isViewPath && !showDoctorStaffDialog ? (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<AddIcon />}
                                                    onClick={handleOpenPractice}
                                                    sx={{
                                                        padding: "6px 11px",
                                                        minWidth: "200px",
                                                        textTransform: "capitalize",
                                                        borderRadius: "100px",
                                                        marginLeft: "10px"
                                                    }}
                                                >
                                                    <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Add New Practice</Typography>
                                                </Button>
                                            ) : null}
                                        </Box>
                                    </Grid> : null}
                                {!isViewPath && !showDoctorStaffDialog ?
                                    <Grid item lg={4} md={4} sm={6} xs={12} p={3}>
                                        <Typography className={UpdateUserStyles.label}>Disable</Typography>
                                        <Switch
                                            checked={formik.values.isDisabled === 1 ? true : false}
                                            onChange={handleDisabilityChange}
                                            sx={{ marginTop: "10px" }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Grid> : null}
                            </Grid>

                            {!isViewPath && userId ?
                                <Box onClick={handleResetPassword} sx={{ margin: "15px", width: { md: '150px', xs: '100%' }, textAlign: { md: 'left', xs: 'center' } }}>
                                    <Typography className={UpdateUserStyles.resetPassword}>Reset Password</Typography>
                                </Box> : null}

                            {!isViewPath ?
                                <Box m={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: { md: 'flex-end', xs: 'center' } }}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleNavigate}
                                        sx={{
                                            padding: "6px 11px",
                                            minWidth: "120px",
                                            textTransform: "capitalize",
                                            borderRadius: "100px"
                                        }}
                                    >
                                        <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Cancel</Typography>
                                    </Button>
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        loading={userAdding}
                                        type="submit"
                                        sx={{
                                            padding: "6px 11px",
                                            minWidth: "120px",
                                            textTransform: "capitalize",
                                            borderRadius: "100px",
                                            marginLeft: "10px"
                                        }}
                                    >
                                        <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Save</Typography>
                                    </LoadingButton>
                                </Box> : null}
                        </form>
                    </>}
            </Box>
            <ConfirmationDialog
                open={!!deletedPractice}
                accept={handleDeletePractice}
                reject={() => setDeletedPractice(null)}
                msg={`Are you sure you want to delete ${deletedPractice?.PracticeName} practice?`}
                acceptText={"Yes"}
                rejectText={"No"}
                isLoading={practiceDeleting}
            />
            <ConfirmationDialog
                open={!!deleteUserId}
                accept={handleDeleteUser}
                reject={() => dispatch(UserSliceActions.patchState({ deleteUserId: null }))}
                msg={`Are you sure you want to delete the user?`}
                acceptText={"Yes"}
                rejectText={"No"}
                isLoading={isDeleting}
            />
            <ConfirmationDialog
                open={!!statusChangingUserId}
                accept={handleUserStatus}
                reject={() => dispatch(UserSliceActions.patchState({ statusChangingUserId: null, statusMsg: null }))}
                msg={`Are you sure you want to ${statusMsg} the user?`}
                acceptText={"Yes"}
                rejectText={"No"}
                isLoading={statusChanging}
            />
            <ConfirmationDialog
                open={!!approvedUserId}
                accept={handleApprovedUser}
                reject={() => dispatch(UserSliceActions.patchState({ approvedUserId: null }))}
                msg={`Are you sure you want to approve the user?`}
                acceptText={"Yes"}
                rejectText={"No"}
                isLoading={isApproving}
            />
            <ConfirmationDialog
                open={!!rejectedUserId}
                accept={handleRejectedUser}
                reject={() => dispatch(UserSliceActions.patchState({ rejectedUserId: null }))}
                msg={`Are you sure you want to reject the user?`}
                acceptText={"Yes"}
                rejectText={"No"}
                isLoading={isRejecting}
            />
        </Box>
    );
}

export default UpdateUser;