import { AnyAction, ThunkDispatch, configureStore } from "@reduxjs/toolkit";
import { createLogger } from 'redux-logger';
import AppReducer from "./Reducer";
import interceptors from "../Interceptors";

const logger = createLogger();

export const store = configureStore({
    reducer: AppReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(logger),  // Add logger here
});

interceptors(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;