import React, { useEffect, useState } from "react";
import {
    Autocomplete,
    Box, Button, Chip, FormControl, FormHelperText, Grid, InputAdornment,
    MenuItem, Popper, Select, SelectChangeEvent, TextField, Typography
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import CloseIcon from '@mui/icons-material/Close';

import AvantIndex from "../HomeIndex";
import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import { PatientThunkApis } from "../../redux/actions/patient-actions";
import { patientOccupationsResponse } from "../../models/patient.model";
import api from "../../api";
import { SIGNUP_USER } from "../../constants/api-constants";
import { DOCTOR_ROLE, DOCTOR_STAFF_ROLE, DOCTOR_STAFF_SINGLE_PRACTICE_VALIDATION, NEW_USER_FAILED, OTHER_OCCUPATION, httpStatusCode, userRoles } from "../../constants/variable-constants";
import { AuthSliceActions } from "../../redux/reducers/login-slice";
import MaskedInput from "react-text-mask";
import { ToastAlert } from "../../components/toast-alert";
import { PracticeThunkApis } from "../../redux/actions/practice-actions";
import { practicesResponseParseProps } from "../../models/practice.model";
import { OccupationTunkApis } from "../../redux/actions/occupation-actions";
import { subCategoriesByOccupationProps } from "../../models/occupation.model";

import SignUpStyles from "./SignUp.module.css";
interface SignUpData {
    firstName: string,
    lastName: string,
    emailAddress: string,
    phoneNumber: string,
    occupationId: number | null,
    otherOccupation: string,
    practiceNameId: string,
    roleId: number,
    updatedBy: string | null,
    profilePicPath: string,
    isDisabled: number,
    createdBy: number,
    subcategoryId: null
}

const SignUp = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { patientOccupations } = useAppSelector(state => state.patients! || {});
    const { practiceLists } = useAppSelector(state => state.practices! || {});
    const { categoriesByOccupation } = useAppSelector(state => state.occupations! || {});

    const [isLoading, setLoading] = useState<boolean>(false);

    const initialValues: SignUpData = {
        firstName: '',
        lastName: '',
        emailAddress: '',
        practiceNameId: '',
        roleId: 3,
        createdBy: 1,
        updatedBy: null,
        phoneNumber: '',
        occupationId: null,
        otherOccupation: '',
        profilePicPath: '',
        isDisabled: 0,
        subcategoryId: null
    }

    const validationSchema = Yup.object({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        emailAddress: Yup.string().email("Invalid email address").required("Email is required"),
        roleId: Yup.number().required("Role is required"),
        phoneNumber: Yup.string()
            .required("Phone number is required")
            .matches(/^\d+$/, "Phone number must be only digits")
            .transform((value, originalValue) => originalValue ? originalValue.replace(/\D/g, '') : originalValue)
            .min(10, "Phone number must be exactly 10 digits")
            .max(10, "Phone number must be exactly 10 digits"),
        practiceNameId: Yup.array()
            .of(Yup.number())
            .required('Practice name is required')
            .test(
                'is-single-practice-for-doctor-staff',
                DOCTOR_STAFF_SINGLE_PRACTICE_VALIDATION,
                function (value) {
                    const { roleId } = this.parent;
                    if (roleId === DOCTOR_STAFF_ROLE && value.length > 1) {
                        return false;
                    }
                    return true;
                }
            )
    });

    useEffect(() => {
        dispatch(PracticeThunkApis.getAllPractices(''));
        dispatch(PatientThunkApis.getPatientOccupations(''));
    }, [dispatch]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values: SignUpData) => {
            setLoading(true);
            try {
                const signupRes = await api.fetchAuthPostWithBody<{ message?: string, userId: number }>(SIGNUP_USER, { ...values, occupationId: values?.occupationId?.toString() });
                if (signupRes?.status === httpStatusCode?.SUCCESS) {
                    dispatch(AuthSliceActions.patchState({ successiveMsg: signupRes?.data?.message! }));
                    navigate(-1);
                } else if (signupRes?.status === httpStatusCode.CREATED) {
                    ToastAlert(signupRes?.data?.message!, 'error');
                } else {
                    throw new Error(NEW_USER_FAILED);
                }
            } catch (error: any) {
                console.error(NEW_USER_FAILED, error);
            } finally {
                setLoading(false);
            }
        },
    });

    const handlePracticeChange = (event: React.SyntheticEvent<Element, Event>, value: practicesResponseParseProps[] | null | any) => {
        if (value) {
            const practiceNameIds = value.map((practice: practicesResponseParseProps) => practice.PracticeNameId);
            // formik.setFieldValue('practiceName', practiceNames);
            formik.setFieldValue('practiceNameId', practiceNameIds);
        }
    };

    useEffect(() => {
        if (!formik.values.occupationId) {
            return;
        }
        dispatch(OccupationTunkApis.getSubCategoriesByOccupation({ id: formik.values.occupationId! }));
        if (formik.values.occupationId === OTHER_OCCUPATION.ID) {
            formik.setFieldValue('subcategoryId', null);
        }
    }, [formik.values.occupationId]);

    const handleDefaultOccupation = () => {
        formik.handleChange('occupationId')('2');
        formik.handleChange('otherOccupation')('');
        formik.handleChange('subcategoryId')('');
    }

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('phoneNumber', event.target.value);
    }

    const handleRoleChange = (event: SelectChangeEvent<number>) => {
        const roleId = event.target.value as number;
        formik.setFieldValue('roleId', roleId);
        //  If the selected role is DOCTOR_STAFF_ROLE, clear the occupationId and subcategoryId fields
        if (roleId === DOCTOR_STAFF_ROLE) {
            formik.setFieldValue('occupationId', null);
            formik.setFieldValue('subcategoryId', null);
        }
    };

    const handleOccupationChange = (event: SelectChangeEvent<number>) => {
        const occupationId = event.target.value as number;
        formik.setFieldValue('occupationId', occupationId);
        formik.setFieldValue('subcategoryId', null);
    }

    useEffect(() => {
        return () => {
            formik.resetForm();
        }
    }, []);

    return (
        <AvantIndex>
            <>
                <Typography sx={{ fontSize: "18px" }} color="primary">New User</Typography>
                <Box mt={5}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container className="registerForm" sx={{
                            '@media (orientation: landscape) and (max-width: 991px)': {
                                height: 'calc(100vh - 175px)',
                                overflowY: 'auto'
                            }
                        }}>
                            <Grid item lg={4} md={6} sm={6} xs={12} px={3} mb={3}>
                                <Typography className={SignUpStyles.label}>First Name</Typography>
                                <TextField
                                    id="firstName"
                                    name="firstName"
                                    variant="outlined"
                                    size="small"
                                    placeholder="First Name"
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            borderRadius: '100px'
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.firstName}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                />
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12} px={3} mb={3}>
                                <Typography className={SignUpStyles.label}>Last Name</Typography>
                                <TextField
                                    id="lastName"
                                    name="lastName"
                                    variant="outlined"
                                    size="small"
                                    placeholder="Last Name"
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            borderRadius: '100px'
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.lastName}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                />
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12} px={3} mb={3}>
                                <Typography className={SignUpStyles.label}>Email</Typography>
                                <TextField
                                    id="emailAddress"
                                    name="emailAddress"
                                    variant="outlined"
                                    size="small"
                                    placeholder="Email address"
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            borderRadius: '100px'
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.emailAddress}
                                    error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                                    helperText={formik.touched.emailAddress && formik.errors.emailAddress}
                                />
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12} px={3} mb={3}>
                                <Typography className={SignUpStyles.label}>Role</Typography>
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    error={formik.touched.roleId && Boolean(formik.errors.roleId)}
                                >
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        name="roleId"
                                        value={formik.values.roleId}
                                        onChange={handleRoleChange}
                                        onBlur={formik.handleBlur}
                                    >
                                        {userRoles
                                            .filter(role => [DOCTOR_ROLE, DOCTOR_STAFF_ROLE].includes(role.value))
                                            .map((item) => (
                                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {formik.touched.roleId && formik.errors.roleId && (
                                        <FormHelperText>{formik.errors.roleId}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12} px={3} mb={3}>
                                <Typography className={SignUpStyles.label}>Phone Number</Typography>
                                <MaskedInput
                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                    placeholder="Enter a phone number"
                                    guide={false}
                                    id="phoneNumber"
                                    value={formik.values.phoneNumber!}
                                    onBlur={formik.handleBlur}
                                    onChange={handlePhoneChange}
                                    render={(ref, props) => (
                                        <TextField
                                            {...props}
                                            inputRef={ref}
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    borderRadius: '100px'
                                                }
                                            }}
                                            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            {formik.values.roleId === DOCTOR_ROLE ?
                                <Grid item lg={4} md={6} sm={6} xs={12} px={3} mb={3}>
                                    <Typography className={SignUpStyles.label}>Occupation</Typography>
                                    {formik.values.occupationId !== OTHER_OCCUPATION.ID ?
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            error={formik.touched.occupationId && Boolean(formik.errors.occupationId)}
                                        >
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                name="occupationId"
                                                value={formik.values.occupationId!}
                                                onChange={handleOccupationChange}
                                                onBlur={formik.handleBlur}
                                            >
                                                {patientOccupations?.length ? (
                                                    patientOccupations.map((item: patientOccupationsResponse) => (
                                                        <MenuItem key={item.OccupationId} value={item.OccupationId!}>
                                                            {item.OccupationName}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem value={4}>Others</MenuItem>
                                                )}
                                            </Select>
                                            {formik.touched.occupationId && formik.errors.occupationId && (
                                                <FormHelperText>{formik.errors.occupationId}</FormHelperText>
                                            )}
                                        </FormControl> :
                                        <TextField
                                            id="otherOccupation"
                                            name="otherOccupation"
                                            variant="outlined"
                                            size="small"
                                            placeholder="Enter other occupation"
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    borderRadius: '100px'
                                                }
                                            }}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CloseIcon sx={{ display: formik.values.otherOccupation ? "inline-block" : "none", fontSize: "22px" }} className={SignUpStyles.closeIcon} onClick={handleDefaultOccupation} />
                                                    </InputAdornment>
                                                )
                                            }}
                                            value={formik.values.otherOccupation}
                                            error={formik.touched.otherOccupation && Boolean(formik.errors.otherOccupation)}
                                            helperText={formik.touched.otherOccupation && formik.errors.otherOccupation}
                                        />}
                                </Grid> : null}
                            {formik.values.roleId !== DOCTOR_STAFF_ROLE && formik.values.occupationId !== OTHER_OCCUPATION.ID ?
                                <Grid item lg={4} md={6} sm={6} xs={12} px={3} mb={3}>
                                    <Typography className={SignUpStyles.label}>Sub-Category</Typography>
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={formik.touched.subcategoryId && Boolean(formik.errors.subcategoryId)}
                                    >
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            name="subcategoryId"
                                            value={formik.values.subcategoryId}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            {categoriesByOccupation?.length ? (
                                                categoriesByOccupation.map((item: subCategoriesByOccupationProps) => (
                                                    <MenuItem key={item.SubcategoryId} value={item.SubcategoryId!}>
                                                        {item.SubcategoryName}
                                                    </MenuItem>
                                                ))
                                            ) : null}
                                        </Select>
                                        {formik.touched.subcategoryId && formik.errors.subcategoryId && (
                                            <FormHelperText>{formik.errors.subcategoryId}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid> : null}

                            <Grid item lg={formik.values.roleId === DOCTOR_ROLE ? 8 : 4} md={6} sm={6} xs={12} px={3} mb={3}>
                                <Typography className={SignUpStyles.label}>Name of the practice</Typography>
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    error={formik.touched.practiceNameId && Boolean(formik.errors.practiceNameId)}
                                >
                                    <Autocomplete
                                        multiple
                                        options={practiceLists || []}
                                        getOptionLabel={(option) => option?.PracticeName!}
                                        value={practiceLists?.filter(option => formik.values.practiceNameId!.includes(option.PracticeNameId! as any)) || []}
                                        onChange={handlePracticeChange}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="standard" />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            <div
                                                style={{
                                                    maxHeight: '50px',
                                                    overflowY: 'auto',
                                                    padding: '4px'
                                                }}
                                            >
                                                {value.map((option, index) => (
                                                    <Chip
                                                        variant="outlined"
                                                        label={option.PracticeName}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))}
                                            </div>
                                        }
                                        PopperComponent={(props) => (
                                            <Popper {...props} placement="bottom-start" style={{ zIndex: 1300 }} />
                                        )}
                                        freeSolo={false}
                                    />
                                    {formik.touched.practiceNameId && formik.errors.practiceNameId && (
                                        <FormHelperText>{formik.errors.practiceNameId}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Box my={2} className="flexCenterCenter">
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate(-1)}
                                sx={{
                                    padding: "6px 11px",
                                    minWidth: "120px",
                                    textTransform: "capitalize",
                                    borderRadius: "100px"
                                }}
                            >
                                <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Cancel</Typography>
                            </Button>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                loading={isLoading}
                                type="submit"
                                sx={{
                                    padding: "6px 11px",
                                    minWidth: "120px",
                                    textTransform: "capitalize",
                                    borderRadius: "100px",
                                    marginLeft: "10px"
                                }}
                            >
                                <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Sign Up</Typography>
                            </LoadingButton>
                        </Box>
                    </form>
                </Box>
            </>
        </AvantIndex>
    );
}

export default SignUp