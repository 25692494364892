import { Box, FormControl, InputLabel, SelectProps, Skeleton } from "@mui/material"
import { makeStyles } from "@mui/styles";
import { UsePaginationProps } from "@mui/lab";
import ArrowDropDownIcon from '@mui/icons-material/KeyboardArrowDown';

import AvantSelect from "../select-menu-items/Select";
import AvantMenuItem from "../select-menu-items/MenuItem";
import AvantPagination from "./Pagination";

interface AvantPaginationWithPageSizeProps {
    pageSize: number;
    handlePageSizeChange: SelectProps<unknown>['onChange'];
    page: number;
    totalPages: number;
    disabled: boolean;
    handlePageChange: UsePaginationProps['onChange'];
    alignContent?: 'start' | 'center' | 'end';
    pageLoading?: boolean;
}

const iconStyles = makeStyles(() => ({
    icon: {
        fontSize: 30
    }
}))

let sizeList = [{ title: "10", key: "10" }, { title: "20", key: "20" }, { title: "50", key: "50" }, { title: "100", key: "100" }];

const AvantPaginationWithPageSize = ({
    pageSize,
    handlePageSizeChange,
    page,
    totalPages,
    disabled,
    handlePageChange,
    alignContent = 'start',
    pageLoading = false
}: AvantPaginationWithPageSizeProps) => {

    const iconStyle = iconStyles();

    return (
        <Box display="flex" flex={1} alignItems={"center"} justifyContent={alignContent}>
            <Box width={100} marginRight={2}>
                <FormControl fullWidth disabled={disabled}>
                    <InputLabel id="select-page-dropdown-label" style={{ fontSize: 12.5 }}>{"Results per page"}</InputLabel>
                    <AvantSelect
                        labelId="select-page-dropdown-label"
                        id="simple-sort-select"
                        label={"Results per page"}
                        value={pageSize}
                        onChange={handlePageSizeChange}
                        style={{ borderRadius: 0, height: 35, fontSize: 14 }}
                        classes={{
                            icon: iconStyle.icon
                        }}
                        IconComponent={(props: any) => <ArrowDropDownIcon fontSize="medium" {...props} color={"secondary"} />}
                    >
                        {
                            sizeList.map((item, index) => (
                                <AvantMenuItem value={item.key!} key={index} style={{ padding: '0px 5px 0px 5px', margin: 0, height: 25, fontSize: 14, fontWeight: '450' }}
                                >{item.title}</AvantMenuItem>
                            ))
                        }
                    </AvantSelect>
                </FormControl>
            </Box>

            {pageLoading ?
                <Skeleton width={300} /> :
                <AvantPagination
                    page={page + 1}
                    count={totalPages}
                    disabled={disabled}
                    onChange={handlePageChange}
                />}
        </Box>
    )
}

export default AvantPaginationWithPageSize;