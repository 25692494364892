import React, { useEffect } from "react";
import {
    Box, Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton,
    MenuItem, Select, SelectChangeEvent, Typography
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { useFormik } from 'formik';

import { useAppDispatch, useAppSelector } from "../../../redux/Hooks";
import { patientOccupationsResponse } from "../../../models/patient.model";
import { OTHER_OCCUPATION, USER_ROLES, userRoles } from "../../../constants/variable-constants";
import { subCategoriesByOccupationProps } from "../../../models/occupation.model";
import { UserSliceActions } from "../../../redux/reducers/user-slice";
import { practiceSelectors } from "../../../redux/reducers/practice-slice";
import { OccupationTunkApis } from "../../../redux/actions/occupation-actions";
import { OccupationSliceActions } from "../../../redux/reducers/occupation-slice";

import UserListStyles from "../UserLists.module.css";

const FilterDialog = ({ closeFilter }: { closeFilter: () => void }) => {
    const dispatch = useAppDispatch();
    const { roleId } = useAppSelector(state => state.auth.userDetails! || {});
    const { patientOccupations } = useAppSelector(state => state.patients! || {});
    const { categoriesByOccupation } = useAppSelector(state => state.occupations! || {});
    const { adminPractices } = useAppSelector(state => state.practices! || {});
    const { occupationId, subcategoryId, roleId: filterByRoleId, practiceNameId } = useAppSelector(state => state.users! || {});

    const practiceLists = useAppSelector(practiceSelectors.selectAll);

    const formik = useFormik({
        initialValues: {
            occupationId: occupationId || null,
            subcategoryId: subcategoryId || null,
            roleId: filterByRoleId || null,
            practiceNameId: practiceNameId || null
        },
        onSubmit: (values) => {
            dispatch(UserSliceActions.patchState({
                occupationId: values.occupationId,
                subcategoryId: values.subcategoryId,
                roleId: values.roleId,
                practiceNameId: values.practiceNameId
            }));
            closeFilter();
        }
    });

    const handleOccupationChange = (event: SelectChangeEvent<number>) => {
        const occupationId = event.target.value as number;
        formik.setFieldValue('occupationId', occupationId);
    }

    useEffect(() => {
        if (!formik.values.occupationId) {
            return;
        }
        dispatch(OccupationTunkApis.getSubCategoriesByOccupation({ id: formik.values.occupationId! }));
    }, [formik.values.occupationId]);

    const handleSubCategoryChange = (event: SelectChangeEvent<number>) => {
        const subcategoryId = event.target.value as number;
        formik.setFieldValue('subcategoryId', subcategoryId);
    }

    const handleRoleChange = (event: SelectChangeEvent<number>) => {
        const roleId = event.target.value as number;
        formik.setFieldValue('roleId', roleId);
    };

    const handleAdminPracticeChange = (event: SelectChangeEvent<number>) => {
        const practiceNameId = event.target.value as number;
        formik.setFieldValue('practiceNameId', practiceNameId);
    }

    const handlePracticeChange = (event: SelectChangeEvent<number>) => {
        const practiceNameId = event.target.value as number;
        formik.setFieldValue('practiceNameId', practiceNameId);
    };

    const handleReset = () => {
        formik.resetForm({
            values: {
                occupationId: null,
                subcategoryId: null,
                roleId: null,
                practiceNameId: null
            }
        });
        dispatch(UserSliceActions.patchState({
            occupationId: null,
            subcategoryId: null,
            roleId: null,
            practiceNameId: null
        }));
        dispatch(OccupationSliceActions.patchState({ categoriesByOccupation: [] }));
    };

    return (
        <Dialog
            fullWidth
            maxWidth={"md"}
            open={true}
            onClose={closeFilter}
            aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiDialog-paper': {
                  width: '100%',
                  maxWidth: 'md',
                  '@media (max-width: 480px)': {
                    width: '100%',
                    maxWidth: '100%',
                    margin: '10px'
                  }
                }
              }}
        >
            <DialogTitle id="alert-dialog-title">
                <Typography className={UserListStyles.filterTitle} pb={3}>{"Users Filter"}</Typography>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={closeFilter}
                sx={{
                    position: 'absolute',
                    right: 2,
                    top: 2,
                }}
            >
                <CancelIcon color={'primary'} />
            </IconButton>
            <DialogContent>
                <Box component="form" onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} p={3}>
                            <Typography className={UserListStyles.label}>Occupation</Typography>
                            <FormControl fullWidth variant="standard">
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    label="Occupation"
                                    value={formik.values.occupationId!}
                                    onChange={handleOccupationChange}
                                    sx={{
                                        borderRadius: '25px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderRadius: '25px'
                                        }
                                        , '& .MuiSelect-select': {
                                            padding: '7px 14px'
                                        }
                                    }}
                                >
                                    {patientOccupations?.length ? (
                                        patientOccupations?.filter(occupation => occupation.OccupationName !== OTHER_OCCUPATION.NAME).map((item: patientOccupationsResponse) => (
                                            <MenuItem key={item.OccupationId} value={item.OccupationId!}>
                                                {item.OccupationName}
                                            </MenuItem>
                                        ))
                                    ) : null}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} p={3}>
                            <Typography className={UserListStyles.label}>Sub-Category</Typography>
                            <FormControl fullWidth variant="standard">
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    label={"Sub-Category"}
                                    value={formik.values.subcategoryId!}
                                    onChange={handleSubCategoryChange}
                                    sx={{
                                        borderRadius: '25px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderRadius: '25px'
                                        }
                                        , '& .MuiSelect-select': {
                                            padding: '7px 14px'
                                        }
                                    }}
                                >
                                    {categoriesByOccupation?.length ? (
                                        categoriesByOccupation.map((item: subCategoriesByOccupationProps) => (
                                            <MenuItem key={item.SubcategoryId} value={item.SubcategoryId!}>
                                                {item.SubcategoryName}
                                            </MenuItem>
                                        ))
                                    ) : null}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} p={3}>
                            <Typography className={UserListStyles.label}>Role</Typography>
                            <FormControl fullWidth variant="standard">
                                <Select
                                    labelId="role-select-label"
                                    id="role-select"
                                    label={"Role"}
                                    value={formik.values.roleId!}
                                    onChange={handleRoleChange}
                                    sx={{
                                        borderRadius: '25px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderRadius: '25px'
                                        }
                                        , '& .MuiSelect-select': {
                                            padding: '7px 14px'
                                        }
                                    }}
                                >
                                    {userRoles?.length ? (
                                        userRoles.filter(role => role.value !== USER_ROLES.SUPER_ADMIN).map((item) => (
                                            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                        ))
                                    ) : null}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} p={3}>
                            <Typography className={UserListStyles.label}>Name of the Practice</Typography>
                            <FormControl fullWidth variant="standard">
                                <Select
                                    labelId="practice-select-label"
                                    id="practice-select"
                                    label={"Name of the Practice"}
                                    value={formik.values.practiceNameId || ""}
                                    onChange={roleId === USER_ROLES.USER_ADMIN ? handleAdminPracticeChange : handlePracticeChange}
                                    sx={{
                                        borderRadius: '25px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderRadius: '25px'
                                        },
                                        '& .MuiSelect-select': {
                                            padding: '7px 14px'
                                        }
                                    }}
                                >
                                    {(roleId === USER_ROLES.USER_ADMIN ? adminPractices : practiceLists)?.map((practice) => (
                                        <MenuItem key={practice.PracticeNameId} value={practice.PracticeNameId!}>
                                            {practice.PracticeName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Box className="flexCenterCenter filterAction" gap={2} my={3}>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            sx={{
                                padding: "6px 11px",
                                minWidth: "110px",
                                textTransform: "capitalize",
                                borderRadius: "100px"
                            }}>
                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Continue</Typography>
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={closeFilter}
                            sx={{
                                padding: "6px 11px",
                                minWidth: "110px",
                                textTransform: "capitalize",
                                borderRadius: "100px"
                            }}>
                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Cancel</Typography>
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleReset}
                            sx={{
                                padding: "6px 11px",
                                minWidth: "110px",
                                textTransform: "capitalize",
                                borderRadius: "100px"
                            }}>
                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Reset</Typography>
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default FilterDialog;