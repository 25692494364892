import { createEntityAdapter, createSlice, PayloadAction, EntityId } from "@reduxjs/toolkit";

import { RootState } from "../Store";
import { doctorListsResponse } from "../../models/practice.model";
import { doctorSliceProps } from "../../models/doctor.model";
import { DoctorThunkApis } from "../actions/doctor-actions";
import { USER_ROLE_NAMES } from "../../constants/variable-constants";
import { resetState } from "../actions/reset-actions";

const doctorAdapter = createEntityAdapter<doctorListsResponse>({
    selectId: (doctor) => {
        // Ensure userId is not null
        if (doctor.UserId === null) {
            throw new Error('userId cannot be null');
        }
        return doctor.UserId as EntityId;
    },
    sortComparer: (a, b) => {
        if (a.UserId === null || b.UserId === null) {
            return 0;
        }
        return b.UserId! - a.UserId!;
    },
});

const initialState = doctorAdapter.getInitialState<doctorSliceProps>({
    pagesize: 10,
    currentPage: 0,
    listLoading: false,
    totalDoctors: 0,
    searchTerm: null,
    doctorLists: [],
    doctorstaffLists: [],
    doctorStaffWithoutPractice: [],
    allPracticeDoctorList: []
});

const DoctorSlice = createSlice({
    name: 'doctors',
    initialState,
    reducers: {
        patchState(state, action: PayloadAction<Partial<doctorSliceProps>>) {
            return { ...state, ...action.payload };
        },
        setInitialState(state) {
            return {
                ...state,
                pagesize: 10,
                currentPage: 0,
                totalDoctors: 0,
                searchTerm: null
            }
        },
        removeDoctor: (state, action: PayloadAction<{ id: number }>) => {
            doctorAdapter.removeOne(state, action.payload.id);
        }
    },
    extraReducers: (builder) => {
        // To reset all the state variables
        builder.addCase(resetState, () => initialState);
        // getDoctorsAssociatedPractice
        builder.addCase(DoctorThunkApis.getDoctorsAssociatedPractice.fulfilled, (state, action) => {
            const doctors = action.payload.data || [];
            const doctorsData = doctors.filter(doctor => doctor.RoleName === USER_ROLE_NAMES.DOCTOR);
            state.allPracticeDoctorList = doctorsData;
        });
        // getAllDoctorsThroughPractice
        builder.addCase(DoctorThunkApis.getAllDoctorsThroughPractice.pending, (state) => {
            state.listLoading = true
        });
        builder.addCase(DoctorThunkApis.getAllDoctorsThroughPractice.fulfilled, (state, action) => {
            state.listLoading = false;
            state.totalDoctors = action.payload.data.length! || 0;
            const groupedData = action.payload.data?.filter(val => val.RoleName === USER_ROLE_NAMES.DOCTOR)?.reduce((acc, item) => {
                const key = `${item.FullName}-${item.EmailAddress}`;

                if (!acc[key]) {
                    acc[key] = {
                        FullName: item.FullName,
                        EmailAddress: item.EmailAddress,
                        OccupationName: item.OccupationName,
                        RoleName: item.RoleName,
                        PracticeId: item.PracticeId,
                        UserId: item.UserId,
                        PatientStatusIds: item.PatientStatusIds,
                        SubcategoryName: item.SubcategoryName ? [item.SubcategoryName] : []
                    };
                } else if (item.SubcategoryName && !acc[key].SubcategoryName.includes(item.SubcategoryName)) {
                    acc[key].SubcategoryName.push(item.SubcategoryName);
                }

                return acc;
            }, {});

            doctorAdapter.setAll(state, Object.values(groupedData) || []);
        });
        builder.addCase(DoctorThunkApis.getAllDoctorsThroughPractice.rejected, (state) => {
            state.listLoading = false
        });
        // getAllDoctors
        builder.addCase(DoctorThunkApis.getAllDoctors.fulfilled, (state, action) => {
            if (action.payload.data.length) {
                const uniqueDoctors = Array.from(new Set(action.payload.data.map(item => item.UserId)))
                    .map(id => action.payload.data.find(item => item.UserId === id))
                    .filter(item => item !== undefined); // Filter out undefined values

                state.doctorLists = uniqueDoctors as doctorListsResponse[];
            } else {
                state.doctorLists = [];
            }
        });
        // getAllDoctorStaffs
        builder.addCase(DoctorThunkApis.getAllDoctorStaffs.fulfilled, (state, action) => {
            if (action.payload.data.length) {
                const uniqueDoctorStaffs = Array.from(new Set(action.payload.data.map(item => item.UserId)))
                    .map(id => action.payload.data.find(item => item.UserId === id))
                    .filter(item => item !== undefined); // Filter out undefined values

                state.doctorstaffLists = uniqueDoctorStaffs as doctorListsResponse[];
            } else {
                state.doctorstaffLists = [];
            }
        });
        // getAllDoctorStaffWithoutPractice
        builder.addCase(DoctorThunkApis.getAllDoctorStaffWithoutPractice.fulfilled, (state, action) => {
            state.doctorStaffWithoutPractice = action.payload.data
        });
    },
});

export const doctorSelectors = doctorAdapter.getSelectors<RootState>((state) => state.doctors);

export const DoctorSliceActions = DoctorSlice.actions;
export default DoctorSlice.reducer;