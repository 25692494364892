import React, { useState } from "react";
import { Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { useAppDispatch } from "../../../redux/Hooks";
import { useDebounce } from "../../../components/custom-hook/useDebounce";
import { OccupationSliceActions } from "../../../redux/reducers/occupation-slice";

import OccupationListsStyles from "../OccupationList.module.css";

const OccupationListHeader = () => {
    const dispatch = useAppDispatch();

    const [searchTerm, setSearchTerm] = useState<string>("");

    const handleDebouncedSearch = useDebounce((term: string) => {
        dispatch(OccupationSliceActions.patchState({ searchTerm: term }));
    }, 2000);

    const handleSearchTerm = (term: string) => {
        setSearchTerm(term);
        dispatch(OccupationSliceActions.patchState({ currentPage: 0, pageSize: 10 }));
        handleDebouncedSearch(term);
    };

    const clearSearchTerm = () => {
        setSearchTerm("");
        dispatch(OccupationSliceActions.patchState({ searchTerm: null }));
    }

    const handleNewOccupation = () => {
        dispatch(OccupationSliceActions.patchState({ openOccupationDialog: true }));
    }

    return (
        <Box className={`displayWrap ${OccupationListsStyles.search_panal}`}>
            <TextField
                value={searchTerm}
                onChange={(e) => handleSearchTerm(e.target.value)}
                sx={{ minWidth: '200px' }}
                variant="standard"
                placeholder='Search Occupations'
                size='small'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <SearchIcon />
                        </InputAdornment>),
                    endAdornment: (
                        <InputAdornment position="end">
                            <CloseIcon sx={{ display: searchTerm ? "inline-block" : "none", fontSize: "22px" }} className={OccupationListsStyles.closeIcon} onClick={clearSearchTerm} />
                        </InputAdornment>
                    )
                }}
            />
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleNewOccupation}
                sx={{
                    padding: "6px 11px",
                    minWidth: "200px",
                    textTransform: "capitalize",
                    borderRadius: "100px",
                    marginLeft: "10px"
                }}
            >
                <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Add New Occupation</Typography>
            </Button>
        </Box>
    );
}

export default OccupationListHeader