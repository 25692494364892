import React, { useState } from "react";
import { Badge, Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import TuneIcon from '@mui/icons-material/Tune';
import EmailIcon from '@mui/icons-material/Email';
import DraftsIcon from '@mui/icons-material/Drafts';
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../redux/Hooks";
import { useDebounce } from "../../../components/custom-hook/useDebounce";
import { UserSliceActions } from "../../../redux/reducers/user-slice";
import FilterDialog from "./FilterDialog";
import { useFilterHasValue } from "../../../components/custom-selectors/userSelectors";
import EmailTemplateDialog from "./EmailTemplateDialog";

import UserListsStyles from "../UserLists.module.css";

const UserListHeader = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [openMailDialog, setOpenMailDialog] = useState<boolean>(false);

    const { listLoading, selectedUserMails } = useAppSelector(state => state.users! || {});

    const filterHasValue = useFilterHasValue();

    const handleDebouncedSearch = useDebounce((term: string) => {
        dispatch(UserSliceActions.patchState({ searchTerm: term }));
    }, 2000);

    const handleSearchTerm = (term: string) => {
        setSearchTerm(term);
        dispatch(UserSliceActions.patchState({ currentPage: 0, pagesize: 10 }));
        handleDebouncedSearch(term);
    };

    const clearSearchTerm = () => {
        setSearchTerm("");
        dispatch(UserSliceActions.patchState({ searchTerm: null }));
    }

    const handleClose = () => {
        setOpenFilter(false);
    }

    const handleNavigate = () => {
        navigate('/home/user_lists/add-user');
    }

    const handleSendMails = () => {
        setOpenMailDialog(true);
    }

    const handleNavigateToMails = () => {
        navigate('mail-list');
    }

    return (
        <>
            <Box className={UserListsStyles.search_panal}>
                <TextField
                    value={searchTerm}
                    onChange={(e) => handleSearchTerm(e.target.value)}
                    sx={{ minWidth: '200px' }}
                    variant="standard"
                    className="searchInput"
                    placeholder='Search Users'
                    size='small'
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon />
                            </InputAdornment>),
                        endAdornment: (
                            <InputAdornment position="end">
                                <CloseIcon sx={{ display: searchTerm ? "inline-block" : "none", fontSize: "22px" }} className={UserListsStyles.closeIcon} onClick={clearSearchTerm} />
                            </InputAdornment>
                        )
                    }}
                />
                <Box className="flexCenterCenter userAction" gap={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EmailIcon />}
                        onClick={handleSendMails}
                        disabled={listLoading}
                        sx={{
                            padding: "6px 11px",
                            minWidth: "120px",
                            textTransform: "capitalize",
                            borderRadius: "100px"
                        }}
                    >
                        <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Send Mail</Typography>
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<DraftsIcon />}
                        onClick={handleNavigateToMails}
                        sx={{
                            padding: "6px 11px",
                            minWidth: "120px",
                            textTransform: "capitalize",
                            borderRadius: "100px"
                        }}
                    >
                        <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>View Mail</Typography>
                    </Button>
                    <Badge color="primary" sx={{ "& .MuiBadge-badge": { minWidth: "14px", height: "14px" } }} badgeContent={filterHasValue ? " " : undefined}>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<TuneIcon color={listLoading ? 'inherit' : 'primary'} />}
                            onClick={() => setOpenFilter(true)}
                            disabled={listLoading}
                            sx={{
                                padding: "6px 11px",
                                minWidth: "120px",
                                textTransform: "capitalize",
                                borderRadius: "100px"
                            }}
                        >
                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Filter</Typography>
                        </Button>
                    </Badge>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleNavigate}
                        sx={{
                            padding: "6px 11px",
                            minWidth: "120px",
                            textTransform: "capitalize",
                            borderRadius: "100px"
                        }}
                    >
                        <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Add User</Typography>
                    </Button>
                </Box>
            </Box>
            {openFilter ? <FilterDialog closeFilter={handleClose} /> : null}
            {openMailDialog ? <EmailTemplateDialog userMails={selectedUserMails} closeMailDialog={() => setOpenMailDialog(false)} /> : null}
        </>
    );
}

export default UserListHeader