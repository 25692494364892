import React, { useState } from "react";
import {
    Box,
    Dialog, DialogContent,
    DialogTitle, IconButton, Typography
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import ImageUploader from 'react-images-upload';
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";

import { ToastAlert } from "../../../components/toast-alert";
import api from "../../../api";
import { UPLOAD_IMAGE } from "../../../constants/api-constants";
import { useAppDispatch, useAppSelector } from "../../../redux/Hooks";
import { IMAGE_UPLOAD_FAILED_MSG, httpStatusCode } from "../../../constants/variable-constants";
import { PatientThunkApis } from "../../../redux/actions/patient-actions";
import { handleErrorMsg } from "../../../components/utils";

import UpdatePatientStyles from "../UpdatePatient.module.css";

interface imageUploadProps {
    openImageUploadModal: boolean,
    setOpenImageUploadModal: Function
}

const ImageUploadDialog = ({ openImageUploadModal, setOpenImageUploadModal }: imageUploadProps) => {
    const { patientId } = useParams<{ patientId: string }>();

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<FormData | null>(null);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { patientDetails, newPatientId } = useAppSelector(state => state.patients! || {});

    const handleClose = () => {
        setOpenImageUploadModal(false);
    };

    const handleNavigate = () => {
        navigate('/home/patient_lists');
    }

    const handleChangeFile = (files: File[]) => {
        if (!files || files.length < 1) {
            return undefined;
        }

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            formData.append('images', file);
        }

        setData(formData);
    }

    const handleImageUpload = async () => {
        setLoading(true);
        try {
            const uploadRes = await api.fetchAuthPostImageWithBody<{ message: string, thumbnail: string }>(UPLOAD_IMAGE(patientDetails ? patientDetails.patientId! : newPatientId!), data);
            if (uploadRes?.status === httpStatusCode.SUCCESS) {
                ToastAlert(uploadRes?.data?.message, 'success');
                dispatch(PatientThunkApis.getPatientDetails({ patientId: patientDetails ? patientDetails.patientId! : newPatientId! }));
                if (!patientId) {
                    handleNavigate();
                }
            } else {
                throw new Error(IMAGE_UPLOAD_FAILED_MSG);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setLoading(false);
            setData(null);
            handleClose();
        }
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth maxWidth={"sm"}
                open={openImageUploadModal}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <Typography className="flexCenterStart" sx={{ color: '#818080', fontSize: '1.5rem' }}>
                        Image Upload
                    </Typography>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CancelIcon color={'primary'} />
                </IconButton>
                <DialogContent>
                    <Box className={UpdatePatientStyles.uploadPanel}>
                        <ImageUploader
                            withIcon={true}
                            buttonText='Choose images'
                            onChange={handleChangeFile}
                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                            maxFileSize={10485760}
                            accept="accept=image/*"
                            withPreview={true}
                            fileContainerStyle={{
                                display: 'flex ',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            name="file"
                            label='Max file size: 10 MB, Accepted: jpg|png|gif'
                        />
                    </Box>
                    <Box my={2} className="flexCenterCenter">
                        <LoadingButton
                            loading={loading}
                            variant="contained"
                            color="primary"
                            onClick={handleImageUpload}
                            disabled={!data}
                            sx={{
                                padding: "6px 11px",
                                minWidth: "120px",
                                textTransform: "capitalize"
                            }}
                        >
                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Update & Save</Typography>
                        </LoadingButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default ImageUploadDialog