import styled from "@emotion/styled";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface AvantTooltipProps extends TooltipProps {
    offset?: [number, number];
}

export const AvantTooltip = styled(({ className, offset, ...props }: AvantTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} PopperProps={{ modifiers: [{ name: 'offset', options: { offset: offset || [0, 0] } }] }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'black',
        fontFamily: 'Rubik',
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'center',
    },
}));