import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../redux/Hooks";
import { sessionStorageGet } from "../components/session-storage-helpers/session-storage-actions";
import { AuthSliceActions } from "../redux/reducers/login-slice";
import Loader from "../components/loader/Loader";
import { SESSION_KEYS, USER_ROLES } from "../constants/variable-constants";
import { AuthThunkApis } from "../redux/actions/login-actions";

const LoginLayout: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { isLoggedIn, userDetails, isLoggedChecked } = useAppSelector(state => state.auth);

    useEffect(() => {
        const token = sessionStorageGet(SESSION_KEYS.TOKEN);
        const userId = sessionStorageGet(SESSION_KEYS.USER_ID);

        if (!isLoggedIn) {
            if (token) {
                dispatch(AuthThunkApis.getUserDetails({ userId }));
            } else {
                dispatch(AuthSliceActions.setIsLoggedIn({ isLoggedIn: false }));
            }
        }

        if (isLoggedIn) {
            if (userDetails) {
                const { roleId } = userDetails;
                const rediectUrl = sessionStorageGet(SESSION_KEYS.REDIRECT_URL);
                const page = sessionStorageGet(SESSION_KEYS.LAST_VISITED_PAGE) || undefined;

                if (!rediectUrl) {
                    const navigateTo = Object.values(USER_ROLES).includes(roleId!) ? '/home/dashboard' : '/home/patient_lists';
                    navigate(navigateTo);
                } else {
                    const params = new URLSearchParams();
                    if (page) {
                        params.append('page', page);
                    }
                    navigate({ pathname: rediectUrl, search: `?${params.toString()}` });
                }
            }
        }

    }, [isLoggedIn, userDetails]);

    return isLoggedChecked ? <Outlet /> : <Loader />;
};

export default LoginLayout;