import React, { useRef, useState } from "react";
import { AppBar, Badge, Box, Button, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import Websocket from 'react-websocket';

import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import { useLogoutService } from "../custom-hook/useLogoutService";
import { apiConstants } from "../../constants/api-constants";
import { UserSliceActions } from "../../redux/reducers/user-slice";
import { USER_LIST, USER_ROLES } from "../../constants/variable-constants";
import { PatientSliceActions } from "../../redux/reducers/patient-slice";

import "./Header.css";
interface notificationProps {
    data: string[],
    patientId: number,
    type: string,
    id?: number,
    userId?: number,
    approvalStatus?: number
}

const useStyles = makeStyles((theme) => ({
    customBadge: {
        position: "absolute",
        right: "5px",
        top: "3px"
    }
}));

const MyHeader = () => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [notificationsAnchorEl, setNotificationsAnchorEl] = useState<null | HTMLElement>(null);
    const [notifications, setNotifications] = useState<notificationProps[]>([]);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { currentPath, userDetails } = useAppSelector(state => state.auth || {});
    const { firstName, lastName, emailAddress, userId, roleId } = userDetails || {};
    const userName = `${firstName} ${lastName}`;
    const { isMenuOpen } = useAppSelector(state => state.users);

    const { logout } = useLogoutService();
    const classes = useStyles();

    const websocketRef = useRef<any>(null);

    const handleWebSocketOpen = () => {
        console.log('WebSocket connected');
        websocketRef.current?.sendMessage(JSON.stringify({
            userId,
            userName: emailAddress,
            msgType: 'LOGIN'
        }));
    };

    const handleWebSocketMessage = (message: string) => {
        console.log('Received message:', message);
        try {
            const parsedData = JSON.parse(message);
            const outputData = parsedData?.data?.map((item: any) => {
                if (typeof item === 'string') {
                    return JSON.parse(item);
                }
                return item;
            });
            setNotifications(outputData || []);
        } catch (error) {
            console.error('Failed to parse message:', error);
        }
    };

    const handleWebSocketClose = () => {
        console.log('WebSocket disconnected');
    };

    const handleClearNotifications = () => {
        setNotifications([]);
        websocketRef.current?.sendMessage(JSON.stringify({
            userId,
            userName: emailAddress,
            msgType: 'MSG_READ'
        }));
        dispatch(PatientSliceActions.patchState({ highlightPatientId: null }));
        dispatch(UserSliceActions.patchState({ highlightUserId: null }));
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleNotificationsOpen = (event: React.MouseEvent<HTMLElement>) => {
        setNotificationsAnchorEl(event.currentTarget);
    };

    const handleNotificationsClose = () => {
        setNotificationsAnchorEl(null);
    };

    const handleLogout = () => {
        logout();
    };

    const handleMenuOption = (route: string) => {
        const assignedDefaultList = {
            approved: true,
            waiting: false,
            rejected: false
        };
        navigate(route);
        handleMenuClose();
        dispatch(UserSliceActions.setInitialState());
        dispatch(UserSliceActions.patchState({ isUsersOpen: false, userListId: USER_LIST.approved, activeUserList: assignedDefaultList }));
    };

    const handleUserNavigate = (notification: notificationProps) => {
        const { id: notificationId, userId: notificationUserId, patientId, approvalStatus } = notification;
        const navigateTo = notificationUserId ? '/home/user_lists' : '/home/patient_lists';
        const assignedDefaultList = {
            approved: USER_LIST.approved === approvalStatus,
            waiting: USER_LIST.waiting === approvalStatus,
            rejected: USER_LIST.rejected === approvalStatus
        };

        navigate(navigateTo);

        // Send WebSocket message to mark notification as viewed
        websocketRef.current?.sendMessage(
            JSON.stringify({
                userId,
                notificationId,
                msgType: 'VIEWED_MSG',
            })
        );

        if (notificationUserId) {
            dispatch(UserSliceActions.patchState({ activeUserList: assignedDefaultList, isUsersOpen: true, userListId: approvalStatus, highlightUserId: notificationUserId }));
        } else {
            dispatch(PatientSliceActions.patchState({ highlightPatientId: patientId }));
        }

        handleNotificationsClose();
    }
    const toggleMenu = (type: boolean) => {
        dispatch(UserSliceActions.patchState({isMenuOpen: !type}));
    };

    return (
        <Box className="header-container">
            <AppBar position="static" elevation={0} className="appBarTransparent appBarHeader">
                <Toolbar className="flexCenterBetween">
                    <Box display="flex" alignItems="center" justifyContent="space-between" width={1}>
                        <Box display="flex" alignItems="center">
                        <Box className="mobileMenu flex">
                            <img src={"images/menu.svg"} alt="Avant_menu" onClick={ () => toggleMenu(isMenuOpen)} />
                        </Box>
                        <Typography className="sidebar_active_text">{currentPath}</Typography>
                        </Box>
                        <Box display="grid" gridAutoFlow="column" className="actionWrap">
                            <Badge
                                classes={{ badge: classes.customBadge }}
                                badgeContent={notifications.length}
                                color="primary"
                                onClick={handleNotificationsOpen}
                            >
                                <NotificationsOutlinedIcon color="action" sx={{ fontSize: 35, cursor: "pointer" }} />
                            </Badge>
                            <img src="images/avatar.svg" alt="Avatar_Img" className="avatar_logo" />
                            <Box className="flexCenterCenter" onClick={handleMenuOpen}>
                                <Typography className="user_name">{userName}</Typography>
                                <IconButton id="open-profile">
                                    <ArrowDropDownIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>

            <Menu
                anchorEl={document.getElementById("open-profile")}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                className="menuTop"
            >
                {roleId !== USER_ROLES.SUPER_ADMIN && (
                    <MenuItem onClick={() => handleMenuOption('profile')}>View Profile</MenuItem>
                )}
                {[USER_ROLES.USER_ADMIN, USER_ROLES.SUPER_ADMIN].includes(roleId!) && (
                    <MenuItem onClick={() => handleMenuOption('notifications')}>Notification Settings</MenuItem>
                )}
                {roleId !== USER_ROLES.SUPER_ADMIN && (
                    <MenuItem onClick={() => handleMenuOption('custom-notifications')}>Custom Notifications</MenuItem>
                )}
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>

            <Menu
                anchorEl={notificationsAnchorEl}
                open={Boolean(notificationsAnchorEl)}
                onClose={handleNotificationsClose}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                className="menuTop"
            >
                {notifications && notifications.length > 0 ? (
                    notifications.map((notification, index) => (
                        <MenuItem key={index} onClick={() => handleUserNavigate(notification)}>{notification?.data?.[0]}</MenuItem>
                    ))
                ) : (
                    <MenuItem>
                        <InfoOutlinedIcon color="primary" sx={{ marginRight: '5px' }} />
                        No notifications found
                    </MenuItem>
                )}
                {notifications && notifications.length > 0 && (
                    <MenuItem sx={{ justifyContent: 'flex-end' }}>
                        <Button
                            onClick={handleClearNotifications}
                            size="small"
                            sx={{
                                border: '1px solid #6fc1e8',
                                color: '#6fc1e8',
                                textTransform: 'none',
                                marginLeft: 'auto',
                            }}
                        >
                            Clear All
                        </Button>
                    </MenuItem>
                )}
            </Menu>

            <Websocket
                url={apiConstants.WEB_SOCK_URL}
                onOpen={handleWebSocketOpen}
                onMessage={handleWebSocketMessage}
                onClose={handleWebSocketClose}
                ref={websocketRef}
            />
        </Box>
    );
};

export default MyHeader;