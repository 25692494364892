import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Typography, Button, InputAdornment, IconButton } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from "@mui/lab";

import { useAppSelector } from "../../redux/Hooks";
import { PasswordDataProps } from "../../models/login.model";
import api from "../../api";
import { PASSWORD_CHANGE } from "../../constants/api-constants";
import { CHANGE_PASSWORD_FAILED_MSG, httpStatusCode } from "../../constants/variable-constants";
import { useLogoutService } from "../../components/custom-hook/useLogoutService";
import { ToastAlert } from "../../components/toast-alert";
import { handleErrorMsg } from "../../components/utils";

import ChangePasswordStyles from "./Profile.module.css";

const ChangePassword = () => {
    const [isPasswordChange, setIsPasswordChange] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<{ [key: string]: boolean }>({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false,
    });

    const { emailAddress } = useAppSelector(state => state.auth.userDetails! || {});

    const { logout } = useLogoutService();

    const initialValues: PasswordDataProps = { userName: emailAddress || "", oldPassword: "", newPassword: "", confirmPassword: "" };

    const validationSchema = Yup.object({
        oldPassword: Yup.string().required("Old Password is a required field"),
        newPassword: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .max(20, 'Password must be at most 20 characters')
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[^\+\-=()]+$/,
                'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character from the set [!@#$%^&*]'
            )
            .notOneOf([Yup.ref('oldPassword')], 'New password must be different from old password')
            .required("New Password is a required field"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword') as any], 'Password and confirm password should be same').required("Confirm Password is a required field")
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            handleUpdateSubmit(values);
        },
    });

    const redirectLogin = () => {
        logout();
    }

    const handleUpdateSubmit = async (values: PasswordDataProps) => {
        setLoading(true);
        const body = {
            userName: emailAddress,
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
            fromEmail: 0
        };

        try {
            const response = await api.fetchAuthPostWithBody<any>(PASSWORD_CHANGE, body);
            if (response.status === httpStatusCode.SUCCESS) {
                ToastAlert(response.data.message, 'success', redirectLogin);
            } else {
                throw new Error(response.data.message || CHANGE_PASSWORD_FAILED_MSG);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setLoading(false);
            setIsPasswordChange(false);
        }
    };

    const handleClickShowPassword = (field: string) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const resetForm = () => {
        formik.resetForm();
    }

    const handleCancelPasswordChange = () => {
        setIsPasswordChange(false);
        resetForm();
    }

    useEffect(() => {
        return () => {
            resetForm();
        }
    }, []);

    return (
        <Box my={2}>
            {!isPasswordChange ?
                <Box onClick={() => setIsPasswordChange(true)} ml={2} sx={{ width: "150px" }}>
                    <Typography className={ChangePasswordStyles.changetext}>Change Password</Typography>
                </Box> :
                <form onSubmit={formik.handleSubmit}>
                    <Grid container>
                        <Grid item lg={4} sm={6} xs={12} sx={{padding: {md: '15px', sm: '15px', xs: '10px 0'}}}>
                            <Typography className={ChangePasswordStyles.label}>Old Password</Typography>
                            <TextField
                                id="oldPassword"
                                name="oldPassword"
                                type={showPassword.oldPassword ? 'text' : 'password'}
                                value={formik.values.oldPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                                helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickShowPassword("oldPassword")}
                                            >
                                                {showPassword['oldPassword'] ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={4} sm={6} xs={12} sx={{padding: {md: '15px', sm: '15px', xs: '10px 0'}}}>
                            <Typography className={ChangePasswordStyles.label}>New Password</Typography>
                            <TextField
                                id="newPassword"
                                name="newPassword"
                                type={showPassword.newPassword ? 'text' : 'password'}
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                helperText={formik.touched.newPassword && formik.errors.newPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickShowPassword("newPassword")}
                                            >
                                                {showPassword['newPassword'] ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={4} sm={6} xs={12} sx={{padding: {md: '15px', sm: '15px', xs: '10px 0'}}}>
                            <Typography className={ChangePasswordStyles.label}>Confirm New Password</Typography>
                            <TextField
                                id="confirmPassword"
                                name="confirmPassword"
                                type={showPassword.confirmPassword ? 'text' : 'password'}
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickShowPassword("confirmPassword")}
                                            >
                                                {showPassword['confirmPassword'] ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Box className="flexCenterCenter" gap={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{
                                padding: "6px 11px",
                                minWidth: "120px",
                                textTransform: "capitalize",
                                borderRadius: "100px"
                            }}
                            onClick={handleCancelPasswordChange}
                        >
                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Cancel</Typography>
                        </Button>
                        <LoadingButton
                            loading={loading}
                            variant="contained"
                            color="primary"
                            type="submit"
                            sx={{
                                padding: "6px 11px",
                                minWidth: "120px",
                                textTransform: "capitalize",
                                borderRadius: "100px"
                            }}
                        >
                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Update</Typography>
                        </LoadingButton>
                    </Box>
                </form>
            }
        </Box>
    );
};

export default ChangePassword;