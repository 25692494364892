import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useLocation } from 'react-router-dom';

import { PasswordDataProps } from "../models/login.model";
import api from "../api";
import { PASSWORD_CHANGE } from "../constants/api-constants";
import { CHANGE_PASSWORD_FAILED_MSG, PASSWORD_CHANGE_STRINGS, httpStatusCode } from "../constants/variable-constants";
import { ToastAlert } from "../components/toast-alert";
import { handleErrorMsg } from "../components/utils";
import AvantIndex from "./HomeIndex";

import ChangePasswordStyles from "./login/Login.module.css";

const ChangePassword = () => {
    const location = useLocation();

    const [loading, setLoading] = useState<boolean>(false);

    const queryParams = new URLSearchParams(location.search);
    const emailAddress = queryParams.get('userName') || "";

    const initialValues: PasswordDataProps = { userName: emailAddress, oldPassword: "", newPassword: "", confirmPassword: "" };

    const validationSchema = Yup.object({
        oldPassword: Yup.string().required(PASSWORD_CHANGE_STRINGS.OLD_PASSWORD_REQUIRED),
        newPassword: Yup.string()
            .min(8, PASSWORD_CHANGE_STRINGS.PASSWORD_MIN_LENGTH)
            .max(20, PASSWORD_CHANGE_STRINGS.PASSWORD_MAX_LENGTH)
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[^\+\-=()]+$/,
                PASSWORD_CHANGE_STRINGS.PASSWORD_VALIDATION
            )
            .notOneOf([Yup.ref('oldPassword')], PASSWORD_CHANGE_STRINGS.PASSWORD_NOT_SAME)
            .required(PASSWORD_CHANGE_STRINGS.NEW_PASSWORD_REQUIRED),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword') as any], PASSWORD_CHANGE_STRINGS.PASSWORDS_MUST_MATCH).required(PASSWORD_CHANGE_STRINGS.CONFIRM_PASSWORD_REQUIRED)
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            handleUpdateSubmit(values);
        },
    });

    const handleUpdateSubmit = async (values: PasswordDataProps) => {
        setLoading(true);
        const body = {
            userName: emailAddress,
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
        };

        try {
            const response = await api.fetchAuthPostWithBody<{ message: string }>(PASSWORD_CHANGE, body);
            if (response.status === httpStatusCode.SUCCESS) {
                ToastAlert(response.data.message, 'success');
                window.location.href = "#/";
            } else if (response?.status === httpStatusCode.CREATED) {
                ToastAlert(response?.data?.message, 'error');
            } else {
                throw new Error(response.data.message || CHANGE_PASSWORD_FAILED_MSG);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        return () => {
            formik.resetForm();
        }
    }, []);

    return (
        <AvantIndex>
            <form onSubmit={formik.handleSubmit}>
                <Box className="flexCenterCenter f-col">
                    <Box className="flexCenterStart f-col">
                        <Typography className={ChangePasswordStyles.loginText}>{PASSWORD_CHANGE_STRINGS.CHANGE_PASSWORD}</Typography>
                        <TextField
                            required
                            id="oldPassword"
                            name="oldPassword"
                            variant="outlined"
                            size="small"
                            type="password"
                            placeholder={PASSWORD_CHANGE_STRINGS.OLD_PASSWORD}
                            sx={{
                                '& .MuiInputBase-root': {
                                    width: '280px',
                                    borderRadius: '100px',
                                    marginTop: '20px'
                                }
                            }}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.oldPassword}
                            error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                        />
                        <Box sx={{ width: "280px" }}>
                            <TextField
                                required
                                id="newPassword"
                                name="newPassword"
                                variant="outlined"
                                size="small"
                                type="password"
                                placeholder={PASSWORD_CHANGE_STRINGS.NEW_PASSWORD}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        width: '280px',
                                        borderRadius: '100px',
                                        marginTop: '20px'
                                    }
                                }}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.newPassword}
                                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                helperText={formik.touched.newPassword && formik.errors.newPassword}
                            />
                        </Box>

                        <TextField
                            required
                            id="confirmPassword"
                            name="confirmPassword"
                            variant="outlined"
                            size="small"
                            type="password"
                            placeholder={PASSWORD_CHANGE_STRINGS.CONFIRM_PASSWORD}
                            sx={{
                                '& .MuiInputBase-root': {
                                    width: '280px',
                                    borderRadius: '100px',
                                    marginTop: '20px'
                                }
                            }}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.confirmPassword}
                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        />
                    </Box>
                    <Box sx={{ width: '280px' }}>
                        <LoadingButton
                            type="submit"
                            loading={loading}
                            sx={{
                                color: '#ffffff',
                                backgroundColor: loading ? '#6fc1e8' : '#b5b5b5',
                                padding: '5px 15px',
                                minWidth: '100px',
                                borderRadius: '100px',
                                marginTop: '20px',
                                '&:hover': {
                                    color: '#ffffff',
                                    backgroundColor: '#6fc1e8'
                                }
                            }}
                        >
                            <Typography sx={{ textTransform: 'capitalize', fontSize: '14px' }}>{PASSWORD_CHANGE_STRINGS.UPDATE}</Typography>
                        </LoadingButton>
                    </Box>
                </Box>
            </form>
        </AvantIndex >
    );
};

export default ChangePassword;