export const httpStatusCode = {
    SUCCESS: 200,
    CREATED: 201,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    UN_AUTORIZED: 401,
    EXPECTATION_FAILED: 417,
    INTERNAL_ERRORS: 500,
    SESSION_TIMEOUT: 408,
    OK: 200,
    NOT_FOUND: 404,
    ERROR: 400,
    NOT_ALLOWED: 405,
    NOT_MODIFIED: 304
};

export const USER_ROLES = {
    SUPER_ADMIN: 1,
    USER_ADMIN: 2
}

export const DOCTOR_ROLE = 3;
export const DOCTOR_STAFF_ROLE = 4;

export const userRoles = [
    { value: 1, label: "Super Admin" },
    { value: 2, label: "Admin" },
    { value: 3, label: "Doctor" },
    { value: 4, label: "Doctor Staff" }
];

export const genderList = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Unknown', label: 'Unknown' }
];

export const roleList = [
    { value: 1, label: 'Super Admin' },
    { value: 2, label: 'Admin' },
    { value: 3, label: 'Doctor' },
    { value: 4, label: 'Doctor Staff' }
];

export const pathMap = {
    'create': 'Add Patient',
    'add-user': 'Add User',
    'add-practice': 'Add Practice',
    'patient_lists': 'Patients',
    'practice_lists': 'Practices',
    'occupation_lists': 'Occupations',
    'mail-list': 'Mails',
    'user_lists': 'Users',
    'doctor_lists': 'Doctors',
    'dashboard': 'Dashboard',
    'profile': 'Profile',
    'summary_status': 'Patients',
    'custom': 'Custom Notifications',
    'referral': 'Referrals',
    'notifications': 'Notification Settings',
};

export const patientStatusNotesIds = [2, 5, 7];

export const patientDateIds = [3, 4, 6, 8];

export const SESSION_KEYS = {
    TOKEN: 'token',
    USER_ID: 'userId',
    REDIRECT_URL: 'redirectUrl',
    LAST_VISITED_PAGE: 'page',
    LAST_VISIBILITY_TIME: 'lastVisibilityTime'
};

export const LOGIN_FAILED_MSG = "Login failed. Please try again.";
export const PROFILE_UPDATE_FAILED_MSG = "Failed to update the user profile.";
export const CHANGE_PASSWORD_FAILED_MSG = "Failed to change the password.";
export const EMAIL_EXISTS_MSG = "Email already exists!";
export const ADD_PATIENT_FAILED_MSG = "Failed to add the patient.";
export const SAVE_PATIENT_FAILED_MSG = "Failed to save the patient details.";
export const IMAGE_UPLOAD_FAILED_MSG = "Failed to upload the images.";
export const ADD_USER_FAILED_MSG = "Failed to add the user.";
export const UPDATE_USER_FAILED_MSG = "Failed to update the user.";
export const USER_DELETE_FAILED_MSG = "Failed to delete the user";
export const USER_DELETE_SUCCESS_MSG = "User deleted successfully";
export const DELETE_PRACTICE_FAILED_MSG = "Failed to delete the practice.";
export const DELETE_PRACTICE_SUCCESS_MSG = "Practice deleted successfully";
export const RESET_PASSWORD_FAILED_MSG = "Failed to reset the password";
export const USER_STATUS_FAILED_MSG = "Failed to update the user status";
export const USER_APPROVED_SUCCESS_MSG = "User approved successfully";
export const USER_APPROVED_FAILED_MSG = "Failed to approve the user";
export const USER_REJECTED_SUCCESS_MSG = "User rejected successfully";
export const USER_REJECTED_FAILED_MSG = "Failed to reject the user";
export const NON_EMPTY_PRACTICE_MSG = "Please provide a non-empty name of practice";
export const PRACTICE_EXISTS_MSG = "Given name of practice already exists";
export const ADD_OR_UPDATE_PRACTICE_FAILED_MSG = "Failed to add/update the practice.";
export const SESSION_EXPIRED_MSG = "Session Expired. Do you want to continue ?";
export const LOGOUT_SUCCESS_MSG = "Logout successfully";
export const NEW_USER_FAILED = "Failed to create the user";
export const USER_NOTIFICATIONS_SUCCESS = "User notifications have been saved successfully";
export const DOCTOR_STAFF_SINGLE_PRACTICE_VALIDATION = "Doctor Staff can only be associated with one practice";
export const PHONE_NUMBER_EXACT_LENGTH = "Phone number must be exactly 10 digits";
export const PHONE_OR_EMAIL_REQUIRED = "Either phone number or email is required";

export const USER_NOT_FOUND = "user_not_found";
export const INVALID_ROLE = "invalid_role";
export const LOGGED_OUT = "logged out";
export const CANCELED = "canceled";
export const LOGOUT = "logout";
export const IS_EMPTY = "NA";

export const USER_ROLE_NAMES = {
    SUPER_ADMIN: "Super Admin",
    ADMIN: "Admin",
    DOCTOR: "Doctor",
    DOCTOR_STAFF: "Doctor Staff"
}

export const USERLIST_STATUS = {
    APPROVED: "approved",
    WAITING: "waiting",
    REJECTED: "rejected"
}

export const PATIENT_COLUMNS = {
    REFERRED_TO: "Referred To",
    REFERRED_FOR: "Referred For",
    REFERRED_BY: "Referral Doctor",
    PRACTICE: "Practice",
    HOSPITAL: "Hospital"
}

export const ACTION_COLUMN = "Action";
export const SUB_CATEGORY_COLUMN = "Sub-Category";

export const PASSWORD_CHANGE_STRINGS = {
    OLD_PASSWORD_REQUIRED: "Old Password is a required field",
    NEW_PASSWORD_REQUIRED: "New Password is a required field",
    CONFIRM_PASSWORD_REQUIRED: "Confirm Password is a required field",
    PASSWORD_MIN_LENGTH: 'Password must be at least 8 characters',
    PASSWORD_MAX_LENGTH: 'Password must be at most 20 characters',
    PASSWORD_VALIDATION: 'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character from the set [!@#$%^&*]',
    PASSWORD_NOT_SAME: 'New password must be different from old password',
    PASSWORDS_MUST_MATCH: 'Password and confirm password should be same',
    UPDATE: 'Update',
    CHANGE_PASSWORD: 'Change Password',
    OLD_PASSWORD: 'Old Password',
    NEW_PASSWORD: 'New Password',
    CONFIRM_PASSWORD: 'Confirm Password'
};

export const CHECKED_USER = 1;
export const UNCHECKED_USER = 0;

export const PRACTICE = {
    NAME_REQUIRED: "Practice name is required",
    PHONE_NUMBER_REQUIRED: "Phone number must be only digits",
    INVALID_EMAIL: "Invalid email format",
    INVALID_WEBSITE: "Invalid URL format",
    DOCTOR_NAME_REQUIRED: "Doctor name is required",
    DOCTOR_STAFF_REQUIRED: "Doctor staff name is required"
}

export const ERROR_MESSAGE = "Something went wrong!";

export const ALL_PATIENT_STATUS = 0;

export const DOWNLOAD_FAILED = "Failed to download the patient details";

export const FILE_TYPE = {
    XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    CSV: "text/csv;charset=utf-8",
    RIS: "application/x-research-info-systems",
    DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
}

export const CHECKBOX_SELECT = "Select";

export const OCCUPATION = {
    ADDED: "Occupation added successfully",
    UPDATED: "Occupation updated successfully",
    FAILED: "Failed to add/update the occupation",
    NON_EMPTY: "Please provide a non-empty name of occupation",
    ALREADY_EXISTS: "Given name of occupation already exists",
    DELETED: "Occupation deleted successfully"
}

export const OTHER_OCCUPATION = {
    NAME: "Others",
    ID: 4
}

export const EMAIL_SEND = {
    SUCCESS: "Emails sent successfully",
    FAILED: "Failed to sent the emails",
    DRAFT: "Draft saved successfully! Your changes have been securely stored for later use"
}

export const HOSPITAL_NAMES = {
    AVANT_LASIK: 1,
    SHETH_HORSLEY: 2
}

export const ADMIN_MAIL = "shethhorsleyeyecenter@gmail.com";

export const INDEX_FOR_REFERRAL_REASONS = 3;

export const EXPORT_COLUMN = {
    REASON_FOR_REFERRAL: "Reason for Referral",
    REFERRAL_DATE: "Referral Date",
    PHONE_NUMBER: "Phone Number"
}

export const MAIL_LIST_SCREENS = [
    { value: "sent", label: "Sent List" },
    { value: "draft", label: "Draft List" }
];

export const INITIAL_MAIL_LIST_SCREEN = "sent";
export const DRAFT_MAIL = "draft";
export const DELETE_MAIL_FAILED = "Failed to delete the mail";

export const MAX_ATTACHMENT_SIZE_MB = 10;
export const MAX_ATTACHMENT_SIZE_BYTES = MAX_ATTACHMENT_SIZE_MB * 1024 * 1024;

export const CUSTOM_NOTIFICATIONS = {
    CREATION: "savePatient",
    UPDATION: "updatePatient",
    DELETION: "deletePatient"
}

export const FAILED_CUSTOM_NOTIFICATIONS = "Failed to save all the custom notifications";
export const FAILED_CUSTOM_NOTIFICATION_SETTINGS = "Failed to fetch custom notification settings";
export const FAILED_ENABLED_CUSTOM_NOTIFICATION_SETTINGS = "Failed to enable the custom notification settings";
export const FAILED_ALL_NOTIFICATIONS = "Failed to get the overall custom notification settings";

export const USER_LIST = {
    approved: 1,
    waiting: 0,
    rejected: 2
}

export const REFERRAL_REASON = {
    ADDED: "Referral reason added successfully",
    UPDATED: "Referral reason updated successfully",
    FAILED: "Failed to add/update the Referral reason",
    NON_EMPTY: "Please provide a non-empty name of referral reason",
    ALREADY_EXISTS: "Given name of referral reason already exists",
    DELETED: "Referral reason deleted successfully"
}

export const OTHER_REFERRAL_REASON = "Other";

export const VIEW_LIST = {
    USER: "User",
    ADMIN: "Admin"
}
export const NOTIFICATION_VIEW_LIST = ["User", "Admin"];
export const ADMIN_TAB_INFO_FOR_STATUS_UPDATES = "This setting can only be updated by a Super Admin";
export const ADMIN_INFO_OVERALL_STATUS_UPDATES = "This feature allows you to update the bulk status for all selected users simultaneously";