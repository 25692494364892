import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { dashboardSliceProps } from "../../models/dashboard.model";
import { DashboardThunkApis } from "../actions/dashboard-actions";
import { resetState } from "../actions/reset-actions";

const initialState: dashboardSliceProps = {
    isLoading: false,
    dashboardData: null
}

const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        patchState(state, action: PayloadAction<Partial<dashboardSliceProps>>) {
            return { ...state, ...action.payload }
        }
    },
    extraReducers: (builder) => {
        // To reset all the state variables
        builder.addCase(resetState, () => initialState);
        //getDashboardStatus
        builder.addCase(DashboardThunkApis.getDashboardStatus.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(DashboardThunkApis.getDashboardStatus.fulfilled, (state, action) => {
            state.isLoading = false;
            let dashboardData = action.payload.data;

            const index = dashboardData?.findIndex(item => item.PatientStatusName === "Patient requests contact in future");

            if (index !== -1) {
                const [item] = dashboardData.splice(index, 1);
                dashboardData.push(item);
            }

            state.dashboardData = dashboardData;
        });

        builder.addCase(DashboardThunkApis.getDashboardStatus.rejected, (state) => {
            state.isLoading = false
        });
    }
});

export const DashboardSliceActions = DashboardSlice.actions;
export default DashboardSlice.reducer;