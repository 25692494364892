import { createEntityAdapter, createSlice, PayloadAction, EntityId } from "@reduxjs/toolkit";

import { RootState } from "../Store";
import { notificationSliceProps, selectedUsersProps } from "../../models/notifications.model";
import { NotificationThunkApis } from "../actions/notification-actions";
import { CHECKED_USER, UNCHECKED_USER, USER_ROLES, USER_ROLE_NAMES, VIEW_LIST } from "../../constants/variable-constants";
import { doctorListsResponse } from "../../models/practice.model";
import { resetState } from "../actions/reset-actions";

const notificationUsersAdapter = createEntityAdapter<doctorListsResponse>({
    selectId: (user) => {
        // Ensure user id is not null
        if (user.UserId === null) {
            throw new Error('Email cannot be null');
        }
        return user.UserId as EntityId;
    },
    sortComparer: (a, b) => {
        if (a.FullName === null || b.FullName === null) {
            return 0;
        }
        return b.FullName.toLowerCase().localeCompare(b.FullName.toLowerCase());
    },
});

const initialState = notificationUsersAdapter.getInitialState<notificationSliceProps>({
    pagesize: 10,
    currentPage: 0,
    listLoading: false,
    totalUsers: 0,
    searchTerm: null,
    selectedUsers: [],
    selectedPracticeId: null,
    practiceOrientedPatientStatusIds: [],
    isOverallPatientStatusEnabled: false,
    viewList: VIEW_LIST.USER
});

const NotificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        patchState(state, action: PayloadAction<Partial<notificationSliceProps>>) {
            return { ...state, ...action.payload };
        },
        setInitialState(state) {
            return {
                ...state,
                pagesize: 10,
                currentPage: 0,
                totalUsers: 0,
                searchTerm: null,
                selectedUsers: [],
                selectedPracticeId: null,
                isOverallPatientStatusEnabled: false,
                viewList: VIEW_LIST.USER
            }
        },
        removeNotificationUser: (state, action: PayloadAction<{ id: number }>) => {
            notificationUsersAdapter.removeOne(state, action.payload.id);
        },
        updateUserPatientStatusIds: (state, action: PayloadAction<{ id: number, patientStatusIds: number[] }>) => {
            const { id, patientStatusIds } = action.payload;

            notificationUsersAdapter.updateOne(state, {
                id,
                changes: { PatientStatusIds: patientStatusIds }
            });
        },
        selectAllUsers: (state, action: PayloadAction<{ roleId: number }>) => {
            const { roleId } = action.payload;

            const filterUsersByRole = (user: doctorListsResponse | undefined) => {
                if (roleId === USER_ROLES.USER_ADMIN) {
                    return user?.RoleName !== USER_ROLE_NAMES.ADMIN;
                }
                return true; // Default behavior for other roles
            };

            const mapUsersToSelected = (user: doctorListsResponse | undefined) => ({
                userId: user?.UserId!,
                isSelected: CHECKED_USER,
                practiceId: state.selectedPracticeId!
            });

            // Preserve users selected by SUPER_ADMIN
            const adminSelectedUsers = state.selectedUsers.filter(selected =>
                state.entities[selected.userId]?.RoleName === USER_ROLE_NAMES.ADMIN &&
                selected.isSelected === CHECKED_USER &&
                selected.RoleId === USER_ROLES.SUPER_ADMIN
            );

            const selectAllUsers = Object.values(state.entities || {})
                .filter(filterUsersByRole)
                .map(mapUsersToSelected);

            state.selectedUsers = roleId === USER_ROLES.USER_ADMIN ? [...adminSelectedUsers, ...selectAllUsers] : [...selectAllUsers];
        },
        deselectAllUsers: (state, action: PayloadAction<{ roleId: number }>) => {
            const { roleId } = action.payload;
            if (roleId === USER_ROLES.USER_ADMIN) {
                state.selectedUsers = state.selectedUsers.filter(selected =>
                    state.entities[selected.userId]?.RoleName === USER_ROLE_NAMES.ADMIN &&
                    selected.isSelected === CHECKED_USER &&
                    selected.RoleId === USER_ROLES.SUPER_ADMIN);
            } else {
                state.selectedUsers = [];
            }
        },
        toggleUserSelection(state, action: PayloadAction<{ id?: number, user?: doctorListsResponse }>) {
            const userId = action.payload.user?.UserId ?? action.payload.id;
            if (userId === undefined) {
                return;
            }
            const user = state.selectedUsers.find(user => user.userId === userId);

            if (user) {
                user.isSelected = user.isSelected === CHECKED_USER ? UNCHECKED_USER : CHECKED_USER;
            } else {
                const newUser: selectedUsersProps = { userId, isSelected: CHECKED_USER };
                if (action.payload.user?.RoleName === USER_ROLE_NAMES.ADMIN) {
                    newUser.practiceId = state.selectedPracticeId!;
                }
                state.selectedUsers.push(newUser);
            }
        },
    },
    extraReducers: (builder) => {
        // To reset all the state variables
        builder.addCase(resetState, () => initialState);
        // getNotificationUserLists
        builder.addCase(NotificationThunkApis.getNotificationUserLists.pending, (state) => {
            state.listLoading = true;
        });
        builder.addCase(NotificationThunkApis.getNotificationUserLists.fulfilled, (state, action) => {
            state.listLoading = false;
            state.totalUsers = action.payload.data.length! || 0;
            const groupedData = action.payload.data?.reduce((acc, item) => {
                const key = `${item.FullName}-${item.EmailAddress}`;

                if (!acc[key]) {
                    acc[key] = {
                        FullName: item.FullName,
                        EmailAddress: item.EmailAddress,
                        OccupationName: item.OccupationName,
                        RoleName: item.RoleName,
                        PracticeId: item.PracticeId,
                        UserId: item.UserId,
                        PatientStatusIds: item.PatientStatusIds,
                        SubcategoryName: item.SubcategoryName ? [item.SubcategoryName] : []
                    };
                } else if (item.SubcategoryName && !acc[key].SubcategoryName.includes(item.SubcategoryName)) {
                    acc[key].SubcategoryName.push(item.SubcategoryName);
                }

                return acc;
            }, {});

            notificationUsersAdapter.setAll(state, Object.values(groupedData) || []);
        });
        builder.addCase(NotificationThunkApis.getNotificationUserLists.rejected, (state) => {
            state.listLoading = false;
        });
        // getUserNotificationList
        builder.addCase(NotificationThunkApis.getSelectedUsersNotificationList.fulfilled, (state, action) => {
            const users = action.payload.data?.map(user => ({
                userId: user.UserId!,
                isSelected: CHECKED_USER,
                RoleId: user.RoleId
            })) || [];
            state.selectedUsers = users;
        });
    },
});

export const notificationUserSelectors = notificationUsersAdapter.getSelectors<RootState>((state) => state.notifications);

export const NotificationSliceActions = NotificationSlice.actions;
export default NotificationSlice.reducer;