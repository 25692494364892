import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../api";
import { referralReasonsListResponse } from "../../models/referral-reasons.model";
import { ALL_REFERRAL_REASONS, REFERRAL_REASONS } from "../../constants/api-constants";
import { ResponseModel, referralReasonsProps } from "../../models/patient.model";
import { ERROR_MESSAGE } from "../../constants/variable-constants";

const getReferralReasonLists = createAsyncThunk(
    'referralReasons/getReferralReasonLists',
    async (payload: string, { rejectWithValue }) => {

        try {
            const response = await api.fetchAuthGet<ResponseModel<referralReasonsListResponse[]>>(ALL_REFERRAL_REASONS);
            return response.data;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return rejectWithValue(errorMessage);
        }
    }
);

const getReferralreasons = createAsyncThunk(
    'referralReasons/getReferralreasons',
    async (payload: string, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<referralReasonsProps[]>>(REFERRAL_REASONS);
            return response.data
        } catch (error: any) {
            let errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

export const ReferralReasonsThunkApis = { getReferralReasonLists, getReferralreasons };