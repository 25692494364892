import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { theme } from './theme/theme-const';
import { store } from "./redux/Store";

import "./css/index.css";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();