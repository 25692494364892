import React from "react";
import { Box, IconButton, Modal } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';

import UpdateUser from "../../update-user/UpdateUser";

import styles from './AddDoctorStaffModal.module.css';

interface AddDoctorStaffModalProps {
    open?: boolean,
    handleClose?: () => void
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: "20px 20px 0px 20px",
    maxHeight: "95%",
    borderRadius: 2
};

const AddDoctorStaffModal = ({ open, handleClose }: AddDoctorStaffModalProps) => {

    return (
        <Modal open={!!open} onClose={handleClose} disableEscapeKeyDown>
            <Box className={styles.modalCont} sx={style}>
                <UpdateUser />
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 2,
                        top: 2,
                    }}
                >
                    <CancelIcon color={'primary'} />
                </IconButton>
            </Box>
        </Modal>
    )
}

export default AddDoctorStaffModal;