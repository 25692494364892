import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { authSliceProps, LoggedInOrNotProps } from "../../models/login.model";
import { AuthThunkApis } from "../actions/login-actions";
import { resetState } from "../actions/reset-actions";

const initialState: authSliceProps = {
    isLoggedIn: false,
    loginDetails: null,
    currentPath: null,
    userDetails: null,
    isLoggedChecked: false,
    successiveMsg: null,
}

const AuthSlice = createSlice({
    name: 'authDetails',
    initialState,
    reducers: {
        patchState(state, action: PayloadAction<Partial<authSliceProps>>) {
            return { ...state, ...action.payload }
        },
        setIsLoggedIn: (state, { payload }: PayloadAction<LoggedInOrNotProps>) => {
            state.isLoggedIn = payload?.isLoggedIn
            state.isLoggedChecked = true;
            if (!payload.isLoggedIn) {
                state.userDetails = null
            }
        }
    },
    extraReducers: (builder) => {
        // To reset all the state variables
        builder.addCase(resetState, () => initialState);
        // getUserDetails
        builder.addCase(AuthThunkApis.getUserDetails.fulfilled, (state, action) => {
            state.isLoggedIn = true
            state.isLoggedChecked = true
            state.userDetails = action.payload.data
        });
    }
});

export const AuthSliceActions = AuthSlice.actions;
export default AuthSlice.reducer;