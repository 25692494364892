import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import { DashboardThunkApis } from "../../redux/actions/dashboard-actions";
import ComponentLoader from "../../components/ComponentLoader";
import { dashboardDetailsProps } from "../../models/dashboard.model";
import { getPracticeNameId } from "../../components/utils";
import { NotificationSliceActions } from "../../redux/reducers/notification-slice";
import { PracticeThunkApis } from "../../redux/actions/practice-actions";
import { practiceSelectors } from "../../redux/reducers/practice-slice";
import { USER_ROLES } from "../../constants/variable-constants";

import DashboardStyles from "./Dashboard.module.css";

const Dashboard = () => {
    const dispatch = useAppDispatch();
    const { isLoading, dashboardData } = useAppSelector((state) => state.dashboard || {});
    const { practiceNameId, roleId } = useAppSelector(state => state.auth.userDetails! || {});

    const practicesData = useAppSelector(practiceSelectors.selectAll);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(DashboardThunkApis.getDashboardStatus(''));
        dispatch(PracticeThunkApis.getAuthAllPractices({ roleId: roleId! }));
        if (roleId === USER_ROLES.USER_ADMIN) {
            dispatch(PracticeThunkApis.getAllAdminPractices(''));
        }
    }, [dispatch]);

    const getStatusName = (data: dashboardDetailsProps) => {
        return data?.PatientStatusName === "New Patient" ? "REFERRALS RECEIVED" : data?.PatientStatusName?.toUpperCase();
    };

    const getTotalCount = () => {
        return dashboardData?.reduce((acc, data) => acc + data?.PatientCount, 0) || 0;
    };

    const handlePatientsListByStatus = (id: number) => {
        navigate(`/home/patient_lists/summary_status/${id}`);
    }

    useEffect(() => {
        if (practiceNameId) {
            const initialId = getPracticeNameId(practiceNameId);
            dispatch(NotificationSliceActions.patchState({ selectedPracticeId: roleId === USER_ROLES.SUPER_ADMIN ? practicesData?.[0]?.PracticeNameId : initialId }));
        }
    }, [practiceNameId, practicesData]);

    return (
        <Box className={DashboardStyles.container}>
            {isLoading ? (
                <ComponentLoader />
            ) : (
                <Grid container>
                    {dashboardData?.length ? (
                        <>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Box className={DashboardStyles.wrapperBox}>
                                    <Typography variant="h5" className={DashboardStyles.count}>
                                        {getTotalCount()}
                                    </Typography>
                                    <Typography className={DashboardStyles.name}>TOTAL REFERRALS</Typography>
                                </Box>
                            </Grid>
                            {dashboardData.map((data) => (
                                <Grid item lg={3} md={4} sm={6} xs={12} key={data?.PatientStatusId} sx={{ cursor: "pointer" }} onClick={() => handlePatientsListByStatus(data?.PatientStatusId)}>
                                    <Box className={DashboardStyles.wrapperBox}>
                                        <Typography variant="h5" className={DashboardStyles.count}>
                                            {data?.PatientCount}
                                        </Typography>
                                        <Typography className={DashboardStyles.name}>
                                            {getStatusName(data)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </>
                    ) : (
                        <Typography className="flexCenterCenter" variant="h6" my={5} width={"100%"}>
                            No data found
                        </Typography>
                    )}
                </Grid>
            )}
        </Box>
    );
};

export default Dashboard;