import { CHECKBOX_SELECT, DOCTOR_STAFF_ROLE, USER_ROLES } from "../constants/variable-constants";

export const userTableHeaders = ["Name", "Email", "Occupation", "Sub-Category", "Role", "Action"];

export const notificationTableHeaders = ["Name", "Email", "Occupation", "Sub-Category", "Patient Status", "Role"];

export const practiceTableHeaders = ["Name", "Email", "Phone number", "Doctor staff", "Action"];

export const occupationTableHeaders = ["Name", "Sub-Category", "Action"];

export const getPatientTableHeaders = (roleId: number | null) => {
    const headers = ["Name", "Age", "Gender", "Referral Doctor", "Date of Referral", "Reason for Referral", "Practice", "Status", "Action"];
    if ([USER_ROLES.SUPER_ADMIN, USER_ROLES.USER_ADMIN, DOCTOR_STAFF_ROLE].includes(roleId!)) {
        headers.unshift(CHECKBOX_SELECT);
    }
    return headers;
};

export const getUserTableHeaders = (roleId: number | null) => {
    const headers = ["Name", "Email", "Occupation", "Sub-Category", "Role", "Action"];
    if ([USER_ROLES.SUPER_ADMIN, USER_ROLES.USER_ADMIN].includes(roleId!)) {
        headers.unshift(CHECKBOX_SELECT);
    }
    return headers;
};