import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CancelIcon from '@mui/icons-material/Cancel';

import { ToastAlert } from '../../../components/toast-alert';
import { OccupationSliceActions } from '../../../redux/reducers/occupation-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/Hooks';
import api from '../../../api';
import { ADD_OCCUPATION, UPDATE_OCCUPATION } from '../../../constants/api-constants';
import { ERROR_MESSAGE, OCCUPATION, httpStatusCode } from '../../../constants/variable-constants';
import { OccupationTunkApis } from '../../../redux/actions/occupation-actions';
import SubCategoryInput from './SubCategoryInput';
import { occupationListProps } from '../../../models/occupation.model';

import OccupationListStyles from "../OccupationList.module.css";

const AddOrEditOccupationDialog = () => {
    const [occupationName, setOccupationName] = useState<string | null>(null);
    const [subCategories, setSubCategories] = useState<string[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const { occupationList, editOccupation } = useAppSelector(state => state.occupations! || {});

    useEffect(() => {
        if (editOccupation) {
            setOccupationName(editOccupation?.OccupationName);
            setSubCategories(editOccupation?.SubcategoryName || []);
        }
    }, [editOccupation]);

    const closeDialog = () => {
        dispatch(OccupationSliceActions.patchState({ openOccupationDialog: false, editOccupation: null }));
        setOccupationName(null);
        setSubCategories([]);
    };

    const handleAddOccupation = async () => {
        const response = await api.fetchAuthPostWithBody<{ auth: boolean, message: string }>(ADD_OCCUPATION, { occupationName, subcategories: subCategories });
        if (response.status === httpStatusCode.SUCCESS) {
            ToastAlert(OCCUPATION.ADDED, 'success');
            closeDialog();
            dispatch(OccupationTunkApis.getOccupationsWithSubCategories(''));
        } else {
            throw new Error(OCCUPATION.FAILED);
        }
    };

    const handleUpdateOccupation = async () => {
        const response = await api.fetchAuthPutWithBody<{ auth: boolean, message: string }>(UPDATE_OCCUPATION, convertToDesiredFormat(editOccupation!));
        if (response.status === httpStatusCode.SUCCESS) {
            ToastAlert(OCCUPATION.UPDATED, 'success');
            closeDialog();
            dispatch(OccupationTunkApis.getOccupationsWithSubCategories(''));
        } else {
            throw new Error(OCCUPATION.FAILED);
        }
    };

    const handleOccupation = async () => {
        setLoading(true);
        if (!occupationName) {
            ToastAlert(OCCUPATION.NON_EMPTY, 'error');
            setLoading(false);
            return;
        }
        if (!editOccupation && occupationList.some(occupation => occupation.OccupationName!.toLowerCase() === occupationName!.toLowerCase())) {
            ToastAlert(OCCUPATION.ALREADY_EXISTS, 'error');
            setLoading(false);
            return;
        }
        try {
            if (!!editOccupation) {
                await handleUpdateOccupation();
            } else {
                await handleAddOccupation();
            }
        } catch (error: any) {
            ToastAlert(error?.response?.data?.message || ERROR_MESSAGE, 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleAddSubCategory = () => {
        setSubCategories([...subCategories, '']);
    };

    const handleSubCategoryChange = (index: number, value: string) => {
        const updatedSubCategories = subCategories.map((subCategory, idx) => (idx === index ? value : subCategory));
        setSubCategories(updatedSubCategories);
    };

    const handleRemoveSubCategory = (index: number) => {
        const updatedSubCategories = subCategories.filter((_, idx) => idx !== index);
        setSubCategories(updatedSubCategories);
    };

    const convertToDesiredFormat = (occupation: occupationListProps) => {
        const { OccupationId, SubcategoryId } = occupation;

        const subcategories = subCategories?.map((name, index) => ({
            subcategoryId: SubcategoryId[index] || null,
            subcategoryName: name
        })) || [];

        return {
            occupationId: OccupationId,
            occupationName: occupationName,
            subcategories: subcategories
        };
    };

    return (
        <Dialog
            className='occupationDialog'
            fullWidth
            maxWidth="sm"
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiDialog-paper': {
                  width: '100%',
                  maxWidth: 'sm',
                  '@media (max-width: 480px)': {
                    width: '100%',
                    maxWidth: '100%',
                    margin: '10px'
                  }
                }
              }}
        >
            <IconButton
                aria-label="close"
                onClick={closeDialog}
                sx={{
                    position: 'absolute',
                    right: 2,
                    top: 2,
                }}
            >
                <CancelIcon color="primary" />
            </IconButton>
            <DialogContent>
                <DialogTitle id="alert-dialog-title" sx={{padding: {md: '16px 24px', xs: '16px 0'}}}>
                    <Typography className={OccupationListStyles.title}>{"Add/Edit Occupations"}</Typography>
                </DialogTitle>
                <DialogContentText id="alert-dialog-description">
                    <Box>
                        <Box className="flexCenterCenter" sx={{gap: {md: '10px', xs: '5px'}}}>
                            <TextField
                                id="occupationName"
                                name="occupationName"
                                variant="outlined"
                                size='small'
                                placeholder="Enter an occupation name"
                                value={occupationName}
                                onChange={(e) => setOccupationName(e.target.value)}
                                fullWidth
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '12px',
                                        padding: '3px 5px',
                                    },
                                }}
                            />
                            <LoadingButton
                                loading={isLoading}
                                onClick={handleOccupation}
                                color="primary"
                                variant="contained"
                                autoFocus
                                sx={{
                                    padding: "6px 11px",
                                    minWidth: "70px",
                                    textTransform: "capitalize",
                                    borderRadius: "100px",
                                }}
                            >
                                <Typography sx={{ textTransform: "capitalize", fontWeight: 500 }}>Save</Typography>
                            </LoadingButton>
                        </Box>
                        <Box mt={2}>
                            {subCategories.map((subCategory, idx) => (
                                <SubCategoryInput
                                    key={idx}
                                    value={subCategory}
                                    onChange={(e) => handleSubCategoryChange(idx, e.target.value)}
                                    onRemove={() => handleRemoveSubCategory(idx)}
                                />
                            ))}
                            <Box mt={2} display="flex" sx={{justifyContent: {md: 'flex-end', sm: 'flex-end', xs: 'center'}}}>
                                <Button
                                    onClick={handleAddSubCategory}
                                    color="primary"
                                    variant="contained"
                                    autoFocus
                                    sx={{
                                        padding: "6px 11px",
                                        minWidth: "70px",
                                        textTransform: "capitalize",
                                        borderRadius: "100px",
                                    }}
                                >
                                    <Typography sx={{ textTransform: "capitalize", fontWeight: 500 }}>Add Sub-Category</Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default AddOrEditOccupationDialog;