import { createEntityAdapter, createSlice, PayloadAction, EntityId } from "@reduxjs/toolkit";

import { patientDataProps, patientSliceProps } from "../../models/patient.model";
import { PatientThunkApis } from "../actions/patient-actions";
import { RootState } from "../Store";
import { resetState } from "../actions/reset-actions";
import { OTHER_OCCUPATION, OTHER_REFERRAL_REASON } from "../../constants/variable-constants";

const patientAdapter = createEntityAdapter<patientDataProps>({
    selectId: (patient) => {
        // Ensure patientId is not null
        if (patient.patientId === null) {
            throw new Error('patientId cannot be null');
        }
        return patient.patientId as EntityId;
    },
    sortComparer: (a, b) => {
        if (a.patientId === null || b.patientId === null) {
            return 0;
        }
        return b.patientId - a.patientId;
    },
});

const initialState = patientAdapter.getInitialState<patientSliceProps>({
    patientStatus: null,
    currentPatientStatusId: [],
    pageListSize: 10,
    currentPage: 0,
    listLoading: false,
    totalPatients: 0,
    searchTerm: null,
    patientOccupations: null,
    referralReasons: null,
    newPatientId: null,
    patientDetails: null,
    patientDetailsLoading: false,
    deletePatientId: null,
    referredById: null,
    referralReasonId: [],
    referredOn: null,
    referredDate: null,
    highlightPatientId: null,
    selectedPatientIds: [],
    hospitalsList: [],
    selectedHospitalId: null
});

const PatientSlice = createSlice({
    name: 'patients',
    initialState,
    reducers: {
        patchState(state, action: PayloadAction<Partial<patientSliceProps>>) {
            return { ...state, ...action.payload };
        },
        setInitialState(state) {
            return {
                ...state,
                pageListSize: 10,
                currentPage: 0,
                totalPatients: 0,
                searchTerm: null,
                deletePatientId: null,
                referredToId: null,
                highlightPatientId: null
            }
        },
        removePatient: (state, action: PayloadAction<{ id: number }>) => {
            patientAdapter.removeOne(state, action.payload.id);
        },
        togglePatientSelection: (state, action: PayloadAction<number>) => {
            if (state.selectedPatientIds.includes(action.payload)) {
                state.selectedPatientIds = state.selectedPatientIds.filter(id => id !== action.payload);
            } else {
                state.selectedPatientIds.push(action.payload);
            }
        },
        toggleAllPatients: (state) => {
            if (state.selectedPatientIds.length === state.ids.length) {
                state.selectedPatientIds = [];
            } else {
                state.selectedPatientIds = Object.keys(state.entities).map(Number);
            }
        },
    },
    extraReducers: (builder) => {
        // To reset all the state variables
        builder.addCase(resetState, () => initialState);
        // getPatientStatus
        builder.addCase(PatientThunkApis.getPatientStatus.fulfilled, (state, action) => {
            state.patientStatus = action.payload.data;
        });
        // getPatientLists
        builder.addCase(PatientThunkApis.getPatientLists.pending, (state) => {
            state.listLoading = true;
        });
        builder.addCase(PatientThunkApis.getPatientLists.fulfilled, (state, action) => {
            state.listLoading = false;
            state.totalPatients = action.payload.total!;
            patientAdapter.setAll(state, action.payload.data || []);
        });
        builder.addCase(PatientThunkApis.getPatientLists.rejected, (state) => {
            state.listLoading = false;
        });
        // getPatientOccupations
        builder.addCase(PatientThunkApis.getPatientOccupations.fulfilled, (state, action) => {
            // Filter out the "Others" occupation
            const othersOccupation = action.payload.data.filter(occupation => occupation.OccupationName === OTHER_OCCUPATION.NAME);
            // Filter out all other occupations except "Others"
            const remainingOccupations = action.payload.data.filter(occupation => occupation.OccupationName !== OTHER_OCCUPATION.NAME);
            state.patientOccupations = [...remainingOccupations, ...othersOccupation];
        });
        // getReferralreasons
        builder.addCase(PatientThunkApis.getReferralreasons.fulfilled, (state, action) => {
            // Filter out the "Other" referral reason
            const otherReferralReasons = action.payload.data?.filter(reason => reason.ReferralReasonName === OTHER_REFERRAL_REASON);
            // Filter out all other referral reasons except "Other"
            const remainingReferralReasons = action.payload.data?.filter(reason => reason.ReferralReasonName !== OTHER_REFERRAL_REASON);
            state.referralReasons = [...remainingReferralReasons, ...otherReferralReasons];
        });
        // getPatientDetails
        builder.addCase(PatientThunkApis.getPatientDetails.pending, (state) => {
            state.patientDetailsLoading = true
        });
        builder.addCase(PatientThunkApis.getPatientDetails.fulfilled, (state, action) => {
            state.patientDetailsLoading = false
            state.patientDetails = action.payload.data;
        });
        builder.addCase(PatientThunkApis.getPatientDetails.rejected, (state) => {
            state.patientDetailsLoading = false
        });
        // getHospitals
        builder.addCase(PatientThunkApis.getHospitals.fulfilled, (state, action) => {
            state.hospitalsList = action.payload.data;
        });
    },
});

export const patientSelectors = patientAdapter.getSelectors<RootState>((state) => state.patients);

export const PatientSliceActions = PatientSlice.actions;
export default PatientSlice.reducer;