import React, { useEffect, useMemo, useState } from "react";
import {
    Box, Checkbox, CircularProgress, SelectChangeEvent, Table, TableBody, TableCell,
    TableHead, TableRow, Typography, IconButton
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import InfoIcon from '@mui/icons-material/Info';

import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import ComponentLoader from "../../components/ComponentLoader";
import AvantPaginationWithPageSize from "../../components/pagination/PaginationWithPageSize";
import { NotificationSliceActions, notificationUserSelectors } from "../../redux/reducers/notification-slice";
import NotificationsHeader from "./components/NotificationsHeader";
import { ADMIN_TAB_INFO_FOR_STATUS_UPDATES, CHECKED_USER, NOTIFICATION_VIEW_LIST, UNCHECKED_USER, USER_NOTIFICATIONS_SUCCESS, USER_ROLES, USER_ROLE_NAMES, VIEW_LIST, httpStatusCode } from "../../constants/variable-constants";
import api from "../../api";
import { ADD_USERS_NOTIFICATION } from "../../constants/api-constants";
import { getPracticeNameId, handleErrorMsg } from "../../components/utils";
import { ToastAlert } from "../../components/toast-alert";
import { PracticeThunkApis } from "../../redux/actions/practice-actions";
import { NotificationThunkApis } from "../../redux/actions/notification-actions";
import { doctorListsResponse } from "../../models/practice.model";
import { practiceSelectors } from "../../redux/reducers/practice-slice";
import { notificationTableHeaders } from "../../components/table-headers";
import { PatientThunkApis } from "../../redux/actions/patient-actions";
import UpdatePatientStatus from "./components/UpdatePatientStatus";
import AvantTabs from "../../components/tabs/Tabs";
import AvantTab from "../../components/tabs/Tab";
import { AvantTooltip } from "../../components/tool-tip/Tooltip";

import NotificationStyles from "./Notifications.module.css";

const Notifications = () => {
    const [isEnable, setEnable] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const { listLoading, totalUsers, pagesize, currentPage, searchTerm, selectedUsers, selectedPracticeId, viewList } = useAppSelector(state => state.notifications! || {});
    const { practiceNameId, roleId } = useAppSelector(state => state.auth.userDetails! || {});

    const usersData = useAppSelector(notificationUserSelectors.selectAll);
    const practicesData = useAppSelector(practiceSelectors.selectAll);

    const isSuperAdmin = roleId === USER_ROLES.SUPER_ADMIN;
    const isAdminView = viewList === VIEW_LIST.ADMIN;

    const selectedUsersLength = selectedUsers.filter(user => user.isSelected !== 0).length || 0;

    // Preserve users selected by SUPER_ADMIN
    const adminSelectedUsers = selectedUsers.filter(selected => {
        const user = usersData.find(user => user.UserId === selected.userId && user.RoleName === USER_ROLE_NAMES.ADMIN);
        return user && selected.isSelected === CHECKED_USER && selected.RoleId === USER_ROLES.SUPER_ADMIN;
    });

    // Filter users based on role
    const adminUserIds = usersData.filter(user => user.RoleName === USER_ROLE_NAMES.ADMIN).map(user => user.UserId);
    const nonAdminUsers = usersData.filter(user => user.RoleName !== USER_ROLE_NAMES.ADMIN);
    const nonAdminUserIds = nonAdminUsers?.map(user => user.UserId);

    const selectedUserIds = selectedUsers
        .filter(user => user.isSelected === CHECKED_USER)
        .map(user => user.userId);

    // Checks if all or some non-admin users are selected
    const allNonAdminUserIdsSelected = nonAdminUserIds.every(id => selectedUserIds.includes(id!));
    const someNonAdminUserIdsSelected = nonAdminUserIds.some(id => selectedUserIds.includes(id!));

    // Checks if some admin users are selected
    const someAdminUserIdsSelected = adminUserIds.some(id => selectedUserIds.includes(id!));

    // Combine admin selected users and non-admin users
    const combinedSelectedUsers = [...adminSelectedUsers, ...nonAdminUsers];

    const usersLength = !isSuperAdmin ? combinedSelectedUsers.length : usersData.length;

    const checkSomeUsersSelected = () => {
        if (isSuperAdmin) {
            return selectedUsersLength > 0 && selectedUsersLength < usersLength;
        }
        return someNonAdminUserIdsSelected;
    };

    const checkAllUsersSelected = () => {
        if (isSuperAdmin) {
            return usersLength > 0 && selectedUsersLength === usersLength;
        }
        return allNonAdminUserIdsSelected;
    };

    // Determine indeterminate, checked, and unchecked states
    const isIndeterminate = checkSomeUsersSelected();
    const isChecked = checkAllUsersSelected();

    useEffect(() => {
        dispatch(PracticeThunkApis.getAuthAllPractices({ roleId: roleId! }));
        dispatch(PatientThunkApis.getPatientStatus(""));
        if (roleId === USER_ROLES.USER_ADMIN) {
            dispatch(PracticeThunkApis.getAllAdminPractices(''));
        }
    }, [dispatch]);

    const filteredUsers = useMemo(() => {
        return usersData.filter(user => {
            // Filter by search term if provided
            const matchesSearchTerm = searchTerm
                ? user?.FullName?.toLowerCase()?.includes(searchTerm.toLowerCase())
                : true;

            // Apply role-based filtering
            const matchesRole = isSuperAdmin
                || (isAdminView ? user.RoleName === USER_ROLE_NAMES.ADMIN : user.RoleName !== USER_ROLE_NAMES.ADMIN);

            // Return true if both conditions are met
            return matchesSearchTerm && matchesRole;
        });
    }, [usersData, searchTerm, roleId, viewList]);

    const paginatedUsers = useMemo(() => {
        const startIndex = currentPage * pagesize;
        const endIndex = startIndex + pagesize;
        return filteredUsers.slice(startIndex, endIndex);
    }, [filteredUsers, currentPage, pagesize]);

    const resultPages = useMemo(() => Math.ceil(filteredUsers.length / pagesize), [filteredUsers, pagesize]);

    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
        const size = event.target.value as any;
        dispatch(NotificationSliceActions.patchState({ currentPage: 0, pagesize: size }));
    }

    const handlePageChange = (e: any, val: number) => {
        dispatch(NotificationSliceActions.patchState({ currentPage: val - 1 }));
    };

    const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            dispatch(NotificationSliceActions.selectAllUsers({ roleId: roleId! }));
        } else {
            dispatch(NotificationSliceActions.deselectAllUsers({ roleId: roleId! }));
        }
        setEnable(true);
    };

    const handleClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, user: doctorListsResponse) => {
        const isInsideAutocomplete = !!(event.target as HTMLElement).closest('.MuiAutocomplete-root');

        // Ensure that the click inside the Autocomplete (including 'Select All') does not trigger row selection
        if (!isInsideAutocomplete) {
            dispatch(NotificationSliceActions.toggleUserSelection({ user: user! }));
            setEnable(true);
        }
    };

    useEffect(() => {
        if (practiceNameId) {
            const initialId = getPracticeNameId(practiceNameId);
            dispatch(NotificationSliceActions.patchState({ selectedPracticeId: isSuperAdmin ? practicesData?.[0]?.PracticeNameId : initialId }));
        }
    }, [practiceNameId, practicesData]);

    const isSelected = (user: doctorListsResponse) => {
        return selectedUsers.some((selectedUser) => selectedUser.userId === user.UserId && selectedUser.isSelected === CHECKED_USER);
    };

    const isSelectedBySuperAdmin = (user: doctorListsResponse) => {
        return selectedUsers.some((selectedUser) => (
            selectedUser.userId === user.UserId &&
            user.RoleName === USER_ROLE_NAMES.ADMIN &&
            selectedUser.isSelected === CHECKED_USER &&
            selectedUser.RoleId === USER_ROLES.SUPER_ADMIN
        ));
    };

    const handleSort = (a: doctorListsResponse, b: doctorListsResponse) => {
        if (a.RoleName === USER_ROLE_NAMES.ADMIN && b.RoleName !== USER_ROLE_NAMES.ADMIN) {
            return -1;
        } else if (a.RoleName !== USER_ROLE_NAMES.ADMIN && b.RoleName === USER_ROLE_NAMES.ADMIN) {
            return 1;
        }
        return 0
    }

    const handleSendNotifications = async () => {
        const prepareUsersForNotification = () => {
            return selectedUsers.map(selectedUser => {
                const matchingUser = usersData.find(user => user.UserId === selectedUser.userId);
                return {
                    userId: selectedUser.userId,
                    isSelected: selectedUser.isSelected,
                    practiceId: selectedUser.practiceId || matchingUser?.PracticeId || null,
                    patientStatusIds: matchingUser?.PatientStatusIds || []
                };
            });
        };

        const users = prepareUsersForNotification();

        setLoading(true);
        try {
            const response = await api.fetchAuthPostWithBody<{ auth: boolean, message: string }>(ADD_USERS_NOTIFICATION, users);
            if (response?.status === httpStatusCode.SUCCESS) {
                ToastAlert(USER_NOTIFICATIONS_SUCCESS, 'success');
                setEnable(false);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (selectedPracticeId) {
            dispatch(NotificationThunkApis.getNotificationUserLists({ id: selectedPracticeId }));
            dispatch(NotificationThunkApis.getSelectedUsersNotificationList({ id: selectedPracticeId }));
        }
    }, [selectedPracticeId]);

    const getUserName = (userId: number) => {
        // Use filteredUsers for non-SUPER_ADMIN roles, otherwise use usersData
        const relevantData = isSuperAdmin ? usersData : filteredUsers;
        // Find the user by userId and return the FullName or an empty string if not found
        return relevantData.find(user => user.UserId === userId)?.FullName || "";
    };

    const handleDeleteChip = (userId: number) => {
        dispatch(NotificationSliceActions.toggleUserSelection({ id: userId! }));
        setEnable(true);
    };

    const handleTabChange = (event: React.SyntheticEvent, tabValue: string) => {
        dispatch(NotificationSliceActions.patchState({ viewList: tabValue, searchTerm: null }));
    }

    // Check if the user is either an admin viewing users or a super admin
    const canShowCheckbox =
        (!isSuperAdmin && !isAdminView) ||
        isSuperAdmin;

    // Determine whether to show the chips container based on the role and view
    const showChipsContainer = isSuperAdmin
        ? selectedUsersLength
        : isAdminView
            ? !!someAdminUserIdsSelected
            : !!someNonAdminUserIdsSelected;

    useEffect(() => {
        return () => {
            dispatch(NotificationSliceActions.setInitialState());
        }
    }, [dispatch]);

    return (
        <Box className={NotificationStyles.container}>
            <NotificationsHeader />
            {!!showChipsContainer ?
                <Box display="flex" flexWrap="wrap" gap={2} className={NotificationStyles.chip_panel}>
                    {selectedUsers?.filter(user => user.isSelected !== UNCHECKED_USER).map(user => (
                        !!getUserName(user.userId) ?
                            <Box position={'relative'} key={user.userId}>
                                <Box bgcolor={'#D7F2FF'} borderRadius={'5px'} p={'4px'}>
                                    <Typography fontSize={'14px'} color={'#686868'} component={'span'} fontWeight={500}>{getUserName(user.userId)}</Typography>
                                </Box>
                                <Box position={'absolute'} top={-13} right={-12}>
                                    <IconButton sx={{ p: 0 }} disabled={!isSuperAdmin && isAdminView} onClick={(e) => { e.stopPropagation(); handleDeleteChip(user.userId) }}>
                                        <CancelRoundedIcon sx={{ height: 15 }} />
                                    </IconButton>
                                </Box>
                            </Box> : null
                    ))}
                </Box> : null}
            {!isSuperAdmin ?
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { md: 'space-between', xs: 'center' }, flexDirection: { md: 'row', xs: 'column' }, gap: { md: '0px', xs: '15px' }, margin: { md: '15px', sm: '15px', xs: '5px 5px 15px 5px' } }}>
                    <AvantTabs
                        value={viewList}
                        onChange={handleTabChange}
                        textColor="primary"
                        indicatorColor="primary"
                        aria-label="primary tabs example"
                    >
                        {NOTIFICATION_VIEW_LIST?.map((screen: string, index: number) => (
                            <AvantTab
                                key={index}
                                value={screen}
                                label={<Box className="flexCenterCenter" gap={"5px"}>
                                    <Typography sx={{ fontSize: "17px", fontWeight: "500" }}>{screen}</Typography>
                                    {screen === VIEW_LIST.ADMIN ?
                                        <AvantTooltip title={ADMIN_TAB_INFO_FOR_STATUS_UPDATES}>
                                            <InfoIcon sx={{ color: !isAdminView ? "#6fc1e8" : "#ffffff" }} />
                                        </AvantTooltip> : null}
                                </Box>}
                            />
                        ))}
                    </AvantTabs>
                </Box> : null}
            {!paginatedUsers?.length && listLoading ?
                <ComponentLoader /> :
                <Box display="flex" flexDirection="column">
                    <Box className={NotificationStyles.notificationTableBox}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        {canShowCheckbox ?
                                            <Checkbox
                                                indeterminate={isIndeterminate && !isChecked} // Indeterminate if some but not all are selected
                                                checked={isChecked} // Fully checked if all are selected
                                                onChange={handleSelectAllChange}
                                            /> : null}
                                    </TableCell>
                                    {notificationTableHeaders?.map((label, index) => (
                                        <TableCell key={index}>
                                            <Typography fontWeight={500} color={'primary'}>{label}</Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedUsers.length ? (
                                    paginatedUsers.sort(handleSort).map((user, index) => {
                                        const isItemSelected = isSelected(user);
                                        const isSuperAdminChecked = isSelectedBySuperAdmin(user);
                                        const isItemDisabled = !isSuperAdmin ? user.RoleName === USER_ROLE_NAMES.ADMIN || !!isSuperAdminChecked : false;

                                        return (
                                            <TableRow
                                                key={user.UserId}
                                                hover
                                                onClick={(event) => !isItemDisabled && handleClick(event, user)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        disabled={isItemDisabled}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className={NotificationStyles.label}>{user.FullName || "-"}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className={NotificationStyles.label}>{user.EmailAddress || "-"}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className={NotificationStyles.label}>{user.OccupationName || "-"}</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <ul>
                                                        {user.SubcategoryName?.length ? (
                                                            user.SubcategoryName.map((subcategory, idx) => (
                                                                <li key={idx}>
                                                                    <Typography>{subcategory}</Typography>
                                                                </li>
                                                            ))
                                                        ) : (
                                                            <Typography>-</Typography>
                                                        )}
                                                    </ul>
                                                </TableCell>
                                                <TableCell>
                                                    <UpdatePatientStatus isItemDisabled={isItemDisabled} isItemSelected={isItemSelected} user={user} setEnable={setEnable} />
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className={NotificationStyles.label}>{user.RoleName || "-"}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={7}>
                                            <Typography variant="h6" align="center">
                                                No Users Found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Box>
                    <Box sx={{ display: 'grid', gridAutoFlow: { xs: 'row', md: 'column' }, alignItems: 'center', justifyContent: { md: 'space-between', sm: 'center', xs: 'center' }, marginY: 3, gap: { md: '10px', xs: '15px' }, }}>
                        {!isAdminView ?
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                disabled={isLoading || !isEnable}
                                loading={isLoading}
                                onClick={handleSendNotifications}
                                sx={{
                                    padding: "6px 11px",
                                    minWidth: "120px",
                                    textTransform: "capitalize",
                                    borderRadius: "100px",
                                }}
                            >
                                <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Save</Typography>
                            </LoadingButton> : <Box />}
                        <>
                            {listLoading ? <Box mr={2} className="flexCenterCenter">
                                <CircularProgress size={25} />
                            </Box> : null}
                            <AvantPaginationWithPageSize
                                {...{
                                    pageSize: pagesize!,
                                    handlePageSizeChange,
                                    page: currentPage!,
                                    totalPages: resultPages
                                }}
                                disabled={listLoading || !totalUsers}
                                handlePageChange={handlePageChange}
                            />
                        </>
                    </Box>
                </Box>}
        </Box>
    );
}

export default Notifications;