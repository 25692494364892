import React, { useState } from "react";
import { Badge, Box, Button, CircularProgress, FormControl, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import TuneIcon from '@mui/icons-material/Tune';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import * as FileSaver from "file-saver";
import * as XLSX from 'xlsx';

import { useAppDispatch, useAppSelector } from "../../../redux/Hooks";
import { PatientSliceActions, patientSelectors } from "../../../redux/reducers/patient-slice";
import { useDebounce } from "../../../components/custom-hook/useDebounce";
import FilterDialog from "./FilterDialog";
import { useFilterHasValue } from "../../../components/custom-selectors/patientSelectors";
import { ALL_PATIENT_STATUS, DOCTOR_ROLE, DOCTOR_STAFF_ROLE, DOWNLOAD_FAILED, EXPORT_COLUMN, FILE_TYPE, USER_ROLES, httpStatusCode } from "../../../constants/variable-constants";
import { patientStatusResponse } from "../../../models/patient.model";
import api from "../../../api";
import { EXPORT_PATIENT_DETAILS } from "../../../constants/api-constants";
import { ToastAlert } from "../../../components/toast-alert";
import { combinedReasonForReferral } from "../../../components/utils";

import PatientListsStyles from "../PatientLists.module.css";

dayjs.extend(utc);

const PatientListsHeader = () => {
    const { statusId } = useParams<{ statusId: string }>();

    const [isLoading, setLoading] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { listLoading, patientStatus, currentPatientStatusId, referralReasons, referredOn, referredById, referralReasonId, selectedPatientIds } = useAppSelector(state => state.patients! || {});
    const { roleId, userId } = useAppSelector(state => state.auth.userDetails! || {});

    const patientsData = useAppSelector(patientSelectors.selectAll);

    const filterHasValue = useFilterHasValue();

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [openFilter, setOpenFilter] = useState<boolean>(false);

    const getReferralreasonName = (referralReasonIdArray: string[]) =>
        referralReasonIdArray
            .map(id => referralReasons?.find(reason => reason.ReferralReasonId === +id)?.ReferralReasonName)
            .filter(Boolean);  // Filter out any null or undefined values

    const handleDebouncedSearch = useDebounce((term: string) => {
        dispatch(PatientSliceActions.patchState({ searchTerm: term }));
    }, 2000);

    const handleSearchTerm = (term: string) => {
        setSearchTerm(term);
        dispatch(PatientSliceActions.patchState({ currentPage: 0, pageListSize: 10 }));
        handleDebouncedSearch(term);
    };

    const handleChange = (event: SelectChangeEvent<number>) => {
        const statusId = event.target.value as number;
        if (statusId === ALL_PATIENT_STATUS) {
            dispatch(PatientSliceActions.patchState({ currentPatientStatusId: [] }));
        } else {
            dispatch(PatientSliceActions.patchState({ currentPatientStatusId: [statusId] }));
        }
    };

    const clearSearchTerm = () => {
        setSearchTerm("");
        dispatch(PatientSliceActions.patchState({ searchTerm: null }));
    }

    const handleNavigate = () => {
        navigate('/home/patient_lists/create');
    }

    const handleClose = () => {
        setOpenFilter(false);
    }

    const handlePrintData = () => {
        const headers = `
            <tr>
                <th><strong>Name</strong></th>
                <th><strong>Age</strong></th>
                <th><strong>Doctor</strong></th>
                <th><strong>Referral Date</strong></th>
                <th><strong>Reason for Referral</strong></th>
                <th><strong>Email</strong></th>
                <th><strong>Phone</strong></th>
            </tr>
        `;

        const patientRows = patientsData
            ?.filter(patient => selectedPatientIds.includes(patient.patientId))
            ?.map(patient => `
                <tr>
                    <td>${patient.firstName} ${patient.lastName}</td>
                    <td>${patient.age}</td>
                    <td>${patient.referredBy}</td>
                    <td>${dayjs.utc(patient.referredOn).format('YYYY-MM-DD') || "-"}</td>
                    <td>${getReferralreasonName(patient.referralReasonIds!)}</td>
                    <td>${patient.emailAddress}</td>
                    <td>${patient.phoneNumber}</td>
                </tr>
            `).join('');

        const printContent = `
            <table class="patient-table">
                <colgroup>
                    <col style="width: 12%;" />
                    <col style="width: 5%;" />
                    <col style="width: 14%;" />
                    <col style="width: 12%;" />
                    <col style="width: 20%;" />
                    <col style="width: 22%;" />
                    <col style="width: 15%;" />
                </colgroup>
                ${headers}
                ${patientRows}
            </table>
        `;

        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.width = '0';
        iframe.style.height = '0';
        iframe.style.border = 'none';
        document.body.appendChild(iframe);

        const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
        if (iframeDoc) {
            iframeDoc.open();
            iframeDoc.write(`
                <html>
                <head>
                    <style>
                        @page {
                            margin: 0;
                            size: landscape; /* Set layout to landscape */
                        }
                        body {
                            font-family: Arial, sans-serif;
                            margin: 0;
                            padding: 20px;
                            -webkit-print-color-adjust: exact !important; /* Ensures background colors are printed */
                            -moz-print-color-adjust: exact !important;
                            -ms-print-color-adjust: exact !important;
                            print-color-adjust: exact !important;
                        }
                        .container {
                            background: rgba(255, 255, 255, 0.8);
                            border-radius: 10px;
                            padding: 20px;
                        }
                        .header {
                            padding: 20px;
                            margin-bottom: 10px;
                            background-color: #6fc1e8 !important; /* Force background color for print */
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }
                        .header img {
                            height: 50px;
                        }
                        .patient-table {
                            width: 100%;
                            border-collapse: collapse;
                            margin-bottom: 20px;
                            border: 1px solid #6fc1e8;
                        }
                        .patient-table th, .patient-table td {
                            padding: 8px;
                            border: 1px solid #6fc1e8;
                            background-color: #f9f9f9;
                            page-break-inside: avoid; /* Prevents rows from breaking across pages */
                        }
                        .patient-table th {
                            background-color: #e0f7fa;
                        }
                        .title {
                            font-size: 24px;
                            font-weight: bold;
                            margin-bottom: 20px;
                        }
                        @media print {
                            body {
                                margin: 0;
                                padding: 0;
                                -webkit-print-color-adjust: exact !important;
                                -moz-print-color-adjust: exact !important;
                                -ms-print-color-adjust: exact !important;
                                print-color-adjust: exact !important;
                            }
                            .container {
                                margin: 0;
                            }
                            .header {
                                background-color: #6fc1e8 !important;
                            }
                        }
                    </style>
                </head>
                <body>
                   <div class="header">
                        <img src="images/logo.svg" alt="Avant_logo-1" />
                        <img src="images/logo2.png" alt="Avant_logo-2" />
                    </div>
                    <div class="container">
                        <div class="title">Patient Details</div>
                        ${printContent}
                    </div>
                    <script>
                        window.onload = function() {
                            window.print();
                        };
                        window.onafterprint = function() {
                            document.body.removeChild(window.frameElement);
                        };
                    </script>
                </body>
                </html>
            `);
            iframeDoc.close();
        }
    };

    const handleExportData = async () => {
        const filterObject = {
            firstName: searchTerm || "",
            lastName: searchTerm || "",
            ...(referredById || roleId === DOCTOR_ROLE ? { referredById: referredById ? referredById : userId } : {}),
            ...(currentPatientStatusId?.length || statusId ? { currentPatientStatusId: currentPatientStatusId?.length ? currentPatientStatusId : [+statusId!] } : {}),
            ...(referralReasonId?.length ? { referralReasonId } : {}),
            ...(referredOn ? { referredOn } : {}),
            ...(selectedPatientIds?.length ? { patientId: selectedPatientIds } : {})
        };

        setLoading(true);
        try {
            const response = await api.fetchAuthPostWithBody<any>(EXPORT_PATIENT_DETAILS, filterObject);
            if (response?.status === httpStatusCode.SUCCESS) {
                let data = response?.data;

                // Ensure data is an array of objects
                if (typeof data === 'string') {
                    // Convert CSV string to array of objects if necessary
                    data = data.split('\n').slice(1).map(row => {
                        const values = row.split(',');
                        const convertedValues = combinedReasonForReferral(values!);

                        return {
                            Name: convertedValues[0],
                            Age: convertedValues[1],
                            Doctor: convertedValues[2],
                            [EXPORT_COLUMN.REASON_FOR_REFERRAL]: convertedValues[3],
                            [EXPORT_COLUMN.REFERRAL_DATE]: convertedValues[4],
                            Email: convertedValues[5],
                            [EXPORT_COLUMN.PHONE_NUMBER]: convertedValues[6]
                        };
                    });
                }

                // Remove the last object from the array - handle the undefined
                data.pop();

                // Creating a new workbook and a sheet
                const ws = XLSX.utils.json_to_sheet(data);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "Patients");

                // Set column widths
                const wscols = [
                    { wch: 20 }, // Name
                    { wch: 10 }, // Age
                    { wch: 20 }, // Doctor
                    { wch: 30 }, // Reason for Referral
                    { wch: 15 }, // Referral Date
                    { wch: 30 }, // Email
                    { wch: 15 }  // Phone Number
                ];
                ws['!cols'] = wscols;

                // Exporting the data to xlsx
                const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([wbout], { type: FILE_TYPE.XLSX });

                // Downloading the XLSX file using FileSaver
                FileSaver.saveAs(blob, `${encodeURIComponent("patient_details")}.xlsx`);

                setLoading(false);
            } else {
                ToastAlert(DOWNLOAD_FAILED, 'error');
                setLoading(false);
            }
        } catch (error) {
            console.error('Failed to download the patient details', error);
            setLoading(false);
        }
    }

    return (
        <>
            <Box className={PatientListsStyles.search_panal}>
                <TextField
                    value={searchTerm}
                    className="searchInput"
                    onChange={(e) => handleSearchTerm(e.target.value)}
                    sx={{ minWidth: '200px' }}
                    variant="standard"
                    placeholder='Search Patients'
                    size='small'
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon />
                            </InputAdornment>),
                        endAdornment: (
                            <InputAdornment position="end">
                                <CloseIcon sx={{ display: searchTerm ? "inline-block" : "none", fontSize: "22px" }} className={PatientListsStyles.closeIcon} onClick={clearSearchTerm} />
                            </InputAdornment>
                        )
                    }}
                />
                <Box display={'flex'} gap={2} className="patientAction">
                    {[USER_ROLES.USER_ADMIN, USER_ROLES.SUPER_ADMIN, DOCTOR_STAFF_ROLE].includes(roleId!) ?
                        <Box display={'flex'} gap={2} className="buttonBox">
                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={isLoading ? <CircularProgress size={10} /> : <DownloadIcon />}
                                disabled={!selectedPatientIds?.length}
                                onClick={handleExportData}
                                sx={{
                                    padding: "6px 11px",
                                    minWidth: "120px",
                                    textTransform: "capitalize",
                                    borderRadius: "100px"
                                }}
                            >
                                <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Export</Typography>
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<PrintOutlinedIcon />}
                                disabled={!selectedPatientIds?.length}
                                onClick={handlePrintData}
                                sx={{
                                    padding: "6px 11px",
                                    minWidth: "120px",
                                    textTransform: "capitalize",
                                    borderRadius: "100px"
                                }}
                            >
                                <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Print</Typography>
                            </Button>
                            <Badge color="primary" sx={{ "& .MuiBadge-badge": { minWidth: "14px", height: "14px" } }} badgeContent={filterHasValue ? " " : undefined}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<TuneIcon color={listLoading ? 'inherit' : 'primary'} />}
                                    onClick={() => setOpenFilter(true)}
                                    disabled={listLoading}
                                    sx={{
                                        padding: "6px 11px",
                                        minWidth: "120px",
                                        textTransform: "capitalize",
                                        borderRadius: "100px"
                                    }}
                                >
                                    <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Filter</Typography>
                                </Button>
                            </Badge>
                        </Box>
                        :
                        <FormControl sx={{ minWidth: "250px" }}>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={currentPatientStatusId?.[0] || 0}
                                label="Status"
                                onChange={handleChange}
                                sx={{
                                    borderRadius: '25px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderRadius: '25px'
                                    }
                                    , '& .MuiSelect-select': {
                                        padding: '7px 14px'
                                    }
                                }}
                            >
                                <MenuItem value={0}>All</MenuItem>
                                {patientStatus?.length ? patientStatus.map((status: patientStatusResponse) => (
                                    <MenuItem key={status.PatientStatusId} value={status.PatientStatusId!}>{status.PatientStatusName}</MenuItem>
                                )) : null}
                            </Select>
                        </FormControl>}
                    <Button
                        variant="contained"
                        color="primary"
                        className="addBtn"
                        startIcon={<AddIcon />}
                        onClick={handleNavigate}
                        sx={{
                            padding: "6px 11px",
                            minWidth: "120px",
                            textTransform: "capitalize",
                            borderRadius: "100px"
                        }}
                    >
                        <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}> Add Patient</Typography>
                    </Button>
                </Box>
            </Box>
            {openFilter ? <FilterDialog closeFilter={handleClose} /> : null}
        </>
    );
}

export default PatientListsHeader