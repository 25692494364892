import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import AvantIndex from "../HomeIndex";
import api from "../../api";
import { RESET_PASSWORD } from "../../constants/api-constants";
import { httpStatusCode } from "../../constants/variable-constants";
import { useAppDispatch } from "../../redux/Hooks";
import { AuthSliceActions } from "../../redux/reducers/login-slice";
import { handleErrorMsg } from "../../components/utils";

const ResetPassword = () => {
    const [isLoading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const validationSchema = Yup.object({
        userName: Yup.string().email("Invalid email address").required("Email is required")
    });

    const formik = useFormik({
        initialValues: { userName: "" },
        validationSchema,
        onSubmit: async (values: { userName: string }) => {
            setLoading(true);
            try {
                const resetRes = await api.fetchPostWithBody<{ message: string }>(RESET_PASSWORD, values);
                if (resetRes?.status === httpStatusCode?.SUCCESS) {
                    dispatch(AuthSliceActions.patchState({ successiveMsg: resetRes?.data?.message }));
                    navigate(-1);
                }
            } catch (error: any) {
                handleErrorMsg(error);
            } finally {
                setLoading(false);
            }
        }
    });

    return (
        <AvantIndex>
            <>
                <Typography sx={{ fontSize: "2rem", fontWeight: "500" }}>Forgot Password</Typography>
                <Typography sx={{ fontSize: "14px", textAlign: "center" }} my={3}>The new password will be send to the entered e-mail address.</Typography>
                <form onSubmit={formik.handleSubmit} className="forgotForm">
                    <Box className="flexCenterCenter f-col">
                        <TextField
                            required
                            id="userName"
                            name="userName"
                            variant="outlined"
                            size="small"
                            placeholder="Your e-mail address"
                            sx={{
                                '& .MuiInputBase-root': {
                                    borderRadius: '100px'
                                }
                            }}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.userName}
                            error={formik.touched.userName && Boolean(formik.errors.userName)}
                            helperText={formik.touched.userName && formik.errors.userName}
                        />
                        <Box my={5} className="flexCenterCenter">
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate(-1)}
                                sx={{
                                    padding: "6px 11px",
                                    minWidth: "120px",
                                    textTransform: "capitalize",
                                    borderRadius: "100px"
                                }}
                            >
                                <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Cancel</Typography>
                            </Button>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                loading={isLoading}
                                type="submit"
                                sx={{
                                    padding: "6px 11px",
                                    minWidth: "130px",
                                    textTransform: "capitalize",
                                    borderRadius: "100px",
                                    marginLeft: "10px"
                                }}
                            >
                                <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Reset Password</Typography>
                            </LoadingButton>
                        </Box>
                    </Box>
                </form>
            </>
        </AvantIndex>
    );
}

export default ResetPassword