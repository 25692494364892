import React from "react";
import { Box, Checkbox, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../redux/Hooks";
import { CHECKBOX_SELECT, DOCTOR_STAFF_ROLE, PATIENT_COLUMNS, USER_ROLES } from "../../../constants/variable-constants";
import { PatientSliceActions, patientSelectors } from "../../../redux/reducers/patient-slice";

import patientListStyles from "../PatientLists.module.css";

const PatientTableHeader = ({ label, roleId }: { label: string, roleId: number | null }) => {
    const dispatch = useAppDispatch();
    const { selectedPatientIds } = useAppSelector(state => state.patients! || {});

    const patientsData = useAppSelector(patientSelectors.selectAll);

    const shouldHideHeader = (label: string, roleId: number | null) => {
        if ([USER_ROLES.USER_ADMIN, USER_ROLES.SUPER_ADMIN].includes(roleId!)) return false; // Show all columns for admin and super admin
        if (roleId === DOCTOR_STAFF_ROLE) {
            // Show all columns except PRACTICE but always show REFERRED_BY
            return label === PATIENT_COLUMNS.PRACTICE;
        }
        // Default behavior
        return [PATIENT_COLUMNS.PRACTICE, PATIENT_COLUMNS.REFERRED_BY, CHECKBOX_SELECT, PATIENT_COLUMNS.HOSPITAL].includes(label) && ![USER_ROLES.USER_ADMIN, USER_ROLES.SUPER_ADMIN].includes(roleId!);
    };

    const handleToggleAll = () => {
        dispatch(PatientSliceActions.toggleAllPatients());
    }

    if (shouldHideHeader(label, roleId!)) return null;

    return (
        <Box className={patientListStyles.patientTableHeader}>
            {label === CHECKBOX_SELECT ? (
                <Checkbox
                    checked={selectedPatientIds.length > 0}
                    indeterminate={selectedPatientIds.length > 0 && selectedPatientIds.length < patientsData.length}
                    onChange={handleToggleAll}
                />
            ) : (
                <Typography mr={1} fontWeight={500} color="primary">
                    {label}
                </Typography>
            )}
        </Box>
    );
};

export default PatientTableHeader;