export const apiConstants = {
    API_URL: 'https://uat.avantlasikspa.com/api',
    WEB_SOCK_URL: 'wss://uat.avantlasikspa.com:1337',
    //API_URL: 'http://localhost:3002/api',
    //WEB_SOCK_URL: 'ws://localhost:1337',
    GOOGLE_CAPTCHA_SITE_KEY: '6LcYOr8UAAAAADkXPy4YzwbwfeQRcrAAlJMxzXAE',
    UI_IDLE_TIMEOUT: '60' //in minutes
};

export const API_URL = 'https://uat.avantlasikspa.com/api';
// export const API_URL = 'http://localhost:3002/api';

// Login Page API 
export const LOGIN_API = "/auth/login"; //POST

// Sign up 
export const SIGNUP_USER = "/users/signup"; //POST

// Reset password
export const RESET_PASSWORD = "/auth/password/forgot"; //POST

// Logout
export const LOGOUT = (userId: number | string) => `/users/logout?userid=${userId}`; //PUT

// Getting the patient status
export const PATIENT_STATUS = "/lookup/patientstatus/all"; //GET
//Getting the occupations
export const OCCUPATIONS_LIST = "/lookup/occupations/all"; //GET
// Getting the referral reasons 
export const REFERRAL_REASONS = "/lookup/referralreason/all"; //GET
// Getting all the practices - without authentication
export const PRACTICES_LIST = "/lookup/practices/all"; //GET
// Getting all the practices - authentication
export const AUTH_PRACTICES_LIST = "/staff/practices/all"; //GET
// Getting the patients list
export const PATIENT_LISTS = "/patients/filter"; //POST
// Getting user details
export const USER_DETAILS = "/users/id/"; //GET
// To change a password
export const PASSWORD_CHANGE = "/auth/password/change"; //POST
// Add a new patient
export const ADD_PATIENT = "/patients/add";//POST
// To check whether the patient is new or not
export const CHECK_PATIENT = (email: string) => `/patients/check/exists?emailAddress=${email}`; //GET
// Add a image to the patient
export const UPLOAD_IMAGE = (patientId: number) => `/patients/images/add?patientid=${patientId}`; //POST
// Get a patient details
export const PATIENT_DETAILS = (patientId: number) => `/patients/${patientId}`; //GET
// Get a image 
export const GET_IMAGE = (patientId: number, fileName: string) => `/patients/images/get/b64?patientid=${patientId}&filename=${fileName}`; //GET
// To delete a image
export const DELETE_IMAGE = "/patients/images/delete"; //DELETE

// Dashboard Status
export const DASHBOARD_STATUS = "/dashboard/patient/status/summary"; //GET
// Delete a patient
export const DELETE_PATIENT = (patientId: number) => `/patients/${patientId}`; //DELETE

// Get all the users
export const GET_ALL_USERS = "/users/search"; //GET
// Get all the users for super admin
export const GET_USERS_SUPER_ADMIN = "/users/search/superadmin"; //GET
// Add new practice
export const ADD_PRACTICE = "/practice/add/new"; //POST
// Update practice
export const UPDATE_PRACTICE = "/practice/update"; //POST
// Delete practice
export const DELETE_PRACTICE = "/practice/delete"; //POST

// Add a new user
export const ADD_USER = "/users/add"; //POST
// Get user details or delete user
export const GET_USER_DETAILS = (userId: string | number) => `/users/id/${userId}`; //GET or DELETE or PUT
// User password reset
export const USER_PASSWORD_RESET = "/users/password/reset"; //POST
// Enable or disable user
export const USER_STATUS = (status: string, userId: string | number) => `/users/${status}/${userId}`; //POST
// Approve a user
export const APPROVE_USER = (userId: number) => `/users/approve/id/${userId}`; //POST
// Reject a user
export const REJECT_USER = (userId: number) => `/users/reject/id/${userId}`; //POST
// Get doctor details through practice
export const DOCTOR_LISTS_THROUGH_PRACTICE = (id: string) => `/lookup/practice/getdoctordetails/${id}`; //GET
// Get a doctor lists that can be associated with the particular practice
export const DOCTOR_LISTS_ASSOCIATED_PRACTICE = "/staff/get"; //GET
// Getting all the approved users
export const ALL_APPROVED_USERS = "/users/search/all"; //GET
// Add a user to the notifications setting
export const ADD_USERS_NOTIFICATION = "/notification/add"; //POST
// Getting a selected users for notifications setting
export const SELECTED_USERS_NOTIFICATION = (practiceId: number) => `/notification/get/${practiceId}`; //GET
// Get all the doctors
export const DOCTORS_LIST = "/staff/getAllDoctors"; //GET
// Get all the doctor staffs
export const DOCTOR_STAFFS_LIST = "/staff/getAllDoctorStaff"; //GET
// Get all Admin, Doctor and Doctor Staff based on Practice ID
export const GET_USERS_THROUGH_PRACTICE = (practiceId: number) => `/staff/get/doctorStaffDetails/${practiceId}`; //GET
// Get all the practices - doctor login
export const DOCTOR_PRACTICES = "/staff/getAllDoctorsPractices"; //GET
// Get all the practices - doctor staff login
export const DOCTOR_STAFF_PRACTICES = "/staff/getAllDoctorStaffPractice"; //GET
// Get all the practices - admin login
export const ADMIN_PRACTICES = "/staff/practices/getUserPracticesForAdmin"; //GET
// Get DoctorStaff without having practice
export const DOCTOR_STAFF_WITHOUT_PRACTICE = "/staff/getDoctorStaffWithoutPractice"; //GET
// Download patients data
export const EXPORT_PATIENT_DETAILS = "/export/patientData"; //POST
// Getting the practices for admin
export const ADMIN_ASSOCIATED_PRACTICES = "/practice/get/admin"; //GET
// Get occupation along with its sub category
export const OCCUPATION_WITH_SUB_CATEGORY = "/lookup/occupations/subcategory"; //GET
// GET all subcategories
export const ALL_SUB_CATEGORIES = "/lookup/subcategories/all"; //GET
// Add a new occupation
export const ADD_OCCUPATION = "/occupation/add"; //POST
// Save occupation along with its sub category
export const ADD_SUB_CATEGORY = "/occupation/add/subcategories"; //POST
// Update occupation along with its sub category
export const UPDATE_OCCUPATION = "/occupation/update"; //PUT
// Delete occupation along with it's sub-categories
export const DELETE_OCCUPATION = "/occupation/delete"; //DELETE
// Subcategories by occupation
export const SUBCATEGORIES_BY_OCCUPATION = "/lookup/subcategoriesbyoccupation"; //GET
// Get users after filter
export const FILTER_BY_USERS = "/filter/doctor"; //GET
// Send mail after applying user list filters
export const SEND_MAIL_BY_FILTER = "/filter/send-email"; //POST
// Get hospitals
export const ALL_HOSPITALS = "/staff/getHospitals"; //GET
// Get all the mails
export const ALL_MAILS = (status: string) => `/filter/get-email?status=${status}`; //GET
// Delete a mail
export const DELETE_MAIL = (id: number) => `/filter/email/${id}/delete`; //DELETE
// Get email details by Id
export const EMAIL_DETAILS_BY_ID = (id: number) => `/filter/email/${id}`; //GET
// Turn off/on all the notifications
export const ALL_NOTIFICATION_SETTINGS = "/notification/save"; //POST
// Get a details for off/on notifications
export const GET_ALL_NOTIFICATION_SETTINGS = "/notification/get"; //GET
// Update all the custom notifications
export const SAVE_ALL_CUSTOM_NOTIFICATIONS = "/notification/save-all-settings"; //POST
// Get a custom notification settings by practice_id
export const GET_SETTINGS_BY_PRACTICEID = (practiceId: number) => `/notification/settings/${practiceId}`; //GET
// Get all the referral reasons
export const ALL_REFERRAL_REASONS = "/referralReason/get/referralReason"; //GET
// Add a new reason for referral
export const ADD_REFERRAL_REASON = "/referralReason/save"; //POST
// Update a reason for referral
export const UPDATE_REFERRAL_REASON = "/referralReason/update"; //PUT