import React, { useState } from "react";
import {
    Box,
    Button,
    Dialog, DialogContent,
    DialogTitle, IconButton, Typography
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';

import { useAppSelector } from "../../../redux/Hooks";
import { patientImageProps } from "../../../models/patient.model";
import api from "../../../api";
import { GET_IMAGE } from "../../../constants/api-constants";
import { httpStatusCode } from "../../../constants/variable-constants";
import { handleErrorMsg } from "../../../components/utils";

import UpdatePatientStyles from "../UpdatePatient.module.css";

interface MultiImagePrintProps {
    openMultiImageModal: boolean,
    setOpenMultiImageModal: (value: boolean) => void
}

interface SelectedImageProps {
    key: number;
    value: string;
}

const MultipleImagePrint = ({ openMultiImageModal, setOpenMultiImageModal }: MultiImagePrintProps) => {
    const [selectedImages, setSelectedImages] = useState<SelectedImageProps[]>([]);
    const { patientDetails } = useAppSelector(state => state.patients || {});

    const handleClose = () => {
        setOpenMultiImageModal(false);
        setSelectedImages([]);
    };

    const handlePrintMultiImages = () => {
        const imgSrc = selectedImages?.map(image => `
            <div align='center'>
                <img style='width:auto;height:calc(100vh - 16px)' alt='Loading...' src='${image.value}' />
            </div>
        `).join('');

        // Create an iframe for printing
        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.width = '0';
        iframe.style.height = '0';
        iframe.style.border = 'none';
        document.body.appendChild(iframe);

        const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
        if (iframeDoc) {
            iframeDoc.open();
            iframeDoc.write(`
                <html>
                <head>
                    <title>Avant</title>
                    <style>
                        @page {
                            margin: 0; /* Remove default page margins */
                        }
                        body {
                            margin: 0;
                            padding: 0;
                        }
                        img {
                            width: auto;
                            height: calc(100vh - 16px);
                        }
                        @media print {
                            body {
                                margin: 0;
                                padding: 0;
                            }
                        }
                    </style>
                </head>
                <body>
                    ${imgSrc}
                    <script>
                        window.onload = function() {
                            window.print();
                        };
                        window.onafterprint = function() {
                            document.body.removeChild(window.frameElement);
                        };
                    </script>
                </body>
                </html>
            `);
            iframeDoc.close();
        }
    };

    const handleSelectImage = async (image: patientImageProps, index: number) => {
        const isSelected = selectedImages.some(selectedImage => selectedImage.key === index);
        if (isSelected) {
            setSelectedImages(prev => prev.filter(img => img.key !== index));
        } else {
            try {
                const response = await api.fetchAuthImageGet(GET_IMAGE(patientDetails?.patientId!, image?.relativePath!));
                if (response.status === httpStatusCode.SUCCESS || response.status === httpStatusCode.NOT_MODIFIED) {
                    const responseImg = `data:image;base64,${response.data}`;
                    setSelectedImages(prev => [...prev, { key: index, value: responseImg }]);
                }
            } catch (error: any) {
                handleErrorMsg(error);
            }
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={openMultiImageModal}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <Typography className="flexCenterStart" sx={{ color: '#818080', fontSize: '1.5rem' }}>
                    Select Images
                </Typography>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CancelIcon color="primary" />
            </IconButton>
            <DialogContent>
                <Box my={2}>
                    {patientDetails?.imageDetails?.length ? (
                        <Box className="flexWrap" gap={5}>
                            {patientDetails.imageDetails.map((image: patientImageProps, index: number) => (
                                <Box
                                    key={index}
                                    className={UpdatePatientStyles.imageItem}
                                    onClick={() => handleSelectImage(image, index)}
                                >
                                    <img src={image.imageThumbnail!} className={UpdatePatientStyles.fullImage} />
                                    {selectedImages.some(selectedImage => selectedImage.key === index) && (
                                        <img src="images/tick.svg" alt="Selected" className={UpdatePatientStyles.selectImage} />
                                    )}
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <Typography className="flexCenterCenter" sx={{ fontSize: "16px", fontWeight: "500" }}>
                            No images found
                        </Typography>
                    )}
                </Box>
                <Box my={2} className="flexCenterCenter">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePrintMultiImages}
                        disabled={!selectedImages?.length}
                        sx={{
                            padding: "6px 11px",
                            minWidth: "120px",
                            textTransform: "capitalize"
                        }}
                    >
                        <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Print</Typography>
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default MultipleImagePrint;