import React, { useState } from "react";
import { Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../../redux/Hooks";
import { PracticeSliceActions } from "../../../redux/reducers/practice-slice";
import { useDebounce } from "../../../components/custom-hook/useDebounce";

import PracticeListsStyles from "../PracticeLists.module.css";

const PracticeListHeader = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = useState<string>("");

    const handleDebouncedSearch = useDebounce((term: string) => {
        dispatch(PracticeSliceActions.patchState({ searchTerm: term }));
    }, 2000);

    const handleSearchTerm = (term: string) => {
        setSearchTerm(term);
        dispatch(PracticeSliceActions.patchState({ currentPage: 0, pagesize: 10 }));
        handleDebouncedSearch(term);
    };

    const clearSearchTerm = () => {
        setSearchTerm("");
        dispatch(PracticeSliceActions.patchState({ searchTerm: null }));
    }

    const handleNavigate = () => {
        navigate('/home/practice_lists/add-practice');
    }

    return (
        <Box className={`displayWrap ${PracticeListsStyles.search_panal}`}>
            <TextField
                value={searchTerm}
                onChange={(e) => handleSearchTerm(e.target.value)}
                sx={{ minWidth: '200px' }}
                variant="standard"
                placeholder='Search Practices'
                size='small'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <SearchIcon />
                        </InputAdornment>),
                    endAdornment: (
                        <InputAdornment position="end">
                            <CloseIcon sx={{ display: searchTerm ? "inline-block" : "none", fontSize: "22px" }} className={PracticeListsStyles.closeIcon} onClick={clearSearchTerm} />
                        </InputAdornment>
                    )
                }}
            />
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleNavigate}
                sx={{
                    padding: "6px 11px",
                    minWidth: "200px",
                    textTransform: "capitalize",
                    borderRadius: "100px",
                    marginLeft: "10px"
                }}
            >
                <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Add New Practice</Typography>
            </Button>
        </Box>
    );
}

export default PracticeListHeader