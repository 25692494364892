import React, { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, SelectChangeEvent, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import { PatientThunkApis } from "../../redux/actions/patient-actions";
import UserListHeader from "./components/UserListsHeader";
import { UserThunkApis } from "../../redux/actions/user-actions";
import { UserSliceActions, userSelectors } from "../../redux/reducers/user-slice";
import ComponentLoader from "../../components/ComponentLoader";
import AvantPaginationWithPageSize from "../../components/pagination/PaginationWithPageSize";
import { USER_APPROVED_FAILED_MSG, USER_APPROVED_SUCCESS_MSG, USER_DELETE_FAILED_MSG, USER_DELETE_SUCCESS_MSG, USER_REJECTED_FAILED_MSG, USER_REJECTED_SUCCESS_MSG, USER_STATUS_FAILED_MSG, httpStatusCode } from "../../constants/variable-constants";
import UserTableHeader from "./components/UsersTableHeader";
import UserTableRow from "./components/UsersTableRow";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import api from "../../api";
import { ResponseModel } from "../../models/patient.model";
import { APPROVE_USER, GET_USER_DETAILS, REJECT_USER, USER_STATUS } from "../../constants/api-constants";
import { ToastAlert } from "../../components/toast-alert";
import { usersDetailResponse } from "../../models/user.model";
import { handleErrorMsg } from "../../components/utils";
import { PracticeThunkApis } from "../../redux/actions/practice-actions";
import { OccupationTunkApis } from "../../redux/actions/occupation-actions";
import { useFilterHasValue } from "../../components/custom-selectors/userSelectors";
import { getUserTableHeaders } from "../../components/table-headers";

import UserListsStyles from "./UserLists.module.css";

const UserLists = () => {

    const [statusChanging, setStatusChanging] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isApproving, setIsApproving] = useState<boolean>(false);
    const [isRejecting, setIsRejecting] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const { listLoading, totalUsers, pagesize, currentPage, searchTerm, occupationId, subcategoryId, roleId: filterByRoleId, practiceNameId,
        statusChangingUserId, statusMsg, deleteUserId, userListId, approvedUserId, rejectedUserId } = useAppSelector(state => state.users! || {});
    const { roleId } = useAppSelector(state => state.auth.userDetails! || {});

    const usersData = useAppSelector(userSelectors.selectAll);

    const filterHasValue = useFilterHasValue();

    const paginatedUsers = useMemo(() => {
        const startIndex = currentPage * pagesize;
        const endIndex = startIndex + pagesize;
        return usersData.slice(startIndex, endIndex);
    }, [usersData, currentPage, pagesize]);

    const resultPages = useMemo(() => Math.ceil(usersData.length / pagesize), [usersData, pagesize]);

    const searchParams = {
        query: searchTerm ? searchTerm : "",
        pagesize: null,
        page: null,
        t: userListId
    }

    const filterParams = {
        page: null,
        pageSize: null,
        userStatus: userListId,
        ...(occupationId ? { occupationId } : {}),
        ...(subcategoryId ? { subcategoryId } : {}),
        ...(filterByRoleId ? { roleId: filterByRoleId } : {}),
        ...(practiceNameId ? { practiceNameId } : {}),
    }

    useEffect(() => {
        dispatch(PatientThunkApis.getPatientOccupations(''));
        dispatch(PracticeThunkApis.getAuthAllPractices({ roleId: roleId! }));
        dispatch(OccupationTunkApis.getAllSubCategories(''));
        dispatch(PracticeThunkApis.getAllAdminPractices(''));
    }, [dispatch]);

    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
        const size = event.target.value as any;
        dispatch(UserSliceActions.patchState({ currentPage: 0, pagesize: size }));
    }

    const handlePageChange = (e: any, val: number) => {
        dispatch(UserSliceActions.patchState({ currentPage: val - 1 }));
    };

    useEffect(() => {
        if (filterHasValue) {
            dispatch(UserThunkApis.getFilterUserLists({ userBody: filterParams }));
        } else {
            dispatch(UserThunkApis.getUserLists({ userBody: searchParams, roleId: roleId! }));
        }
    }, [dispatch, searchTerm, userListId, occupationId, subcategoryId, filterByRoleId, practiceNameId]);

    const handleUserStatus = async () => {
        setStatusChanging(true);
        try {
            const statusRes = await api.fetchAuthPostWithBody<ResponseModel<{}>>(USER_STATUS(statusMsg!, statusChangingUserId!), {});
            if (statusRes?.status === httpStatusCode.SUCCESS) {
                ToastAlert(`User ${statusMsg}d successfully`, 'success');
                dispatch(UserSliceActions.patchState({ statusChangingUserId: null, statusMsg: null }));
                dispatch(UserThunkApis.getUserLists({ userBody: searchParams, roleId: roleId! }));
            } else {
                throw new Error(USER_STATUS_FAILED_MSG);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setStatusChanging(false);
        }
    }

    const handleDeleteUser = async () => {
        setIsDeleting(true);
        try {
            const deleteRes = await api.deleteAuth<ResponseModel<{}>>(GET_USER_DETAILS(deleteUserId!));
            if (deleteRes?.status === httpStatusCode.SUCCESS) {
                ToastAlert(USER_DELETE_SUCCESS_MSG, 'success');
                await dispatch(UserSliceActions.removeUser({ id: deleteUserId! }));
                dispatch(UserSliceActions.patchState({ deleteUserId: null }));
            } else {
                throw new Error(USER_DELETE_FAILED_MSG);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setIsDeleting(false);
        }
    }

    const handleApprovedUser = async () => {
        setIsApproving(true);
        try {
            const approveRes = await api.fetchAuthPostWithBody<ResponseModel<usersDetailResponse>>(APPROVE_USER(approvedUserId!), {});
            if (approveRes?.status === httpStatusCode.SUCCESS) {
                ToastAlert(USER_APPROVED_SUCCESS_MSG, 'success');
                dispatch(UserSliceActions.patchState({ approvedUserId: null }));
                dispatch(UserThunkApis.getUserLists({ userBody: searchParams, roleId: roleId! }));
            } else {
                throw new Error(USER_APPROVED_FAILED_MSG);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setIsApproving(false);
        }
    }

    const handleRejectedUser = async () => {
        setIsRejecting(true);
        try {
            const rejectRes = await api.fetchAuthPostWithBody<ResponseModel<{}>>(REJECT_USER(rejectedUserId!), {});
            if (rejectRes?.status === httpStatusCode.SUCCESS) {
                ToastAlert(USER_REJECTED_SUCCESS_MSG, 'success');
                dispatch(UserSliceActions.patchState({ rejectedUserId: null }));
                dispatch(UserThunkApis.getUserLists({ userBody: searchParams, roleId: roleId! }));
            } else {
                throw new Error(USER_REJECTED_FAILED_MSG);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setIsRejecting(false);
        }
    }

    useEffect(() => {
        return () => {
            dispatch(UserSliceActions.setInitialState());
        }
    }, []);

    return (
        <Box className={UserListsStyles.container}>
            <UserListHeader />
            {!paginatedUsers?.length && listLoading ?
                <ComponentLoader /> :
                <Box display="flex" flexDirection="column">
                    <Box className={UserListsStyles.userTableBox} display={'grid'} gridTemplateColumns={'50px 1fr 1fr 1fr 1fr 1fr 1fr'}>
                        {getUserTableHeaders(roleId)?.map((header, index) => <UserTableHeader label={header} key={index} />)}
                        <>
                            {paginatedUsers?.length ? paginatedUsers.map((user, index) => (
                                <UserTableRow key={index} data={user} userIndex={index} />
                            )) :
                                <Box gridColumn="span 7" py={5}>
                                    <Typography variant="h6" align="center">
                                        No Users Found
                                    </Typography>
                                </Box>}
                        </>
                    </Box>
                    <Box display={"grid"} gridAutoFlow={"column"} alignItems={"center"} justifyContent={"flex-end"} marginY={2}>
                        {listLoading ? <Box mr={2} className="flexCenterCenter">
                            <CircularProgress size={25} />
                        </Box> : null}
                        <AvantPaginationWithPageSize
                            {...{
                                pageSize: pagesize!,
                                handlePageSizeChange,
                                page: currentPage!,
                                totalPages: resultPages
                            }}
                            disabled={listLoading || !totalUsers}
                            handlePageChange={handlePageChange}
                        />
                    </Box>
                </Box>}
            <ConfirmationDialog
                open={!!statusChangingUserId}
                accept={handleUserStatus}
                reject={() => dispatch(UserSliceActions.patchState({ statusChangingUserId: null, statusMsg: null }))}
                msg={`Are you sure you want to ${statusMsg} the user?`}
                acceptText={"Yes"}
                rejectText={"No"}
                isLoading={statusChanging}
            />
            <ConfirmationDialog
                open={!!deleteUserId}
                accept={handleDeleteUser}
                reject={() => dispatch(UserSliceActions.patchState({ deleteUserId: null }))}
                msg={`Are you sure you want to delete the user?`}
                acceptText={"Yes"}
                rejectText={"No"}
                isLoading={isDeleting}
            />
            <ConfirmationDialog
                open={!!approvedUserId}
                accept={handleApprovedUser}
                reject={() => dispatch(UserSliceActions.patchState({ approvedUserId: null }))}
                msg={`Are you sure you want to approve the user?`}
                acceptText={"Yes"}
                rejectText={"No"}
                isLoading={isApproving}
            />
            <ConfirmationDialog
                open={!!rejectedUserId}
                accept={handleRejectedUser}
                reject={() => dispatch(UserSliceActions.patchState({ rejectedUserId: null }))}
                msg={`Are you sure you want to reject the user?`}
                acceptText={"Yes"}
                rejectText={"No"}
                isLoading={isRejecting}
            />
        </Box>
    );
}

export default UserLists