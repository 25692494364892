import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
    CHECKED_USER, CUSTOM_NOTIFICATIONS, DOCTOR_ROLE, DOCTOR_STAFF_ROLE, FAILED_ALL_NOTIFICATIONS,
    FAILED_CUSTOM_NOTIFICATIONS, FAILED_CUSTOM_NOTIFICATION_SETTINGS, FAILED_ENABLED_CUSTOM_NOTIFICATION_SETTINGS, UNCHECKED_USER,
    USER_ROLES, httpStatusCode
} from "../../constants/variable-constants";
import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import { PatientThunkApis } from "../../redux/actions/patient-actions";
import { PracticeThunkApis } from "../../redux/actions/practice-actions";
import api from "../../api";
import { ResponseModel } from "../../models/patient.model";
import { ALL_NOTIFICATION_SETTINGS, GET_ALL_NOTIFICATION_SETTINGS, GET_SETTINGS_BY_PRACTICEID, SAVE_ALL_CUSTOM_NOTIFICATIONS } from "../../constants/api-constants";
import { ToastAlert } from "../../components/toast-alert";
import { handleErrorMsg } from "../../components/utils";
import {
    allNotificationSettingsProps, customNotificationProps, notificationSettingsProps,
    notificationStatusSettingsProps, referralReasonSettingsProps
} from "../../models/notifications.model";

import NotificationStyles from "./CustomNotifications.module.css";
interface NotificationProps {
    savePatient: boolean;
    updatePatient: boolean;
    deletePatient: boolean;
}

const CustomNotifications = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { userDetails } = useAppSelector((state) => state.auth! || {});
    const { patientStatus, referralReasons } = useAppSelector((state) => state.patients! || {});
    const { doctorPractices, doctorStaffPractices, adminPractices } = useAppSelector(state => state.practices! || {});

    const [offAllNotifications, setOffAllNotifications] = useState<boolean>(false);
    const [applyForAllPractices, setApplyForAllPractices] = useState<boolean>(false);
    const [notificationSettings, setNotificationSettings] = useState<NotificationProps>({
        savePatient: false,
        updatePatient: false,
        deletePatient: false
    });
    const [referralReasonItems, setReferralReasonItems] = useState<Record<number, boolean>>({});
    const [patientStatusItems, setPatientStatusItems] = useState<Record<number, boolean>>({});
    const [selectedPracticeId, setSelectedPracticeId] = useState<number | null>(null);
    const [selectedStatusIds, setSelectedStatusIds] = useState<number[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                // Fetch referral reasons and patient statuses
                dispatch(PatientThunkApis.getReferralreasons(""));
                dispatch(PatientThunkApis.getPatientStatus(""));

                // Fetch practices based on user role
                fetchPracticesByRole(userDetails?.roleId!);

                // Fetch notification settings
                await fetchNotificationSettings();
            } catch (error: any) {
                handleErrorMsg(error);
            }
        };

        const fetchPracticesByRole = (roleId: number | undefined) => {
            switch (roleId) {
                case DOCTOR_ROLE:
                    dispatch(PracticeThunkApis.getAllDoctorPractices(''));
                    break;
                case DOCTOR_STAFF_ROLE:
                    dispatch(PracticeThunkApis.getAllDoctorStaffPractices(''));
                    break;
                case USER_ROLES.USER_ADMIN:
                    dispatch(PracticeThunkApis.getAllAdminPractices(''));
                    break;
                default:
                    if (roleId) {
                        dispatch(PracticeThunkApis.getAuthAllPractices({ roleId }));
                    }
            }
        };

        const fetchNotificationSettings = async () => {
            const response = await api.fetchAuthGet<ResponseModel<allNotificationSettingsProps[]>>(GET_ALL_NOTIFICATION_SETTINGS);
            if (response?.status === httpStatusCode.SUCCESS) {
                const notificationPreference = response?.data?.data?.[0]?.NotificationPreference;
                setOffAllNotifications(notificationPreference === CHECKED_USER);
            } else {
                throw new Error(FAILED_ALL_NOTIFICATIONS);
            }
        };

        fetchInitialData();
    }, [dispatch, userDetails?.roleId]);

    const handleNavigate = async () => {
        const navigateTo = Object.values(USER_ROLES).includes(userDetails?.roleId!)
            ? "/home/dashboard"
            : userDetails?.roleId! === DOCTOR_STAFF_ROLE
                ? "/home/doctor_lists"
                : "/home/patient_lists";
        navigate(navigateTo);
    };

    const handleReset = () => {
        const updatedStatus = patientStatus?.reduce(
            (acc, status) => ({ ...acc, [status.PatientStatusId!]: false }),
            {}
        ) || {};

        const updatedReferralReasons = referralReasons?.reduce(
            (acc, reason) => ({ ...acc, [reason.ReferralReasonId!]: false }),
            {}
        ) || {};
        setNotificationSettings({
            savePatient: false,
            updatePatient: false,
            deletePatient: false
        });
        setPatientStatusItems(updatedStatus);
        setReferralReasonItems(updatedReferralReasons);
    }

    const handleAllNotifications = async (checked: boolean) => {
        setOffAllNotifications(checked);
        try {
            const response = await api.fetchAuthPostWithBody<ResponseModel<{}>>(ALL_NOTIFICATION_SETTINGS,
                { notificationPreferenceEnabled: !!checked ? CHECKED_USER : UNCHECKED_USER });
            if (response?.status === httpStatusCode.SUCCESS) {
                console.log('Notifications on/off mode enabled');
                if (!checked) {
                    handleReset();
                } else {
                    fetchInitialData();
                }
            } else {
                throw new Error(FAILED_ENABLED_CUSTOM_NOTIFICATION_SETTINGS);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        }
    };

    useEffect(() => {
        // Update notification settings when patientStatus and referralReasons change
        setPatientStatusItems(
            patientStatus?.reduce((acc, status) => ({ ...acc, [status.PatientStatusId!]: false }), {}) || {}
        );
        setReferralReasonItems(
            referralReasons?.reduce((acc, reason) => ({ ...acc, [reason.ReferralReasonId!]: false }), {}) || {}
        );
    }, [patientStatus, referralReasons]);

    const handleToggle = (type: string) => {
        setNotificationSettings((prevState) => ({
            ...prevState,
            [type]: !prevState[type],
        }));
    }

    useEffect(() => {
        if (userDetails?.roleId === USER_ROLES.USER_ADMIN) {
            setSelectedPracticeId(adminPractices[0]?.PracticeNameId);
        } else if (userDetails?.roleId === DOCTOR_ROLE) {
            setSelectedPracticeId(doctorPractices[0]?.PracticeId);
        } else {
            setSelectedPracticeId(doctorStaffPractices[0]?.PracticeId);
        }
    }, [adminPractices, doctorPractices, doctorStaffPractices]);

    const handlePatientStatusChange = (id: number) => {
        setPatientStatusItems((prevItems) => ({
            ...prevItems,
            [id]: !prevItems[id],
        }));
    };

    const handleReasonChange = (id: number) => {
        setReferralReasonItems((prevItems) => ({
            ...prevItems,
            [id]: !prevItems[id],
        }));
    };

    const handlePracticeChange = (event: SelectChangeEvent<number>) => {
        const id = event.target.value as number;
        setSelectedPracticeId(id);
    };

    const fetchInitialData = async () => {
        if (!selectedPracticeId) return;

        try {
            const response = await api.fetchAuthGet<ResponseModel<customNotificationProps>>(
                GET_SETTINGS_BY_PRACTICEID(selectedPracticeId!)
            );

            if (response?.status === httpStatusCode.SUCCESS && response.data) {
                const { data } = response.data!;

                // Update notification settings
                const newNotificationSettings = {
                    savePatient: data?.notificationSettings?.some((ns: notificationSettingsProps) => ns.NotificationType === CUSTOM_NOTIFICATIONS.CREATION),
                    updatePatient: data?.notificationSettings?.some((ns: notificationSettingsProps) => ns.NotificationType === CUSTOM_NOTIFICATIONS.UPDATION),
                    deletePatient: data?.notificationSettings?.some((ns: notificationSettingsProps) => ns.NotificationType === CUSTOM_NOTIFICATIONS.DELETION)
                };
                setNotificationSettings(newNotificationSettings);

                // Update patient status items with enabled or disabled based on admin selection
                const selectedStatusSet = new Set(
                    data?.notificationStatusSettings?.map((status: notificationStatusSettingsProps) => status.PatientStatusId) || []
                );
                // Convert Set to Array before setting the state
                setSelectedStatusIds(Array.from(selectedStatusSet));

                // Update patient status items
                const newPatientStatusItems = data?.notificationStatusSettings?.reduce(
                    (acc: any, status: notificationStatusSettingsProps) => ({
                        ...acc,
                        [status.PatientStatusId]: true
                    }),
                    {}
                );
                setPatientStatusItems(newPatientStatusItems);

                if (data?.referralReasonSettings?.length) {
                    const newReferralReasonItems = data.referralReasonSettings.reduce(
                        (acc: Record<number, boolean>, reason: referralReasonSettingsProps) => {
                            acc[reason.ReferralReasonId] = reason.IsEnabled === CHECKED_USER;
                            return acc;
                        },
                        {}
                    );
                    setReferralReasonItems(newReferralReasonItems);
                }
            } else {
                // Handle failure case
                throw new Error(FAILED_CUSTOM_NOTIFICATION_SETTINGS);
            }
        } catch (error) {
            handleErrorMsg(error);
        }
    };

    useEffect(() => {
        if (!offAllNotifications) {
            return
        }
        fetchInitialData();
    }, [selectedPracticeId, offAllNotifications]);

    const handleSubmit = async () => {
        const payload = {
            applyToAllPractices: applyForAllPractices,
            ...(!applyForAllPractices ? { practiceNameId: selectedPracticeId } : {}),
            notificationSettings: [
                {
                    notificationType: CUSTOM_NOTIFICATIONS.CREATION,
                    isEnabled: notificationSettings.savePatient ? CHECKED_USER : UNCHECKED_USER
                },
                {
                    notificationType: CUSTOM_NOTIFICATIONS.UPDATION,
                    isEnabled: notificationSettings.updatePatient ? CHECKED_USER : UNCHECKED_USER
                },
                {
                    notificationType: CUSTOM_NOTIFICATIONS.DELETION,
                    isEnabled: notificationSettings.deletePatient ? CHECKED_USER : UNCHECKED_USER
                }
            ],
            notificationStatus: {
                ...(!applyForAllPractices ? { practiceNameId: selectedPracticeId } : {}),
                patientStatusIds: Object.keys(patientStatusItems)
                    .filter(key => patientStatusItems[parseInt(key)])
                    .map(Number)
            },
            referralReasonSettings: Object.keys(referralReasonItems).map(key => ({
                referralReasonId: parseInt(key),
                isEnabled: referralReasonItems[parseInt(key)] ? CHECKED_USER : UNCHECKED_USER,
                ...(!applyForAllPractices ? { practiceNameId: selectedPracticeId } : {})
            }))
        };

        setLoading(true);
        try {
            const response = await api.fetchAuthPostWithBody<ResponseModel<{}>>(SAVE_ALL_CUSTOM_NOTIFICATIONS, payload);
            if (response?.status === httpStatusCode.SUCCESS) {
                ToastAlert(response?.data?.message!, 'success');
                handleNavigate();
            } else {
                throw new Error(FAILED_CUSTOM_NOTIFICATIONS);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setLoading(false);
        }
    }

    const isDisabled = useCallback(
        (statusId: number) => {
            if (userDetails?.roleId === USER_ROLES.USER_ADMIN) {
                return false;
            }
            return !selectedStatusIds.includes(statusId);
        },
        [userDetails?.roleId, selectedStatusIds]
    );

    useEffect(() => {
        return () => {
            handleReset();
            setSelectedStatusIds([]);
        }
    }, []);

    return (
        <Box className={NotificationStyles.container}>
            <Box className={NotificationStyles.search_panal}>
                <Box className={`${NotificationStyles.panel_container} flexWrap`} sx={{ display: 'flex', alignItems: 'center', justifyContent: { md: 'space-between', sm: 'center', xs: 'center' }, gap: { sm: '5px', xs: '10px' } }}>
                    <Box className="flexCenterStart pointer customBoxTitle" sx={{ width: "300px" }} onClick={handleNavigate}>
                        <img src="images/back.svg" className={NotificationStyles.backImg} />
                        <Typography ml={2} className={NotificationStyles.panal_header}>
                            Customize Notification Settings
                        </Typography>
                    </Box>
                    <Box className="customBoxMain flexWrap" sx={{ display: 'flex', alignItems: 'center', justifyContent: { md: 'flex-start', sm: 'center', xs: 'center' }, flexDirection: { md: 'row', xs: 'column' }, gap: { md: '5px', xs: '10px' } }}>
                        {userDetails?.roleId === USER_ROLES.USER_ADMIN ?
                            <>
                                <Typography className={NotificationStyles.label}>Apply changes to all practices</Typography>
                                <Switch
                                    checked={applyForAllPractices}
                                    onChange={(event) => setApplyForAllPractices(event.target.checked)}
                                />
                            </> : null}
                        {!applyForAllPractices ?
                            <FormControl sx={{ minWidth: "250px" }}>
                                <InputLabel id="practice-select-label">Practice</InputLabel>
                                <Select
                                    labelId="practice-select-label"
                                    id="practice-select"
                                    value={selectedPracticeId || 0}
                                    label="Practice"
                                    onChange={handlePracticeChange}
                                    sx={{
                                        borderRadius: '25px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderRadius: '25px'
                                        }
                                        , '& .MuiSelect-select': {
                                            padding: '7px 14px'
                                        }
                                    }}
                                >
                                    {userDetails?.roleId === USER_ROLES.USER_ADMIN ? (
                                        adminPractices?.map((practice) => (
                                            <MenuItem key={practice?.PracticeNameId} value={practice?.PracticeNameId!}>{practice?.PracticeName}</MenuItem>
                                        ))
                                    ) : userDetails?.roleId === DOCTOR_ROLE ? (
                                        doctorPractices?.map((practice) => (
                                            <MenuItem key={practice?.PracticeId} value={practice?.PracticeId!}>{practice?.PracticeName}</MenuItem>
                                        ))
                                    ) : (
                                        doctorStaffPractices?.map((practice) => (
                                            <MenuItem key={practice?.PracticeId} value={practice?.PracticeId!}>{practice?.PracticeName}</MenuItem>
                                        ))
                                    )}
                                </Select>
                            </FormControl> : null}
                        <Typography className={NotificationStyles.label}>Turn on/off all notifications</Typography>
                        <Switch
                            checked={offAllNotifications}
                            onChange={(event) => handleAllNotifications(event.target.checked)}
                        />
                    </Box>
                </Box>
                <Grid container spacing={2} ml={3}>
                    <Grid item xs={12} sm={6} md={4} p={3}>
                        <Typography className={NotificationStyles.title}>Patient's Creation</Typography>
                        <Switch
                            checked={notificationSettings.savePatient}
                            onChange={() => handleToggle(CUSTOM_NOTIFICATIONS.CREATION)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} p={3}>
                        <Typography className={NotificationStyles.title}>Patient's Updation</Typography>
                        <Switch
                            checked={notificationSettings.updatePatient}
                            onChange={() => handleToggle(CUSTOM_NOTIFICATIONS.UPDATION)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} p={3}>
                        <Typography className={NotificationStyles.title}>Patient's Deletion</Typography>
                        <Switch
                            checked={notificationSettings.deletePatient}
                            onChange={() => handleToggle(CUSTOM_NOTIFICATIONS.DELETION)}
                        />
                    </Grid>
                    {!!notificationSettings.updatePatient ?
                        <>
                            <Grid item xs={12} md={12} p={3}>
                                <Typography className={NotificationStyles.title}>Patient's Status Updation</Typography>
                            </Grid>
                            {patientStatus?.map((status) => {
                                const statusId = status.PatientStatusId!;
                                const isStatusChecked = !!patientStatusItems[statusId];
                                const isStatusDisabled = isDisabled(statusId);

                                return (
                                    <Grid key={statusId} item xs={12} sm={6} md={6} lg={4} p={3} className="flexCenterStart" gap={1}>
                                        <Switch
                                            disabled={isStatusDisabled}
                                            checked={isStatusChecked}
                                            onChange={() => handlePatientStatusChange(statusId)}
                                        />
                                        <Typography className={NotificationStyles.label}>
                                            {status.PatientStatusName}
                                        </Typography>
                                    </Grid>
                                );
                            })}
                        </> : null}
                    {/* Section for Admin users only */}
                    {userDetails?.roleId === USER_ROLES.USER_ADMIN && !!notificationSettings.savePatient ? (
                        <>
                            <Grid item xs={12} md={12} p={3}>
                                <Typography className={NotificationStyles.title}>
                                    Based on the reason for referrals
                                </Typography>
                            </Grid>
                            {referralReasons?.map((reason) => (
                                <Grid key={reason.ReferralReasonId} item xs={12} sm={6} md={6} lg={4} p={3} className="flexCenterStart" gap={1}>
                                    <Switch
                                        checked={referralReasonItems[reason.ReferralReasonId!] || false}
                                        onChange={() => handleReasonChange(reason.ReferralReasonId!)}
                                    />
                                    <Typography className={NotificationStyles.label}>
                                        {reason.ReferralReasonName}
                                    </Typography>
                                </Grid>
                            ))}
                        </>
                    ) : null}
                </Grid>
                <Box m={2} className="flexCenterEnd">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleNavigate}
                        sx={{
                            padding: "6px 11px",
                            minWidth: "120px",
                            textTransform: "capitalize",
                            borderRadius: "100px"
                        }}
                    >
                        <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Cancel</Typography>
                    </Button>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        loading={isLoading}
                        onClick={handleSubmit}
                        sx={{
                            padding: "6px 11px",
                            minWidth: "120px",
                            textTransform: "capitalize",
                            borderRadius: "100px",
                            marginLeft: "10px"
                        }}
                    >
                        <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Save</Typography>
                    </LoadingButton>
                </Box>
            </Box>
        </Box>
    );
};

export default CustomNotifications;