import React from "react";
import { Box, Typography } from "@mui/material";

import { doctorListsResponse } from "../../../models/practice.model";

import DoctorListsStyles from "../DoctorLists.module.css";

interface doctorTableRowProps {
    data: doctorListsResponse,
    userIndex: number
}

const DoctorsTableRow = ({ data, userIndex }: doctorTableRowProps) => {
    const { FullName, EmailAddress, OccupationName, RoleName, SubcategoryName } = data! || {};

    const cellClassName = !(userIndex % 2) ? DoctorListsStyles.doctorListTableOddCell : DoctorListsStyles.doctorListTableEvenCell;

    return (
        <Box className={DoctorListsStyles.doctorListTableRow} display={'contents'}>
            <Box className={cellClassName}>
                <Typography className={DoctorListsStyles.link}>{FullName}</Typography>
            </Box>
            <Box className={cellClassName}>
                <Typography>{EmailAddress || "-"}</Typography>
            </Box>
            <Box className={cellClassName}>
                <Typography>{OccupationName || "-"}</Typography>
            </Box>
            <Box className={cellClassName}>
                <ul>
                    {SubcategoryName?.length ? (
                        SubcategoryName.map((subcategory, idx) => (
                            <li key={idx}>
                                <Typography>{subcategory}</Typography>
                            </li>
                        ))
                    ) : (
                        <Typography>-</Typography>
                    )}
                </ul>
            </Box>
            <Box className={cellClassName}>
                <Typography>{RoleName || "-"}</Typography>
            </Box>
        </Box>
    );
}

export default DoctorsTableRow