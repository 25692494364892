import React, { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, SelectChangeEvent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import AvantPaginationWithPageSize from "../../components/pagination/PaginationWithPageSize";
import { OccupationSliceActions } from "../../redux/reducers/occupation-slice";
import OccupationListHeader from "./components/OccupationListHeader";
import ComponentLoader from "../../components/ComponentLoader";
import { OCCUPATION, httpStatusCode } from "../../constants/variable-constants";
import { AvantTooltip } from "../../components/tool-tip/Tooltip";
import { OccupationTunkApis } from "../../redux/actions/occupation-actions";
import AddOrEditOccupationDialog from "./components/AddOrEditOccupationDialog";
import { occupationListProps } from "../../models/occupation.model";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import api from "../../api";
import { DELETE_OCCUPATION } from "../../constants/api-constants";
import { ToastAlert } from "../../components/toast-alert";
import { handleErrorMsg } from "../../components/utils";
import { occupationTableHeaders } from "../../components/table-headers";

import OccupationListStyles from "./OccupationList.module.css";

const OccupationList = () => {
    const [isLoading, setLoading] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const { listLoading, pageSize, currentPage, totalOccupations, searchTerm, openOccupationDialog, occupationList, editOccupation, deleteOccupation } = useAppSelector(state => state.occupations! || {});

    const filteredOccupations = useMemo(() => {
        return searchTerm
            ? occupationList.filter(occupation => occupation.OccupationName?.toLowerCase()?.includes(searchTerm.toLowerCase()))
            : occupationList;
    }, [occupationList, searchTerm]);

    const paginatedOccupations = useMemo(() => {
        const startIndex = currentPage * pageSize;
        const endIndex = startIndex + pageSize;
        return filteredOccupations.slice(startIndex, endIndex);
    }, [filteredOccupations, currentPage, pageSize]);

    const resultPages = useMemo(() => Math.ceil(filteredOccupations.length / pageSize), [filteredOccupations, pageSize]);

    useEffect(() => {
        dispatch(OccupationTunkApis.getOccupationsWithSubCategories(''));
    }, [dispatch]);

    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
        const size = event.target.value as number;
        dispatch(OccupationSliceActions.patchState({ currentPage: 0, pageSize: size }));
    };

    const handlePageChange = (event: any, page: number) => {
        dispatch(OccupationSliceActions.patchState({ currentPage: page - 1 }));
    };

    const handleEditOccupation = (occupation: occupationListProps) => {
        dispatch(OccupationSliceActions.patchState({ editOccupation: occupation }));
    }

    const handleDeleteOccupation = (occupation: occupationListProps) => {
        dispatch(OccupationSliceActions.patchState({ deleteOccupation: occupation }));
    }

    const handleDeleteOccupationWithSubcategories = async () => {
        setLoading(true);
        try {
            const response = await api.deleteAuthWithBody<{ auth: Boolean, message: string }>(DELETE_OCCUPATION, { occupationId: deleteOccupation?.OccupationId });
            if (response?.status === httpStatusCode?.SUCCESS) {
                ToastAlert(OCCUPATION.DELETED, 'success');
                await dispatch(OccupationSliceActions.removeOccupation({ id: deleteOccupation?.OccupationId! }));
                dispatch(OccupationSliceActions.patchState({ deleteOccupation: null }));
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        return () => {
            dispatch(OccupationSliceActions.setInitialState());
        };
    }, [dispatch]);

    return (
        <Box className={OccupationListStyles.container}>
            <OccupationListHeader />
            {!paginatedOccupations.length && listLoading ? (
                <ComponentLoader />
            ) : (
                <Box display="flex" flexDirection="column">
                    <Box className={OccupationListStyles.occupationTableBox}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {occupationTableHeaders?.map((label, index) => (
                                        <TableCell key={index}>
                                            <Typography fontWeight={500} color={'primary'}>{label}</Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedOccupations.length ? (
                                    paginatedOccupations.map((occupation, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Typography className={OccupationListStyles.label}>{occupation.OccupationName || "-"}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <ul>
                                                    {occupation.SubcategoryName.length ? (
                                                        occupation.SubcategoryName.map((subcategory, idx) => (
                                                            <li key={idx}>
                                                                <Typography className={OccupationListStyles.label}>{subcategory}</Typography>
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <Typography className={OccupationListStyles.label}>-</Typography>
                                                    )}
                                                </ul>
                                            </TableCell>
                                            <TableCell>
                                                <Box display={"flex"} alignItems={"center"} gap={2}>
                                                    <AvantTooltip title="Edit" placement="top" arrow followCursor>
                                                        <img src="images/edit.svg" alt="Edit_Image" className={OccupationListStyles.editImg} onClick={() => handleEditOccupation(occupation)} />
                                                    </AvantTooltip>
                                                    <AvantTooltip title="Delete" placement="top" arrow followCursor>
                                                        <img src="images/delete.svg" alt="Delete_Image" className={OccupationListStyles.editImg} onClick={() => handleDeleteOccupation(occupation)} />
                                                    </AvantTooltip>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <Typography variant="h6" align="center">
                                                No Occupations Found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Box>
                    <Box display={"grid"} gridAutoFlow={"column"} alignItems={"center"} justifyContent={"flex-end"} marginY={2}>
                        {listLoading && (
                            <Box mr={2} className="flexCenterCenter">
                                <CircularProgress size={25} />
                            </Box>
                        )}
                        <AvantPaginationWithPageSize
                            pageSize={pageSize}
                            handlePageSizeChange={handlePageSizeChange}
                            page={currentPage}
                            totalPages={resultPages}
                            disabled={listLoading || !totalOccupations}
                            handlePageChange={handlePageChange}
                        />
                    </Box>
                </Box>
            )}
            {openOccupationDialog || !!editOccupation ? <AddOrEditOccupationDialog /> : null}
            <ConfirmationDialog
                open={!!deleteOccupation}
                accept={handleDeleteOccupationWithSubcategories}
                reject={() => dispatch(OccupationSliceActions.patchState({ deleteOccupation: null }))}
                msg={`Are you sure you want to delete the ${deleteOccupation?.OccupationName} occupation?`}
                acceptText={"Yes"}
                rejectText={"No"}
                isLoading={isLoading}
            />
        </Box>
    );
};

export default OccupationList;