import React, { useEffect, useState } from "react";
import {
    Autocomplete, Box, Button, Chip, FormControl, FormHelperText, Grid, IconButton, InputAdornment,
    MenuItem, Popper, Select, SelectChangeEvent, TextField, Typography
} from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import MaskedInput from "react-text-mask";

import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import { PatientThunkApis } from "../../redux/actions/patient-actions";
import { sessionStorageGet } from "../../components/session-storage-helpers/session-storage-actions";
import { DOCTOR_ROLE, DOCTOR_STAFF_ROLE, DOCTOR_STAFF_SINGLE_PRACTICE_VALIDATION, OTHER_OCCUPATION, PROFILE_UPDATE_FAILED_MSG, SESSION_KEYS, USER_ROLES, USER_ROLE_NAMES, httpStatusCode, userRoles } from "../../constants/variable-constants";
import ChangePassword from "./ChangePassword";
import { AuthThunkApis } from "../../redux/actions/login-actions";
import { patientOccupationsResponse } from "../../models/patient.model";
import api from "../../api";
import { GET_USER_DETAILS } from "../../constants/api-constants";
import { ToastAlert } from "../../components/toast-alert";
import { convertToArray, handleErrorMsg } from "../../components/utils";
import { practiceSelectors } from "../../redux/reducers/practice-slice";
import { PracticeThunkApis } from "../../redux/actions/practice-actions";
import { adminPracticeResponseProps, practicesBasedLoginProps, practicesResponseParseProps } from "../../models/practice.model";
import { subCategoriesByOccupationProps } from "../../models/occupation.model";
import { OccupationTunkApis } from "../../redux/actions/occupation-actions";

import ProfileStyles from "./Profile.module.css";

const MyProfile = () => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [profileUpdating, setProfileUpdating] = useState<boolean>(false);

    const navigate = useNavigate();

    const userId = sessionStorageGet(SESSION_KEYS.USER_ID);

    const dispatch = useAppDispatch();
    const practiceLists = useAppSelector(practiceSelectors.selectAll);
    const { patientOccupations } = useAppSelector(state => state.patients! || {});
    const { userDetails } = useAppSelector(state => state.auth! || {});
    const { doctorPractices, doctorStaffPractices, adminPractices } = useAppSelector(state => state.practices! || {});
    const { categoriesByOccupation } = useAppSelector(state => state.occupations! || {});

    useEffect(() => {
        if (!userId) return;

        const fetchPractices = () => {
            switch (userDetails?.roleId) {
                case DOCTOR_ROLE:
                    dispatch(PracticeThunkApis.getAllDoctorPractices(''));
                    break;
                case DOCTOR_STAFF_ROLE:
                    dispatch(PracticeThunkApis.getAllDoctorStaffPractices(''));
                    break;
                case USER_ROLES.USER_ADMIN:
                    dispatch(PracticeThunkApis.getAllAdminPractices(''));
                    break;
                default:
                    dispatch(PracticeThunkApis.getAuthAllPractices({ roleId: userDetails?.roleId! }));
            }
        };

        fetchPractices();
        dispatch(PatientThunkApis.getPatientOccupations(''));

        if (userDetails?.roleId !== USER_ROLES.SUPER_ADMIN) {
            dispatch(AuthThunkApis.getUserDetails({ userId }));
        }
    }, [dispatch]);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        emailAddress: Yup.string().email("Invalid email address").required("Email is required"),
        roleId: Yup.number().required("Role is required"),
        phoneNumber: Yup.string()
            .required("Phone number is required")
            .matches(/^\d+$/, "Phone number must be only digits")
            .transform((value, originalValue) => originalValue ? originalValue.replace(/\D/g, '') : originalValue)
            .min(10, "Phone number must be exactly 10 digits")
            .max(10, "Phone number must be exactly 10 digits"),
        practiceNameId: Yup.array().of(Yup.number()).when('roleId', {
            is: (roleId: number) => roleId === USER_ROLES.USER_ADMIN || roleId === DOCTOR_ROLE,
            then: schema => schema.required('Practice name is required'),
            otherwise: schema => schema
                .test(
                    'is-single-practice-for-doctor-staff',
                    DOCTOR_STAFF_SINGLE_PRACTICE_VALIDATION,
                    function (value) {
                        const { roleId } = this.parent;
                        if (roleId === DOCTOR_STAFF_ROLE && value && value.length > 1) {
                            return false;
                        }
                        return true;
                    }
                ),
        }),
    });

    const formik = useFormik({
        initialValues: { ...userDetails!, practiceNameId: userDetails ? convertToArray(userDetails?.practiceNameId as string) : [] },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            setProfileUpdating(true);
            try {
                const userRes = await api.fetchAuthPutWithBody<{ auth: boolean, message: string }>(GET_USER_DETAILS(userId), values);
                if (userRes?.status === httpStatusCode.SUCCESS) {
                    ToastAlert(userRes?.data?.message, 'success');
                    dispatch(AuthThunkApis.getUserDetails({ userId }));
                    handleNavigate();
                } else {
                    throw new Error(PROFILE_UPDATE_FAILED_MSG);
                }
            } catch (error: any) {
                handleErrorMsg(error);
            } finally {
                setProfileUpdating(false);
            }
        },
    });

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('phoneNumber', event.target.value);
    }

    const handleNavigate = async () => {
        const navigateTo = Object.values(USER_ROLES).includes(userDetails?.roleId!)
            ? '/home/dashboard'
            : userDetails?.roleId! === DOCTOR_STAFF_ROLE
                ? '/home/doctor_lists'
                : '/home/patient_lists';
        navigate(navigateTo);
    }

    const handleDefaultOccupation = () => {
        formik.handleChange('occupationId')('2');
        formik.handleChange('otherOccupation')('');
        formik.handleChange('subcategoryId')('');
    }

    const handlePracticeChange = (event: React.SyntheticEvent<Element, Event>, value: practicesResponseParseProps[] | null | any) => {
        if (value) {
            const practiceNameIds = value.map((practice: practicesResponseParseProps) => practice.PracticeNameId);
            formik.setFieldValue('practiceNameId', practiceNameIds);
        } else {
            formik.setFieldValue('practiceNameId', []);
        }
    };

    const handleDoctorPracticeChange = (event: React.SyntheticEvent<Element, Event>, value: practicesBasedLoginProps[] | null | any) => {
        if (value) {
            const practiceNameIds = value.map((practice: practicesBasedLoginProps) => practice.PracticeId);
            formik.setFieldValue('practiceNameId', practiceNameIds);
        } else {
            formik.setFieldValue('practiceNameId', []);
        }
    };

    const handleDoctorStaffPracticeChange = (event: React.SyntheticEvent<Element, Event>, value: practicesBasedLoginProps[] | null | any) => {
        if (value) {
            const practiceNameIds = value.map((practice: practicesBasedLoginProps) => practice.PracticeId);
            formik.setFieldValue('practiceNameId', practiceNameIds);
        } else {
            formik.setFieldValue('practiceNameId', []);
        }
    };

    const handleAdminPracticeChange = (event: React.SyntheticEvent<Element, Event>, value: adminPracticeResponseProps[] | null | any) => {
        if (value) {
            const practiceNameIds = value.map((practice: adminPracticeResponseProps) => practice.PracticeNameId);
            formik.setFieldValue('practiceNameId', practiceNameIds);
        } else {
            formik.setFieldValue('practiceNameId', []);
        }
    };

    useEffect(() => {
        if (!formik.values.occupationId) {
            return;
        }
        dispatch(OccupationTunkApis.getSubCategoriesByOccupation({ id: formik.values.occupationId! }));
        if (formik.values.occupationId === OTHER_OCCUPATION.ID) {
            formik.setFieldValue('subcategoryId', null);
        }
    }, [formik.values.occupationId]);

    const handleRoleChange = (event: SelectChangeEvent<number>) => {
        const roleId = event.target.value as number;
        formik.setFieldValue('roleId', roleId);
        //  If the selected role is DOCTOR_STAFF_ROLE, clear the occupationId and subcategoryId fields
        if (roleId === DOCTOR_STAFF_ROLE) {
            formik.setFieldValue('occupationId', null);
            formik.setFieldValue('subcategoryId', null);
        }
    };

    const handleOccupationChange = (event: SelectChangeEvent<number>) => {
        const occupationId = event.target.value as number;
        formik.setFieldValue('occupationId', occupationId);
        formik.setFieldValue('subcategoryId', null);
    }

    useEffect(() => {
        return () => {
            formik.resetForm();
        }
    }, []);

    return (
        <Box className={ProfileStyles.container}>
            <Box className={ProfileStyles.search_panal}>
                <Box className={`${ProfileStyles.panel_container} flexCenterBetween`}>
                    <Box className="flexCenterStart pointer" sx={{ width: "200px" }} onClick={handleNavigate}>
                        <img src="images/back.svg" className={ProfileStyles.backImg} />
                        <Typography ml={2} className={ProfileStyles.panal_header}>User Details</Typography>
                    </Box>
                    {!isEdit ?
                        <Box className="flexCenterCenter pointer" onClick={() => setIsEdit(true)}>
                            <Typography fontWeight={'normal'} fontSize={"18px"}>Edit</Typography>
                            <IconButton>
                                <EditOutlinedIcon color="primary" />
                            </IconButton>
                        </Box> : null}
                </Box>

                <form onSubmit={formik.handleSubmit}>
                    <Grid container>
                        <Grid item lg={4} sm={6} xs={12} p={3}>
                            <Typography className={ProfileStyles.label}>First Name</Typography>
                            <TextField
                                id="firstName"
                                name="firstName"
                                variant="standard"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}
                                disabled={!isEdit}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={4} sm={6} xs={12} p={3}>
                            <Typography className={ProfileStyles.label}>Last Name</Typography>
                            <TextField
                                id="lastName"
                                name="lastName"
                                variant="standard"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                helperText={formik.touched.lastName && formik.errors.lastName}
                                disabled={!isEdit}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={4} sm={6} xs={12} p={3}>
                            <Typography className={ProfileStyles.label}>Email</Typography>
                            <TextField
                                id="emailAddress"
                                name="emailAddress"
                                variant="standard"
                                value={formik.values.emailAddress}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                                helperText={formik.touched.emailAddress && formik.errors.emailAddress}
                                disabled={!isEdit}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={4} sm={6} xs={12} p={3}>
                            <Typography className={ProfileStyles.label}>Role</Typography>
                            <FormControl
                                variant="standard"
                                fullWidth
                                error={formik.touched.roleId && Boolean(formik.errors.roleId)}
                            >
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    name="roleId"
                                    value={formik.values.roleId!}
                                    onChange={handleRoleChange}
                                    onBlur={formik.handleBlur}
                                    disabled={!isEdit}
                                >
                                    {userDetails?.roleId === USER_ROLES.SUPER_ADMIN ? (
                                        userRoles.map((item) => (
                                            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                        ))
                                    ) : userDetails?.roleId === DOCTOR_ROLE ? (
                                        <MenuItem key={DOCTOR_ROLE} value={DOCTOR_ROLE}>{USER_ROLE_NAMES.DOCTOR}</MenuItem>
                                    ) : userDetails?.roleId === DOCTOR_STAFF_ROLE ? (
                                        <MenuItem key={DOCTOR_STAFF_ROLE} value={DOCTOR_STAFF_ROLE}>{USER_ROLE_NAMES.DOCTOR_STAFF}</MenuItem>
                                    ) : (
                                        userRoles
                                            .filter(role => role.value !== USER_ROLES.SUPER_ADMIN)
                                            .map((item) => (
                                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                            ))
                                    )}
                                </Select>
                                {formik.touched.roleId && formik.errors.roleId && (
                                    <FormHelperText>{formik.errors.roleId}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item lg={4} sm={6} xs={12} p={3}>
                            <Typography className={ProfileStyles.label}>Phone Number</Typography>
                            <MaskedInput
                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                placeholder="Enter a phone number"
                                guide={false}
                                id="phoneNumber"
                                value={formik.values.phoneNumber!}
                                onBlur={formik.handleBlur}
                                onChange={handlePhoneChange}
                                render={(ref, props) => (
                                    <TextField
                                        {...props}
                                        inputRef={ref}
                                        variant="standard"
                                        disabled={!isEdit}
                                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        {formik.values.roleId !== DOCTOR_STAFF_ROLE ?
                            <Grid item lg={4} sm={6} xs={12} p={3}>
                                <Typography className={ProfileStyles.label}>Occupation</Typography>
                                {formik.values.occupationId !== OTHER_OCCUPATION.ID ?
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={formik.touched.occupationId && Boolean(formik.errors.occupationId)}
                                    >
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            name="occupationId"
                                            value={formik.values.occupationId!}
                                            onChange={handleOccupationChange}
                                            onBlur={formik.handleBlur}
                                            disabled={!isEdit}
                                        >
                                            {patientOccupations?.length ? (
                                                patientOccupations.map((item: patientOccupationsResponse) => (
                                                    <MenuItem key={item.OccupationId} value={item.OccupationId!}>
                                                        {item.OccupationName}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem value={4}>Others</MenuItem>
                                            )}
                                        </Select>
                                        {formik.touched.occupationId && formik.errors.occupationId && (
                                            <FormHelperText>{formik.errors.occupationId}</FormHelperText>
                                        )}
                                    </FormControl> :
                                    <TextField
                                        required
                                        id="otherOccupation"
                                        name="otherOccupation"
                                        variant="standard"
                                        size="small"
                                        placeholder="Enter other occupation"
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                borderRadius: '100px'
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        disabled={!isEdit}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CloseIcon sx={{ display: formik.values.otherOccupation ? "inline-block" : "none", fontSize: "22px" }} className={ProfileStyles.closeIcon} onClick={handleDefaultOccupation} />
                                                </InputAdornment>
                                            )
                                        }}
                                        value={formik.values.otherOccupation}
                                        error={formik.touched.otherOccupation && Boolean(formik.errors.otherOccupation)}
                                        helperText={formik.touched.otherOccupation && formik.errors.otherOccupation}
                                        fullWidth
                                    />}
                            </Grid> : null}
                        {formik.values.roleId !== DOCTOR_STAFF_ROLE && formik.values.occupationId !== OTHER_OCCUPATION.ID ?
                            <Grid item lg={4} sm={6} xs={12} p={3}>
                                <Typography className={ProfileStyles.label}>Sub-Category</Typography>
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    error={formik.touched.subcategoryId && Boolean(formik.errors.subcategoryId)}
                                >
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        name="subcategoryId"
                                        value={formik.values.subcategoryId}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={!isEdit}
                                    >
                                        {categoriesByOccupation?.length ? (
                                            categoriesByOccupation.map((item: subCategoriesByOccupationProps) => (
                                                <MenuItem key={item.SubcategoryId} value={item.SubcategoryId!}>
                                                    {item.SubcategoryName}
                                                </MenuItem>
                                            ))
                                        ) : null}
                                    </Select>
                                    {formik.touched.subcategoryId && formik.errors.subcategoryId && (
                                        <FormHelperText>{formik.errors.subcategoryId}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid> : null}
                        <Grid item lg={4} sm={6} xs={12} p={3}>
                            <Typography className={ProfileStyles.label}>Name of Practice</Typography>
                            {userDetails?.roleId === DOCTOR_ROLE ?
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    error={formik.touched.practiceNameId && Boolean(formik.errors.practiceNameId)}
                                >
                                    <Autocomplete
                                        multiple
                                        options={doctorPractices || []}
                                        getOptionLabel={(option: any) => option?.PracticeName!}
                                        value={doctorPractices?.filter(option => (formik.values.practiceNameId! || []).includes(option.PracticeId!)) || []}
                                        onChange={handleDoctorPracticeChange}
                                        disabled
                                        renderInput={(params) => (
                                            <TextField {...params} variant="standard" />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    variant="outlined"
                                                    label={option.PracticeName}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                        PopperComponent={(props) => (
                                            <Popper {...props} placement="bottom-start" style={{ zIndex: 1300 }} />
                                        )}
                                        freeSolo={false}
                                    />
                                    {formik.touched.practiceNameId && formik.errors.practiceNameId && (
                                        <FormHelperText>{formik.errors.practiceNameId as []}</FormHelperText>
                                    )}
                                </FormControl> : userDetails?.roleId === DOCTOR_STAFF_ROLE ?
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        error={formik.touched.practiceNameId && Boolean(formik.errors.practiceNameId)}
                                    >
                                        <Autocomplete
                                            multiple
                                            options={doctorStaffPractices || []}
                                            getOptionLabel={(option: any) => option?.PracticeName!}
                                            value={doctorStaffPractices?.filter(option => (formik.values.practiceNameId! || []).includes(option.PracticeId!)) || []}
                                            onChange={handleDoctorStaffPracticeChange}
                                            disabled
                                            renderInput={(params) => (
                                                <TextField {...params} variant="standard" />
                                            )}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        variant="outlined"
                                                        label={option.PracticeName}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                            PopperComponent={(props) => (
                                                <Popper {...props} placement="bottom-start" style={{ zIndex: 1300 }} />
                                            )}
                                            freeSolo={false}
                                        />
                                        {formik.touched.practiceNameId && formik.errors.practiceNameId && (
                                            <FormHelperText>{formik.errors.practiceNameId as []}</FormHelperText>
                                        )}
                                    </FormControl> : userDetails?.roleId === USER_ROLES.USER_ADMIN ?
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            error={formik.touched.practiceNameId && Boolean(formik.errors.practiceNameId)}
                                        >
                                            <Autocomplete
                                                multiple
                                                options={adminPractices || []}
                                                getOptionLabel={(option: any) => option?.PracticeName!}
                                                value={adminPractices?.filter(option => (formik.values.practiceNameId! || []).includes(option.PracticeNameId!)) || []}
                                                onChange={handleAdminPracticeChange}
                                                disabled
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="standard" />
                                                )}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            label={option.PracticeName}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                PopperComponent={(props) => (
                                                    <Popper {...props} placement="bottom-start" style={{ zIndex: 1300 }} />
                                                )}
                                                freeSolo={false}
                                            />
                                            {formik.touched.practiceNameId && formik.errors.practiceNameId && (
                                                <FormHelperText>{formik.errors.practiceNameId as []}</FormHelperText>
                                            )}
                                        </FormControl> :
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            error={formik.touched.practiceNameId && Boolean(formik.errors.practiceNameId)}
                                        >
                                            <Autocomplete
                                                multiple
                                                options={practiceLists || []}
                                                getOptionLabel={(option: any) => option?.PracticeName!}
                                                value={practiceLists?.filter(option => (formik.values.practiceNameId! || []).includes(option.PracticeNameId!)) || []}
                                                onChange={handlePracticeChange}
                                                disabled
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="standard" />
                                                )}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            label={option.PracticeName}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                PopperComponent={(props) => (
                                                    <Popper {...props} placement="bottom-start" style={{ zIndex: 1300 }} />
                                                )}
                                                freeSolo={false}
                                            />
                                            {formik.touched.practiceNameId && formik.errors.practiceNameId && (
                                                <FormHelperText>{formik.errors.practiceNameId as []}</FormHelperText>
                                            )}
                                        </FormControl>}
                        </Grid>
                    </Grid>
                    {isEdit &&
                        <Box className="flexCenterEnd" my={2}>
                            <Button
                                startIcon={<CloseOutlinedIcon />}
                                variant="outlined"
                                color="primary"
                                onClick={() => setIsEdit(false)}
                                sx={{
                                    padding: "6px 11px",
                                    minWidth: "120px",
                                    textTransform: "capitalize",
                                    borderRadius: "100px"
                                }}
                            >
                                <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Cancel</Typography>
                            </Button>
                            <LoadingButton
                                startIcon={<DoneOutlinedIcon />}
                                variant="contained"
                                color="primary"
                                loading={profileUpdating}
                                type="submit"
                                sx={{
                                    padding: "6px 11px",
                                    minWidth: "120px",
                                    textTransform: "capitalize",
                                    borderRadius: "100px",
                                    marginLeft: "10px"
                                }}
                            >
                                <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Save</Typography>
                            </LoadingButton>
                        </Box>
                    }
                </form>
                <ChangePassword />
            </Box>
        </Box >
    );
}

export default MyProfile;