import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../api";
import { ResponseModel } from "../../models/patient.model";
import { mailDetailResponse } from "../../models/mail.model";
import { ALL_MAILS, EMAIL_DETAILS_BY_ID } from "../../constants/api-constants";
import { ERROR_MESSAGE } from "../../constants/variable-constants";

const getMailLists = createAsyncThunk(
    'mails/getMailLists',
    async (payload: { status: string }, { rejectWithValue }) => {

        try {
            const response = await api.fetchAuthGet<ResponseModel<mailDetailResponse[]>>(ALL_MAILS(payload.status));
            return response.data;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return rejectWithValue(errorMessage);
        }
    }
);

const getMailDetails = createAsyncThunk(
    'mails/getMailDetails',
    async (payload: { id: number }, { rejectWithValue }) => {

        try {
            const response = await api.fetchAuthGet<ResponseModel<mailDetailResponse[]>>(EMAIL_DETAILS_BY_ID(payload.id!));
            return response.data;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return rejectWithValue(errorMessage);
        }
    }
);

export const MailTunkApis = { getMailLists, getMailDetails }