import { createAsyncThunk } from "@reduxjs/toolkit";

import { ALL_HOSPITALS, OCCUPATIONS_LIST, PATIENT_DETAILS, PATIENT_LISTS, PATIENT_STATUS, REFERRAL_REASONS } from "../../constants/api-constants"
import api from "../../api"
import {
    ResponseModel, hospitalProps, patientBodyProps, patientDetailsProps, patientListResponse,
    patientOccupationsResponse, patientStatusResponse, referralReasonsProps
} from "../../models/patient.model";
import { ERROR_MESSAGE } from "../../constants/variable-constants";

const getPatientStatus = createAsyncThunk(
    'patients/getPatientStatus',
    async (payload: string, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<patientStatusResponse[]>>(PATIENT_STATUS);
            return response.data
        } catch (error: any) {
            let errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

const getPatientOccupations = createAsyncThunk(
    'patients/getPatientOccupations',
    async (payload: string, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<patientOccupationsResponse[]>>(OCCUPATIONS_LIST);
            return response.data
        } catch (error: any) {
            let errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

const getPatientLists = createAsyncThunk(
    'patients/getPatientLists',
    async (payload: patientBodyProps, thunk) => {
        try {
            const response = await api.fetchAuthPostWithBody<patientListResponse>(PATIENT_LISTS, payload);
            return response.data
        } catch (error: any) {
            let errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

const getReferralreasons = createAsyncThunk(
    'patients/getReferralreasons',
    async (payload: string, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<referralReasonsProps[]>>(REFERRAL_REASONS);
            return response.data
        } catch (error: any) {
            let errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

const getPatientDetails = createAsyncThunk(
    'patients/getPatientDetails',
    async (payload: { patientId: number }, thunk) => {
        try {
            const response = await api.fetchAuthGet<{ data: patientDetailsProps, message: string }>(PATIENT_DETAILS(payload.patientId!));
            return response.data
        } catch (error: any) {
            let errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

const getHospitals = createAsyncThunk(
    'patients/getHospitals',
    async (payload: string, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<hospitalProps[]>>(ALL_HOSPITALS);
            return response.data
        } catch (error: any) {
            let errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

export const PatientThunkApis = { getPatientStatus, getPatientLists, getPatientOccupations, getReferralreasons, getPatientDetails, getHospitals }