import React from "react";
import { Box, Checkbox, Typography } from "@mui/material";

import { ACTION_COLUMN, CHECKBOX_SELECT } from "../../../constants/variable-constants";
import { useAppDispatch, useAppSelector } from "../../../redux/Hooks";
import { UserSliceActions, userSelectors } from "../../../redux/reducers/user-slice";

import userListStyles from "../UserLists.module.css";

const UserTableHeader = ({ label, hideAction = false }: { label: string, hideAction?: boolean }) => {
    const dispatch = useAppDispatch();

    const { selectedUserMails } = useAppSelector(state => state.users! || {});

    const usersData = useAppSelector(userSelectors.selectAll);

    const handleToggleAll = () => {
        dispatch(UserSliceActions.toggleAllUsers());
    }

    if (hideAction && label === ACTION_COLUMN) return null;

    return (
        <Box className={userListStyles.userTableHeader}>
            {label === CHECKBOX_SELECT ? (
                <Checkbox
                    checked={selectedUserMails.length > 0}
                    indeterminate={selectedUserMails.length > 0 && selectedUserMails.length < usersData.length}
                    onChange={handleToggleAll}
                />
            ) : (
                <Typography mr={1} fontWeight={500} color={'primary'}>{label}</Typography>
            )}
        </Box>
    );
}

export default UserTableHeader;