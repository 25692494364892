import { createEntityAdapter, createSlice, PayloadAction, EntityId } from "@reduxjs/toolkit";

import { RootState } from "../Store";
import { resetState } from "../actions/reset-actions";
import { mailDetailResponse, mailSliceProps } from "../../models/mail.model";
import { MailTunkApis } from "../actions/mail-actions";

const mailAdapter = createEntityAdapter<mailDetailResponse>({
    selectId: (mail) => {
        // Ensure SentEmailId is not null
        if (mail.SentEmailId === null) {
            throw new Error('SentEmailId cannot be null');
        }
        return mail.SentEmailId as EntityId;
    },
    sortComparer: (a, b) => {
        if (a.SentEmailId === null || b.SentEmailId === null) {
            return 0;
        }
        return b.SentEmailId! - a.SentEmailId!;
    },
});

const initialState = mailAdapter.getInitialState<mailSliceProps>({
    pageSize: 10,
    currentPage: 0,
    listLoading: false,
    totalMails: 0,
    emailDetails: null
});

const MailSlice = createSlice({
    name: 'mails',
    initialState,
    reducers: {
        patchState(state, action: PayloadAction<Partial<mailSliceProps>>) {
            return { ...state, ...action.payload };
        },
        setInitialState(state) {
            return {
                ...state,
                pageSize: 10,
                currentPage: 0,
                listLoading: false,
                totalMails: 0
            }
        },
        removeMail: (state, action: PayloadAction<{ id: number }>) => {
            mailAdapter.removeOne(state, action.payload.id);
        }
    },
    extraReducers: (builder) => {
        // To reset all the state variables
        builder.addCase(resetState, () => initialState);
        // getMailLists
        builder.addCase(MailTunkApis.getMailLists.pending, (state) => {
            state.listLoading = true;
        });
        builder.addCase(MailTunkApis.getMailLists.fulfilled, (state, action) => {
            state.listLoading = false;
            state.totalMails = action.payload.data?.length!;
            mailAdapter.setAll(state, action.payload.data || []);
        });
        builder.addCase(MailTunkApis.getMailLists.rejected, (state) => {
            state.listLoading = false;
        });
        // getMailDetails
        builder.addCase(MailTunkApis.getMailDetails.fulfilled, (state, action) => {
            state.emailDetails = action.payload.data?.[0] || {};
        });
    },
});

export const mailSelectors = mailAdapter.getSelectors<RootState>((state) => state.mails);

export const MailSliceActions = MailSlice.actions;
export default MailSlice.reducer;