import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface AlertDialogProps {
    open: boolean,
    accept?: () => void,
    reject?: () => void,
    msg: string,
    acceptText?: string,
    rejectText?: string,
    isLoading?: boolean,
}

const SessionExpiredDialog = ({ open, accept, reject, msg, acceptText, rejectText, isLoading = false }: AlertDialogProps) => {
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {msg}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {rejectText &&
                    <Button
                        onClick={reject}
                        color="primary"
                        variant="outlined"
                        sx={{
                            padding: "6px 11px",
                            minWidth: "70px",
                            textTransform: "capitalize",
                            borderRadius: "100px"
                        }}>
                        <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>{rejectText}</Typography>
                    </Button>}
                <LoadingButton
                    loading={isLoading}
                    onClick={accept}
                    color="primary"
                    variant="contained"
                    autoFocus
                    sx={{
                        padding: "6px 11px",
                        minWidth: "70px",
                        textTransform: "capitalize",
                        borderRadius: "100px"
                    }}
                >
                    <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>{acceptText}</Typography>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default SessionExpiredDialog