import React, { useEffect, useState, useMemo } from "react";
import { Box, CircularProgress, SelectChangeEvent, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import { PracticeThunkApis } from "../../redux/actions/practice-actions";
import { PracticeSliceActions, practiceSelectors } from "../../redux/reducers/practice-slice";
import PracticeTableHeader from "./components/PracticeTableHeader";
import PracticeTableRow from "./components/PracticeTableRow";
import { ResponseModel } from "../../models/patient.model";
import api from "../../api";
import { DELETE_PRACTICE } from "../../constants/api-constants";
import PracticeListHeader from "./components/PracticeListHeader";
import PracticeAssociatedWithDoctorsDialog from "./components/PracticeAssociatedWithDoctorsDialog";
import { httpStatusCode } from "../../constants/variable-constants";
import { ToastAlert } from "../../components/toast-alert";
import ComponentLoader from "../../components/ComponentLoader";
import AvantPaginationWithPageSize from "../../components/pagination/PaginationWithPageSize";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { DoctorThunkApis } from "../../redux/actions/doctor-actions";
import { practicesResponseParseProps } from "../../models/practice.model";
import { practiceTableHeaders } from "../../components/table-headers";

import PracticeListStyles from "./PracticeLists.module.css";

const PracticeLists = () => {
    const dispatch = useAppDispatch();
    const { roleId } = useAppSelector(state => state.auth.userDetails! || {});
    const { listLoading, pagesize, currentPage, totalPractices, deletePractice, searchTerm, viewPractice } = useAppSelector(state => state.practices! || {});

    const practicesData = useAppSelector(practiceSelectors.selectAll);

    const [practiceDeleting, setPracticeDeleting] = useState<boolean>(false);

    const filteredPractices = useMemo(() => {
        return searchTerm
            ? practicesData.filter(practice => practice.PracticeName?.toLowerCase()?.includes(searchTerm.toLowerCase()))
            : practicesData;
    }, [practicesData, searchTerm]);

    const paginatedPractices = useMemo(() => {
        const startIndex = currentPage * pagesize;
        const endIndex = startIndex + pagesize;
        return filteredPractices.slice(startIndex, endIndex);
    }, [filteredPractices, currentPage, pagesize]);

    const resultPages = useMemo(() => Math.ceil(filteredPractices.length / pagesize), [filteredPractices, pagesize]);

    useEffect(() => {
        dispatch(PracticeThunkApis.getAuthAllPractices({ roleId: roleId! }));
        dispatch(DoctorThunkApis.getAllDoctorStaffs(''));
    }, [dispatch]);

    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
        const size = event.target.value as number;
        dispatch(PracticeSliceActions.patchState({ currentPage: 0, pagesize: size }));
    };

    const handlePageChange = (event: any, page: number) => {
        dispatch(PracticeSliceActions.patchState({ currentPage: page - 1 }));
    };

    const handleDeletePractice = async () => {
        setPracticeDeleting(true);
        try {
            const response = await api.fetchAuthPostWithBody<ResponseModel<practicesResponseParseProps[]>>(DELETE_PRACTICE, { PracticeNameId: deletePractice?.PracticeNameId });
            if (response.status === httpStatusCode.SUCCESS) {
                dispatch(PracticeSliceActions.removePractice({ id: deletePractice?.PracticeNameId! }));
                ToastAlert('Practice deleted successfully', 'success');
                dispatch(PracticeSliceActions.patchState({ deletePractice: null }));
            } else {
                throw new Error('Failed to delete the practice.');
            }
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Something went wrong!";
            ToastAlert(errorMessage, 'error');
        } finally {
            setPracticeDeleting(false);
        }
    };

    const renderTableRows = () => {
        if (!paginatedPractices.length) {
            return (
                <Box gridColumn="span 5" py={5}>
                    <Typography variant="h6" align="center">
                        No Practices Found
                    </Typography>
                </Box>
            );
        }

        return paginatedPractices.map((practice, index) => (
            <PracticeTableRow key={index} data={practice} userIndex={index} />
        ));
    };

    useEffect(() => {
        return () => {
            dispatch(PracticeSliceActions.setInitialState());
        };
    }, [dispatch]);

    return (
        <Box className={PracticeListStyles.container}>
            <PracticeListHeader />
            {!paginatedPractices.length && listLoading ? (
                <ComponentLoader />
            ) : (
                <Box display="flex" flexDirection="column">
                    <Box className={PracticeListStyles.practiceTableBox} display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr 1fr">
                        {practiceTableHeaders.map((header, index) => (
                            <PracticeTableHeader label={header} key={index} />
                        ))}
                        {renderTableRows()}
                    </Box>
                    <Box display="grid" gridAutoFlow="column" alignItems="center" justifyContent="flex-end" marginY={2}>
                        {listLoading && (
                            <Box mr={2} className="flexCenterCenter">
                                <CircularProgress size={25} />
                            </Box>
                        )}
                        <AvantPaginationWithPageSize
                            pageSize={pagesize}
                            handlePageSizeChange={handlePageSizeChange}
                            page={currentPage}
                            totalPages={resultPages}
                            disabled={listLoading || !totalPractices}
                            handlePageChange={handlePageChange}
                        />
                    </Box>
                </Box>
            )}
            <ConfirmationDialog
                open={!!deletePractice}
                accept={handleDeletePractice}
                reject={() => dispatch(PracticeSliceActions.patchState({ deletePractice: null }))}
                msg={`Are you sure you want to delete ${deletePractice?.PracticeName} practice?`}
                acceptText={"Yes"}
                rejectText={"No"}
                isLoading={practiceDeleting}
            />
            {viewPractice ?
                <PracticeAssociatedWithDoctorsDialog /> : null}
        </Box>
    );
};

export default PracticeLists;