import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../api";
import { DASHBOARD_STATUS } from "../../constants/api-constants";
import { dashboardResponse } from "../../models/dashboard.model";
import { ERROR_MESSAGE } from "../../constants/variable-constants";

const getDashboardStatus = createAsyncThunk(
    'dashboard/summaryStatus',
    async (payload: string, thunk) => {
        try {
            const response = await api.fetchAuthGet<dashboardResponse>(DASHBOARD_STATUS);
            return response.data
        } catch (error: any) {
            let errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

export const DashboardThunkApis = { getDashboardStatus }