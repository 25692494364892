import { Tabs, styled, TabsProps, tabsClasses } from '@mui/material';

const AvantTabs = styled((props: TabsProps & { isPrimary?: boolean }) => {
    return <Tabs {...props} sx={{ "& .MuiTabs-indicator": { display: "none" } }} />
})((
    { theme, isPrimary }
) => ({
    textTransform: 'none',
    [`& .${tabsClasses.indicator}`]: {
        backgroundColor: '#6fc1e8',
    }
}))

export default AvantTabs