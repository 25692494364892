import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    spacing: 5,
    breakpoints: {
        values: {
            xs: 0,
            iphone5: 320,
            iphone6: 414,
            iphone6s: 475,
            sm: 600,
            ipad: 768,
            md: 960,
            ipad_ls: 1024,
            desktop: 992,
            lg: 1280,
            xl: 1440,
        },
    },
    palette: {
        primary: {
            light: "#70bfe4",
            main: "#6fc1e8",
            dark: "rgba(0,123,255,.25)",
            contrastText: "#fff",
        },
        secondary: {
            light: "#4e4e4e",
            main: "#262626",
            dark: "#000000",
            contrastText: "#fff",
        },
    },
    status: {
        danger: "orange",
    },
    tabSecondary: {
        color: "#1D3264",
    },
    textGrey: {
        color: "#464646",
    },
    textGreyLight: {
        color: "#767676",
    },
    lightGreyBorder: {
        borderColor: "#cecece",
    },
    typography: {
        fontFamily: `"Rubik", "Helvetica", "Arial", sans-serif`,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    "&:hover": {
                        backgroundColor: "#6fc1e8"
                    },
                },
            },
        },
    }
});