import React, { useEffect, useMemo } from "react";
import { Box, CircularProgress, SelectChangeEvent, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import ComponentLoader from "../../components/ComponentLoader";
import AvantPaginationWithPageSize from "../../components/pagination/PaginationWithPageSize";
import { DoctorSliceActions, doctorSelectors } from "../../redux/reducers/doctor-slice";
import { DoctorThunkApis } from "../../redux/actions/doctor-actions";
import { getPracticeNameId } from "../../components/utils";
import DoctorListsHeader from "./components/DoctorListsHeader";
import DoctorsTableHeader from "./components/DoctorsTableHeader";
import DoctorsTableRow from "./components/DoctorsTableRow";
import { userTableHeaders } from "../../components/table-headers";

import DoctorListStyles from "./DoctorLists.module.css";

const DoctorLists = () => {
    const dispatch = useAppDispatch();
    const { listLoading, pagesize, currentPage, totalDoctors, searchTerm } = useAppSelector(state => state.doctors! || {});
    const { practiceNameId } = useAppSelector(state => state.auth.userDetails! || {});

    const doctorsData = useAppSelector(doctorSelectors.selectAll);

    const filteredDoctors = useMemo(() => {
        return searchTerm
            ? doctorsData.filter(doctor => doctor.FullName?.toLowerCase()?.includes(searchTerm.toLowerCase()))
            : doctorsData;
    }, [doctorsData, searchTerm]);

    const paginatedDoctors = useMemo(() => {
        const startIndex = currentPage * pagesize;
        const endIndex = startIndex + pagesize;
        return filteredDoctors.slice(startIndex, endIndex);
    }, [filteredDoctors, currentPage, pagesize]);

    const resultPages = useMemo(() => Math.ceil(filteredDoctors.length / pagesize), [filteredDoctors, pagesize]);

    useEffect(() => {
        if (practiceNameId) {
            dispatch(DoctorThunkApis.getAllDoctorsThroughPractice({ id: getPracticeNameId(practiceNameId) || '' }));
        }
    }, [dispatch, practiceNameId]);

    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
        const size = event.target.value as number;
        dispatch(DoctorSliceActions.patchState({ currentPage: 0, pagesize: size }));
    };

    const handlePageChange = (event: any, page: number) => {
        dispatch(DoctorSliceActions.patchState({ currentPage: page - 1 }));
    };

    const renderTableRows = () => {
        if (!paginatedDoctors.length) {
            return (
                <Box gridColumn="span 5" py={5}>
                    <Typography variant="h6" align="center">
                        No Doctors Found
                    </Typography>
                </Box>
            );
        }

        return paginatedDoctors.map((doctor, index) => (
            <DoctorsTableRow key={index} data={doctor} userIndex={index} />
        ));
    };

    useEffect(() => {
        return () => {
            dispatch(DoctorSliceActions.setInitialState());
        };
    }, [dispatch]);

    return (
        <Box className={DoctorListStyles.container}>
            <DoctorListsHeader />
            {!paginatedDoctors.length && listLoading ? (
                <ComponentLoader />
            ) : (
                <Box display="flex" flexDirection="column">
                    <Box className={DoctorListStyles.doctorTableBox} display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr 1fr">
                        {userTableHeaders.map((header, index) => (
                            <DoctorsTableHeader label={header} key={index} />
                        ))}
                        {renderTableRows()}
                    </Box>
                    <Box display="grid" gridAutoFlow="column" alignItems="center" justifyContent="flex-end" marginY={2}>
                        {listLoading && (
                            <Box mr={2} className="flexCenterCenter">
                                <CircularProgress size={25} />
                            </Box>
                        )}
                        <AvantPaginationWithPageSize
                            pageSize={pagesize}
                            handlePageSizeChange={handlePageSizeChange}
                            page={currentPage}
                            totalPages={resultPages}
                            disabled={listLoading || !totalDoctors}
                            handlePageChange={handlePageChange}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default DoctorLists;