import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import indexStyles from "./login/Login.module.css";

const AvantIndex = ({ children }: { children: JSX.Element }) => {
    return (
        <Box className={`flexCenterCenter ${indexStyles.bg_overlay}`}>
            <Box className={indexStyles.loginContainer}>
                <Grid container sx={{ height: '100%', '@media (orientation: landscape) and (max-width: 991px)': {flexWrap: 'inherit'}}}>
                    {/* Left Section */}
                    <Grid item md={4} xs={12} className={indexStyles.leftContainer}>
                        <Box className="flexCenterCenter f-col">
                            <img src={"images/logo.svg"} alt="Avant_logo-1" />
                            <img src={"images/logo2.png"} alt="Avant_logo-2" className={indexStyles.secondLogo} />
                        </Box>
                        <Typography className={`${indexStyles.footer} flexCenterCenter`}>contact@avant.com</Typography>
                    </Grid>

                    {/* Right Section */}
                    <Grid item md={8} xs={12} className={indexStyles.rightContainer}>
                        {children}
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
}

export default AvantIndex