import { createAsyncThunk } from "@reduxjs/toolkit";

import { ALL_SUB_CATEGORIES, OCCUPATION_WITH_SUB_CATEGORY, SUBCATEGORIES_BY_OCCUPATION } from "../../constants/api-constants";
import { ResponseModel } from "../../models/patient.model";
import { occupationListProps, subCategoriesByOccupationProps, subCategoriesProps } from "../../models/occupation.model";
import api from "../../api";
import { ERROR_MESSAGE } from "../../constants/variable-constants";

const getOccupationsWithSubCategories = createAsyncThunk(
    'occupations/getOccupationsWithSubCategories',
    async (payload: string, thunk) => {
        try {
            const response = await api.fetchGet<ResponseModel<occupationListProps[]>>(OCCUPATION_WITH_SUB_CATEGORY);
            return response.data
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

const getAllSubCategories = createAsyncThunk(
    'occupations/getAllSubCategories',
    async (payload: string, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<subCategoriesProps[]>>(ALL_SUB_CATEGORIES);
            return response.data
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

const getSubCategoriesByOccupation = createAsyncThunk(
    'occupations/getSubCategoriesByOccupation',
    async (payload: { id: number }, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<subCategoriesByOccupationProps[]>>(SUBCATEGORIES_BY_OCCUPATION, { occupationId: payload.id });
            return response.data
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

export const OccupationTunkApis = { getOccupationsWithSubCategories, getAllSubCategories, getSubCategoriesByOccupation }