import { toast } from "react-toastify";

export const ToastAlert = (message: string, type: 'success' | 'error', callback?: () => void) => {
    const options = {
        onClose: callback ? () => setTimeout(callback, 5000) : undefined
    };

    if (type === 'success') {
        toast.success(message, options);
    } else if (type === 'error') {
        toast.error(message, options);
    }
};