import React from "react";
import { Box, Typography } from "@mui/material";

import { ACTION_COLUMN } from "../../../constants/variable-constants";

import DoctorListStyles from "../DoctorLists.module.css";

const DoctorsTableHeader = ({ label }: { label: string }) => {
    if (label === ACTION_COLUMN) return null;

    return (
        <Box className={DoctorListStyles.doctorTableHeader}>
            <Typography mr={1} fontWeight={500} color={'primary'}>{label}</Typography>
        </Box>
    );
}

export default DoctorsTableHeader