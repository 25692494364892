import React, { useEffect, useMemo } from "react";
import { Box, CircularProgress, SelectChangeEvent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import AvantPaginationWithPageSize from "../../components/pagination/PaginationWithPageSize";
import ComponentLoader from "../../components/ComponentLoader";
import { ReferralReasonsSliceActions, referralReasonsSelectors } from "../../redux/reducers/referral-reasons-slice";
import ReferralReasonListHeader from "./components/ReferralReasonListHeader";
import { AvantTooltip } from "../../components/tool-tip/Tooltip";
import AddOrEditReferralReasonDialog from "./components/AddOrEditReferralReasonDialog";
import { ReferralReasonsThunkApis } from "../../redux/actions/referral-reason-actions";
import { referralReasonsProps } from "../../models/patient.model";

import ReferralReasonStyles from "./ReferralReasonList.module.css";

const ReferralReasonList = () => {

    const dispatch = useAppDispatch();
    const { listLoading, pageSize, currentPage, totalReferralReasons, searchTerm, openReferralReasonDialog, referralReasonDetails } = useAppSelector(state => state.referralReasons! || {});

    const referralReasonsData = useAppSelector(referralReasonsSelectors.selectAll);

    const filteredReferralReasons = useMemo(() => {
        return searchTerm
            ? referralReasonsData.filter(reason => reason.ReferralReasonName?.toLowerCase()?.includes(searchTerm.toLowerCase()))
            : referralReasonsData;
    }, [referralReasonsData, searchTerm]);

    const paginatedReferralReasons = useMemo(() => {
        const startIndex = currentPage * pageSize;
        const endIndex = startIndex + pageSize;
        return filteredReferralReasons.slice(startIndex, endIndex);
    }, [filteredReferralReasons, currentPage, pageSize]);

    const resultPages = useMemo(() => Math.ceil(filteredReferralReasons.length / pageSize), [filteredReferralReasons, pageSize]);

    useEffect(() => {
        dispatch(ReferralReasonsThunkApis.getReferralreasons(''));
    }, [dispatch]);

    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
        const size = event.target.value as number;
        dispatch(ReferralReasonsSliceActions.patchState({ currentPage: 0, pageSize: size }));
    };

    const handlePageChange = (event: any, page: number) => {
        dispatch(ReferralReasonsSliceActions.patchState({ currentPage: page - 1 }));
    };

    const handleEditReferralReason = (reason: referralReasonsProps) => {
        dispatch(ReferralReasonsSliceActions.patchState({ referralReasonDetails: reason }));
    }

    useEffect(() => {
        return () => {
            dispatch(ReferralReasonsSliceActions.setInitialState());
        };
    }, [dispatch]);

    return (
        <Box className={ReferralReasonStyles.container}>
            <ReferralReasonListHeader />
            {!paginatedReferralReasons.length && listLoading ? (
                <ComponentLoader />
            ) : (
                <Box display="flex" flexDirection="column">
                    <Box className={ReferralReasonStyles.referralReasonTableBox}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography fontWeight={500} color={'primary'}>Name</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography fontWeight={500} color={'primary'}>Action</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedReferralReasons.length ? (
                                    paginatedReferralReasons.map((reason, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Typography className={ReferralReasonStyles.label}>{reason.ReferralReasonName || "-"}</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <AvantTooltip title="Edit" placement="top" arrow followCursor>
                                                    <img src="images/edit.svg" alt="Edit_Image" className={ReferralReasonStyles.editImg} onClick={() => handleEditReferralReason(reason)} />
                                                </AvantTooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Typography variant="h6" align="center">
                                                No Referral Reasons Found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Box>
                    <Box display={"grid"} gridAutoFlow={"column"} alignItems={"center"} justifyContent={"flex-end"} marginTop={3} className="mailPaginatonOption">
                        {listLoading && (
                            <Box mr={2} className="flexCenterCenter">
                                <CircularProgress size={25} />
                            </Box>
                        )}
                        <AvantPaginationWithPageSize
                            pageSize={pageSize}
                            handlePageSizeChange={handlePageSizeChange}
                            page={currentPage}
                            totalPages={resultPages}
                            disabled={listLoading || !totalReferralReasons}
                            handlePageChange={handlePageChange}
                        />
                    </Box>
                </Box>
            )}
            {openReferralReasonDialog || !!referralReasonDetails ? <AddOrEditReferralReasonDialog /> : null}
        </Box>
    );
};

export default ReferralReasonList;