import { createAsyncThunk } from "@reduxjs/toolkit";

import { ResponseModel } from "../../models/patient.model";
import { doctorListsResponse, doctorStaffListResponse } from "../../models/practice.model";
import { DOCTORS_LIST, DOCTOR_LISTS_ASSOCIATED_PRACTICE, DOCTOR_STAFFS_LIST, DOCTOR_STAFF_WITHOUT_PRACTICE, GET_USERS_THROUGH_PRACTICE } from "../../constants/api-constants";
import api from "../../api";
import { ERROR_MESSAGE } from "../../constants/variable-constants";

const getDoctorsAssociatedPractice = createAsyncThunk(
    'doctors/getDoctorsAssociatedPractice',
    async (payload: { staffId: number[] }, thunk) => {
        try {
            const practiceNameId = payload?.staffId.length === 1 ? payload.staffId[0] : payload.staffId;
            const response = await api.fetchAuthGet<ResponseModel<doctorListsResponse[]>>(DOCTOR_LISTS_ASSOCIATED_PRACTICE, { practiceNameId });
            return response.data
        } catch (error: any) {
            let errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

const getAllDoctors = createAsyncThunk(
    'doctors/getAllDoctors',
    async (payload: string, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<doctorListsResponse[]>>(DOCTORS_LIST);
            return response.data
        } catch (error: any) {
            let errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

const getAllDoctorStaffs = createAsyncThunk(
    'doctors/getAllDoctorStaffs',
    async (payload: string, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<doctorListsResponse[]>>(DOCTOR_STAFFS_LIST);
            return response.data
        } catch (error: any) {
            let errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

const getAllDoctorStaffWithoutPractice = createAsyncThunk(
    'doctors/getAllDoctorStaffWithoutPractice',
    async (payload: string, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<doctorStaffListResponse[]>>(DOCTOR_STAFF_WITHOUT_PRACTICE);
            return response.data
        } catch (error: any) {
            let errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

const getAllDoctorsThroughPractice = createAsyncThunk(
    'doctors/getAllDoctorsThroughPractice',
    async (payload: { id: number }, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<doctorListsResponse[]>>(GET_USERS_THROUGH_PRACTICE(payload?.id));
            return response.data
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

export const DoctorThunkApis = { getDoctorsAssociatedPractice, getAllDoctors, getAllDoctorStaffs, getAllDoctorStaffWithoutPractice, getAllDoctorsThroughPractice }