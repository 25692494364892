import { Box, Paper, Typography, useTheme } from '@mui/material';

import styles from './UnAuthorized.module.css';

export default function UnAuthorized() {
    const theme = useTheme();
    return <div className={styles.container}>
        <Box display="grid">
            <Paper className={styles.card}>
                <Box display="grid">
                    <Typography className={styles.mainText} textAlign="center" fontSize="45px">Access Denied</Typography>
                    <Box className={styles.underline} />
                    <Box display="grid" gap="5px">
                        <Typography textAlign="center" fontSize="22px">
                            Please contact <span style={{ color: theme.palette.primary.main }}>Avant Lasik Support</span> for upgrading your access permissions
                        </Typography>
                        <Typography textAlign="center" fontSize="22px">
                            Apologies for the inconvenience
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </Box>
    </div>
}