import { Tab, styled, TabProps, tabClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AvantTooltip } from '../tool-tip/Tooltip';

// Define tooltip styles using makeStyles
const useTooltipStyles = makeStyles(() => ({
    tooltipText: {
        fontSize: 14,
        fontWeight: 450,
        padding: '15px 10px',
        backgroundColor: 'rgba(70, 70, 70, 0.7)',
    },
}));

// Styled AvantTab component with Tooltip integration
const AvantTab = styled((props: TabProps & { isPrimary?: boolean; tooltipText?: string }) => {
    const classes = useTooltipStyles();

    return (
        <AvantTooltip
            title={props.tooltipText}
            placement="bottom"
            componentsProps={{ tooltip: { className: classes.tooltipText } }}
        >
            <Tab {...props} />
        </AvantTooltip>
    );
})(({ theme, isPrimary }) => ({
    textTransform: 'none',
    minWidth: 160,
    minHeight: 20,
    backgroundColor: '#FFFFFF',
    border: '1px solid #6fc1e8',
    color: 'black',
    fontSize: 16,
    fontWeight: 400,
    padding: 10,
    [`&.${tabClasses.selected}`]: {
        color: '#FFFFFF',
        backgroundColor: '#6fc1e8',
        fontWeight: 500,
    },
    '@media (max-width: 1350px)': {
        minWidth: 'auto',
    },
}));

export default AvantTab;