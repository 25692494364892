import React, { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, SelectChangeEvent, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import PatientListsHeader from "./components/PatientListsHeader";
import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import { PatientThunkApis } from "../../redux/actions/patient-actions";
import { DOCTOR_ROLE, DOCTOR_STAFF_ROLE, USER_ROLES, httpStatusCode } from "../../constants/variable-constants";
import ComponentLoader from "../../components/ComponentLoader";
import PatientTableHeader from "./components/PatientTableHeader";
import { PatientSliceActions, patientSelectors } from "../../redux/reducers/patient-slice";
import PatientTableRow from "./components/PatientTableRow";
import AvantPaginationWithPageSize from "../../components/pagination/PaginationWithPageSize";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { ToastAlert } from "../../components/toast-alert";
import api from "../../api";
import { DELETE_PATIENT } from "../../constants/api-constants";
import { convertToArray, handleErrorMsg } from "../../components/utils";
import { DoctorThunkApis } from "../../redux/actions/doctor-actions";
import { PracticeThunkApis } from "../../redux/actions/practice-actions";
import { getPatientTableHeaders } from "../../components/table-headers";

import PatientListsStyles from "./PatientLists.module.css";

const PatientLists = () => {
    const { statusId } = useParams<{ statusId: string }>();

    const dispatch = useAppDispatch();
    const { currentPatientStatusId, pageListSize, listLoading, totalPatients, currentPage, searchTerm, deletePatientId,
        referralReasonId, referredById, referredOn, selectedHospitalId } = useAppSelector(state => state.patients! || {});
    const { roleId, practiceNameId, userId } = useAppSelector(state => state.auth.userDetails! || {});

    const patientsData = useAppSelector(patientSelectors.selectAll);

    const [isLoading, setLoading] = useState<boolean>(false);

    const paginatedPatients = useMemo(() => {
        const startIndex = currentPage * pageListSize;
        const endIndex = startIndex + pageListSize;
        return patientsData.slice(startIndex, endIndex);
    }, [patientsData, currentPage, pageListSize]);

    const resultPages = useMemo(() => Math.ceil(patientsData.length / pageListSize), [patientsData, pageListSize]);

    const filterObject = {
        firstName: searchTerm || "",
        lastName: searchTerm || "",
        page: null,
        pageSize: null,
        ...(referredById || roleId === DOCTOR_ROLE ? { referredById: referredById ? referredById : userId } : {}),
        ...(currentPatientStatusId?.length || statusId ? { currentPatientStatusId: currentPatientStatusId?.length ? currentPatientStatusId : [+statusId!] } : {}),
        ...(referralReasonId?.length ? { referralReasonId } : {}),
        ...(referredOn ? { referredOn } : {}),
        ...(selectedHospitalId ? { hospitalId: selectedHospitalId } : {})
    };

    useEffect(() => {
        dispatch(PatientThunkApis.getPatientStatus(''));
        dispatch(PatientThunkApis.getReferralreasons(''));
        dispatch(PatientThunkApis.getHospitals(''));
        dispatch(DoctorThunkApis.getAllDoctors(''));
        const fetchPractices = () => {
            switch (roleId) {
                case DOCTOR_ROLE:
                    dispatch(PracticeThunkApis.getAllDoctorPractices(''));
                    break;
                case DOCTOR_STAFF_ROLE:
                    dispatch(PracticeThunkApis.getAllDoctorStaffPractices(''));
                    break;
                case USER_ROLES.USER_ADMIN:
                    dispatch(PracticeThunkApis.getAllAdminPractices(''));
                    break;
                default:
                    dispatch(PracticeThunkApis.getAuthAllPractices({ roleId: roleId! }));
            }
        };

        fetchPractices();
        const practiceId = convertToArray(practiceNameId!);
        if (practiceId) {
            dispatch(DoctorThunkApis.getDoctorsAssociatedPractice({ staffId: practiceId }));
        }
    }, [dispatch, practiceNameId]);

    useEffect(() => {
        dispatch(PatientThunkApis.getPatientLists(filterObject));
    }, [dispatch, currentPatientStatusId, searchTerm, statusId, referralReasonId, referredById, referredOn, selectedHospitalId]);

    const handlePageSizeChange = (event: SelectChangeEvent<unknown>) => {
        const size = event.target.value as any;
        dispatch(PatientSliceActions.patchState({ currentPage: 0, pageListSize: size }));
    }

    const handlePageChange = (e: any, val: number) => {
        dispatch(PatientSliceActions.patchState({ currentPage: val - 1 }));
    };

    const handleDeletePatient = async () => {
        setLoading(true);
        try {
            const patientRes = await api.deleteAuthWithBody<{ message: string }>(DELETE_PATIENT(deletePatientId!), { patientId: deletePatientId });
            if (patientRes?.status === httpStatusCode?.SUCCESS) {
                ToastAlert(patientRes?.data?.message, 'success');
                await dispatch(PatientSliceActions.removePatient({ id: deletePatientId! }));
                dispatch(PatientSliceActions.patchState({ deletePatientId: null }));
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setLoading(false);
        }
    }

    const getHeaderClassName = (roleId: number | null) => {
        if (roleId === USER_ROLES.SUPER_ADMIN) {
            return PatientListsStyles.patientsHeaderColumnsSuperAdmin;
        } else if (roleId === USER_ROLES.USER_ADMIN) {
            return PatientListsStyles.patientsHeaderColumnsAdmin;
        } else if (roleId === DOCTOR_STAFF_ROLE) {
            return PatientListsStyles.patientsHeaderColumnsDoctorStaff;
        } else {
            return PatientListsStyles.patientsHeaderColumnsUser;
        }
    }

    const getNotFoundClassName = (roleId: number | null) => {
        if (roleId === DOCTOR_STAFF_ROLE) {
            return 'span 9';
        } else if (roleId === USER_ROLES.SUPER_ADMIN || roleId === USER_ROLES.USER_ADMIN) {
            return 'span 10';
        } else {
            return 'span 6';
        }
    }

    useEffect(() => {
        return () => {
            dispatch(PatientSliceActions.setInitialState());
        }
    }, []);

    return (
        <Box className={PatientListsStyles.container}>
            <PatientListsHeader />
            {!patientsData?.length && listLoading ?
                <ComponentLoader /> :
                <Box display="flex" flexDirection="column">
                    <Box className={`${PatientListsStyles.patientTableBox} ${getHeaderClassName(roleId)}`}>
                        {getPatientTableHeaders(roleId)?.map((header, index) => <PatientTableHeader label={header} roleId={roleId} key={index} />)}
                        <>
                            {paginatedPatients?.length ? paginatedPatients.map((patient, index) => (
                                <PatientTableRow key={index} data={patient} patientIndex={index} />
                            )) :
                                <Box gridColumn={getNotFoundClassName(roleId)} py={5}>
                                    <Typography variant="h6" align="center">
                                        No Patients Found
                                    </Typography>
                                </Box>}
                        </>
                    </Box>
                    <Box display={"grid"} gridAutoFlow={"column"} alignItems={"center"} justifyContent={"flex-end"} marginY={2}>
                        {listLoading ? <Box mr={2} className="flexCenterCenter">
                            <CircularProgress size={25} />
                        </Box> : null}
                        <AvantPaginationWithPageSize
                            {...{
                                pageSize: pageListSize,
                                handlePageSizeChange,
                                page: currentPage,
                                totalPages: resultPages
                            }}
                            disabled={listLoading || !totalPatients}
                            handlePageChange={handlePageChange}
                        />
                    </Box>
                </Box>}
            <ConfirmationDialog
                open={!!deletePatientId}
                accept={handleDeletePatient}
                reject={() => dispatch(PatientSliceActions.patchState({ deletePatientId: null }))}
                msg={"Are you sure you want to delete the patient?"}
                acceptText={"Yes"}
                rejectText={"No"}
                isLoading={isLoading}
            />
        </Box>
    );
}

export default PatientLists