import React, { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";

import { useAppSelector } from "../../redux/Hooks";
import { useLogoutService } from "./useLogoutService";
import { sessionStorageGet, sessionStorageSet } from "../session-storage-helpers/session-storage-actions";
import { SESSION_KEYS } from "../../constants/variable-constants";

interface UseIdleTimerPopupOptions {
    defaultTimeout: number; // Default timeout in milliseconds
}

const useIdleTimerPopUp = (options: UseIdleTimerPopupOptions) => {
    const { isLoggedIn } = useAppSelector((state) => state.auth || {});

    const [open, setOpen] = useState<boolean>(false);
    const [logoutTimeoutId, setLogoutTimeoutId] = useState<number | null>(null);

    const { logout, clearLoginState } = useLogoutService();

    const handleOnIdleTimer = () => {
        if (shouldOpenPopup()) {
            setOpen(true);
            setLogoutTimeout();
        }
    };

    const { reset } = useIdleTimer({
        timeout: options.defaultTimeout,
        onIdle: handleOnIdleTimer,
        debounce: 500,
    });

    const accept = () => {
        setOpen(false);
        clearLogoutTimeout();
        reset(); // Reset idle timer on user activity
    };

    const reject = () => {
        setOpen(false);
        clearLogoutTimeout();
        logout();
    };

    const sessionExpiredCall = () => {
        setOpen(false);
        clearLogoutTimeout();
        window.location.href = "#/";
        clearLoginState();
    };

    const shouldOpenPopup = () => isLoggedIn && !open;

    const setLogoutTimeout = () => {
        const timeoutId = window.setTimeout(() => {
            setOpen(false);
            logout();
        }, 2 * 60 * 1000); // Close the popup if no response within 2 mins

        setLogoutTimeoutId(timeoutId);
    };

    const clearLogoutTimeout = () => {
        if (logoutTimeoutId !== null) {
            clearTimeout(logoutTimeoutId);
        }
    };

    const handleVisibilityChange = () => {
        if (!document.hidden) {
            // Page is visible, clear the logout timeout
            clearLogoutTimeout();

            const lastVisibilityTime = sessionStorageGet(SESSION_KEYS.LAST_VISIBILITY_TIME);

            // Check if the system was asleep for more than 17 minutes
            if (lastVisibilityTime && Date.now() - lastVisibilityTime > 17 * 60 * 1000) {
                sessionExpiredCall(); // Navigate to login page
            }
        } else {
            // Page is hidden, update the last visible time
            sessionStorageSet(SESSION_KEYS.LAST_VISIBILITY_TIME, Date.now());
        }
    };

    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        return clearLogoutTimeout;
    }, [logoutTimeoutId]);

    return { open, accept, reject };
};

export default useIdleTimerPopUp;