import React, { useEffect, useState } from "react";
import { Autocomplete, Checkbox, Chip, Popper, TextField } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../redux/Hooks";
import { patientStatusResponse } from "../../../models/patient.model";
import { NotificationSliceActions } from "../../../redux/reducers/notification-slice";
import { doctorListsResponse } from "../../../models/practice.model";
import { VIEW_LIST } from "../../../constants/variable-constants";

interface updatePatientStatusProps {
    isItemDisabled: boolean,
    isItemSelected: boolean,
    user: doctorListsResponse,
    setEnable: Function
}

const UpdatePatientStatus = ({ isItemDisabled, isItemSelected, user, setEnable }: updatePatientStatusProps) => {
    const dispatch = useAppDispatch();
    const { patientStatus } = useAppSelector(state => state.patients! || {});
    const { practiceOrientedPatientStatusIds, isOverallPatientStatusEnabled, viewList } = useAppSelector(state => state.notifications! || {});
    const [patientStatusIds, setPatientStatusIds] = useState<number[]>([]);

    const isAdminView = viewList === VIEW_LIST.ADMIN;

    useEffect(() => {
        if (user?.PatientStatusIds?.length) {
            setPatientStatusIds(user.PatientStatusIds);
        }
    }, [user]);

    useEffect(() => {
        if (isItemSelected && !isAdminView && practiceOrientedPatientStatusIds.length) {
            setPatientStatusIds(practiceOrientedPatientStatusIds);
            dispatch(NotificationSliceActions.updateUserPatientStatusIds({
                id: user?.UserId!,
                patientStatusIds: practiceOrientedPatientStatusIds
            }));
            setEnable(true);
        }
    }, [practiceOrientedPatientStatusIds]);

    useEffect(() => {
        if (isItemSelected && isOverallPatientStatusEnabled && !practiceOrientedPatientStatusIds?.length) {
            const updatedPatientStatusIds: number[] = [];
            setPatientStatusIds(updatedPatientStatusIds);

            dispatch(NotificationSliceActions.updateUserPatientStatusIds({
                id: user?.UserId!,
                patientStatusIds: updatedPatientStatusIds
            }));

            setEnable(true);
        }
    }, [isItemSelected, isOverallPatientStatusEnabled, practiceOrientedPatientStatusIds, user?.UserId]);

    const handleStatusChange = (
        event: React.SyntheticEvent<Element, Event>,
        newValue: patientStatusResponse[]
    ) => {
        event.stopPropagation();

        if (!newValue || isItemDisabled) return;

        const selectedPatientStatusIds = newValue.map(({ PatientStatusId }) => PatientStatusId!);
        setPatientStatusIds(selectedPatientStatusIds);
        dispatch(NotificationSliceActions.updateUserPatientStatusIds({
            id: user?.UserId!,
            patientStatusIds: selectedPatientStatusIds
        }));
        setEnable(true);
    };

    const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();

        if (isItemDisabled) return;

        const checked = event.target.checked;

        if (checked) {
            const allIds = patientStatus?.map(({ PatientStatusId }) => PatientStatusId!) || [];
            setPatientStatusIds(allIds);
            dispatch(NotificationSliceActions.updateUserPatientStatusIds({
                id: user?.UserId!,
                patientStatusIds: allIds
            }));
        } else {
            setPatientStatusIds([]);
            dispatch(NotificationSliceActions.updateUserPatientStatusIds({
                id: user?.UserId!,
                patientStatusIds: []
            }));
        }
        setEnable(true);
    };

    return (
        <Autocomplete
            multiple
            limitTags={1}
            size="small"
            id="patient-status-autocomplete"
            options={patientStatus || []}
            disableCloseOnSelect
            value={patientStatus?.filter(status => patientStatusIds.includes(status.PatientStatusId!)) || []}
            getOptionLabel={(option) => option.PatientStatusName!}
            onChange={handleStatusChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select patient status..."
                    disabled={isItemDisabled}
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => e.stopPropagation()}
                />
            )}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        sx={{ p: 0, mr: 1, color: '#6fc1e8', '& .MuiSvgIcon-root': { fontSize: 24 } }}
                        checked={!!patientStatusIds?.includes(option.PatientStatusId!)}
                    />
                    {option.PatientStatusName}
                </li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        variant="outlined"
                        label={option.PatientStatusName}
                        {...getTagProps({ index })}
                    />
                ))
            }
            PopperComponent={(props) => (
                <Popper {...props} placement="bottom-start" style={{ zIndex: 1300 }} />
            )}
            ListboxComponent={(listboxProps) => (
                <ul {...listboxProps}>
                    <li>
                        <Checkbox
                            sx={{ p: 0, mr: 1, ml: 3, color: '#6fc1e8', '& .MuiSvgIcon-root': { fontSize: 24 } }}
                            checked={patientStatusIds.length > 0}
                            indeterminate={patientStatusIds.length > 0 && patientStatusIds.length < patientStatus?.length!}
                            onChange={handleSelectAllChange}
                        />
                        Select All
                    </li>
                    {listboxProps.children}
                </ul>
            )}
            freeSolo={false}
            style={{ width: 250 }}
        />
    );
};

export default UpdatePatientStatus;