import React from "react";
import { Pagination, PaginationProps } from "@mui/material";
import { makeStyles } from "@mui/styles";

const paginationStyles: any = makeStyles(() => ({
    root: {
        '& .Mui-selected': {
            backgroundColor: 'transparent',
            color: '#000',
            fontWeight: '800'
        },
        "& .MuiPagination-ul": {
            "& > li > button": {
                fontSize: 14,
                padding: 0,
                margin: 0
            },
            "& > li:first-child": {
                "& button": {
                    marginRight: 5,
                    backgroundColor: 'lightgrey',
                },
            },
            "& > li:last-child": {
                "& button": {
                    marginLeft: 5,
                    width: 15,
                    backgroundColor: 'lightgrey'
                },
            },
        },
    },
}))

const AvantPagination: React.FC<PaginationProps> = (props) => {
    const classes = paginationStyles();
    return (
        <Pagination {...props} className={classes.root} color="primary" shape="rounded" />
    )
}

export default AvantPagination;