export const SIDEBAR = {
    ACTIVE: "#6fc1e8",
    IN_ACTIVE: "#fafbfc"
}

export const patientStatusColor: Record<number, string> = {
    1: "red",
    2: "#00bd00",
    3: "#f5be1b",
    4: "blue"
}