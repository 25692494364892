import React from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { usersDetailResponse } from "../../../models/user.model";
import { useAppDispatch, useAppSelector } from "../../../redux/Hooks";
import { UserSliceActions } from "../../../redux/reducers/user-slice";
import { AvantTooltip } from "../../../components/tool-tip/Tooltip";

import UserListsStyles from "../UserLists.module.css";
interface userTableRowProps {
    data: usersDetailResponse,
    userIndex: number
}

const UserTableRow = ({ data, userIndex }: userTableRowProps) => {
    const { firstName, lastName, emailAddress, occupationName, roleName, userId, isDisabled, subcategoryId } = data! || {};

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { userListId, selectedUserMails, highlightUserId } = useAppSelector(state => state.users! || {});
    const { subCategoryLists } = useAppSelector(state => state.occupations! || {});

    const statusMsg = isDisabled === 1 ? 'enable' : 'disable';

    const openStatusDialog = () => {
        dispatch(UserSliceActions.patchState({ statusChangingUserId: userId, statusMsg }));
    }

    const openDeleteUserDialog = () => {
        dispatch(UserSliceActions.patchState({ deleteUserId: userId }));
    }

    const openApprovedUserDialog = () => {
        dispatch(UserSliceActions.patchState({ approvedUserId: userId }));
    }

    const openRejectedUserDialog = () => {
        dispatch(UserSliceActions.patchState({ rejectedUserId: userId }));
    }

    const getSubCategoryName = () => {
        return subCategoryLists?.find(category => category.SubcategoryId === subcategoryId)?.SubcategoryName || "-";
    }

    const handleToggleSelection = () => {
        dispatch(UserSliceActions.toggleUserSelection(emailAddress!));
    };

    const cellClassName = userId === highlightUserId ?
        UserListsStyles.userListTableHighlighted :
        !(userIndex % 2) ? UserListsStyles.userListTableOddCell : UserListsStyles.userListTableEvenCell;

    return (
        <Box className={UserListsStyles.userListTableRow} display={'contents'}>
            <Box className={cellClassName}>
                <Checkbox
                    checked={selectedUserMails.includes(emailAddress!)}
                    onChange={handleToggleSelection}
                />
            </Box>
            <Box className={cellClassName} sx={{width: '200px'}}>
                <Typography component={"div"} onClick={() => navigate(`/home/user_lists/${userId}/view`)} className={UserListsStyles.link}>{firstName + " " + lastName}</Typography>
            </Box>
            <Box className={cellClassName}>
                <Typography>{emailAddress}</Typography>
            </Box>
            <Box className={cellClassName}>
                <Typography>{occupationName}</Typography>
            </Box>
            <Box className={cellClassName}>
                <Typography>{getSubCategoryName()}</Typography>
            </Box>
            <Box className={cellClassName}>
                <Typography>{roleName}</Typography>
            </Box>
            <Box className={cellClassName}>
                <AvantTooltip title="Edit" placement="top" arrow followCursor>
                    <img src="images/edit.svg" alt="Edit_Image" className={UserListsStyles.editImg} onClick={() => navigate(`/home/user_lists/${userId}`)} />
                </AvantTooltip>
                <AvantTooltip title="Delete" placement="top" arrow followCursor>
                    <img src="images/delete.svg" alt="Delete_Image" className={UserListsStyles.editImg} onClick={openDeleteUserDialog} />
                </AvantTooltip>
                {userListId === 1 ?
                    <AvantTooltip title={isDisabled === 1 ? "Disabled" : "Enabled"} placement="top" arrow followCursor>
                        <img src={isDisabled === 1 ? "images/user_disable.svg" : "images/user_enable.svg"} alt={isDisabled === 1 ? "Disable_Image" : "Enable_Image"} className={UserListsStyles.editImg} onClick={openStatusDialog} />
                    </AvantTooltip> : null}
                {userListId !== 1 ?
                    <AvantTooltip title="Approve" placement="top" arrow followCursor>
                        <img src="images/checkMark-01.svg" alt="Approved_Image" className={UserListsStyles.editImg} onClick={openApprovedUserDialog} />
                    </AvantTooltip> : null}
                {userListId === 0 ?
                    <AvantTooltip title="Reject" placement="top" arrow followCursor>
                        <img src="images/disable-01.svg" alt="Rejected_Image" className={UserListsStyles.editImg} onClick={openRejectedUserDialog} />
                    </AvantTooltip> : null}
            </Box>
        </Box>
    );
}

export default UserTableRow