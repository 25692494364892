import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../api";
import { LOGOUT, PASSWORD_CHANGE, USER_DETAILS } from "../../constants/api-constants";
import { PasswordDataProps } from "../../models/login.model";
import { ResponseModel, userDetailProps } from "../../models/patient.model";
import { ERROR_MESSAGE } from "../../constants/variable-constants";

const changePasswordCall = createAsyncThunk(
    'login/changePasswordCall',
    async (payload: PasswordDataProps, thunk) => {
        const body = {
            userName: payload.userName,
            oldPassword: payload.oldPassword,
            newPassword: payload.newPassword,
            fromEmail: 0
        }
        try {
            const response = await api.fetchAuthPostWithBody<any>(PASSWORD_CHANGE, body);
            return response.data
        } catch (error: any) {
            let errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

const getUserDetails = createAsyncThunk(
    'login/getUserDetails',
    async (payload: { userId: string | number }, thunk) => {
        try {
            const response = await api.fetchAuthGet<ResponseModel<userDetailProps>>(`${USER_DETAILS}${payload.userId}`);
            return response.data
        } catch (error: any) {
            let errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

const userLogout = createAsyncThunk(
    'login/userLogout',
    async (payload: { userId: string | number }, thunk) => {
        try {
            const response = await api.fetchAuthPutWithBody<any>(LOGOUT(payload?.userId!), {});
            return response.data
        } catch (error: any) {
            let errorMessage = error.response?.data?.message || ERROR_MESSAGE;
            return thunk.rejectWithValue(errorMessage)
        }
    }
)

export const AuthThunkApis = { changePasswordCall, getUserDetails, userLogout }