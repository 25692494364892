import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import MySidebar from "./sidebar/Sidebar";
import MyHeader from "./header/Header";
import Loader from "./loader/Loader";
import { sessionStorageGet, sessionStorageSet } from "./session-storage-helpers/session-storage-actions";
import { useAppDispatch, useAppSelector } from "../redux/Hooks";
import { AuthThunkApis } from "../redux/actions/login-actions";
import { AuthSliceActions } from "../redux/reducers/login-slice";
import { SESSION_KEYS, pathMap } from "../constants/variable-constants";

const LayoutWithHeaderSidebar = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { isLoggedIn } = useAppSelector(state => state.auth);
    const { isMenuOpen } = useAppSelector(state => state.users);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleUnauthenticatedUser = () => {
            const token = sessionStorageGet(SESSION_KEYS.TOKEN);
            const userId = sessionStorageGet(SESSION_KEYS.USER_ID);
            if (token) {
                dispatch(AuthThunkApis.getUserDetails({ userId }));
            } else {
                // trying to access authenticated pages without authenticating
                const isRedirect = queryParams.get('redirect');
                const page = queryParams.get('page') || '';
                // when an user clicks an external link (eg: a link from email - the link have redirect=true query param)
                // in such case set the redirect url, and re-directs the user automatically to that path after login
                if (isRedirect === 'true') {
                    // store the url in storage
                    sessionStorageSet(SESSION_KEYS.REDIRECT_URL, location.pathname);
                }
                if (page) {
                    sessionStorageSet(SESSION_KEYS.LAST_VISITED_PAGE, page);
                }
                navigate('/');
            }
        };

        if (!isLoggedIn) {
            handleUnauthenticatedUser();
        }

    }, [isLoggedIn, navigate, dispatch]);

    useEffect(() => {
        const updateCurrentPath = () => {
            const pathname = location.pathname;
            let updated = false;

            // when we get a patient Id into the URL alone
            const patientEditPattern = /home\/patient_lists\/\d+/;
            // when we get a patient Id into the URL with view
            const patientDetailsPattern = /^\/home\/patient_lists\/\d+\/view$/;

            // when we get a user Id into the URL alone
            const userEditPattern = /home\/user_lists\/\d+/;
            // when we get a user Id into the URL with view
            const userDetailsPattern = /^\/home\/user_lists\/\d+\/view$/;

            // when we get a practice Id into the URL alone
            const practiceEditPattern = /home\/practice_lists\/\d+/;

            if (patientEditPattern.test(pathname)) {
                dispatch(AuthSliceActions.patchState({ currentPath: 'Edit Patient' }));
                updated = true;
            }

            if (patientDetailsPattern.test(pathname)) {
                dispatch(AuthSliceActions.patchState({ currentPath: 'Patient Details' }));
                updated = true;
            }

            if (userEditPattern.test(pathname)) {
                dispatch(AuthSliceActions.patchState({ currentPath: 'Edit User' }));
                updated = true;
            }

            if (userDetailsPattern.test(pathname)) {
                dispatch(AuthSliceActions.patchState({ currentPath: 'Doctor Detail' }));
                updated = true;
            }

            if (practiceEditPattern.test(pathname)) {
                dispatch(AuthSliceActions.patchState({ currentPath: 'Edit Practice' }));
                updated = true;
            }

            if (!updated) {
                for (const [key, value] of Object.entries(pathMap || {})) {
                    if (pathname.includes(key)) {
                        dispatch(AuthSliceActions.patchState({ currentPath: value }));
                        break;
                    }
                }
            }
        };

        updateCurrentPath();
    }, [location.pathname, dispatch]);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      let deviceName;
      if(windowWidth <= 768) {
        deviceName = 'mobile';
      } else if (windowWidth > 768 && windowWidth <= 1024) {
        deviceName = 'tablet';
      } else {
        deviceName = 'desktop';
      }

    return (
        isLoggedIn ?
            <Box className={`mainWrap ${deviceName}`}>
                <MySidebar />
                <div>
                    <MyHeader />
                    <div className='component-container'>
                        <Outlet />
                    </div>
                </div>
            </Box> : <Loader />
    );
}

export default LayoutWithHeaderSidebar