import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Box, Dialog, DialogContent, DialogContentText, IconButton, TextField, Typography,
    Grid, FormControl, Autocomplete, Chip, Popper, FormHelperText, Button
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useFormik } from "formik";
import * as Yup from "yup";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, RawDraftContentState, convertToRaw } from 'draft-js';
import { LoadingButton } from '@mui/lab';
import draftToHtml from "draftjs-to-html";
import { stateFromHTML } from 'draft-js-import-html';

import { useAppDispatch, useAppSelector } from '../../../redux/Hooks';
import { sentMailProps } from '../../../models/user.model';
import { UserSliceActions, userSelectors } from '../../../redux/reducers/user-slice';
import api from '../../../api';
import { ResponseModel } from '../../../models/patient.model';
import { SEND_MAIL_BY_FILTER } from '../../../constants/api-constants';
import { ADMIN_MAIL, DRAFT_MAIL, EMAIL_SEND, MAX_ATTACHMENT_SIZE_BYTES, MAX_ATTACHMENT_SIZE_MB, httpStatusCode } from '../../../constants/variable-constants';
import { ToastAlert } from '../../../components/toast-alert';
import { handleErrorMsg, uniqueEmailValidation } from '../../../components/utils';
import { MailTunkApis } from '../../../redux/actions/mail-actions';
import { MailSliceActions } from '../../../redux/reducers/mail-slice';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

// Editor styles
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js/dist/Draft.css';

import UsersListStyles from "../UserLists.module.css";
interface emailTemplateDialogProps {
    viewMailId?: number,
    screening?: string,
    userMails?: string[],
    closeMailDialog: () => void
}

const EmailTemplateDialog = ({ viewMailId, screening, userMails, closeMailDialog }: emailTemplateDialogProps) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [openDiscardOrDraftModel, setOpenDiscardOrDraftModel] = useState<boolean>(false);
    const [showMails, setShowMails] = useState<Record<string, boolean>>({
        CCMails: false,
        BCCMails: false
    });
    const [attchmentErrMsg, setAttachmentErrMsg] = useState<string | null>(null);
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
    const [contentState, setContentState] = useState<RawDraftContentState | null>(null);
    const [attachments, setAttachments] = useState<File[]>([]);

    const inputRef = useRef<HTMLInputElement | null>(null);

    const dispatch = useAppDispatch();

    const { occupationId, subcategoryId, roleId, practiceNameId } = useAppSelector(state => state.users! || {});
    const { emailDetails } = useAppSelector(state => state.mails! || {});

    const usersData = useAppSelector(userSelectors.selectAll);

    const isDisabled = !!viewMailId && screening !== DRAFT_MAIL;

    useEffect(() => {
        if (!viewMailId) {
            return;
        }
        dispatch(MailTunkApis.getMailDetails({ id: viewMailId! }));
    }, [viewMailId]);

    const initialValues: sentMailProps = {
        fromEmail: ADMIN_MAIL,
        subject: "",
        message: "",
        toEmails: userMails || [],
        cc: [],
        bcc: []
    }

    const validationSchema = Yup.object().shape({
        fromEmail: Yup.string().required("Sender mail is required"),
        subject: Yup.string().required("Subject is required"),
        toEmails: Yup.array()
            .min(1, "At least one recipient is required")
            .of(Yup.string().email("Invalid email format")),
        cc: Yup.array().of(Yup.string().email("Invalid email format")),
        bcc: Yup.array().of(Yup.string().email("Invalid email format")),
    }).test('unique-email', null as any, function (values) {
        const error = uniqueEmailValidation(values);
        if (error) {
            return this.createError({ path: error.field, message: error.message });
        }
        return true;
    });

    const checkAttachmentLimits = (): boolean => {
        const currentTotalSize = attachments.reduce((acc, file) => acc + file.size, 0);
        return currentTotalSize > MAX_ATTACHMENT_SIZE_BYTES;
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            if (checkAttachmentLimits()) {
                setAttachmentErrMsg(`Total attachment size exceeds the ${MAX_ATTACHMENT_SIZE_MB} MB limit.`);
                return;
            }

            submitFormData(values);
        },
    });

    const submitFormData = async (values: any) => {
        const formData = new FormData();

        formData.append('emailDetails', JSON.stringify({
            fromEmail: values.fromEmail!,
            subject: values.subject!,
            message: values.message || "",
            toEmails: values.toEmails,
            cc: values.cc,
            bcc: values.bcc,
            isDraft: !!openDiscardOrDraftModel ? "true" : "false",
            ...(screening === DRAFT_MAIL ? { draftId: viewMailId } : {})
        }));

        formData.append('filters', JSON.stringify({
            ...(occupationId ? { occupationId } : {}),
            ...(subcategoryId ? { subcategoryId } : {}),
            ...(roleId ? { roleId } : {}),
            ...(practiceNameId ? { practiceNameId } : {})
        }));

        const deSelelctedUsers = usersData?.filter(user => !values.toEmails.includes(user.emailAddress!) && !values.cc.includes(user.emailAddress!) && !values.bcc.includes(user.emailAddress!))?.map(item => item.emailAddress);

        formData.append('deselecteduser', JSON.stringify(deSelelctedUsers));

        attachments.forEach((attachment) => {
            formData.append('attachments', attachment);
        });

        setLoading(true);
        try {
            const response = await api.fetchAuthPostImageWithBody<ResponseModel<{}>>(SEND_MAIL_BY_FILTER, formData);
            if (response?.status === httpStatusCode.SUCCESS) {
                const successMessage = !!openDiscardOrDraftModel ? EMAIL_SEND.DRAFT : EMAIL_SEND.SUCCESS;
                ToastAlert(successMessage, "success");

                if (screening === DRAFT_MAIL && !!viewMailId) {
                    dispatch(MailSliceActions.removeMail({ id: viewMailId! }));
                }
                handleDiscard();
                dispatch(UserSliceActions.patchState({ selectedUserMails: [] }));
            } else {
                throw new Error(EMAIL_SEND.FAILED);
            }
        } catch (error: any) {
            handleErrorMsg(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (emailDetails) {
            const parsedToEmails = JSON.parse(emailDetails.ToEmails || '[]');
            const parsedCcEmails = JSON.parse(emailDetails.CcEmails || '[]');
            const parsedBccEmails = JSON.parse(emailDetails.BccEmails || '[]');
            const parsedAttachments = JSON.parse(emailDetails.Attachments || '[]');

            formik.setValues({
                fromEmail: ADMIN_MAIL,
                subject: emailDetails.Subject || '',
                message: emailDetails.Message || '',
                toEmails: parsedToEmails,
                cc: parsedCcEmails,
                bcc: parsedBccEmails
            });

            const filesArray = parsedAttachments.map((attachment: any) => {
                const byteString = atob(attachment.content);
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                const blob = new Blob([ab], { type: attachment.type });
                return new File([blob], attachment.filename);
            });

            setAttachments(filesArray);

            // Convert HTML string to ContentState
            const contentStateFromHTML = stateFromHTML(emailDetails.Message || '');
            const editorState = EditorState.createWithContent(contentStateFromHTML);

            setEditorState(editorState);
            setContentState(convertToRaw(contentStateFromHTML));

            // Open CC and/or BCC fields if they have values
            setShowMails({
                CCMails: parsedCcEmails.length > 0,
                BCCMails: parsedBccEmails.length > 0
            });
        }
    }, [emailDetails]);

    const uploadClickHandler = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
            inputRef.current.click();
        }
    };

    const resetMailFields = useCallback(() => {
        if (!showMails.CCMails) {
            formik.setFieldValue('cc', []);
        }
        if (!showMails.BCCMails) {
            formik.setFieldValue('bcc', []);
        }
    }, [showMails.CCMails, showMails.BCCMails]);

    useEffect(() => {
        resetMailFields();
    }, [resetMailFields]);

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleToMailsChange = (event: React.SyntheticEvent<Element, Event>, value: string[]) => {
        const validEmails = value.filter((email) => validateEmail(email));
        formik.setFieldValue('toEmails', validEmails);
        dispatch(UserSliceActions.patchState({ selectedUserMails: validEmails }));
        const error = uniqueEmailValidation({ ...formik.values, toEmails: validEmails });
        if (error) formik.setErrors({ [error.field]: error.message });
    };

    const handleCCMailsChange = (event: React.SyntheticEvent<Element, Event>, value: string[]) => {
        const validEmails = value.filter((email) => validateEmail(email));
        formik.setFieldValue('cc', validEmails);
        const error = uniqueEmailValidation({ ...formik.values, cc: validEmails });
        if (error) formik.setErrors({ [error.field]: error.message });
    };

    const handleBCCMailsChange = (event: React.SyntheticEvent<Element, Event>, value: string[]) => {
        const validEmails = value.filter((email) => validateEmail(email));
        formik.setFieldValue('bcc', validEmails);
        const error = uniqueEmailValidation({ ...formik.values, bcc: validEmails });
        if (error) formik.setErrors({ [error.field]: error.message });
    };

    const handleAddTypes = (type: 'CCMails' | 'BCCMails') => {
        setShowMails({ ...showMails, [type]: !showMails[type] });
    }

    const handleAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const filesArray = Array.from(event.target.files);
            setAttachments((prevAttachments) => [...prevAttachments, ...filesArray]);
        }
    };

    const handleRemoveAttachment = (fileName: string) => {
        setAttachments((prevAttachments) =>
            prevAttachments.filter((file) => file.name !== fileName)
        );
        setAttachmentErrMsg(null);
    };

    const handleEditorChange = (editorState: EditorState) => {
        setEditorState(editorState);

        // Convert editor state to raw content state
        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);

        // Convert raw content state to HTML
        const htmlContent = draftToHtml(rawContentState);

        // Set the HTML content in Formik
        formik.setFieldValue('message', htmlContent);
    };

    const handleContentChange = (contentState: RawDraftContentState) => {
        setContentState(contentState);
    };

    const handleClose = () => {
        if (!!viewMailId) {
            handleDiscard();
        } else if (formik.dirty || !!attachments) { //Form values have changed or have a value of attachments
            setOpenDiscardOrDraftModel(true);
        } else {
            closeMailDialog();
        }
    }

    const handleDraftSave = () => {
        submitFormData(formik.values);
    };

    const handleDiscard = () => {
        formik.resetForm();
        setAttachments([]);
        closeMailDialog();
        dispatch(MailSliceActions.patchState({ emailDetails: null }));
        setOpenDiscardOrDraftModel(false);
    };

    return (
        <>
            <Dialog
                fullWidth
                className='emailDialog'
                maxWidth="md"
                open={true}
                onClose={handleDiscard}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    '& .MuiDialog-paper': {
                      width: '100%',
                      maxWidth: 'md',
                      '@media (max-width: 480px)': {
                        width: '100%',
                        maxWidth: '100%',
                        margin: '10px'
                      }
                    }
                  }}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 2,
                        top: 2
                    }}
                >
                    <CancelIcon color="primary" />
                </IconButton>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box display={"flex"} flexDirection={"column"} gap={2} className={UsersListStyles.mailTemplate}>
                            <Grid container>
                                <Grid item xs={12} md={12}   sx={{paddingX: {md: 3, xs: 1}, marginBottom: {md: 3, xs: 3}}}>
                                    <Typography className={UsersListStyles.label}>From</Typography>
                                    <TextField
                                        id="fromEmail"
                                        name="fromEmail"
                                        variant="standard"
                                        value={formik.values.fromEmail}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled
                                        error={formik.touched.fromEmail && Boolean(formik.errors.fromEmail)}
                                        helperText={formik.touched.fromEmail && formik.errors.fromEmail}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{paddingX: {md: 3, xs: 1}, marginBottom: {md: 3, xs: 3}}}>
                                    <Typography className={UsersListStyles.label}>To</Typography>
                                    <Box sx={{gap: {md: '10px', xs: '15px'}, display: 'flex', alignItems: 'center', justifyContent: {xs: 'center', md: 'flex-end'}, flexWrap: {xs: 'wrap', md: 'nowrap'}}}>
                                        <FormControl variant="standard" fullWidth
                                            error={formik.touched.toEmails && Boolean(formik.errors.toEmails)}>
                                            <Autocomplete
                                                multiple
                                                freeSolo
                                                disabled={isDisabled}
                                                options={usersData?.map(user => user.emailAddress!) || []}
                                                getOptionLabel={(option) => option}
                                                value={formik.values.toEmails}
                                                onChange={handleToMailsChange}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="standard" />
                                                )}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            label={option}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                PopperComponent={(props) => (
                                                    <Popper {...props} placement="bottom-start" style={{ zIndex: 1300 }} />
                                                )}
                                            />
                                            {formik.touched.toEmails && formik.errors.toEmails && (
                                                <FormHelperText>{formik.errors.toEmails}</FormHelperText>
                                            )}
                                        </FormControl>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            disabled={isDisabled}
                                            startIcon={!!showMails['CCMails'] ? <RemoveIcon /> : <AddIcon />}
                                            onClick={() => handleAddTypes('CCMails')}
                                            sx={{
                                                padding: "6px 11px",
                                                minWidth: {xs: '100px', md: '70px'},
                                                textTransform: "capitalize",
                                                borderRadius: "100px"
                                            }}>
                                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>CC</Typography>
                                        </Button>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            disabled={isDisabled}
                                            startIcon={!!showMails['BCCMails'] ? <RemoveIcon /> : <AddIcon />}
                                            onClick={() => handleAddTypes('BCCMails')}
                                            sx={{
                                                padding: "6px 11px",
                                               minWidth: {xs: '100px', md: '70px'},
                                                textTransform: "capitalize",
                                                borderRadius: "100px"
                                            }}>
                                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>BCC</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                                {!!showMails['CCMails'] ?
                                    <Grid item xs={12} md={12} sx={{paddingX: {md: 3, xs: 1}, marginBottom: {md: 3, xs: 3}}}>
                                        <Typography className={UsersListStyles.label}>CC Emails</Typography>
                                        <FormControl variant="standard" fullWidth
                                            error={formik.touched.cc && Boolean(formik.errors.cc)}>
                                            <Autocomplete
                                                multiple
                                                freeSolo
                                                disabled={isDisabled}
                                                options={usersData?.map(user => user.emailAddress!) || []}
                                                getOptionLabel={(option) => option}
                                                value={formik.values.cc}
                                                onChange={handleCCMailsChange}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="standard" />
                                                )}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            label={option}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                PopperComponent={(props) => (
                                                    <Popper {...props} placement="bottom-start" style={{ zIndex: 1300 }} />
                                                )}
                                            />
                                            {formik.touched.cc && formik.errors.cc && (
                                                <FormHelperText>{formik.errors.cc}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid> : null}
                                {showMails['BCCMails'] ?
                                    <Grid item xs={12} md={12} sx={{paddingX: {md: 3, xs: 1}, marginBottom: {md: 3, xs: 3}}}>
                                        <Typography className={UsersListStyles.label}>BCC Emails</Typography>
                                        <FormControl variant="standard" fullWidth
                                            error={formik.touched.bcc && Boolean(formik.errors.bcc)}>
                                            <Autocomplete
                                                multiple
                                                freeSolo
                                                disabled={isDisabled}
                                                options={usersData?.map(user => user.emailAddress!) || []}
                                                getOptionLabel={(option) => option}
                                                value={formik.values.bcc}
                                                onChange={handleBCCMailsChange}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="standard" />
                                                )}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            label={option}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                PopperComponent={(props) => (
                                                    <Popper {...props} placement="bottom-start" style={{ zIndex: 1300 }} />
                                                )}
                                            />
                                            {formik.touched.bcc && formik.errors.bcc && (
                                                <FormHelperText>{formik.errors.bcc}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid> : null}
                                <Grid item xs={12} md={12} sx={{paddingX: {md: 3, xs: 1}, marginBottom: {md: 3, xs: 3}}}>
                                    <Typography className={UsersListStyles.label}>Subject</Typography>
                                    <TextField
                                        id="subject"
                                        name="subject"
                                        disabled={isDisabled}
                                        placeholder="Please enter a subject name here"
                                        variant="standard"
                                        value={formik.values.subject}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.subject && Boolean(formik.errors.subject)}
                                        helperText={formik.touched.subject && formik.errors.subject}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{paddingX: {md: 3, xs: 1}, marginBottom: {md: 3, xs: 3}}}>
                                    <Editor
                                        readOnly={isDisabled}
                                        editorState={editorState}
                                        wrapperClassName={UsersListStyles.wrapperClass}
                                        editorClassName={UsersListStyles.editorClass}
                                        toolbarStyle={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }}
                                        onEditorStateChange={handleEditorChange}
                                        onContentStateChange={handleContentChange}
                                        toolbar={{ options: ['inline', 'fontSize', 'list', 'textAlign',] }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{paddingX: {md: 3, xs: 1}, marginBottom: {md: 3, xs: 3}, display: 'flex', flexDirection: 'column', alignItems: {xs:'center', md: 'flex-start'}, justifyContent: {xs:'center', md: 'flex-start'}}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isDisabled}
                                        startIcon={<AddIcon />}
                                        onClick={uploadClickHandler}
                                        sx={{
                                            padding: "6px 11px",
                                            minWidth: "120px",
                                            textTransform: "capitalize",
                                            borderRadius: "100px"
                                        }}
                                    >
                                        <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Add attachments</Typography>
                                    </Button>
                                    <input
                                        type="file"
                                        ref={inputRef}
                                        style={{ display: "none" }}
                                        multiple
                                        onChange={handleAttachmentChange}
                                    />
                                    <Box mt={2} gap={1} display={"flex"} flexWrap={"wrap"}>
                                        {attachments.map((file) => (
                                            <Chip
                                                key={file.name}
                                                label={file.name}
                                                disabled={isDisabled}
                                                onDelete={() => handleRemoveAttachment(file.name)}
                                            />
                                        ))}
                                    </Box>
                                    {!!attchmentErrMsg && <Typography className={UsersListStyles.errorMsg}>{attchmentErrMsg}</Typography>}
                                </Grid>
                            </Grid>
                            {!viewMailId || screening === DRAFT_MAIL ?
                                <Box m={2} sx={{display: 'flex', alignItems: 'center',  justifyContent: {md: 'flex-end', xs: 'center'}}}>
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        loading={isLoading && !openDiscardOrDraftModel}
                                        onClick={formik.handleSubmit as any}
                                        sx={{
                                            padding: "6px 11px",
                                            minWidth: "100px",
                                            textTransform: "capitalize",
                                            borderRadius: "100px",
                                        }}
                                    >
                                        <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Send</Typography>
                                    </LoadingButton>
                                </Box> : null}
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <ConfirmationDialog
                open={!!openDiscardOrDraftModel}
                accept={handleDraftSave}
                reject={handleDiscard}
                msg={"Are you sure you want to discard or save this draft?"}
                acceptText={"Save Draft"}
                rejectText={"Discard"}
                isLoading={isLoading && !!openDiscardOrDraftModel}
                handleOnClose={() => setOpenDiscardOrDraftModel(false)}
                isDraftPopUp={true}
            />
        </>
    );
};

export default EmailTemplateDialog;