// Set item
export const sessionStorageSet = (key: string, val: any) => sessionStorage.setItem(key, val);

// Get item
export const sessionStorageGet = (key: string) => {
    const get = sessionStorage.getItem(key);
    if (get === "true" || get === "false") return JSON.parse(get);
    else return get;
}

// Remove item
export const sessionStorageRemove = (key: string) => sessionStorage.removeItem(key);

// Clear all
export const sessionStorageClear = () => sessionStorage.clear();